import React from 'react';
import ReactDOM from 'react-dom';
import { rootStore, StoreProvider } from './model/Root';
import './index.css';
import App from './components/App';
import * as serviceWorker from './utils/serviceWorker';

const rootNode = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider value={rootStore}>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  rootNode
);

serviceWorker.unregister();
