import { User } from 'types';
import { UserRole } from './constants';

export const getUserRole = (user: User): UserRole => {
  if (user.admin) return UserRole.admin;
  if (user.employee) return UserRole.employee;
  if (user.publisher) return UserRole.publisher;
  if (user.creator) return UserRole.creator;

  return UserRole.portal;
};

export const getUserListRole = (user: User): UserRole => {
  if (user.employee) return UserRole.employee;
  if (user.admin || user.publisher || user.creator) return UserRole.admin;

  return UserRole.portal;
};

export const getUserUrlByLocation = (pathname: string): string => {
  const isAdmin = pathname.includes('admin');
  const isEmployee = pathname.includes('emp');
  const isPortal = pathname.includes('portal');

  if (isPortal) {
    return '/portal-users';
  } else if (isEmployee) {
    return '/emp-users';
  } else if (isAdmin) {
    return '/admin-users';
  }

  return '/'; // fallback
};

export const getUserTitleByLocation = (pathname: string): string => {
  let key = 'users'; // fallback
  const isAdmin = pathname.includes('admin');
  const isEmployee = pathname.includes('emp');
  const isPortal = pathname.includes('portal');

  if (isEmployee) key = 'employee_users';
  if (isPortal) key = 'portal_users';
  if (isAdmin) key = 'admin_users';

  return key;
};

export const getUserRoleByLocation = (pathname: string): UserRole => {
  const isAdmin = pathname.includes('admin');
  const isEmployee = pathname.includes('emp');
  const isPortal = pathname.includes('portal');

  if (isEmployee) return UserRole.employee;
  if (isPortal) return UserRole.portal;
  if (isAdmin) return UserRole.admin;

  return UserRole.portal; // fallback
};
