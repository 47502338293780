import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { CircularProgress } from '@mui/material';
import { useMst } from '../../model/Root';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Layout from '../../components/Layout';
import Table from '../../components/Table/Table';
import AddButton from '../../components/Button/AddButton';
import { useTranslation } from 'react-i18next';
import { NESLETTER_TABLE } from '../../utils/constants';
import { Community, LinkRow, Row } from '../../types';
import { color } from '../../theme';
import AlertModal from '../../components/AlertModal';

const useStyles = makeStyles()({
  infoText: {
    width: '100%',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'normal',
    color: color.black,
  },
});

const NewspaperScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    newspaperStore: { newspapers, getAllNewspapers, loading, deleteNewspaper },
    userStore: { communities, user, getCommunities },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow>();
  const [result, setResult] = useState<Row[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const filterNewspapers = useCallback(
    (communities: Community[], ownCommunities: Community[]) => {
      const filtered = communities.filter((community) => {
        return ownCommunities.some((el) => {
          return el.id === community.id;
        });
      });
      const filteredArticles = newspapers.filter((newspaper) => {
        return filtered.find(({ community }) => community === newspaper.publisher);
      });
      return filteredArticles;
    },
    [newspapers]
  );

  useEffect(() => {
    const getNewspapers = async () => {
      await getAllNewspapers();
      await getCommunities();
      if (user?.admin || user?.publisher) {
        const tempResult: Row[] =
          newspapers.length > 0
            ? newspapers.map((newspaper) => ({
                id: newspaper.id,
                name: newspaper.name,
                value: `${newspaper.name} - ${newspaper.number}`,
                secondValue: newspaper.published ? t('published') : t('draft'),
              }))
            : [];

        setResult(tempResult);
      } else {
        if (communities && user?.communities.length) {
          const filtered = filterNewspapers(communities, user.communities);
          const tempResult: Row[] =
            filtered && filtered.length > 0
              ? filtered.map((newspaper) => ({
                  id: newspaper.id,
                  name: newspaper.name,
                  value: `${newspaper.name} - ${newspaper.number}`,
                  secondValue: newspaper.published ? t('published') : t('draft'),
                }))
              : [];
          setResult(tempResult);
        }
      }
    };

    getNewspapers();
  }, [getAllNewspapers, getCommunities, user, communities, loading, filterNewspapers, newspapers, t]);

  useEffect(() => {
    if (!selectedRow?.id || modalOpen) return;
    navigate(`/newspaper/${selectedRow.id}`);
  }, [selectedRow?.id, navigate, modalOpen]);

  const navigateTo = () => {
    navigate('/newspaper/create');
  };

  const deletePage = async () => {
    if (selectedRow?.id) {
      await deleteNewspaper(selectedRow.id.toString());
      await getAllNewspapers();
      const tempResult: Row[] =
        newspapers.length > 0
          ? newspapers.map((newspaper) => ({
              id: newspaper.id,
              name: newspaper.name,
              value: `${newspaper.name} - ${newspaper.number}`,
              secondValue: newspaper.published ? t('published') : t('draft'),
            }))
          : [];

      setResult(tempResult);
    }
    setModalOpen(false);
  };

  const toggleDelete = (id: number) => {
    setModalOpen(true);
    setSelectedRow({ id: id });
  };

  const cancel = () => {
    setSelectedRow(undefined);
    setModalOpen(false);
  };

  if (loading) {
    return (
      <Layout title={t('newspaper_loading')}>
        <CircularProgress size={40} style={{ alignSelf: 'center', marginTop: 30, color: color.primaryButton }} />
        <p className={classes.infoText}>{t('newspaper_loading_text')}</p>
      </Layout>
    );
  }

  return (
    <Layout title={t('newspaper')}>
      <AddButton onClick={navigateTo} text={t('add_newspaper')} />
      <Table
        tableType={NESLETTER_TABLE}
        data={result}
        setSelectedRow={(value) => setSelectedRow({ id: value?.id, value: value?.value })}
        selectedRow={selectedRow}
        onDelete={(id) => toggleDelete(id)}
      />
      <AlertModal
        open={modalOpen}
        title="delete_newspaper"
        onClose={() => setModalOpen(!modalOpen)}
        leftButtonPress={cancel}
        rightButtonPress={deletePage}
      />
    </Layout>
  );
});

export default NewspaperScreen;
