import { DigiMagazine, DigiMagazineFile } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  digiMagazines: {
    root: '/digiMagazines',
    id: '/digiMagazines/{id}',
  },
});

// DigiMagazine
export const getDigiMagazines = (): Promise<DigiMagazine[]> => api.get(API_PATHS.digiMagazines.root, {});

export const getSingleDigiMagazine = (id: string): Promise<DigiMagazine> =>
  api.get(API_PATHS.digiMagazines.id.replace('{id}', id), {});

export const addSingleDigiMagazine = (
  digiMagazine: DigiMagazine,
  digiMagazineFiles: DigiMagazineFile[],
  files: File[],
  imageFile?: File
): Promise<DigiMagazine> => {
  const formData = new FormData();

  digiMagazineFiles?.forEach((digiMagazineFile) =>
    formData.append('digiMagazineFiles', JSON.stringify(digiMagazineFile))
  );
  files?.forEach((file) => {
    formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  });

  Object.keys(digiMagazine)
    .filter((k) => k !== 'digiMagazineFiles')
    .forEach((key) => {
      formData.append(key, digiMagazine[key as keyof DigiMagazine]?.toString() ?? '');
    });

  if (imageFile) formData.append('image', imageFile ?? digiMagazine.image);

  return api.post(API_PATHS.digiMagazines.root, formData, { timeout: 360000 });
};

export const updateSingleDigiMagazine = (
  digiMagazine: DigiMagazine,
  digiMagazineFiles: DigiMagazineFile[],
  files: File[],
  imageFile?: File
): Promise<DigiMagazine> => {
  const formData = new FormData();

  digiMagazineFiles?.forEach((digiMagazineFile) =>
    formData.append('digiMagazineFiles', JSON.stringify(digiMagazineFile))
  );
  files?.forEach((file) => {
    formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  });

  Object.keys(digiMagazine)
    .filter((k) => k !== 'files')
    .filter((k) => k !== 'digiMagazineFiles')
    .forEach((key) => {
      const value = digiMagazine[key as keyof DigiMagazine];
      value && formData.append(key, digiMagazine[key as keyof DigiMagazine]?.toString() ?? '');
    });

  const imageUpdated = formData.get('image') && !imageFile;
  if (!imageUpdated && imageFile) formData.append('image', imageFile);

  return api.put(API_PATHS.digiMagazines.id.replace('{id}', `${digiMagazine.id}`), formData, { timeout: 360000 });
};

export const deleteSingleDigiMagazine = (id: string): Promise<void> => {
  return api.delete(API_PATHS.digiMagazines.id.replace('{id}', id), {});
};
