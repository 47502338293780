export enum ArticleTypes {
  Undefined = 'Undefined',
  BigStory = 'BigStory',
  ImageStory = 'ImageStory',
  SmallStory = 'SmallStory',
  SmallTextStory = 'SmallTextStory',
  BookReview = 'BookReview',
  Column = 'Column',
  Opinion = 'Opinion',
  PointOfView = 'PointOfView',
  QuickVisit = 'QuickVisit',
  ChurchVisit = 'ChurchVisit',
  Editorial = 'Editorial',
  GuestPen = 'GuestPen',
  MostRedArticles = 'MostRedArticles',
  LatestArticles = 'LatestArticles',
  KeywordArticles = 'KeywordArticles',
  SpeakingEngagements = 'SpeakingEngagements',
  Blog = 'Blog',
}

export enum ArticleTypesEV {
  Undefined = 'Undefined',
  EditorialEV = 'EditorialEV',
  News = 'News',
  Topical = 'Topical',
  ColumnEV = 'ColumnEV',
  International = 'International',
  ChangeStory = 'ChangeStory',
  EverydayHero = 'EverydayHero',
  MyStory = 'MyStory',
  ExpertsOpinion = 'ExpertsOpinion',
  YouthPerspective = 'YouthPerspective',
  Teaching = 'Teaching',
  CoWorkers = 'CoWorkers',
  Anniversary = 'Anniversary',
  Obituary = 'Obituary',
  MostRedArticlesEV = 'MostRedArticlesEV',
  LatestArticlesEV = 'LatestArticlesEV',
  MainArticles = 'MainArticles',
}
