import React, { FC } from 'react';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageNotFound: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <Grid container>
        <Typography>{t('Common_PageNotFound')} </Typography>
      </Grid>
      <Box m={2} />
      <Paper elevation={4} style={{ padding: 24, height: 250 }}>
        <Grid container spacing={3}>
          <Grid item container justifyContent="center">
            <Typography variant="h5">{t('Common_NotFoundInfoText')}</Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Button variant="contained" color="secondary" onClick={() => navigate('/')}>
              {t('Common_ReturnToDashboard')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default PageNotFound;
