import { flow, types, Instance } from 'mobx-state-tree';
import {
  fetchMe,
  fetchUsers,
  getSingleUser,
  createSingleUser,
  updateSingleUser,
  deleteSingleUser,
  changeUserPassword,
  uploadUsersFromFile,
  fetchCommunities,
  getSingleCommunity,
  createSingleCommunity,
  updateSingleCommunity,
  deleteSingleCommunity,
  login,
  logout,
  resetUserPassword,
  forgotUserPassword,
} from '../services/api';
import { getUserStripeSubscriptions, getSubscriptionProducts } from '../services/subscriptionApi';
import { readStorage, writeStorage, deleteStorage } from '../utils/storage';
import { composeError } from '../utils/transforms';
import { withRootStore } from './withRootStore';
import { Community, LoginResponse, StripeSubscription, Subscription, User } from '../types';
import { UserRole } from '../utils/constants';
import { getUserListRole } from 'utils/userUtils';

export const CommunityModel = types.model({
  id: types.number,
  community: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
});

export const SubscriptionTypeModel = types.model({
  id: types.number,
  name: types.string,
  active: types.boolean,
  type: types.number,
  description: types.maybeNull(types.string),
  price: types.number,
});

export const SubscriptionModel = types.model({
  id: types.number,
  valid: types.boolean,
  periodStarts: types.string,
  periodEnds: types.maybeNull(types.string),
  ownerId: types.maybeNull(types.number),
  subscriptionCode: types.maybeNull(types.string),
  subscriptionType: types.maybeNull(SubscriptionTypeModel),
  paymentType: types.maybeNull(types.number),
  supportCode: types.maybeNull(types.string),
  discountCode: types.maybeNull(types.string),
});

export const SubscriptionStripeModel = types.model({
  id: types.string,
  status: types.string,
  currentPeriodStart: types.optional(types.string, ''),
  currentPeriodEnd: types.optional(types.string, ''),
  interval: types.optional(types.string, ''),
  amount: types.maybeNull(types.number),
  subscriptionCode: types.maybeNull(types.string),
  subscriptionId: types.maybeNull(types.string),
  priceId: types.maybeNull(types.string),
  productId: types.maybeNull(types.string),
  paymentType: types.maybeNull(types.number),
  supportCode: types.maybeNull(types.string),
  discountCode: types.maybeNull(types.string),
});

export const UserModel = types.model('UserModel', {
  id: types.number,
  email: types.string,
  firstname: types.string,
  lastname: types.string,
  address: types.optional(types.string, ''),
  postalcode: types.optional(types.string, ''),
  city: types.optional(types.string, ''),
  country: types.optional(types.string, ''),
  phone: types.optional(types.string, ''),
  admin: types.maybeNull(types.boolean),
  publisher: types.maybeNull(types.boolean),
  creator: types.maybeNull(types.boolean),
  employee: types.maybeNull(types.boolean),
  portal: types.maybeNull(types.boolean),
  communities: types.optional(types.array(CommunityModel), []),
  subscriptions: types.optional(types.array(SubscriptionModel), []),
  newsletter: types.maybeNull(types.boolean),
  empNewsletter: types.maybeNull(types.boolean),
  permission: types.maybeNull(types.boolean),
  subscriber: types.maybeNull(types.boolean),
  tester: types.boolean,
  customerId: types.maybeNull(types.string),
  emailVerified: types.maybeNull(types.boolean),
});

const ProductModel = types.model('ProductModel', {
  id: types.string,
  name: types.string,
  description: types.maybeNull(types.string),
  price_month: types.number,
  price_month_id: types.string,
  price_year: types.number,
  price_year_id: types.string,
  active: types.boolean,
});

export type ProductModelType = Instance<typeof ProductModel>;

export const UserStore = types
  .model({
    state: types.maybeNull(types.enumeration(['LOGGED_OUT', 'LOGGED_IN', 'IN_PROGRESS', 'ERROR'])),
    token: types.optional(types.maybeNull(types.string), readStorage('AUTH_TOKEN')),
    user: types.frozen(),
    error: types.maybeNull(types.frozen()),
    data: types.optional(types.array(UserModel), []),
    users: types.optional(types.array(UserModel), []),
    singleUser: types.maybeNull(UserModel),
    communities: types.optional(types.array(CommunityModel), []),
    stripeSubscriptions: types.optional(types.array(SubscriptionStripeModel), []),
    products: types.optional(types.array(ProductModel), []),
  })
  .extend(withRootStore)
  .actions((self) => {
    const userLogin = flow(function* ({ email, password }) {
      try {
        const response: LoginResponse | undefined = yield login(email, password);
        if (response?.token) {
          self.token = response.token;
          self.user = {
            ...response.user,
            communities: response.user.communities?.map((r: Community) => ({ ...r, name: r.community })),
          };

          self.state = 'LOGGED_IN';
          writeStorage('AUTH_TOKEN', response.token);
        } else {
          const error = composeError(response);
          self.rootStore.errorStore.setError(error.message);
        }
      } catch (err: unknown) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    const userLogout = flow(function* () {
      try {
        yield logout();
        self.user = null;
        self.token = null;
        self.state = 'LOGGED_OUT';
        deleteStorage('AUTH_TOKEN');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    const getMe = flow(function* () {
      try {
        const response = yield fetchMe();
        self.user = {
          ...response.data,
          communities: response.data.communities?.map((r: Community) => ({ ...r, name: r.community })),
        };
        self.state = 'LOGGED_IN';
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.state = 'LOGGED_OUT';
        self.user = null;
        self.token = null;
      }
    });

    // Get users by role (admin (admin, publisher, creator), employee or user)
    const getUsersByRole = flow(function* (userRole: UserRole) {
      try {
        const response = yield fetchUsers(userRole);
        self.users = response.data.map((r: User) => ({
          id: r.id,
          firstname: r.firstname,
          lastname: r.lastname,
          email: r.email,
          address: r.address,
          postalcode: r.postalcode,
          city: r.city,
          country: r.country,
          phone: r.phone,
          admin: r.admin,
          publisher: r.publisher,
          creator: r.creator,
          portal: r.portal,
          employee: r.employee,
          newsletter: r.newsletter,
          empNewsletter: r.empNewsletter,
          permission: r.permission,
          subscripber: r.subscripber,
          tester: r.tester,
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const getUserById = flow(function* (id: string) {
      try {
        const response = yield getSingleUser(id);

        self.singleUser = {
          id: response.data.id,
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          email: response.data.email,
          address: response.data.address,
          postalcode: response.data.postalcode,
          city: response.data.city,
          country: response.data.country,
          phone: response.data.phone,
          admin: response.data.admin,
          publisher: response.data.publisher,
          creator: response.data.creator,
          employee: response.data.employee,
          portal: response.data.portal,
          newsletter: response.data.newsletter,
          empNewsletter: response.data.empNewsletter,
          permission: response.data.permission,
          subscriber: response.data.subscriber,
          tester: response.data.tester,
          customerId: response.data.customerId,
          emailVerified: response.data.emailVerified,
          communities: response.data.communities?.map((r: Community) => ({ ...r, name: r.community })),
          subscriptions: response.data.subscriptions?.map((s: Subscription) => ({
            ...s,
            name: s.subscriptionType?.name ?? '',
          })),
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    // TODO siirrä subscriptionStoreen
    const getStripeSubscriptions = flow(function* (id: string) {
      try {
        const response = yield getUserStripeSubscriptions(id);
        self.stripeSubscriptions = response.data?.map((s: StripeSubscription) => ({
          ...s,
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    // TODO siirrä subscriptionStoreen
    const getProducts = flow(function* () {
      try {
        const result = yield getSubscriptionProducts();

        self.products = result.data.map((product: ProductModelType) => ({
          ...product,
        }));
      } catch (error) {
        console.error(error);
      }
    });

    const createUser = flow(function* (newUser) {
      try {
        yield createSingleUser(newUser);
        self.rootStore.errorStore.setSuccess('save_success');
        getUsersByRole(getUserListRole(newUser));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const updateUser = flow(function* (id, user) {
      try {
        yield updateSingleUser(id, user);
        self.rootStore.errorStore.setSuccess('save_success');
        getUsersByRole(getUserListRole(user));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const deleteUser = flow(function* (id: number, userRole: UserRole) {
      try {
        yield deleteSingleUser(id);
        self.rootStore.errorStore.setSuccess('delete_success');
        getUsersByRole(userRole);
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const changePassword = flow(function* (password: string) {
      try {
        yield changeUserPassword(password);
        self.rootStore.errorStore.setSuccess('password_changed');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const uploadUsers = flow(function* (file) {
      try {
        yield uploadUsersFromFile(file);
        self.rootStore.errorStore.setSuccess('save_success');
        getUsersByRole(UserRole.employee);
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const hasCommunityAccess = (communityId: number) => {
      try {
        if (self.user?.admin) {
          return true;
        }

        if (!self.user) {
          return false;
        }

        const exists = self.user.communities?.some((c) => c.id == communityId);
        return exists;
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    };

    const getCommunities = flow(function* () {
      try {
        const response = yield fetchCommunities();
        self.communities = response.data.map((r: Community) => ({ ...r, name: r.community }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const getCommunityById = flow(function* (id: string) {
      try {
        const response = yield getSingleCommunity(id);
        return response.data;
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const createCommunity = flow(function* (fields: Community) {
      try {
        yield createSingleCommunity(fields);
        self.rootStore.errorStore.setSuccess('save_success');
        getCommunities();
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const updateCommunity = flow(function* (id: string, fields: Community) {
      try {
        yield updateSingleCommunity(id, fields);
        self.rootStore.errorStore.setSuccess('save_success');
        getCommunities();
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const deleteCommunity = flow(function* (id: string) {
      try {
        yield deleteSingleCommunity(id);
        self.rootStore.errorStore.setSuccess('delete_success');
        getCommunities();
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const changePasswordWithToken = flow(function* (password: string, resetToken: string) {
      try {
        yield resetUserPassword(password, resetToken);
        self.rootStore.errorStore.setSuccess('password_changed');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const sendPasswordResetLink = flow(function* (email: string) {
      try {
        yield forgotUserPassword(email);
        self.rootStore.errorStore.setSuccess('password_reset_link_sent');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    return {
      userLogin,
      userLogout,
      getMe,
      getUsersByRole,
      getUserById,
      createUser,
      updateUser,
      deleteUser,
      changePassword,
      uploadUsers,
      hasCommunityAccess,
      getCommunities,
      createCommunity,
      updateCommunity,
      deleteCommunity,
      getCommunityById,
      changePasswordWithToken,
      sendPasswordResetLink,
      getStripeSubscriptions,
      getProducts,
    };
  });
