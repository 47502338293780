import { flow, types } from 'mobx-state-tree';
import { fetchTags, createNewTag, tagUpdate, tagDelete } from '../services/api';
import { composeError } from '../utils/transforms';
import { withRootStore } from './withRootStore';
import { MediaTypes, Tag } from '../types';

export const TagStateObject = types.model('TagStateObject', {
  id: types.optional(types.number, -1),
  tag: types.optional(types.string, ''),
  value: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  communityId: types.maybeNull(types.number),
});

export const TagStore = types
  .model({
    tags: types.optional(types.array(TagStateObject), []),
    error: types.frozen(),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getTags = flow(function* (mediatype: MediaTypes) {
      try {
        const response = yield fetchTags(mediatype);
        self.tags = response.data.map((tag: Tag) => ({
          id: tag.id,
          tag: tag.tag,
          value: tag.tag,
          mediatype: tag.mediaType?.id,
          communityId: tag.community?.id,
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.error = error;
        console.error(err);
        console.error(error);
      }
    });

    const createTag = flow(function* (tag: Tag) {
      try {
        yield createNewTag(tag);
        self.rootStore.errorStore.setSuccess('save_success');
        getTags(tag.mediaTypeId);
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.error = error;
        console.error(err);
        console.error(error);
      }
    });

    const updateTag = flow(function* (id: number, tag: Tag) {
      try {
        yield tagUpdate(id, tag);
        self.rootStore.errorStore.setSuccess('save_success');
        getTags(tag.mediaTypeId);
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.error = error;
        console.error(err);
        console.error(error);
      }
    });

    const deleteTag = flow(function* (id: number, mediatype: MediaTypes) {
      try {
        yield tagDelete(id);
        self.rootStore.errorStore.setSuccess('delete_success');
        getTags(mediatype);
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.error = error;
        console.error(err);
        console.error(error);
      }
    });

    return {
      getTags,
      createTag,
      updateTag,
      deleteTag,
    };
  });
