import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';
import { Button as MUButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMst } from '../model/Root';
import { color } from '../theme';
import Layout from '../components/Layout';
import Button from '../components/Button/Button';
import AddButton from '../components/Button/AddButton';
import LinkTable from '../components/Link/LinkTable';
import { Link, AppItem } from '../types';
import LinkForm from '../components/Link/LinkForm';
import TextInput from '../components/FormComponents/TextInput';
import AddIcon from '../static/add_icon.svg';
import ErrorText from '../components/ErrorText';
import { LinkDefaults } from '../utils/defaults';
import SvgIcon from 'components/SvgIcon';

const useStyles = makeStyles()({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: 700,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    maxWidth: 700,
    minWidth: 500,
    alignItems: 'flex-start',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: 'auto',
    height: 60,
    objectFit: 'scale-down',
  },
  imageButton: {
    padding: 0,
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 18,
    textDecorationLine: 'underline',
    textTransform: 'none',
    marginTop: 10,
  },
  imageButtonIcon: {
    marginLeft: 5,
    width: 15,
    height: 15,
  },
  inputTitle: {
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  input: {
    fontFamily: 'Ubuntu',
    width: '90%',
    maxWidth: 600,
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
    padding: 10,
    alignSelf: 'flex-start',
    margin: 0,
  },
  addressContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    maxWidth: 600,
    justifyContent: 'space-between',
    padding: 0,
    margin: 0,
  },
  smallInputContainer: {
    width: '90%',
    maxWidth: 250,
    padding: 0,
    alignSelf: 'center',
  },
  smallInput: {
    width: '100%',
    fontFamily: 'Ubuntu',
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
    padding: 10,
    alignSelf: 'center',
    margin: 0,
  },
  button: {
    width: 100,
    marginTop: 30,
    fontFamily: 'Ubuntu',
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'uppercase',
    color: 'white',
    backgroundColor: color.primaryButton,
    padding: 10,
    '&:hover': {
      backgroundColor: color.primaryButton,
    },
  },
  linkHeader: {
    fontFamily: 'Ubuntu',
    fontWeight: 'normal',
    fontSize: 24,
  },
});

const AppItemScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    appStore: {
      getAppItems,
      updateAppItems,
      updateFooterLinkOrder,
      addNewFooterLink,
      updateFooterLink,
      deleteFooterLink,
    },
  } = useMst();

  const [edit, setEdit] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [selectedLink, setSelectedLink] = useState<Link | null>();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [result, setResult] = useState<AppItem>();
  const [hasErrors, setHasErrors] = useState(false);

  const getItems = useCallback(() => {
    getAppItems().then((result) => {
      if (!result) return;
      setResult(result);
      setImagePreviewUrl(result?.logo ?? null);
    });
  }, [getAppItems]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target?.files?.length) return;
    const image = URL.createObjectURL(target.files[0]);
    setSelectedImage(target.files[0]);
    setImagePreviewUrl(image);
  };

  const onChange = (key: string, value: string) => {
    setResult((result: AppItem | undefined) => {
      if (!result) return;
      return { ...result, [key]: value };
    });
  };

  const editLink = (linkId: number | string) => {
    if (!result?.footerLinks) return;
    setEdit(!edit);
    const selected = result.footerLinks.find((link) => link.id === linkId);
    if (selected) {
      setSelectedLink(selected);
    }
  };

  const addNewLink = () => {
    setAddNew(!addNew);
    setSelectedLink(null);
  };

  const onLinkChange = (key: string, value: string) => {
    setSelectedLink((link: Link | undefined | null) => {
      const tempLink = link ?? LinkDefaults;
      return { ...tempLink, [key]: value };
    });
  };

  const saveFooterLinks = async (linkId: number | null | string) => {
    // check that link has text and url
    if (!selectedLink?.text?.length || !selectedLink?.url?.length) return;
    // Check if link exists
    if (linkId && linkId !== -1) {
      await updateFooterLink(linkId, selectedLink);
    } else {
      const tempLink = { text: selectedLink.text, url: selectedLink.url };
      await addNewFooterLink({
        ...tempLink,
        placement: result?.footerLinks?.length ? result?.footerLinks?.length : 0,
        isFooter: true,
      });
    }
    setEdit(false);
    setAddNew(false);
    setSelectedLink(null);
    getItems();
  };

  const deleteLink = async (linkId: number | string) => {
    await deleteFooterLink(linkId);
    getItems();
  };

  const changeLinkOrder = async (linkId: number | string, direction: string) => {
    await updateFooterLinkOrder(linkId, direction);
    getItems();
  };

  const save = async () => {
    if (!result) return;
    // check that required fields are filled
    const tempItem: AppItem = { ...result };
    delete tempItem.footerLinks;

    const isImageValid = !!result?.logo?.length || selectedImage !== null;
    const isValid = isImageValid && Object.values(tempItem).every((value) => value && value.length > 0);
    if (isValid) {
      await updateAppItems(result, selectedImage);
      getItems();
    }
    setHasErrors(!isValid);
    setEdit(false);
    setAddNew(false);
  };

  const renderTextField = (label: string, type: string, placeholder: string, width?: number) => (
    <TextInput
      label={label}
      value={result ? result[type as keyof AppItem]?.toString() : ''}
      type={type}
      placeholder={placeholder}
      onChange={(key: string, value: string) => onChange(key, value)}
      style={{ width: width ?? '90%' }}
    />
  );

  return (
    <Layout title={t('header_footer')}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.imageContainer}>
            {imagePreviewUrl && <img src={imagePreviewUrl ?? ''} className={classes.image} />}
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handleImageSelection}
            />
            <label htmlFor="raised-button-file">
              <MUButton component="span" className={classes.imageButton}>
                {t('upload_logo')} <SvgIcon icon={AddIcon} width={15} height={15} style={{ marginLeft: 5 }} />
              </MUButton>
            </label>
          </div>
          {renderTextField('app_name', 'appName', 'app_name')}
          {renderTextField('address', 'address', 'address')}
          <div className={classes.addressContainer}>
            {renderTextField('postalcode', 'postalcode', 'postalcode', 200)}
            {renderTextField('city', 'city', 'city', 200)}
          </div>
          {renderTextField('phone', 'phone', 'phone')}
          {renderTextField('business_id', 'businessId', 'business_id')}
          <Button onClick={save} text="save" />
          {hasErrors && <ErrorText text="app_item_save_error" style={{ fontSize: 12 }} />}
        </div>
        <div className={classes.contentContainer}>
          <h3 className={classes.linkHeader}>{t('footer_links')}</h3>
          <LinkTable
            links={result?.footerLinks ?? []}
            onEdit={(linkId) => editLink(linkId)}
            onDelete={(linkId) => deleteLink(linkId)}
            onOrderChange={(linkId, direction) => changeLinkOrder(linkId, direction)}
          />
          <AddButton onClick={addNewLink} text={t('add_link')} />
          {(edit || addNew) && (
            <LinkForm
              link={selectedLink ?? null}
              onChange={(value, newValue) => onLinkChange(value, newValue)}
              onSave={(linkId) => saveFooterLinks(linkId)}
            />
          )}
        </div>
      </div>
    </Layout>
  );
});

export default AppItemScreen;
