import { flow, types } from 'mobx-state-tree';
import {
  getPrograms,
  getSingleProgram,
  addSingleProgram,
  updateSingleProgram,
  deleteSingleProgram,
} from '../services/programApi';
import { composeError } from '../utils/transforms';
import { ProgramResponse, Program, Category, TargetGroup, Video } from '../types';
import { withRootStore } from './withRootStore';

const VideoModel = types.model('VideoModel', {
  id: types.number,
  name: types.string,
  image: types.maybeNull(types.string),
  releaseDate: types.maybeNull(types.string),
  src: types.maybeNull(types.string),
  season: types.maybeNull(types.number),
  order: types.number,
  notes: types.string,
  state: types.optional(types.string, ''),
});

const ProgramModel = types.model('ProgramModel', {
  id: types.optional(types.number, -1),
  name: types.string,
  image: types.maybeNull(types.string),
  description: types.string,
  releaseDate: types.maybeNull(types.string),
  author: types.string,
  published: types.optional(types.boolean, false),
  publisher: types.string,
  language: types.maybeNull(types.string),
  webUrl: types.maybeNull(types.string),
  order: types.maybeNull(types.number),
  mediaTypeId: types.optional(types.number, -1),
  videos: types.optional(types.array(VideoModel), []),
  categoryIds: types.optional(types.array(types.number), []),
  targetGroupIds: types.optional(types.array(types.number), []),
});

export const ProgramStore = types
  .model({
    programs: types.optional(types.array(ProgramModel), []),
    selectedProgram: types.maybeNull(ProgramModel),
    loading: false,
  })
  .extend(withRootStore)
  .actions((self) => {
    const getAllPrograms = flow(function* () {
      try {
        const response = yield getPrograms();
        self.programs = response.data.map((program: ProgramResponse) => ({
          ...program,
          // These are not used where getAllPrograms is called
          // mediaTypeId: program.mediaType.id,
          // categoryIds: program.categories?.map((data: Category) => data.id),
          // targetGroupIds: program.targetGroups?.map((data: TargetGroup) => data.id),
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const getSelectedProgram = flow(function* (id: string) {
      try {
        const response = yield getSingleProgram(id);

        if (response.data.rssFeed && response.data.rssFeed != '') {
          self.selectedProgram = {
            ...response.data,
            mediaTypeId: response.data.mediaType.id,
            categoryIds: response.data.categories?.map((data: Category) => data.id),
            targetGroupIds: response.data.targetGroups?.map((data: TargetGroup) => data.id),
          };
        } else {
          self.selectedProgram = {
            ...response.data,
            mediaTypeId: response.data.mediaType.id,
            videos: response.data.videos?.map((video: Video) => ({
              name: video.name,
              src: video.src,
              image: video.image,
              releaseDate: video.releaseDate,
              season: video.season,
              order: video.order,
              notes: video.notes,
              id: video.id,
            })),
            categoryIds: response.data.categories?.map((data: Category) => data.id),
            targetGroupIds: response.data.targetGroups?.map((data: TargetGroup) => data.id),
          };

          // Sort videos
          self.selectedProgram?.videos.sort((a, b) => a.order - b.order);
        }
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const createProgram = flow(function* (program: Program, videos: Video[], files: File[], image?: File) {
      self.loading = true;
      try {
        yield addSingleProgram(program, videos, files, image);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const updateProgram = flow(function* (program: Program, videos: Video[], files: File[], image?: File) {
      self.loading = true;
      try {
        yield updateSingleProgram(program, videos, files, image);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const deleteProgram = flow(function* (id: string) {
      try {
        yield deleteSingleProgram(id);
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getAllPrograms,
      getSelectedProgram,
      createProgram,
      updateProgram,
      deleteProgram,
    };
  });
