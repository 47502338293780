import React from 'react';
import { TableCell, TableRow as MURow, IconButton } from '@mui/material';
import { color } from '../../theme';
import { makeStyles } from 'tss-react/mui';
import EditIcon from '../../static/edit.svg';
import CancelIcon from '../../static/delete.svg';

const useStyles = makeStyles()({
  root: {
    borderBottom: `1px solid ${color.greyBorderLight}`,
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'flex-start',
    width: '100%',
    maxWidht: 1000,
  },
  cell: {
    display: 'flex',
    alignSelf: 'flex-start',
    fontSize: '18px',
    color: color.black,
    fontWeight: 400,
    lineHeight: '24px',
    padding: 0,
    border: 0,
    marginTop: 20,
    minWidth: 300,
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  cell_draft: {
    display: 'flex',
    alignSelf: 'flex-start',
    fontSize: '18px',
    color: color.black,
    backgroundColor: color.warning,
    fontWeight: 400,
    lineHeight: '24px',
    padding: '3px 3px 3px 12px',
    border: 0,
    marginTop: 20,
    minWidth: 100,
    textTransform: 'capitalize',
    textAlign: 'left',
    borderRadius: 3,
  },
  cell_published: {
    display: 'flex',
    alignSelf: 'flex-start',
    fontSize: '18px',
    color: color.black,
    backgroundColor: color.success,
    fontWeight: 400,
    lineHeight: '24px',
    padding: '3px 3px 3px 12px',
    border: 0,
    marginTop: 20,
    minWidth: 100,
    textTransform: 'capitalize',
    textAlign: 'left',
    borderRadius: 3,
  },
  btnCell: {
    border: 0,
  },
});

interface Props {
  showDelete: boolean;
  value: string;
  secondValue?: string;
  onClick: () => void;
  onDelete?: () => void;
}

function TableRow(props: Props) {
  const { value, showDelete, secondValue, onClick, onDelete } = props;
  const { classes } = useStyles();

  const renderDeleteButton = () => {
    if (!onDelete || !showDelete) return;
    return (
      <IconButton onClick={() => onDelete()}>
        <img src={CancelIcon} alt="Delete" />
      </IconButton>
    );
  };

  return (
    <MURow className={classes.root}>
      <TableCell
        className={
          value == 'Luonnos' ? classes.cell_draft : value == 'Julkaistu' ? classes.cell_published : classes.cell
        }
      >
        {value}
      </TableCell>
      {!!secondValue && (
        <TableCell
          className={
            secondValue == 'Luonnos'
              ? classes.cell_draft
              : secondValue == 'Julkaistu'
                ? classes.cell_published
                : classes.cell
          }
        >
          {secondValue}
        </TableCell>
      )}
      <TableCell className={classes.btnCell}>
        <IconButton onClick={onClick}>
          <img src={EditIcon} alt="Edit" />
        </IconButton>
        {renderDeleteButton()}
      </TableCell>
    </MURow>
  );
}

export default TableRow;
