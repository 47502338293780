export enum ReportTypes {
  Feedback = 'Feedback',
  Subscriptions = 'Subscriptions',
  SubscriptionTypes = 'SubscriptionTypes',
  UserStatistics = 'UserStatistics',
  NewsletterEmails = 'NewsletterEmails',
  EmpNewsletterEmails = 'EmpNewsletterEmails',
  MarketingEmails = 'MarketingEmails',
  PermissionEmails = 'PermissionEmails',
  MediaUsageReport = 'MediaUsageReport',
  TestGroupReport = 'TestGroupReport',
  SupportCodeReport = 'SupportCodeReport',
}
