import React, { FC, useCallback, useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import {
  Article,
  ArticleSections,
  ArticleTypes,
  ArticleTypesEV,
  MagazineSegment,
  MagazineSegmentResponse,
  SegmentFeedTypes,
} from 'types';
import { useTranslation } from 'react-i18next';
import { composeError } from 'utils/transforms';
import { useMst } from 'model/Root';
import { getMagazineSegmentsForPreview } from 'services/magazineSegmentApi';
import { makeStyles } from 'tss-react/mui';
import SubscribersTag from './SubscribersTag';

export type PreviewType = 'mobile' | 'web';

interface MagazinePreviewProps {
  type?: PreviewType;
  onlineMagazineId?: string;
  articles?: Article[];
}

const MagazinePreview: FC<MagazinePreviewProps> = ({ type, onlineMagazineId, articles }: MagazinePreviewProps) => {
  const [segmentRows, setSegmentRows] = useState<MagazineSegment[]>([]);
  const {
    errorStore: { setError },
  } = useMst();

  const getMagazineSegmentsForOnlineMagazine = useCallback(
    async (magazineId?: string) => {
      try {
        if (magazineId) {
          const response = await getMagazineSegmentsForPreview(magazineId);

          const magazineSegments = response.data
            .filter((segment) => (type == 'mobile' ? segment.mobile : segment.web))
            .map((magazineSegment: MagazineSegmentResponse) => ({
              ...magazineSegment,
            }));
          setSegmentRows(magazineSegments);
        }
      } catch (err) {
        const error = composeError(err);
        setError(error.message);
        console.error(err);
        console.error(error);
      }
    },
    [setError, type]
  );

  useEffect(() => {
    const fetchPageData = async () => {
      await getMagazineSegmentsForOnlineMagazine(onlineMagazineId);
    };

    fetchPageData();
  }, [getMagazineSegmentsForOnlineMagazine, onlineMagazineId]);

  if (!type) return null;

  return (
    <Grid container>
      {segmentRows?.map((row) => (
        <SegmentPreview
          key={row.id}
          segment={row}
          articles={articles?.filter(
            (article) =>
              (row.articleType == ArticleTypes.SpeakingEngagements &&
                (article.type == ArticleTypes.Editorial ||
                  article.type == ArticleTypes.Column ||
                  article.type == ArticleTypes.PointOfView ||
                  article.type == ArticleTypes.GuestPen)) ||
              (row.articleType == ArticleTypes.SmallStory &&
                (article.type == ArticleTypes.QuickVisit || article.type == ArticleTypes.ChurchVisit)) ||
              (row.articleType == ArticleTypesEV.MainArticles &&
                (article.type == ArticleTypesEV.News ||
                  article.type == ArticleTypesEV.Topical ||
                  article.type == ArticleTypesEV.International ||
                  article.type == ArticleTypesEV.ChangeStory ||
                  article.type == ArticleTypesEV.EverydayHero ||
                  article.type == ArticleTypesEV.MyStory ||
                  article.type == ArticleTypesEV.Teaching ||
                  article.type == ArticleTypesEV.CoWorkers ||
                  article.type == ArticleTypesEV.ExpertsOpinion ||
                  article.type == ArticleTypesEV.Anniversary ||
                  article.type == ArticleTypesEV.Obituary)) ||
              article.type == row.articleType
          )}
        />
      ))}
    </Grid>
  );
};

export default MagazinePreview;

interface SegmentPreviewProps {
  segment: MagazineSegment;
  articles?: Article[];
}

const SegmentPreview: FC<SegmentPreviewProps> = ({ segment, articles }: SegmentPreviewProps) => {
  let selectedArticles;

  if (segment.feedType == SegmentFeedTypes.Newest) {
    selectedArticles = articles?.slice(segment.articleSkip, segment.articleSkip + segment.articleCount);
  }

  if (segment.feedType == SegmentFeedTypes.Static) {
    const selectedArticle = articles?.find((article) => article.id == segment.singleArticleId);
    return selectedArticle && <ArticleFrontPreview article={selectedArticle} />;
  }

  return selectedArticles?.map((article) => <ArticleFrontPreview key={article.id} article={article} />);
};

interface ArticleFrontPreviewProps {
  article: Article;
}

const articleFrontClasses = makeStyles()({
  image: {
    maxWidth: '100%',
    maxHeight: 300,
    objectFit: 'cover',
    borderRadius: 3,
  },
  image_small: {
    maxWidth: 150,
    maxHeight: 150,
    objectFit: 'cover',
    borderRadius: 3,
  },
  divider: {
    height: 3,
    backgroundColor: 'black',
    borderRadius: 3,
  },
});

const ArticleFrontPreview: FC<ArticleFrontPreviewProps> = ({ article }: ArticleFrontPreviewProps) => {
  const { t } = useTranslation();
  const { classes } = articleFrontClasses();

  if (article.type == ArticleTypes.BigStory) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <Grid item xs={12}>
          <ArticlePreviewTopImage article={article} />
        </Grid>
        {article.section && article.section != ArticleSections.Undefined && (
          <Grid item xs={12} mt={1}>
            <Typography
              fontFamily={'Poppins'}
              fontSize={15}
              fontWeight={'bold'}
              style={{ textDecoration: 'underline', textTransform: 'uppercase' }}
            >
              {t(`articleSections.${article.section}`)}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} mb={1}>
          <Typography fontFamily={'Noto Serif'} fontSize={20} fontWeight={'bold'}>
            {article.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontFamily={'Noto Serif'} fontWeight={600} fontSize={16}>
            {article.lead}
          </Typography>
        </Grid>
        <SubscribersTag article={article} />
        <Grid item xs={12} mt={2} mb={1}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );
  }

  if (article.type == ArticleTypes.SmallStory) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        {article.section && article.section != ArticleSections.Undefined && (
          <Grid item xs={12} mt={1}>
            <Typography
              fontFamily={'Poppins'}
              fontSize={15}
              fontWeight={'bold'}
              style={{ textDecoration: 'underline', textTransform: 'uppercase' }}
            >
              {t(`articleSections.${article.section}`)}
            </Typography>
          </Grid>
        )}
        <Grid item xs={4} mt={2}>
          <ArticlePreviewTopImage article={article} small />
        </Grid>
        <Grid item xs={8} mb={1}>
          <Typography fontFamily={'Noto Serif'} fontSize={15} fontWeight={'bold'}>
            {article.title}
          </Typography>
        </Grid>
        <SubscribersTag article={article} />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.SmallTextStory) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        {article.section && article.section != ArticleSections.Undefined && (
          <Grid item xs={12} mt={1}>
            <Typography
              fontFamily={'Poppins'}
              fontSize={15}
              fontWeight={'bold'}
              style={{ textDecoration: 'underline', textTransform: 'uppercase' }}
            >
              {t(`articleSections.${article.section}`)}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} mb={1}>
          <Typography fontFamily={'Noto Serif'} fontSize={15} fontWeight={'bold'}>
            {article.title}
          </Typography>
        </Grid>
        <SubscribersTag article={article} />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.ImageStory) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <Grid item xs={12}>
          <ArticlePreviewTopImage article={article} />
        </Grid>
        {article.section && article.section != ArticleSections.Undefined && (
          <Grid item xs={12} mt={1}>
            <Typography
              fontFamily={'Poppins'}
              fontSize={15}
              fontWeight={'bold'}
              style={{ textDecoration: 'underline', textTransform: 'uppercase' }}
            >
              {t(`articleSections.${article.section}`)}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} mb={1}>
          <Typography fontFamily={'Noto Serif'} fontSize={24} fontWeight={'bold'}>
            {article.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontFamily={'Noto Serif'} fontWeight={600} fontSize={15}>
            {article.lead}
          </Typography>
        </Grid>
        <SubscribersTag article={article} />
        <Grid item xs={12} mt={2} mb={1}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );
  }

  if (article.type == ArticleTypes.Editorial) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <Grid item xs={12} style={{ backgroundColor: 'black' }} textAlign={'center'} p={1}>
          <Typography fontSize={20} fontWeight={'bold'} color={'white'}>
            {t('articleTypes.Editorial')}
          </Typography>
        </Grid>
        <Grid container item xs={4} style={{ minHeight: 250 }} alignItems={'center'} pl={2}>
          <ArticlePreviewPersonImage article={article} />
        </Grid>
        <Grid container item xs={8} alignContent={'flex-start'}>
          <Grid item xs={12} mt={8}>
            <Typography fontFamily={'Poppins'} fontSize={22}>
              {article.author}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography fontFamily={'Noto Serif'} fontSize={18} fontWeight={'bold'}>
              {article.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );
  }

  if (article.type == ArticleTypes.Column) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <Grid item xs={12} style={{ backgroundColor: 'black' }} textAlign={'center'} p={1}>
          <Typography fontSize={20} fontWeight={'bold'} color={'white'}>
            {t('articleTypes.Column')}
          </Typography>
        </Grid>
        <Grid container item xs={4} alignItems={'center'} pl={2} pt={4}>
          <Grid item xs={12}>
            <ArticlePreviewPersonImage article={article} />
          </Grid>
          <Grid item xs={12} mt={1} mb={1}>
            <Typography fontFamily={'Poppins'} fontSize={16} style={{ textTransform: 'uppercase' }}>
              {article.author}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={8} alignContent={'flex-start'} pt={4}>
          <Grid item xs={12}>
            <Typography fontFamily={'Noto Serif'} fontSize={18} fontWeight={'bold'}>
              {article.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );
  }

  if (article.type == ArticleTypes.PointOfView) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <Grid item xs={12} style={{ backgroundColor: 'black' }} textAlign={'center'} p={1}>
          <Typography fontSize={20} fontWeight={'bold'} color={'white'}>
            {t('articleTypes.PointOfView')}
          </Typography>
        </Grid>
        <Grid container item xs={4} alignItems={'center'} pl={2} pt={4}>
          <Grid item xs={12}>
            <ArticlePreviewPersonImage article={article} circle />
          </Grid>
        </Grid>
        <Grid container item xs={8} alignContent={'flex-start'} pt={4}>
          <Grid item xs={12}>
            <Typography fontFamily={'Noto Serif'} fontSize={18} fontWeight={'bold'}>
              {article.title}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={1} mb={1}>
            <Typography fontFamily={'Poppins'} fontSize={16} style={{ textTransform: 'uppercase' }}>
              {article.author}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={1} mt={2}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );
  }

  if (article.type == ArticleTypes.Blog) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <Grid item xs={12} style={{ backgroundColor: 'black' }} textAlign={'center'} p={1}>
          <Typography fontSize={20} fontWeight={'bold'} color={'white'}>
            {t('articleTypes.PointOfView')}
          </Typography>
        </Grid>
        <Grid container item xs={4} alignItems={'center'} pl={2} pt={4}>
          <Grid item xs={12}>
            <ArticlePreviewPersonImage article={article} circle />
          </Grid>
        </Grid>
        <Grid container item xs={8} alignContent={'flex-start'} pt={4}>
          <Grid item xs={12}>
            <Typography fontFamily={'Noto Serif'} fontSize={18} fontWeight={'bold'}>
              {article.title}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={1} mb={1}>
            <Typography fontFamily={'Poppins'} fontSize={16} style={{ textTransform: 'uppercase' }}>
              {article.author}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={1} mt={2}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );
  }

  if (
    article.type == ArticleTypesEV.MainArticles ||
    article.type == ArticleTypesEV.News ||
    article.type == ArticleTypesEV.Topical ||
    article.type == ArticleTypesEV.International ||
    article.type == ArticleTypesEV.ChangeStory ||
    article.type == ArticleTypesEV.EverydayHero ||
    article.type == ArticleTypesEV.MyStory ||
    article.type == ArticleTypesEV.ExpertsOpinion ||
    article.type == ArticleTypesEV.Teaching ||
    article.type == ArticleTypesEV.CoWorkers ||
    article.type == ArticleTypesEV.Anniversary ||
    article.type == ArticleTypesEV.Obituary
  ) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <Grid item xs={12}>
          <ArticlePreviewTopImage article={article} />
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography fontFamily={'Noto Serif'} fontSize={20} fontWeight={'bold'}>
            {article.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontFamily={'Noto Serif'} fontWeight={600} fontSize={16}>
            {article.lead}
          </Typography>
        </Grid>
        <SubscribersTag article={article} />
        <Grid item xs={12} mt={2} mb={1}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );
  }

  if (
    article.type == ArticleTypesEV.EditorialEV ||
    article.type == ArticleTypesEV.ColumnEV ||
    article.type == ArticleTypesEV.YouthPerspective
  ) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <Grid item xs={12} style={{ backgroundColor: 'black' }} textAlign={'center'} p={1}>
          <Typography fontSize={20} fontWeight={'bold'} color={'white'}>
            {t(`articleTypes_ev.${article.type}`)}
          </Typography>
        </Grid>
        <Grid container item xs={4} style={{ minHeight: 250 }} alignItems={'center'} pl={2}>
          <ArticlePreviewPersonImage article={article} />
        </Grid>
        <Grid container item xs={8} alignContent={'flex-start'}>
          <Grid item xs={12} mt={8}>
            <Typography fontFamily={'Poppins'} fontSize={22}>
              {article.author}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography fontFamily={'Noto Serif'} fontSize={18} fontWeight={'bold'}>
              {article.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={1}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );
  }

  return null;
};

interface ArticlePreviewTopImageProps {
  article: Article;
  small?: boolean;
}

const ArticlePreviewTopImage: FC<ArticlePreviewTopImageProps> = ({ article, small }: ArticlePreviewTopImageProps) => {
  const { classes } = articleFrontClasses();
  const topImage = article.images?.find((image) => image.main && image.top);

  return (
    <>
      {topImage && (
        <img
          src={topImage.src ?? ''}
          className={small ? classes.image_small : classes.image}
          crossOrigin="anonymous"
          width={small ? 200 : 550}
          alt="Artikkelin kuva"
        />
      )}
    </>
  );
};

interface ArticlePreviewPersonImageProps {
  article: Article;
  circle?: boolean;
}

const ArticlePreviewPersonImage: FC<ArticlePreviewPersonImageProps> = ({
  article,
  circle,
}: ArticlePreviewPersonImageProps) => {
  const mainImage = article.images?.find((image) => image.main);

  return (
    <img
      src={mainImage?.src ?? ''}
      style={{ width: 120, height: 120, borderRadius: circle ? 60 : undefined }}
      alt="Henkilökuva"
      crossOrigin="anonymous"
    />
  );
};
