import React, { useState, useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { makeStyles } from 'tss-react/mui';
import { useNavigate, useParams } from 'react-router-dom';
import { Select, FormControl, MenuItem } from '@mui/material';
import Layout from '../../components/Layout';
import Button from '../../components/Button/Button';
import TextInput from '../../components/FormComponents/TextInput';
import ImageUploadButton from '../../components/Button/ImageUploadButton';
import { useTranslation } from 'react-i18next';
import { color } from '../../theme';
import { Promotion } from '../../types/Pages';
import { PromotionDefaults } from '../../utils/defaults';

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: 700,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    maxWidth: 700,
    minWidth: 500,
    alignItems: 'flex-start',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    maxWidth: 700,
    minWidth: 500,
    alignItems: 'flex-start',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
  },
  image: {
    width: 'auto',
    maxWidth: 600,
    height: 360,
    objectFit: 'scale-down',
  },
  imageButton: {
    padding: 0,
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 18,
    textDecorationLine: 'underline',
    textTransform: 'none',
    marginTop: 10,
  },
  imageButtonIcon: {
    marginLeft: 5,
    width: 15,
    height: 15,
  },
  inputTitle: {
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  input: {
    fontFamily: 'Ubuntu',
    width: '90%',
    maxWidth: 600,
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
    padding: 10,
    alignSelf: 'flex-start',
    margin: 0,
  },
  header: {
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    marginLeft: 20,
  },
  button: {
    backgroundColor: color.primaryButton,
    color: color.white,
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    marginTop: '40px',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
  },
  link: {
    alignContent: 'flex-start',
    minWidth: 500,
    padding: 10,
  },
  text: {
    alignContent: 'flex-start',
    minWidth: 500,
    padding: 10,
  },
  saveButton: {
    backgroundColor: color.primaryButton,
    color: color.white,
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    marginTop: 40,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
  },
  priorityContainer: {
    marginTop: 20,
    marginLeft: 10,
  },
  dropDown: {
    width: '100%',
    padding: 10,
    alignSelf: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
  },
  infoText: {
    width: '100%',
    fontFamily: 'Ubuntu',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: 'normal',
    color: color.black,
  },
  charCount: {
    width: '90%',
    fontFamily: 'Ubuntu',
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 'normal',
    color: color.greyText,
  },
});

const EditPromotionScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { promotionId } = useParams<{ promotionId: string }>();
  const navigate = useNavigate();
  const {
    promotionStore: {
      singlePromotion,
      placements,
      getPromotions,
      getPromotionById,
      updatePromotion,
      createNewPromotion,
      deletePromotion,
    },
  } = useMst();

  const [promotion, setPromotion] = useState<Promotion | null>(PromotionDefaults);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [priorities, setPriorities] = useState<number[]>([1, 2, 3]);

  useEffect(() => {
    const getSinglePromotion = async () => {
      const availablePriorities = priorities.filter((priority) => !placements.includes(priority));
      setPriorities(availablePriorities.sort((a, b) => a - b));
      await getPromotions();

      if (promotionId !== 'create') {
        await getPromotionById(promotionId);
        setPromotion(singlePromotion);
        setImagePreviewUrl(singlePromotion?.image);
        // Set available priorities
        if (singlePromotion?.placement && singlePromotion?.placement !== 0) {
          const tempPriorities = [...availablePriorities, singlePromotion.placement];

          setPriorities(tempPriorities.sort((a, b) => a - b));
        }
      }
    };

    getSinglePromotion();
  }, [getPromotionById, getPromotions, placements, promotionId, priorities, singlePromotion]);

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target?.files) {
      const image = URL.createObjectURL(target.files[0]);
      setSelectedImage(target.files[0]);
      setImagePreviewUrl(image);
    }
  };

  const handleChange = (key: string, value: string | number | null) => {
    setPromotion((result: Promotion | null | undefined) => {
      const tempResult = result ? result : PromotionDefaults;

      return { ...tempResult, [key]: value };
    });
  };

  const validateUrl = () => {
    const VALID_URL =
      /^http(s)?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/gm;
    return (promotion?.url?.length ?? 0) > 0 && VALID_URL.test(promotion?.url ?? '');
  };

  const onSave = async () => {
    if (!promotion) return;
    const isImageValid = promotion?.image?.length || selectedImage !== null;
    const isValid = isImageValid && promotion.name.length > 0 && validateUrl();

    if (isValid) {
      if (promotionId !== 'create') {
        await updatePromotion(promotion, selectedImage);
      } else {
        await createNewPromotion(promotion, selectedImage);
      }

      navigate('/promotion');
    }
    setHasErrors(!isValid);
  };

  const onDelete = async () => {
    if (promotionId === 'create') return;
    await deletePromotion(promotionId);
    navigate('/promotion');
  };

  const renderHeader = () => {
    return promotionId !== 'create' ? t('edit_promotion') : t('create_promotion');
  };

  const renderTextField = (label: string, type: string, placeholder: string, multiline?: boolean) => (
    <TextInput
      label={label}
      value={promotion ? promotion[type as keyof Promotion]?.toString() : ''}
      type={type}
      placeholder={placeholder}
      onChange={(key: string, value: string) => handleChange(key, value)}
      style={{ width: '90%' }}
      multiline={multiline}
    />
  );

  return (
    <Layout title={renderHeader()}>
      <div className={classes.wrapper}>
        <div className={classes.leftContainer}>
          <div className={classes.link}>{renderTextField('name', 'name', 'name')}</div>
          <ImageUploadButton
            text="upload_image"
            imagePreviewUrl={imagePreviewUrl}
            handleImageSelection={handleImageSelection}
          />
          <p className={classes.infoText}>{t('promotion_image_size')}</p>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.linkContainer}>
            <div className={classes.link}>{renderTextField('promotion_url', 'url', 'promotion_url')}</div>
          </div>
          <div className={classes.text}>
            {renderTextField('promotion_text', 'promotionText', 'promotion_text', true)}
            <p className={classes.charCount}>{`${promotion?.promotionText?.length || 0}/100`}</p>
          </div>

          <div className={classes.priorityContainer}>
            <FormControl>
              <h3 className={classes.inputTitle}>{t('promotion_priority')}</h3>
              <Select
                className={classes.dropDown}
                placeholder={'promotion_priority'}
                value={promotion ? promotion['placement' as keyof Promotion] : 0}
                onChange={({ target }) => handleChange('placement', target.value)}
                defaultValue={0}
              >
                <MenuItem value={0}>{t('promotion_priority')}</MenuItem>
                {priorities.map((priority, index) => (
                  <MenuItem key={index} value={priority}>
                    {priority}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {priorities.length === 0 && (
            <p className={classes.infoText} style={{ color: color.error }}>
              {t('priority_error')}
            </p>
          )}
        </div>
      </div>
      <div className={classes.btnContainer}>
        <Button onClick={onSave} text="save" />
        {promotionId !== 'create' && <Button onClick={onDelete} text="delete" backgroundColor={color.deleteButton} />}
      </div>
      {hasErrors && (
        <p className={classes.infoText} style={{ color: color.error }}>
          {t('promotion_save_error')}
        </p>
      )}
    </Layout>
  );
});

export default EditPromotionScreen;
