import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface ModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  leftButtonPress: () => void;
  rightButtonPress: () => void;
}

const AlertModal = (props: ModalProps) => {
  const { t } = useTranslation();
  const { title, open, onClose, leftButtonPress, rightButtonPress } = props;

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t(title)}</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>{t('this_action_cannot_be_reversed')}</DialogContent>
        <DialogActions style={{ paddingRight: '24px' }}>
          <Button variant="text" color="inherit" onClick={leftButtonPress}>
            {t('cancel')}
          </Button>
          <Button variant="text" color="error" onClick={rightButtonPress}>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertModal;
