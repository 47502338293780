import { AxiosResponse } from 'axios';
import { Article, ArticleResponse } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  articles: {
    root: '/articles',
    id: '/articles/{id}',
    forMagazine: '/articles/magazine/{id}',
  },
});

// Article
export const getSingleArticle = (id: string): Promise<ArticleResponse> =>
  api.get(API_PATHS.articles.id.replace('{id}', id), {});

export const addSingleArticle = (article: Article): Promise<ArticleResponse> => {
  const formData = getArticleFormData(article);

  return api.post(API_PATHS.articles.root, formData, { timeout: 360000 });
};

export const updateSingleArticle = (article: Article): Promise<ArticleResponse> => {
  const formData = getArticleFormData(article);

  return api.put(API_PATHS.articles.id.replace('{id}', article.id?.toString() ?? '0'), formData, {
    timeout: 360000,
  });
};

export const deleteSingleArticle = (id: string): Promise<void> => {
  return api.delete(API_PATHS.articles.id.replace('{id}', id), {});
};

export const getArticlesByMagazineId = (id: string): Promise<AxiosResponse<Article[]>> =>
  api.get(API_PATHS.articles.forMagazine.replace('{id}', id), {});

function getArticleFormData(article: Article) {
  const formData = new FormData();

  article.images?.forEach((image) => {
    const { file, ...restImage } = image;
    if (file) {
      formData.append('files', file, image.src);
    }
    formData.append('images', JSON.stringify(restImage));
  });

  article.sideStories?.forEach((sideStory) => {
    formData.append('sideStories', JSON.stringify(sideStory));
  });

  article.videos?.forEach((video) => {
    const { file, ...restVideo } = video;
    if (file) {
      // TODO onko filename missä oikeasti tallennettu ja onko jo normalisoitu?
      formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    }
    formData.append('videos', JSON.stringify(restVideo));
  });

  Object.keys(article)
    .filter((k) => k == 'citations')
    .forEach((key) => {
      const value = article[key as keyof Article];
      if (value) {
        if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(article[key as keyof Article]));
        } else {
          formData.append(key, article[key as keyof Article]?.toString() ?? '');
        }
      }
    });

  Object.keys(article)
    .filter((k) => k !== 'images')
    .filter((k) => k !== 'videos')
    .filter((k) => k !== 'citations')
    .filter((k) => k !== 'sideStories')
    .forEach((key) => {
      const value = article[key as keyof Article];
      if (value) {
        formData.append(key, article[key as keyof Article]?.toString() ?? '');
      }
    });

  return formData;
}
