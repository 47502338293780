import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { ArticlePage, Community, SelectedCategory, NewspaperArticle, MediaTypes } from '../../types';
import { color } from '../../theme/index';
import { ArticlePageDefaults } from '../../utils/defaults';
import TextEditor from '../../components/TextEditor/TextEditor';
import CheckboxGroup from '../../components/FormComponents/CheckboxGroup';
import TextInput from '../../components/FormComponents/TextInput';
import Button from '../../components/Button/Button';
import ImageUploadButton from '../../components/Button/ImageUploadButton';
import DateInput from '../../components/FormComponents/DateInput';
import ErrorText from '../../components/ErrorText';
import Select from '../../components/FormComponents/Select';
import SelectNewspapers from '../../components/FormComponents/SelectNewspapers';
import { validatePage } from '../../utils/validation';

const useStyles = makeStyles()({
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 30,
  },
  priorityContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '50%',
    marginTop: 10,
    marginLeft: '50%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '85%',
    justifyContent: 'space-between',
    padding: 0,
    marginTop: 30,
  },
});

const ArticlePageEditScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    articlePageStore: { selectedPage, getSingleArticle, createArticlePage, updateArticlePage },
    categoryStore: { categories, getCategories, createCategory },
    tagStore: { tags, getTags, createTag },
    newspaperStore: { newspapers, getAllNewspapers },
    targetGroupStore: { targetGroups, getTargetGroups },
    userStore: { communities, getCommunities, user },
  } = useMst();
  const { id }: any = useParams();
  const [result, setResult] = useState<ArticlePage | null>();
  const [selectedCategories, setSelectedCategories] = useState<SelectedCategory[]>([]);
  const [selectedTags, setSelectedTags] = useState<SelectedCategory[]>([]);
  const [selectedTargetGroups, setSelectedTargetGroups] = useState<SelectedCategory[]>([]);
  const [selectedCommunities, setSelectedCommunities] = useState<SelectedCategory[]>([]);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [hasErrors, setHasErrors] = useState<boolean>();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [newValue, setNewValue] = useState<any | null>(null);
  const [canPublish, setCanPublish] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [allNewspapers, setAllNewspapers] = useState<NewspaperArticle[]>([]);
  const [selectedNewspapers, setSelectedNewspapers] = useState<NewspaperArticle[]>([]);
  //const [newspaperIds, setNewspaperIds] = useState<number[]>([]);

  const onChange = useCallback(
    (key: string, value: any) => {
      setResult((result: any | ArticlePage) => {
        const tempResult = result ? result : ArticlePageDefaults;
        if (key === 'publisher') {
          const selected = communities.find((community) => community.id === Number(value));
          return { ...tempResult, [key]: selected?.community ?? '' };
        }

        return { ...tempResult, [key]: value };
      });
    },
    [communities]
  );

  const getSelectedCheckboxes = (checkBoxData: any[], selectedData: any[], key: string): number[] => {
    return checkBoxData
      .filter((data: any) => {
        if (!data[key]) return null;
        if (selectedData[data[key]]) {
          return data.id;
        }
      })
      .map((selected) => selected.id);
  };

  useEffect(() => {
    const fetchArticle = async () => {
      await getCategories(MediaTypes.Article);
      await getTags(MediaTypes.Article);
      await getCommunities();
      await getAllNewspapers();
      await getTargetGroups();

      if (id !== 'create') {
        await getSingleArticle(id);
        setResult(selectedPage);
        convertFromHTML(selectedPage.text);
        if (selectedPage.image) {
          setImagePreviewUrl(selectedPage.image);
        }

        const getSelectedCategories = () => {
          categories.forEach((category) => {
            if (!selectedPage?.categories?.length) return;
            const isFound = selectedPage?.categories?.find((el) => el === category.id);
            setSelectedCategories((prev) => ({ ...prev, [category.name]: isFound }) as SelectedCategory[]);
          });

          tags.forEach((tag) => {
            if (!selectedPage?.tags?.length) return;
            const isFound = selectedPage?.tags?.find((el) => el === tag.id);
            setSelectedTags((prev) => ({ ...prev, [tag.tag]: isFound }) as SelectedCategory[]);
          });

          targetGroups.forEach((group) => {
            if (!selectedPage?.targetGroups?.length) return;
            const isFound = selectedPage?.targetGroups?.find((el) => el === group.id);
            setSelectedTargetGroups((prev) => ({ ...prev, [group.name]: isFound }) as SelectedCategory[]);
          });

          communities.forEach((community) => {
            if (!selectedPage.communities?.length) return;
            const isFound = selectedPage?.communities?.find((el) => el === community.id);
            setSelectedCommunities((prev) => ({ ...prev, [community.community]: isFound }) as SelectedCategory[]);
          });
        };
        getSelectedCategories();
      } else {
        setSelectedCategories(categories.map((category: any) => ({ [category.name]: false })));
        setSelectedTags(tags.map((tag: any) => ({ [tag.tag]: false })));
        setSelectedTargetGroups(targetGroups.map((group: any) => ({ [group.name]: false })));
        setSelectedCommunities(communities.map((community: any) => ({ [community.community]: false })));
      }
    };
    fetchArticle();

    const checkPublish = () => {
      if (!user) return null;
      if (user.admin) {
        setCanPublish(true);
        return;
      }

      if (user.creator) {
        setCanPublish(false);
        return;
      }

      if (user.communities.length > 0) {
        const selected = getSelectedCheckboxes(communities, selectedCommunities, 'community');
        if (selected.length === 0) {
          setCanPublish(false);
          return;
        }
        const userCommunityIds = user.communities.map((community: Community) => community.id);
        const publish = selected.filter((val) => !userCommunityIds.includes(val));
        setCanPublish(publish.length === 0);
      }
    };
    checkPublish();

    const checkSave = () => {
      if (!user) return null;
      if (user.admin || user.publish) {
        setCanSave(true);
        return;
      }

      if (user.communities.length > 0) {
        const selected = getSelectedCheckboxes(communities, selectedCommunities, 'community');
        if (selected.length === 0) {
          setCanSave(true);
          return;
        }
        const userCommunityIds = user.communities.map((community: Community) => community.id);
        const publish = selected.filter((val) => !userCommunityIds.includes(val));
        setCanSave(publish.length === 0);
      }
    };
    checkSave();

    // const getNewspaperIds = () => {
    //   if (!selectedPage.newspapers) return;
    //   const temp = [...selectedPage.newspapers];
    //   setNewspaperIds([...temp]);
    // };
    // getNewspaperIds();

    const formattedArticles = () => {
      const temp: NewspaperArticle[] = newspapers.map((newspaper) => {
        return {
          id: newspaper.id,
          name: newspaper.name,
          number: newspaper.number,
          publisher: newspaper.publisher,
          published: newspaper.published,
        };
      });
      if (selectedPage.newspapers) {
        const selected = temp.filter((el) => selectedPage.newspapers.includes(el.id));
        setSelectedNewspapers((prev) => [...prev, ...selected]);
      }
      setAllNewspapers((prev) => [...prev, ...temp]);
    };
    formattedArticles();
  }, [
    getSingleArticle,
    getCategories,
    getTags,
    getTargetGroups,
    getCommunities,
    selectedPage,
    getAllNewspapers,
    id,
    categories,
    tags,
    targetGroups,
    communities,
    user,
    newspapers,
    selectedCommunities,
  ]);
  // TODO selectedCommunities puuttuu yläpuolelta, mutta aiheuttaa päivitys looppin

  useEffect(() => {
    const draft = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange('text', draft);
  }, [editorState, onChange]);

  const saveTagOrCategory = async (key: string) => {
    if (!newValue || newValue[key].length <= 0) return null;
    if (key === 'tag') {
      await createTag({ tag: newValue[key] });
      await getTags();
    } else if (key === 'category') {
      await createCategory({ name: newValue[key] });
      await getCategories();
    }
    setNewValue(null);
  };

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target?.files) {
      const image = URL.createObjectURL(target.files[0]);
      setSelectedImage(target.files[0]);
      setImagePreviewUrl(image);
    }
  };

  const renderHeader = () => {
    return id !== 'create' ? t('articles_edit') : t('articles_add');
  };

  const renderTextField = (
    label: string,
    value: string | undefined,
    type: string,
    placeholder: string,
    multiline?: boolean,
    width?: number,
    inputType?: string
  ) => {
    return (
      <TextInput
        label={label}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={(key, value) => onChange(key, value)}
        style={{ width: width ?? '81%' }}
        inputType={inputType}
        multiline={multiline ?? false}
      />
    );
  };

  const convertFromHTML = (value = '') => {
    if (!value) return;
    const draftDescription = htmlToDraft(value);
    const { contentBlocks, entityMap } = draftDescription;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const newDescription = EditorState.createWithContent(contentState);
    setEditorState(newDescription);
  };

  const onCheckBoxGroupChange = (type: string, name: string | null, checked: boolean | null) => {
    if (!name) return;
    switch (type) {
      case 'category':
        setSelectedCategories({ ...selectedCategories, [name]: checked });
        break;
      case 'tag':
        setSelectedTags({ ...selectedTags, [name]: checked });
        break;
      case 'targetGroup':
        setSelectedTargetGroups({ ...selectedTargetGroups, [name]: checked });
        break;
      case 'community':
        setSelectedCommunities({ ...selectedCommunities, [name]: checked });
        break;
    }
  };

  const getSelectedIds = (selectedData: NewspaperArticle[]): number[] => {
    return selectedData.map((article) => article.id);
  };

  const save = async (publish: boolean) => {
    if (!result) return;
    const isValid = validatePage(result, selectedImage) && result.releaseDate.length && result?.author?.length;
    if (isValid) {
      const tempCategories = getSelectedCheckboxes(categories, selectedCategories, 'name');
      const tempTags = getSelectedCheckboxes(tags, selectedTags, 'tag');
      const tempTargetGroups = getSelectedCheckboxes(targetGroups, selectedTargetGroups, 'targetGroup');
      const tempCommunities = getSelectedCheckboxes(communities, selectedCommunities, 'community');
      const tempNewspapers = getSelectedIds(selectedNewspapers);
      const pageData = {
        ...result,
        published: publish,
        targetGroupIds: tempTargetGroups,
        tagIds: tempTags,
        categoriesIds: tempCategories,
        communitiesIds: tempCommunities,
        newspaperIds: tempNewspapers,
      };
      if (id !== 'create') {
        await updateArticlePage(id, pageData, selectedImage);
      } else {
        await createArticlePage(pageData, selectedImage);
      }
      navigate('/articles');
    }
    setHasErrors(!isValid);
  };
  const onNewChange = (key: string, value: string) => {
    setNewValue((result: any) => {
      return { ...result, [key]: value };
    });
  };

  const renderCheckBoxGroup = (
    type: string,
    data: any[],
    selectedData: any[],
    addNew: boolean,
    label?: string,
    newValue?: string,
    addButtonText?: string
  ) => (
    <CheckboxGroup
      label={label}
      type={type}
      data={data}
      selectedData={selectedData}
      onChange={onCheckBoxGroupChange}
      newValue={newValue}
      onNewChange={addNew ? onNewChange : undefined}
      onSaveNew={addNew ? saveTagOrCategory : undefined}
      addButtonText={addButtonText}
    />
  );

  const getSelected = () => {
    const selected = communities.find(({ community }) => community === result?.publisher);
    return selected?.id ?? 'none';
  };

  const getSelectData = () => {
    return communities.map((community) => ({ name: community.community, id: community.id }));
  };

  const onChangeNewspaper = (selections: NewspaperArticle[]) => {
    setSelectedNewspapers([...selections]);
  };

  return (
    <Layout title={renderHeader()}>
      {renderTextField('page_title', result?.title, 'title', 'page_title')}
      {renderTextField('lead', result?.lead, 'lead', 'lead', true)}
      <div className={classes.inputContainer}>
        {communities && communities.length && (
          <Select label="select_publisher" data={getSelectData()} onChange={onChange} value={getSelected()} />
        )}
        {newspapers && newspapers.length && (
          <SelectNewspapers
            label="newspaper"
            data={allNewspapers}
            selectedData={selectedNewspapers}
            onChange={onChangeNewspaper}
          />
        )}
        {renderTextField('author', result?.author, 'author', 'author')}
        <DateInput
          label="release_date"
          value={result?.releaseDate}
          type="releaseDate"
          placeholder="release_date"
          onChange={onChange}
          style={{ width: '80%' }}
        />
      </div>
      <ImageUploadButton
        text="page_image"
        imagePreviewUrl={imagePreviewUrl}
        handleImageSelection={handleImageSelection}
      />
      {renderTextField('caption', result?.caption, 'caption', 'caption')}
      <div className={classes.editorContainer}>
        <TextEditor editorState={editorState} onChange={(state: any) => setEditorState(state)} />
      </div>
      <div className={classes.container}>
        {renderCheckBoxGroup(
          'category',
          categories,
          selectedCategories,
          true,
          'categories',
          newValue?.category,
          'add_category'
        )}
        {renderCheckBoxGroup('tag', tags, selectedTags, true, 'tags', newValue?.tag, 'add_tag')}
      </div>
      <div className={classes.container}>
        {renderCheckBoxGroup('targetGroup', targetGroups, selectedTargetGroups, false, 'target_groups')}
        {renderCheckBoxGroup('community', communities, selectedCommunities, false, 'communities')}
      </div>
      <div className={classes.priorityContainer}>
        {renderTextField(
          'priority',
          result?.priorityIndex.toString(),
          'priorityIndex',
          'priority',
          false,
          460,
          'number'
        )}
      </div>
      <div>
        <Button
          text="save_draft"
          onClick={() => save(false)}
          backgroundColor={color.secondaryButton}
          width={220}
          marginBottom={30}
          disabled={!canSave}
        />
        <Button text="publish" onClick={() => save(true)} marginBottom={30} disabled={!canPublish} />
      </div>
      {hasErrors && <ErrorText text="content_page_save_error" />}
    </Layout>
  );
});

export default ArticlePageEditScreen;
