import React, { ChangeEvent } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import plus from 'static/plus.svg';
import { color } from '../../theme';
import { Button, Typography } from '@mui/material';

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    justifyItems: 'flex-start',
  },
  fileName: {
    fontWeight: 400,
    fontSize: '18px',
    alignSelf: 'center',
    padding: 0,
    margin: 0,
    marginLeft: 5,
  },
});

interface Props {
  buttonText: string;
  fileName?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  fileType: string;
  style?: React.CSSProperties;
}

export default function UploadButton(props: Props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { buttonText, onChange, fileName, fileType, style } = props;

  return (
    <div className={classes.root} style={style}>
      <input
        accept={fileType}
        style={{ display: 'none' }}
        id={`button-file_${buttonText}`}
        multiple
        type="file"
        onChange={onChange}
      />
      <label htmlFor={`button-file_${buttonText}`}>
        <Button variant="text" component="span">
          <img src={plus} alt="add" style={{ height: 15 }} />
          <Typography color={color.greyText} ml={1}>
            {t(buttonText)}
          </Typography>
        </Button>
      </label>
      {fileName && <p className={classes.fileName}>{fileName}</p>}
    </div>
  );
}
