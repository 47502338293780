import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../model/Root';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import AddButton from '../components/Button/AddButton';
import Table from '../components/Table/Table';
import { CATEGORY_TABLE } from '../utils/constants';
import { Row, LinkRow, MediaTypes } from '../types';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';

const CategoriesScreen = observer(() => {
  const { t } = useTranslation();
  const {
    categoryStore: { categories, getCategories, createCategory, updateCategory, deleteCategory },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow | null>(null);
  const [mediaType, setMediaType] = useState<MediaTypes>();
  const [addCategory, setAddCategory] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setMediaType(parseInt(event.target.value) as MediaTypes);
  };

  useEffect(() => {
    if (mediaType) {
      getCategories(mediaType);
    }
  }, [getCategories, categories, mediaType]);

  const save = async (isNew: boolean) => {
    if (!selectedRow) return;

    if (isNew) {
      await createCategory({ id: selectedRow.id, name: selectedRow.value, mediaTypeId: mediaType });
    } else {
      await updateCategory(selectedRow.id, { id: selectedRow.id, name: selectedRow.value });
    }
    setAddCategory(false);
    setSelectedRow(null);
    getCategories(mediaType);
  };

  const deleteHandler = async (id: number) => {
    await deleteCategory(id);
    setSelectedRow(null);
    getCategories(mediaType);
  };

  const renderTable = (data: Row[]) => (
    <Table
      tableType={CATEGORY_TABLE}
      data={data}
      selectedRow={selectedRow ?? null}
      setSelectedRow={(value: Row | LinkRow | null) => setSelectedRow(value)}
      addNew={addCategory}
      onSave={() => save(addCategory)}
      onAddNew={() => setAddCategory(!addCategory)}
      onDelete={(id) => deleteHandler(id)}
    />
  );

  return (
    <Layout title={t('categories')}>
      <Grid container>
        <Grid item xs={12} mb={1}>
          <Typography variant="body1" color="GrayText">
            {t('category_mediatype_help')}
          </Typography>
        </Grid>
        <Grid item xs={12} mb={3}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Mediatyyppi</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={mediaType?.toString() ?? ''}
              label="Mediatyyppi"
              onChange={handleChange}
            >
              {Object.values(MediaTypes)
                .filter((item) => Number(item) >= 0)
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {t(`mediatype_${key}`)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} mb={1}>
          <AddButton onClick={() => setAddCategory(!addCategory)} text={t('add_category')} />
        </Grid>
        <Grid item xs={12}>
          {renderTable(categories.length > 0 ? categories : [])}
        </Grid>
      </Grid>
    </Layout>
  );
});

export default CategoriesScreen;
