import { Podcast, Episode } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  podcasts: {
    podcasts: '/podcasts',
    id: '/podcasts/{id}',
    checkRssFeed: '/podcasts/checkRssFeed',
  },
});

// Podcast
export const getPodcasts = (): Promise<Podcast[]> => api.get(API_PATHS.podcasts.podcasts, {});

export const getSinglePodcast = (id: string): Promise<Podcast> =>
  api.get(API_PATHS.podcasts.id.replace('{id}', id), {});

export const addSinglePodcast = (
  podcast: Podcast,
  episodes: Episode[],
  files: File[],
  imageFile?: File
): Promise<Podcast> => {
  const formData = new FormData();

  episodes?.forEach((episode) => formData.append('episodes', JSON.stringify(episode)));
  files?.forEach((file) => {
    formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  });

  Object.keys(podcast)
    .filter((k) => k !== 'episodes')
    .forEach((key) => {
      formData.append(key, podcast[key as keyof Podcast]?.toString() ?? '');
    });

  if (imageFile) formData.append('image', imageFile ?? podcast.image);

  return api.post(API_PATHS.podcasts.podcasts, formData, { timeout: 360000 });
};

export const updateSinglePodcast = (
  podcast: Podcast,
  episodes: Episode[],
  files: File[],
  imageFile?: File
): Promise<Podcast> => {
  const formData = new FormData();

  episodes?.forEach((episode) => formData.append('episodes', JSON.stringify(episode)));
  files?.forEach((file) => {
    formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  });

  Object.keys(podcast)
    .filter((k) => k !== 'episodes')
    .forEach((key) => {
      const value = podcast[key as keyof Podcast];
      value && formData.append(key, podcast[key as keyof Podcast]?.toString() ?? '');
    });

  const imageUpdated = formData.get('image') && !imageFile;
  if (!imageUpdated && imageFile) formData.append('image', imageFile);

  return api.put(API_PATHS.podcasts.podcasts, formData, { timeout: 360000 });
};

export const deleteSinglePodcast = (id: string): Promise<void> => {
  return api.delete(API_PATHS.podcasts.id.replace('{id}', id), {});
};

export const checkRssFeed = (url: string) => api.put(API_PATHS.podcasts.checkRssFeed, { feedUrl: url });
