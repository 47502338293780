import React, { FC, ReactNode } from 'react';
import { Checkbox, FormControl, FormHelperText, Grid, Typography } from '@mui/material';
import { FieldArray, useField } from 'formik';

interface FormFieldCheckboxListProps {
  label?: string | ReactNode;
  name: string;
  listItems: Array<{ id: number; name: string }>;
  readonly?: boolean;
  autoFocus?: boolean;
  nameField?: string;
}

const FormFieldCheckboxList: FC<FormFieldCheckboxListProps> = ({
  label,
  name,
  listItems,
  nameField,
}: FormFieldCheckboxListProps) => {
  const [field, meta] = useField(name);

  return (
    <FormControl error={!!meta.error}>
      {label && (
        <Typography variant="h6" style={{ marginBottom: 16 }}>
          {label}
        </Typography>
      )}
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            {listItems &&
              listItems?.length > 0 &&
              listItems.map((item) => (
                <Grid container key={`${name}_${item.id}`}>
                  <Grid item xs={3}>
                    <Checkbox
                      checked={field.value?.includes(item.id) ?? false}
                      onChange={(event) => {
                        if (event.target.checked) {
                          arrayHelpers.push(item.id);
                        } else {
                          const cIndex = field.value.indexOf(item.id) ?? -1;
                          if (cIndex > -1) arrayHelpers.remove(cIndex);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} pt={1} pl={1}>
                    <Typography>{item[nameField ?? 'name']}</Typography>
                  </Grid>
                </Grid>
              ))}
          </>
        )}
      />
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default FormFieldCheckboxList;
