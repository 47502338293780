import { FC } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';

interface KeyMessage {
  key: string;
  message: string;
}

interface SnackbarContentProps {
  onClose: () => void;
  error?: KeyMessage;
  success?: KeyMessage;
}

const Notification: FC<SnackbarContentProps> = (props: SnackbarContentProps) => {
  const { error, success, onClose } = props;
  const { t } = useTranslation();

  if (!error && !success) return <></>;

  let message = '';
  if (error) {
    message = error.message;
  } else {
    message = success?.message ?? '';
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={true}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert severity={error ? 'error' : 'success'}>{t(message)}</Alert>
    </Snackbar>
  );
};

export default Notification;
