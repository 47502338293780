import React, { ChangeEventHandler, FC } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

interface ImageUploadButtonProps {
  text: string;
  handleImageSelection: ChangeEventHandler<HTMLInputElement>;
  imagePreviewUrl?: string | null;
  name?: string;
  noPreview?: boolean;
}

const ImageUploadButton: FC<ImageUploadButtonProps> = ({
  text,
  imagePreviewUrl,
  handleImageSelection,
  name,
  noPreview,
}: ImageUploadButtonProps) => {
  const { t } = useTranslation();

  return (
    <Grid container item>
      <Grid item xs={12}>
        {!noPreview && !!imagePreviewUrl && (
          <img
            alt=""
            src={imagePreviewUrl}
            crossOrigin="anonymous"
            style={{
              height: 340,
              borderRadius: 9,
            }}
          />
        )}
        {!noPreview && !imagePreviewUrl && (
          <Typography variant="caption" color="GrayText" fontSize={16}>
            {t('no_image')}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id={`image-${name ?? 'file'}`}
          type="file"
          onChange={handleImageSelection}
        />
        <label htmlFor={`image-${name ?? 'file'}`}>
          <Button component="span" style={{ fontSize: '16px' }}>
            <AddIcon fontSize="small" /> {t(text)}
          </Button>
        </label>
      </Grid>
    </Grid>
  );
};

export default ImageUploadButton;
