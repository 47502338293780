import React, { FC, ReactNode } from 'react';
import { Switch, FormControlLabel, FormControl } from '@mui/material';
import { useField, ErrorMessage } from 'formik';

interface FormFieldSwitchProps {
  label: string | ReactNode;
  name: string;
  readonly?: boolean;
  autoFocus?: boolean;
}

const FormFieldSwitch: FC<FormFieldSwitchProps> = ({ label, name, readonly, autoFocus }: FormFieldSwitchProps) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    helpers.setValue(event.target.checked);
  };

  return (
    <FormControl error={!!meta.error}>
      <FormControlLabel
        id={field.name}
        control={
          <Switch
            checked={field.value ?? false}
            onChange={handleChange}
            autoFocus={autoFocus}
            name={field.name}
            onBlur={field.onBlur}
            color="primary"
          />
        }
        label={label}
        disabled={readonly}
      />
      <ErrorMessage name={field.name} />
    </FormControl>
  );
};

export default FormFieldSwitch;
