import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import Table from '../../components/Table/Table';
import AddButton from '../../components/Button/AddButton';
import AlertModal from '../../components/AlertModal';
import { useTranslation } from 'react-i18next';
import { ARTICLE_PAGE_TABLE } from '../../utils/constants';
import { LinkRow, Row } from '../../types';

const ArticlePageScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    articlePageStore: { articles, getArticles, deleteArticlePage },
    userStore: { user, communities, getCommunities },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow>();
  const [result, setResult] = useState<Row[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const filterArticles = (allArticles: any[], communities: any[], onwCommunities: any[]) => {
    const filtered = communities.filter((community: any) => {
      return onwCommunities.some((el) => {
        return el.id === community.id;
      });
    });
    const filteredArticles = allArticles.filter((article) => {
      const temp = article.communities.filter((community) => {
        const isFound = filtered.some((el) => {
          return el.id === community;
        });
        return isFound;
      });
      if (article.communities.length === 0) return true;
      if (temp.length > 0) return true;
      else return false;
    });
    return filteredArticles;
  };

  useEffect(() => {
    const fetchArticles = async () => {
      await getArticles();
      await getCommunities();
      if (user?.admin || user?.publisher) {
        const tempResult: Row[] =
          articles.length > 0
            ? articles.map((page) => ({
                id: page.id,
                name: page.title,
                value: page.title,
                secondValue: page.published ? t('published') : t('draft'),
              }))
            : [];
        setResult(tempResult);
      } else {
        if (communities && user?.communities.length) {
          const filteredArticles = filterArticles(articles, communities, user.communities);
          const tempResult: Row[] =
            filteredArticles && filteredArticles.length > 0
              ? filteredArticles.map((page) => ({
                  id: page?.id,
                  name: page?.title,
                  value: page?.title,
                  secondValue: (page?.published ? t('published') : t('draft')) ?? '',
                }))
              : [];
          setResult(tempResult);
        }
      }
    };
    fetchArticles();
  }, [getArticles, articles, getCommunities, user, communities, t]);

  useEffect(() => {
    if (!selectedRow?.id || modalOpen) return;
    navigate(`/articles/${selectedRow.id}`);
  }, [selectedRow?.id, navigate, modalOpen]);

  const navigateTo = () => {
    navigate('/articles/create');
  };

  const cancel = () => {
    setSelectedRow(undefined);
    setModalOpen(false);
  };

  const deletePage = async () => {
    if (selectedRow?.id) {
      await deleteArticlePage(selectedRow.id.toString());
      await getArticles();
      const tempResult: Row[] =
        articles.length > 0
          ? articles.map((page) => ({
              id: page.id,
              name: page.title,
              value: page.title,
              secondValue: page.published ? t('published') : t('draft'),
            }))
          : [];
      setResult(tempResult);
    }
    setModalOpen(false);
  };

  const toggleDelete = (id: number) => {
    setModalOpen(true);
    setSelectedRow({ id: id });
  };

  return (
    <Layout title={t('articles')}>
      <AddButton text={t('add_article')} onClick={navigateTo} />
      <Table
        tableType={ARTICLE_PAGE_TABLE}
        data={result}
        setSelectedRow={(value) => setSelectedRow({ id: value?.id, value: value?.value })}
        selectedRow={selectedRow}
        onDelete={(id) => toggleDelete(id)}
      />
      <AlertModal
        open={modalOpen}
        title="delete_article"
        onClose={() => setModalOpen(!modalOpen)}
        leftButtonPress={cancel}
        rightButtonPress={deletePage}
      />
    </Layout>
  );
});

export default ArticlePageScreen;
