import React, { FC } from 'react';
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { color } from '../../theme';
import { useTranslation } from 'react-i18next';
import { Subscription } from '../../types';
import { format } from 'date-fns';
import { cancelUserSubscription } from 'services/subscriptionApi';

interface UserSubscriptionsProps {
  subscriptions?: Subscription[];
  reloadUser: () => Promise<void>;
}

const UserSubscriptions: FC<UserSubscriptionsProps> = ({ subscriptions, reloadUser }: UserSubscriptionsProps) => {
  const { t } = useTranslation();
  const [selectedSubId, setSelectedSubId] = React.useState(0);

  const handleClickOpen = (id: number) => {
    setSelectedSubId(id);
  };

  const handleClose = () => {
    setSelectedSubId(0);
  };

  const handleSubscriptionCancel = async () => {
    await cancelUserSubscription(selectedSubId);
    setSelectedSubId(0);
    await reloadUser();
  };

  const getPaymentType = (type?: number) => {
    if (!type) return '';

    let result = '';
    switch (type) {
      case 1:
        result = 'Stripe';
        break;
      case 2:
        result = 'Koodi';
        break;
      case 3:
        result = 'Lahja';
        break;
      case 0:
        result = 'Perheenjäsen/ilmainen';
        break;
    }

    return result;
  };

  return (
    <Grid container>
      <Grid item xs={12} mt={3} mb={1}>
        <Typography variant="h5">{t('subscriptions')}</Typography>
      </Grid>
      <Grid container item xs={12} fontWeight={'bold'}>
        <Grid item xs={12} md={2}>
          Tilaustyyppi
        </Grid>
        <Grid item xs={12} md={1}>
          Tila
        </Grid>
        <Grid item xs={12} md={2}>
          Tilausjakso
        </Grid>
        <Grid item xs={12} md={2}>
          Tilauskoodi
        </Grid>
        <Grid item xs={12} md={2}>
          Maksumuoto
        </Grid>
        <Grid item xs={12} md={1}>
          Omistaja
        </Grid>
        <Grid item xs={12} md={2}></Grid>
      </Grid>
      {(!subscriptions || subscriptions.length == 0) && (
        <Grid container item xs={12}>
          <Grid item xs={12} md={2}>
            <Typography fontWeight={500}>{t('no_subcsriptions')}</Typography>
          </Grid>
        </Grid>
      )}
      {subscriptions?.map((sub) => (
        <Grid container item xs={12} key={sub.id}>
          <Grid item xs={12} md={2}>
            <Typography fontWeight={500}>{sub.subscriptionType?.name}</Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography variant="caption" color={sub.valid ? 'green' : 'red'}>
              {sub.valid ? 'voimassa' : 'peruttu'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            {format(new Date(sub.periodStarts), 'd.M.yyyy')} -{' '}
            {sub.periodEnds == null ? 'toistaiseksi' : format(new Date(sub.periodEnds), 'd.M.yyyy')}
          </Grid>
          <Grid item xs={12} md={2}>
            {sub.subscriptionCode}
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography variant="body2" color="darkblue">
              {getPaymentType(sub.paymentType)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            {(sub.ownerId ?? 0) > 0 ? t('family_member') : ''}
            {(sub.subscriptionType.type == 2 || sub.subscriptionType.type == 4) && !sub.ownerId
              ? t('subscription_owner')
              : ''}
          </Grid>
          <Grid item xs={12} md={2}>
            {sub.valid && (
              <Button
                onClick={() => handleClickOpen(sub.id)}
                style={{ backgroundColor: color.deleteButton, color: color.white }}
              >
                {t('cancel_subscription')}
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
      <Dialog open={selectedSubId > 0} onClose={handleClose}>
        <DialogTitle>{t('cancel_subscription_confirm_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('cancel_subscription_confirm_text')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: color.greyText }}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSubscriptionCancel} color="error">
            {t('cancel_subscription')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UserSubscriptions;
