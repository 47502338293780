import { Audiobook, Chapter } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  audiobooks: {
    audiobooks: '/audiobooks',
    id: '/audiobooks/{id}',
  },
});

// Audiobook
export const getAudiobooks = (): Promise<Audiobook[]> => api.get(API_PATHS.audiobooks.audiobooks, {});

export const getSingleAudiobook = (id: string): Promise<Audiobook> =>
  api.get(API_PATHS.audiobooks.id.replace('{id}', id), {});

export const addSingleAudiobook = (
  audiobook: Audiobook,
  chapters: Chapter[],
  files: File[],
  imageFile?: File,
  ebookFile?: File,
  pdfBookFile?: File
): Promise<Audiobook> => {
  const formData = new FormData();

  chapters?.forEach((chapter) => formData.append('chapters', JSON.stringify(chapter)));
  files?.forEach((file) => {
    formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  });

  Object.keys(audiobook)
    .filter((k) => k !== 'chapters')
    .forEach((key) => {
      formData.append(key, audiobook[key as keyof Audiobook]?.toString() ?? '');
    });

  if (imageFile) formData.append('image', imageFile ?? audiobook.image);
  if (ebookFile) formData.append('ebook', ebookFile ?? audiobook.ebook);
  if (pdfBookFile) formData.append('pdfBook', pdfBookFile ?? audiobook.pdfBook);

  return api.post(API_PATHS.audiobooks.audiobooks, formData, { timeout: 360000 });
};

export const updateSingleAudiobook = (
  audiobook: Audiobook,
  chapters: Chapter[],
  files: File[],
  imageFile?: File,
  ebookFile?: File,
  pdfBookFile?: File
): Promise<Audiobook> => {
  const formData = new FormData();

  chapters?.forEach((chapter) => formData.append('chapters', JSON.stringify(chapter)));
  files?.forEach((file) => {
    formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  });

  Object.keys(audiobook)
    .filter((k) => k !== 'chapters')
    .forEach((key) => {
      const value = audiobook[key as keyof Audiobook];
      value != undefined && formData.append(key, audiobook[key as keyof Audiobook]?.toString() ?? '');
    });

  const imageUpdated = formData.get('image') && !imageFile;
  if (!imageUpdated && imageFile) formData.append('image', imageFile);

  const ebookUpdated = formData.get('ebook') && !ebookFile;
  if (!ebookUpdated && ebookFile) formData.append('ebook', ebookFile);

  const pdfBookUpdated = formData.get('pdfBook') && !pdfBookFile;
  if (!pdfBookUpdated && pdfBookFile) formData.append('pdfBook', pdfBookFile);

  return api.put(API_PATHS.audiobooks.audiobooks, formData, { timeout: 360000 });
};

export const deleteSingleAudiobook = (id: string): Promise<void> => {
  return api.delete(API_PATHS.audiobooks.id.replace('{id}', id), {});
};
