import React from 'react';
import { useTranslation } from 'react-i18next';
import { color } from '../theme';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  errorText: {
    width: '100%',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: 'normal',
    color: color.error,
  },
});

interface Props {
  text: string;
  style?: React.CSSProperties;
}

export default function ErrorText(props: Props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { text, style } = props;

  return (
    <p className={classes.errorText} style={style}>
      {t(text)}
    </p>
  );
}
