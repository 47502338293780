import React, { useState, useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { Magazine, MediaTypes } from '../../types';
import { color } from '../../theme';
import Button from '../../components/Button/Button';
import { MagazineDefaults } from '../../utils/defaults';
import { Grid, Typography } from '@mui/material';
import ImageUploadButton from '../../components/Button/ImageUploadButton';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/FormComponents/FormFieldText';
import FormFieldDate from 'components/FormComponents/FormFieldDate';
import FormFieldTextArea from 'components/FormComponents/FormFieldTextArea';
import FormFieldCheckboxList from 'components/FormComponents/FormFieldCheckboxList';
import FileUploadButton from 'components/Button/FileUploadButton';

const getValidationSchema = (t: (key: string) => string, published: boolean) => {
  if (!published) {
    const draftSchema = Yup.object().shape({
      name: Yup.string().required(t('common_required')),
      mediaTypeId: Yup.number().required(t('common_required')),
    });

    return draftSchema;
  }

  const magazineSchema = Yup.object().shape({
    name: Yup.string().required(t('common_required')),
    image: Yup.string().required(t('common_required')),
    description: Yup.string().required(t('common_required')),
    publisher: Yup.string().required(t('common_required')),
    releaseDate: Yup.string().required(t('common_required')),
    language: Yup.string().required(t('common_required')),
    mediaTypeId: Yup.number().required(t('common_required')),
    categoryIds: Yup.array().min(1, t('common_required')),
    targetGroupIds: Yup.array().min(1, t('common_required')),
  });

  return magazineSchema;
};

const MagazineEditScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    categoryStore: { categories, getCategories },
    tagStore: { tags, getTags },
    targetGroupStore: { targetGroups, getTargetGroups },
    magazineStore: { selectedMagazine, getSelectedMagazine, updateMagazine, createMagazine, loading },
  } = useMst();
  const { id } = useParams<{ id: string }>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [selectedEpubFile, setSelectedEpubFile] = useState<File>();
  const [selectedEmagazineFile, setSelectedEmagazineFile] = useState<File>();
  const [magazine, setMagazine] = useState<Magazine>(MagazineDefaults);

  useEffect(() => {
    const fetchLists = async () => {
      await getCategories(MediaTypes.Magazine);
      await getTags(MediaTypes.Magazine);
      await getTargetGroups();
    };

    fetchLists();
  }, [getCategories, getTags, getTargetGroups]);

  useEffect(() => {
    const fetchPage = async () => {
      if (id !== 'create') {
        await getSelectedMagazine(id);
        if (selectedMagazine?.image) {
          setImagePreviewUrl(selectedMagazine?.image);
        }
        setMagazine(selectedMagazine);
      }
    };

    fetchPage();
  }, [getSelectedMagazine, selectedMagazine, id]);

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target?.files) {
      const image = URL.createObjectURL(target.files[0]);
      setSelectedImage(target.files[0]);
      setImagePreviewUrl(image);
    }
  };

  const saveMagazine = async (magazine: Magazine) => {
    if (id !== 'create') {
      await updateMagazine(magazine, selectedImage, selectedEpubFile, selectedEmagazineFile);
    } else {
      await createMagazine(magazine, selectedImage, selectedEpubFile, selectedEmagazineFile);
    }
    navigate('/magazines');
  };

  const renderHeader = () => {
    const modeText = id !== 'create' ? t('magazine.edit') : t('magazine.add');
    return loading ? t('magazine.loading') : modeText;
  };

  if (loading) {
    return (
      <Layout title={renderHeader()}>
        <CircularProgress size={40} style={{ alignSelf: 'center', marginTop: 30, color: color.primaryButton }} />
        <Typography variant="h5">{t('magazine.loading_text')}</Typography>
      </Layout>
    );
  }

  return (
    <Formik
      initialValues={magazine}
      enableReinitialize
      onSubmit={(values: Magazine) => {
        saveMagazine(values);
      }}
      validationSchema={Yup.lazy((values: Magazine) => getValidationSchema(t, values.published))}
    >
      {({ values, isValid, submitForm, setFieldValue }: FormikProps<Magazine>) => {
        return (
          <Layout title={renderHeader()}>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <Typography
                  variant="h4"
                  color={magazine?.published ? 'primary' : 'secondary'}
                  borderColor={magazine?.published ? 'primary' : 'secondary'}
                  border={1}
                  borderRadius={2}
                  sx={{ padding: 1, fontSize: 18, width: 200, marginBottom: 2 }}
                >
                  {magazine?.published ? t('published') : t('draft')}
                </Typography>
              </Grid>

              {/* Add/modify name, description, number and release date */}
              <Grid item xs={10}>
                <FormFieldText label={t('magazine.name')} name="name" />
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={4}>
                  <FormFieldText label={t('magazine.publisher')} name="publisher" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldDate label={t('magazine.release_date')} name="releaseDate" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={4}>
                  <FormFieldText label={t('magazine.language')} name="language" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={12}>
                  <FormFieldTextArea label={t('magazine.description')} name="description" rows={7} />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldText label={t('magazine.shop_url')} name="shopUrl" />
                </Grid>
              </Grid>

              {/* Upload cover image */}
              <Grid item xs={10}>
                <ImageUploadButton
                  text="magazine.image"
                  imagePreviewUrl={imagePreviewUrl}
                  handleImageSelection={handleImageSelection}
                />
              </Grid>

              {/* Upload ePub file */}
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t('magazine.ePub')}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <FileUploadButton
                    text="magazine.ePub_file"
                    idPrefix="ePub"
                    src={values?.ePub}
                    mimeType="application/epub+zip"
                    handleSelection={(event) => {
                      if (event.target.files) {
                        setFieldValue('ePub', event.target.files[0].name);
                        setSelectedEpubFile(event.target.files[0]);
                      }
                    }}
                    handleRemove={() => {
                      setFieldValue('ePub', null);
                      setSelectedEpubFile(undefined);
                    }}
                    emptyText="magazine.ePub_not_set"
                  />
                </Grid>
              </Grid>

              {/* Upload eMagazine file */}
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t('magazine.eMagazine')}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <FileUploadButton
                    text="magazine.eMagazine_file"
                    idPrefix="eMagazine"
                    src={values?.eMagazine}
                    mimeType="application/pdf"
                    handleSelection={(event) => {
                      if (event.target.files) {
                        setFieldValue('eMagazine', event.target.files[0].name);
                        setSelectedEmagazineFile(event.target.files[0]);
                      }
                    }}
                    handleRemove={() => {
                      setFieldValue('eMagazine', null);
                      setSelectedEmagazineFile(undefined);
                    }}
                    emptyText="magazine.eMagazine_not_set"
                  />
                </Grid>
              </Grid>

              {/* Select categories, tags, user groups */}
              <Grid container item xs={12} spacing={3}>
                <Grid container item xs={6} spacing={3} alignContent={'flex-start'}>
                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('categories')} name="categoryIds" listItems={categories} />
                  </Grid>

                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('target_groups')} name="targetGroupIds" listItems={targetGroups} />
                  </Grid>

                  {/* publish or save as draft */}
                  <Grid container item xs={10} spacing={3} ml={1}>
                    <Button
                      text="save_draft"
                      onClick={() => {
                        setFieldValue('published', false);
                        submitForm();
                      }}
                      backgroundColor={color.secondaryButton}
                      width={220}
                      marginBottom={30}
                    />
                    <Button
                      text="publish"
                      onClick={() => {
                        setFieldValue('published', true);
                        submitForm();
                      }}
                      marginBottom={30}
                      disabled={!isValid}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={6} spacing={3}>
                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('tags')} nameField="tag" name="tagIds" listItems={tags} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Layout>
        );
      }}
    </Formik>
  );
});

export default MagazineEditScreen;
