import React, { FC } from 'react';
import { FilledInputProps, InputBaseComponentProps, InputProps, OutlinedInputProps, TextField } from '@mui/material';
import { useField } from 'formik';

interface FormFieldTextProps {
  label?: string | null;
  name: string;
  readonly?: boolean;
  helperText?: string;
  autoFocus?: boolean;
  password?: boolean;
  inputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
  inputTagProps?: InputBaseComponentProps;
}

const FormFieldText: FC<FormFieldTextProps> = ({
  label,
  name,
  readonly,
  helperText,
  autoFocus,
  password,
  inputProps,
  inputTagProps,
}: FormFieldTextProps) => {
  const [field, meta] = useField(name);

  return (
    <TextField
      id={name}
      label={label}
      error={!!meta.error && meta.touched}
      helperText={(!!meta.error && meta.touched && meta.error) ?? helperText}
      fullWidth
      value={field.value ?? ''}
      onBlur={field.onBlur}
      onChange={field.onChange}
      name={field.name}
      disabled={readonly}
      variant="outlined"
      size="small"
      autoFocus={autoFocus}
      type={password ? 'password' : undefined}
      // InputLabelProps={{ shrink: true }}
      InputProps={inputProps}
      inputProps={inputTagProps}
    />
  );
};

export default FormFieldText;
