import UserIcon from '../static/users.svg';
import SettingsIcon from '../static/settings.svg';
import PagesIcon from '../static/pages.svg';
import ArticlesIcon from '../static/articles.svg';
import MediaIcon from '../static/media.svg';
import ReportIcon from '../static/pages.svg';

import BookLogo from '../static/navigation/book.svg';
import GlobeLogo from '../static/navigation/globe.svg';
import HomeLogo from '../static/navigation/home.svg';
import MicLogo from '../static/navigation/mic.svg';
import MusicLogo from '../static/navigation/music.svg';
import NewsLogo from '../static/navigation/news.svg';
import PenLogo from '../static/navigation/pen.svg';
import VideoLogo from '../static/navigation/video.svg';

import ChurchLogo from '../static/header/church.svg';
import ClockLogo from '../static/header/clock.svg';
import HeartLogo from '../static/header/heart.svg';
import ShoppingLogo from '../static/header/shopping.svg';

export const CLIENT_ERROR = 'CLIENT_ERROR';
export const NETWORK_ERROR = 'NETWORK_ERROR';
export const TIMEOUT_ERROR = 'TIMEOUT_ERROR';
export const CONNECTION_ERROR = 'CONNECTION_ERROR';

export enum TableDataTypes {
  user = 'USER',
  link = 'LNK',
  collectionPage = 'COLLECTION_PAGE',
  contentPage = 'CONTENT_PAGE',
  articlePage = 'ARTICLE_PAGE',
  category = 'CATEGORY',
  tag = 'TAG',
  userRole = 'USER_ROLE',
  community = 'COMMUNITY',
  promotion = 'PROMOTION',
  newspaper = 'NEWSLETTER',
  audiobook = 'AUDIOBOOK',
  targetGroup = 'TARGET_GROUP',
  subscriptionType = 'SUBSCRIPTION_TYPE',
  album = 'ALBUM',
  podcast = 'PODCAST',
  magazine = 'MAGAZINE',
  program = 'PROGRAM',
  onlineMagazine = 'ONLINEMAGAZINE',
  digiMagazine = 'DIGIMAGAZINE',
}

export enum UserRole {
  admin = 'admin',
  publisher = 'publisher',
  creator = 'creator',
  employee = 'employee',
  portal = 'user',
}

export const CLIENT_CONNECTION_ERRORS = [NETWORK_ERROR, TIMEOUT_ERROR, CONNECTION_ERROR];

export const USERS_ITEMS = [
  { title: 'admin_users', path: '/admin-users', disabled: false, role: UserRole.admin },
  { title: 'employee_users', path: '/emp-users', disabled: false, role: UserRole.admin },
  { title: 'portal_users', path: '/portal-users', disabled: false, role: UserRole.admin },
  { title: 'target_groups', path: '/targets-groups', disabled: false, role: UserRole.admin },
  { title: 'communities', path: '/communities', disabled: false, role: UserRole.admin },
  { title: 'subscription_types', path: '/subscriptions', disabled: false, role: UserRole.admin },
];

export const SETTINGS_ITEMS = [
  { title: 'header_footer', path: '/header-footer', disabled: false, role: UserRole.admin },
  { title: 'navigation', path: '/navigation', disabled: false, role: UserRole.admin },
  { title: 'categories', path: '/categories', disabled: false, role: UserRole.admin },
  { title: 'tags', path: '/tags', disabled: false, role: UserRole.admin },
];

export const PAGES_ITEMS = [
  { title: 'main_page_promotions', path: '/promotion', disabled: false, role: UserRole.publisher },
  { title: 'collection_page', path: '/collection', disabled: false, role: UserRole.admin },
  { title: 'content_page', path: '/content', disabled: false, role: UserRole.creator },
];

export const MEDIA_ITEMS = [
  //{ title: 'newspaper', path: '/newspaper', disabled: true, role: UserRole.creator },
  // { title: 'magazine.title', path: '/magazines', disabled: false, role: UserRole.creator },
  { title: 'audiobooks', path: '/audiobooks', disabled: false, role: UserRole.admin },
  { title: 'albums', path: '/albums', disabled: false, role: UserRole.admin },
  { title: 'podcasts', path: '/podcasts', disabled: false, role: UserRole.admin },
  { title: 'onlineMagazine.title', path: '/onlinemagazines', disabled: false, role: UserRole.creator },
  { title: 'digiMagazines.title', path: '/digimagazines', disabled: false, role: UserRole.creator },
  { title: 'programs.title', path: '/programs', disabled: false, role: UserRole.admin },
];

export const REPORT_ITEMS = [{ title: 'reports.title', path: '/reports', disabled: false, role: UserRole.admin }];

export const MENU_HEADERS = [
  { title: 'users', icon: UserIcon, items: USERS_ITEMS },
  { title: 'settings', icon: SettingsIcon, items: SETTINGS_ITEMS },
  { title: 'pages', icon: PagesIcon },
  { title: 'article.header', icon: ArticlesIcon },
  { title: 'media', icon: MediaIcon, items: MEDIA_ITEMS },
  { title: 'reports.title', icon: ReportIcon, items: REPORT_ITEMS },
];

export const NAV_LINK_LOGOS = [
  { value: 'book', path: BookLogo },
  { value: 'globe', path: GlobeLogo },
  { value: 'home', path: HomeLogo },
  { value: 'mic', path: MicLogo },
  { value: 'music', path: MusicLogo },
  { value: 'news', path: NewsLogo },
  { value: 'pen', path: PenLogo },
  { value: 'video', path: VideoLogo },
];

export const HEADER_LINK_LOGOS = [
  { value: 'church', path: ChurchLogo },
  { value: 'clock', path: ClockLogo },
  { value: 'heart', path: HeartLogo },
  { value: 'shopping', path: ShoppingLogo },
];

export const USER_TABLE = {
  dataType: TableDataTypes.user,
  headerTitles: ['user_name', 'user_email', 'user_role'],
  isEditable: false,
  showDelete: false,
};

export const CATEGORY_TABLE = {
  dataType: TableDataTypes.category,
  headerTitles: ['category'],
  isEditable: true,
  showDelete: true,
};

export const TAG_TABLE = {
  dataType: TableDataTypes.user,
  headerTitles: ['tag'],
  isEditable: true,
  showDelete: true,
};

export const COLLECTION_PAGE_TABLE = {
  dataType: TableDataTypes.collectionPage,
  headerTitles: ['collection_page'],
  isEditable: false,
  showDelete: false,
};

export const TARGET_GROUP_TABLE = {
  dataType: TableDataTypes.targetGroup,
  headerTitles: ['target_group', 'target_group_type'],
  isEditable: false,
  showDelete: false,
};

export const COMMUNITY_TABLE = {
  dataType: TableDataTypes.community,
  headerTitles: ['commnunity_name'],
  isEditable: false,
  showDelete: false,
};

export const PROMOTIONS_TABLE = {
  dataType: TableDataTypes.promotion,
  headerTitles: ['main_page_promotions', 'visibility'],
  isEditable: false,
  showDelete: false,
};

export const CONTENT_PAGE_TABLE = {
  dataType: TableDataTypes.contentPage,
  headerTitles: ['page', 'visibility'],
  isEditable: false,
  showDelete: true,
};

export const ARTICLE_PAGE_TABLE = {
  dataType: TableDataTypes.articlePage,
  headerTitles: ['article.title', 'visibility'],
  isEditable: false,
  showDelete: true,
};

export const NESLETTER_TABLE = {
  dataType: TableDataTypes.newspaper,
  headerTitles: ['newspaper_name_number', 'visibility'],
  isEditable: false,
  showDelete: true,
};

export const AUDIOBOOK_TABLE = {
  dataType: TableDataTypes.audiobook,
  headerTitles: ['audiobook_name', 'visibility'],
  isEditable: false,
  showDelete: true,
};

export const SUBSCRIPTION_TYPE_TABLE = {
  dataType: TableDataTypes.subscriptionType,
  headerTitles: ['subscription_type_name', 'subscription_type_price'],
  isEditable: false,
  showDelete: true,
};

export const ALBUM_TABLE = {
  dataType: TableDataTypes.album,
  headerTitles: ['albums_name', 'visibility'],
  isEditable: false,
  showDelete: true,
};

export const PODCAST_TABLE = {
  dataType: TableDataTypes.podcast,
  headerTitles: ['podcasts_name', 'visibility'],
  isEditable: false,
  showDelete: true,
};

export const MAGAZINE_TABLE = {
  dataType: TableDataTypes.magazine,
  headerTitles: ['magazine.name', 'visibility'],
  isEditable: false,
  showDelete: true,
};

export const PROGRAM_TABLE = {
  dataType: TableDataTypes.program,
  headerTitles: ['programs.name', 'visibility'],
  isEditable: false,
  showDelete: true,
};

export const DIGIMAGAZINE_TABLE = {
  dataType: TableDataTypes.digiMagazine,
  headerTitles: ['digiMagazines.name', 'visibility'],
  isEditable: false,
  showDelete: true,
};
