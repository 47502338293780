import React, { CSSProperties, FC, ReactNode } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
  SxProps,
  Theme,
  IconButton,
} from '@mui/material';
import LoadButton from 'components/common/LoadButton';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

interface DialogBaseProps {
  children: ReactNode;
  title: string;
  show?: boolean;
  onCancel?: () => void;
  onOk: () => void;
  onClose?: () => void;
  okDisabled?: boolean;
  loading?: boolean;
  okTitle?: string;
  cancelTitle?: string;
  warning?: boolean;
  hideCancel?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  titleHelpText?: string;
  style?: CSSProperties;
  titleClass?: string;
  modal?: boolean;
  extraButton?: ReactNode;
  sx?: SxProps<Theme>;
  fullScreen?: boolean;
  actionBarClassName?: string;
  contentStyle?: CSSProperties;
  hideActions?: boolean;
}

const DialogBase: FC<DialogBaseProps> = ({
  children,
  title,
  show,
  onCancel,
  onOk,
  onClose,
  okDisabled,
  loading,
  okTitle,
  cancelTitle,
  warning,
  hideCancel,
  size,
  titleHelpText,
  style,
  titleClass,
  modal,
  extraButton,
  sx,
  fullScreen,
  actionBarClassName,
  contentStyle,
  hideActions,
}: DialogBaseProps) => {
  const { t } = useTranslation();
  const actionMargin = fullScreen ? 1 : 2;
  const titlePadding = fullScreen ? 1 : undefined;
  const contentOverflow = fullScreen ? 'auto' : 'hidden';

  return (
    <Dialog
      open={show ?? false}
      onClose={(event, reason) => {
        if (modal && (reason == 'escapeKeyDown' || reason == 'backdropClick')) {
          // in modal with ecape or backdrop click should not close dialog
          return;
        }
        onClose && onClose();
      }}
      onKeyDown={({ key }) => {
        if (key === 'Enter') {
          onOk();
          onClose && onClose();
        }
      }}
      fullWidth
      maxWidth={size}
      style={style}
      sx={sx}
      fullScreen={fullScreen}
    >
      <DialogTitle className={titleClass} sx={{ backgroundColor: 'primary.main', padding: titlePadding }}>
        {warning && (
          <Grid container alignItems="center">
            <Grid item xs={1} style={{ marginRight: 8, paddingTop: 5 }}>
              {warning && <WarningIcon fontSize="large" color="secondary" />}
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5" component="div" style={{ color: 'white' }}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        )}
        {!warning && (
          <Grid container alignItems="center">
            <Grid item xs={11}>
              <Typography variant="h5" component="div" style={{ color: 'white' }}>
                {title}
              </Typography>
              {titleHelpText && (
                <Typography variant="body2" component="div" style={{ color: 'silver' }}>
                  {titleHelpText}
                </Typography>
              )}
            </Grid>
            <Grid item xs={1}>
              {hideActions && (
                <IconButton
                  onClick={() => {
                    onClose && onClose();
                  }}
                  style={{ color: 'white' }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          marginTop: '16px',
          overflow: contentOverflow, // hide scroll bar
        }}
        style={contentStyle}
      >
        {children}
      </DialogContent>
      {!hideActions && (
        <DialogActions sx={{ justifyContent: 'flex-start' }} className={actionBarClassName}>
          <Box ml={2} mb={actionMargin}>
            <LoadButton
              loading={loading}
              disabled={okDisabled}
              onClick={() => {
                onOk();
                onClose && onClose();
              }}
              style={{ marginRight: 16 }}
              color={warning ? 'error' : 'primary'}
            >
              {okTitle ? t(okTitle) : t('ok')}
            </LoadButton>
            {hideCancel !== true && (
              <Button
                type="button"
                onClick={() => {
                  onCancel && onCancel();
                  onClose && onClose();
                }}
                color="secondary"
              >
                {cancelTitle ? t(cancelTitle) : t('cancel')}
              </Button>
            )}
            {extraButton}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogBase;
