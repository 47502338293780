import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import {
  Article,
  ArticleImage,
  ArticleSections,
  ArticleTypes,
  ArticleTypesEV,
  MediaTypes,
  TargetGroup,
} from '../../types';
import { ArticleDefaults } from '../../utils/defaults';
import { Grid, Typography, Button, Divider, IconButton, Paper } from '@mui/material';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/FormComponents/FormFieldText';
import FormFieldTextArea from 'components/FormComponents/FormFieldTextArea';
import FormFieldCheckboxList from 'components/FormComponents/FormFieldCheckboxList';
import FormFieldDropDown from 'components/FormComponents/FormFieldDropDown';
import { Editor as TinyMCEEditor } from 'tinymce';
import BundledEditor from 'components/common/BundledEditor';
import DialogBase from 'components/common/DialogBase';
import ArticlePreview from './ArticlePreview';
import { PreviewType } from 'containers/MagazineSegment/MagazinePreview';
import PreviewIcon from '@mui/icons-material/Preview';
import LinkIcon from '@mui/icons-material/Link';
import ArticleImagesEdit from './ArticleImagesEdit';
import ArticleCitationsEdit from './ArticleCitationsEdit';
import ArticleSideStoriesEdit from './ArticleSideStoriesEdit';
import FormFieldDateTime from 'components/FormComponents/FormFieldDateTime';
import FormFieldSwitch from 'components/FormComponents/FormFieldSwitch';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import { parseImportedWordDocument } from 'utils/articleImport';
import constants from 'config/constants';
import copy from 'copy-to-clipboard';

export const getColorByState = (state?: string) => {
  switch (state) {
    case 'notReady':
      return '#FF8766';
    case 'ready':
      return '#11dd11';
  }
};

export const editorSetup = (editor, limited) => {
  if (!limited) {
    editor.ui.registry.addButton(`addCitation`, {
      text: `Nosto`,
      onAction: () => editor.execCommand('mceInsertContent', false, '<p>nosto_1</p>'),
    });

    editor.ui.registry.addButton(`addSideStory`, {
      text: `Kainalojuttu`,
      onAction: () => editor.execCommand('mceInsertContent', false, '<p>kainalojuttu_1</p>'),
    });
  }

  editor.ui.registry.addButton(`addImage`, {
    text: `Kuva`,
    onAction: () => editor.execCommand('mceInsertContent', false, '<p>kuva_1</p>'),
  });
};

const isSectionNotNeeded = (values) => {
  return (
    values.type == ArticleTypes.Editorial ||
    values.type == ArticleTypes.Column ||
    values.type == ArticleTypes.Blog ||
    values.type == ArticleTypes.MostRedArticles ||
    values.type == ArticleTypes.PointOfView ||
    values.type == ArticleTypes.Opinion ||
    values.type == ArticleTypes.BookReview ||
    values.type == ArticleTypes.QuickVisit ||
    values.type == ArticleTypes.ChurchVisit ||
    values.type == ArticleTypes.GuestPen ||
    values.type == ArticleTypesEV.EditorialEV ||
    values.type == ArticleTypesEV.ColumnEV ||
    values.type == ArticleTypesEV.YouthPerspective ||
    values.type == ArticleTypesEV.News ||
    values.type == ArticleTypesEV.Topical ||
    values.type == ArticleTypesEV.International ||
    values.type == ArticleTypesEV.ChangeStory ||
    values.type == ArticleTypesEV.EverydayHero ||
    values.type == ArticleTypesEV.MyStory ||
    values.type == ArticleTypesEV.ExpertsOpinion ||
    values.type == ArticleTypesEV.Teaching ||
    values.type == ArticleTypesEV.CoWorkers ||
    values.type == ArticleTypesEV.Obituary ||
    values.type == ArticleTypesEV.Anniversary
  );
};

const getValidationSchema = (t: (key: string) => string, values: Article) => {
  if (!values) return Yup.object().shape({ title: Yup.string().required(t('common_required')) });

  if (values.published) {
    return Yup.object().shape({
      title: Yup.string().required(t('common_required')),
      type: Yup.string().test('type-exists', t('article.validate_type'), (value) => value != ArticleTypes.Undefined),
      section: isSectionNotNeeded(values)
        ? Yup.string().nullable()
        : Yup.string().test(
            'section-exists',
            t('article.validate_section'),
            (value) => value != ArticleSections.Undefined && value != null
          ),
      visibleDate: Yup.string().required(t('common_required')),
      releaseDate: Yup.string().required(t('common_required')),
      editorNotes: Yup.string().required(t('common_required')),
      targetGroupIds: Yup.array().min(1, t('common_required')),
      onlineMagazineIds: Yup.array().min(1, t('common_required')),
      publisher: Yup.string().required(t('common_required')),
      author: Yup.string().required(t('common_required')),
      images: Yup.array().test(
        'images-exists',
        t(
          values.type == ArticleTypes.SmallTextStory || values.type == ArticleTypes.Opinion
            ? 'article.images_short_required'
            : 'article.images_required'
        ),
        (value) => {
          if (!value) {
            return false;
          }

          return (
            (values.type == ArticleTypes.SmallTextStory ||
              values.type == ArticleTypes.Editorial ||
              values.type == ArticleTypes.Column ||
              values.type == ArticleTypes.PointOfView ||
              values.type == ArticleTypes.GuestPen ||
              values.type == ArticleTypes.Opinion ||
              value.findIndex((img) => img.front && img.src) != -1) &&
            value.findIndex((img) => img.share && img.src) != -1 &&
            value.findIndex((img) => img.link && img.src) != -1
          );
        }
      ),
    });
  } else {
    return Yup.object().shape({
      title: Yup.string().required(t('common_required')),
      type: Yup.string().test('type-exists', t('article.validate_type'), (value) => value != ArticleTypes.Undefined),
      section: isSectionNotNeeded(values)
        ? Yup.string().nullable()
        : Yup.string().test(
            'section-exists',
            t('article.validate_section'),
            (value) => value != ArticleSections.Undefined && value != null
          ),
      editorNotes: Yup.string().required(t('common_required')),
      onlineMagazineIds: Yup.array().min(1, t('common_required')),
    });
  }
};

export const renderSubTitle = (title: string, buttonTitle?: string | null, buttonOnClick?: () => void) => {
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        backgroundColor: '#cacaca',
        marginTop: 40,
        marginLeft: 16,
        height: 35,
        padding: 0,
        alignContent: 'center',
        borderRadius: 3,
      }}
      justifyContent={'space-between'}
    >
      <Grid item alignContent={'center'}>
        <Typography
          variant="caption"
          fontSize={16}
          fontFamily={'Poppins'}
          fontWeight={600}
          ml={1}
          textTransform={'uppercase'}
        >
          {title}
        </Typography>
      </Grid>
      {(buttonTitle?.length ?? 0) > 0 && (
        <Grid item mr={2}>
          <Button variant="text" size="small" style={{ color: '#000000' }} onClick={buttonOnClick}>
            <AddIcon fontSize="small" />
            {buttonTitle}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const ArticleEditScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    tagStore: { tags, getTags },
    targetGroupStore: { targetGroups, getTargetGroups },
    articleStore: { selectedArticle, getSelectedArticle, updateArticle, createArticle, loading, initLoading },
    userStore: { user, communities, getCommunities },
    onlineMagazineStore: { onlineMagazines, getAllOnlineMagazines },
    errorStore: { setSuccess },
  } = useMst();
  const { id, magazineId } = useParams<{ id: string; magazineId: string }>();
  const [article, setArticle] = useState<Article>();
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const editorLangRef = useRef<TinyMCEEditor | null>(null);
  const editorSideStoryRefs = useRef<TinyMCEEditor[]>([]);
  const editorExtraNotesRef = useRef<TinyMCEEditor | null>(null);
  const [previewDialog, setPreviewDialog] = useState<PreviewType>();
  const [pageLoading, setPageLoading] = useState(true);
  const mobileWidth = 480;

  useEffect(() => {
    const fetchLists = async () => {
      await getTargetGroups();
      await getCommunities();
      await getTags(MediaTypes.OnlineMagazine);
      await getAllOnlineMagazines();
      setPageLoading(false);
    };

    fetchLists();
  }, [getTargetGroups, getCommunities, getTags, getAllOnlineMagazines]);

  useEffect(() => {
    const fetchPage = async () => {
      if (id !== 'create') {
        await getSelectedArticle(id);
        setArticle(selectedArticle);
      } else {
        setArticle(ArticleDefaults);
      }
    };

    fetchPage();
  }, [getSelectedArticle, selectedArticle, id]);

  const saveArticle = async (article: Article) => {
    window.scrollTo(0, 0);
    if (!showControlFlags(article)) {
      article.hideFromMediaFront = true;
    }
    if (id !== 'create') {
      await updateArticle(article);
    } else {
      const result = await createArticle(article);
      navigate(`/onlinemagazine/${magazineId}/articles/${result}`);
    }
  };

  const renderHeader = () => {
    const modeText = id !== 'create' ? t('article.edit') : t('article.add');
    return loading ? t('article.loading') : modeText;
  };

  const renderDivider = () => {
    return (
      <Grid item xs={12}>
        <Divider style={{ border: '#cacaca solid 2px', width: '90%' }} />
      </Grid>
    );
  };

  const showAuthorDescription = (values?: Article) => {
    if (!values) return false;
    const articleType: ArticleTypes = ArticleTypes[values.type];
    return [
      ArticleTypes.Editorial,
      ArticleTypes.Column,
      ArticleTypes.GuestPen,
      ArticleTypes.Opinion,
      ArticleTypes.PointOfView,
    ].includes(articleType);
  };

  const showLead = (values?: Article) => {
    if (!values) return false;
    const articleType: ArticleTypes = ArticleTypes[values.type];

    if (!articleType) {
      const articleTypeEv: ArticleTypesEV = ArticleTypesEV[values.type];

      return [
        ArticleTypesEV.News,
        ArticleTypesEV.Topical,
        ArticleTypesEV.International,
        ArticleTypesEV.ChangeStory,
        ArticleTypesEV.EverydayHero,
        ArticleTypesEV.MyStory,
        ArticleTypesEV.Teaching,
        ArticleTypesEV.CoWorkers,
        ArticleTypesEV.Anniversary,
        ArticleTypesEV.Obituary,
      ].includes(articleTypeEv);
    }

    return [
      ArticleTypes.BigStory,
      ArticleTypes.SmallStory,
      ArticleTypes.SmallTextStory,
      ArticleTypes.ImageStory,
      ArticleTypes.QuickVisit,
      ArticleTypes.ChurchVisit,
    ].includes(articleType);
  };

  const showTextsPhotos = (values?: Article) => {
    if (!values) return false;
    const articleType: ArticleTypes = ArticleTypes[values.type];

    return [ArticleTypes.ImageStory, ArticleTypes.QuickVisit].includes(articleType);
  };

  const showSecondAuthor = (values?: Article) => {
    if (!values) return false;
    const articleType: ArticleTypes = ArticleTypes[values.type];

    return [ArticleTypes.Column, ArticleTypes.PointOfView].includes(articleType);
  };

  const showControlFlags = (values?: Article) => {
    if (magazineId != constants.Data.AjassaOnlineMagazineId.toString()) return false;

    if (!values) return false;
    const articleType: ArticleTypes = ArticleTypes[values.type];

    return ![ArticleTypes.SmallTextStory].includes(articleType);
  };

  const enableSingleShowFlags = (values?: Article) => {
    if (magazineId != constants.Data.AjassaOnlineMagazineId.toString()) return false;

    if (!values) return false;
    const articleType: ArticleTypes = ArticleTypes[values.type];

    return [ArticleTypes.BigStory, ArticleTypes.ImageStory].includes(articleType);
  };

  const showSection = (values?: Article) => {
    if (magazineId != constants.Data.AjassaOnlineMagazineId.toString()) return false;

    if (!values) return false;
    const articleType: ArticleTypes = ArticleTypes[values.type];

    return [
      ArticleTypes.BigStory,
      ArticleTypes.ImageStory,
      ArticleTypes.SmallStory,
      ArticleTypes.SmallTextStory,
    ].includes(articleType);
  };

  const showSecondLang = (values?: Article) => {
    if (!values) return false;
    const articleType: ArticleTypesEV = ArticleTypesEV[values.type];

    return [ArticleTypesEV.EditorialEV].includes(articleType);
  };

  const getArticleTypes = () => {
    if (magazineId == constants.Data.AjassaOnlineMagazineId.toString()) {
      return Object.keys(ArticleTypes)
        .filter(
          (key) =>
            key != ArticleTypes.MostRedArticles &&
            key != ArticleTypes.LatestArticles &&
            key != ArticleTypes.KeywordArticles &&
            key != ArticleTypes.Blog &&
            key != ArticleTypes.SpeakingEngagements
        )
        .map((key) => ({ key: key, value: `articleTypes.${key}` }));
    } else {
      return Object.keys(ArticleTypesEV)
        .filter(
          (key) =>
            key != ArticleTypesEV.MostRedArticlesEV &&
            key != ArticleTypesEV.LatestArticlesEV &&
            key != ArticleTypesEV.MainArticles
        )
        .map((key) => ({ key: key, value: `articleTypes_ev.${key}` }));
    }
  };

  const getArticleSections = (): { key: string; value: string }[] => {
    if (magazineId == constants.Data.AjassaOnlineMagazineId.toString()) {
      return Object.keys(ArticleSections).map((key) => ({
        key: key,
        value: `articleSections.${key}`,
      }));
    }
    return [];
  };

  const getInitialValues = (article: Article): Article => {
    let publisher: string;

    if (user?.communities?.length == 1 && magazineId) {
      // set magazine if only one community available

      const communityId = user?.communities[0].id;
      publisher = communityId?.toString();
      const onlineMagazineIds = [parseInt(magazineId)];

      return {
        ...article,
        publisher: publisher,
        onlineMagazineIds: onlineMagazineIds,
        targetGroupIds: [constants.Data.NotLoggedInTargetGroupId],
      };
    }

    if (magazineId == constants.Data.AjassaOnlineMagazineId.toString()) {
      const onlineMagazineIds = [parseInt(magazineId)];

      return {
        ...article,
        onlineMagazineIds: onlineMagazineIds,
        targetGroupIds: id !== 'create' ? article.targetGroupIds : [constants.Data.SubscriperTargetGroupId],
      };
    }

    return article;
  };

  if (loading) {
    return (
      <Layout title={renderHeader()}>
        <Grid container>
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item xs={8} ml={2}>
            <Typography variant="h5">{t('article.saving_text')}</Typography>
          </Grid>
        </Grid>
      </Layout>
    );
  }

  if ((initLoading && id != 'create') || pageLoading || !user || !article) {
    return (
      <Layout title={renderHeader()}>
        <Grid container>
          <Grid item>
            <CircularProgress color="info" />
          </Grid>
          <Grid item xs={8} ml={2}>
            <Typography variant="h5">{t('article.loading_text')}</Typography>
          </Grid>
        </Grid>
      </Layout>
    );
  }

  return (
    <Formik
      initialValues={getInitialValues(article)}
      enableReinitialize
      validateOnMount
      onSubmit={(values: Article, helpers: FormikHelpers<Article>) => {
        const editorText = editorRef.current?.getContent();
        helpers.setFieldValue('text', editorText, false);

        const editorTextLang = editorLangRef.current?.getContent();
        helpers.setFieldValue('textLang', editorTextLang, false);

        const sideStories = values.sideStories?.map((sideStory, index) => {
          const sideStoryText = editorSideStoryRefs.current[index]?.getContent() ?? '';
          helpers.setFieldValue(`sideStories.${index}.text`, sideStoryText, false);
          return { ...sideStory, text: sideStoryText };
        });

        const editorExtraNotesText = editorExtraNotesRef.current?.getContent();
        helpers.setFieldValue('extraNotes', editorExtraNotesText, false);

        let section: string | undefined;
        if (ArticleTypes.BookReview.toString() == article.type) {
          section = ArticleSections.Culture.toString();
        }

        const images: ArticleImage[] = [];

        values.images?.forEach((img) => {
          images.push({
            ...img,
            full: img.front ? img.full : false,
            left: img.front ? img.left : false,
            right: img.front ? img.right : false,
            middle: img.front ? img.middle : false,
            carousel: img.front ? img.carousel : false,

            top: img.main ? img.top : false,
            topWeb: img.main ? img.topWeb : false,
          });
        });

        const newValues: Article = {
          ...values,
          images: images,
          text: editorText ?? '',
          textLang: editorTextLang ?? '',
          sideStories: sideStories,
          extraNotes: editorExtraNotesText,
          section: section ?? values.section,
        };
        helpers.setValues(newValues); // update formik state

        saveArticle(newValues);
      }}
      validationSchema={Yup.lazy((values: Article) => getValidationSchema(t, values))}
    >
      {({ values, isValid, dirty, submitForm, setFieldValue, errors }: FormikProps<Article>) => {
        const togglePrice = () => {
          setFieldValue('published', !values.published);
        };

        const onSelectWordFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files && e.target.files.length > 0) {
            parseImportedWordDocument(e.target.files[0], setFieldValue, communities);

            // remove file
            if (e.target.files) {
              e.target.value = '';
            }
          }
        };

        const toggleBtn = (title: string, active: boolean) => {
          const buttonColor = values?.published ? '#43a047' : '#FFA000';

          return (
            <Paper
              elevation={active ? 3 : 0}
              color="primary"
              style={{
                padding: 10,
                textAlign: 'center',
                backgroundColor: active ? buttonColor : '#ffffff',
                color: active ? '#000000' : '#a1a1a1',
                cursor: active ? undefined : 'pointer',
                marginRight: title == 'product.monthly' ? 10 : 0,
                fontWeight: 600,
              }}
              onClick={active ? undefined : togglePrice}
            >
              {t(title)}
            </Paper>
          );
        };

        return (
          <>
            <Layout title={renderHeader()} fullWidth>
              <Grid container item xs={12} spacing={2} mb={3}>
                <Grid container item xs={12} justifyContent={'space-between'}>
                  <Grid item xs={2}>
                    <Typography
                      variant="h4"
                      color={article?.published ? 'primary' : 'secondary'}
                      borderColor={article?.published ? 'primary' : 'secondary'}
                      border={1}
                      borderRadius={2}
                      sx={{ padding: 1, fontSize: 18, marginBottom: 2 }}
                    >
                      {article?.published ? t('published') : t('draft')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    style={{
                      backgroundColor: getColorByState(values?.imageState),
                      maxHeight: 30,
                      margin: 5,
                      paddingLeft: 8,
                      paddingTop: 2,
                      borderRadius: 3,
                    }}
                  >
                    {values?.imageState && values?.imageState != '' && (
                      <Typography variant="caption" fontWeight={500}>
                        {t(`articleImageStates.${values?.imageState}`)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    style={{
                      backgroundColor: getColorByState(values?.editorState),
                      maxHeight: 30,
                      margin: 5,
                      paddingLeft: 8,
                      paddingTop: 2,
                      borderRadius: 3,
                    }}
                  >
                    {values?.editorState && values?.editorState != '' && (
                      <Typography variant="caption" fontWeight={500}>
                        {t(`articleEditorStates.${values?.editorState}`)}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => setPreviewDialog('mobile')}
                      disabled={id == 'create'}
                    >
                      <PreviewIcon sx={{ marginRight: 1 }} />
                      {t('article.preview_mobile')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => setPreviewDialog('web')}
                      disabled={id == 'create'}
                    >
                      <PreviewIcon sx={{ marginRight: 1 }} />
                      {t('article.preview_web')}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <input
                    id="import_doc"
                    type="file"
                    accept=".doc,.docx,application/msword,application/application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={onSelectWordFile}
                    style={{ display: 'none' }}
                  />
                  <label htmlFor={'import_doc'}>
                    <Button
                      variant="contained"
                      size="small"
                      component="span"
                      style={{ backgroundColor: '#888888', textTransform: 'none' }}
                    >
                      <UploadIcon />
                      <Typography variant="button">{t('article.importDoc')}</Typography>
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      copy(`https://ajassa.fi/applink/article?id=${article.id}&magazineId=${magazineId}`, {
                        format: 'text/plain',
                        onCopy: () => {
                          setSuccess('article.linkCopy_success');
                        },
                      });
                    }}
                  >
                    <LinkIcon fontSize="small" style={{ marginRight: 8 }} />
                    {t('article.linkCopy')}
                  </Button>
                </Grid>

                {renderSubTitle('Perustiedot')}

                {/* Add/modify name, description, number and release date */}
                <Grid container item xs={10} spacing={3}>
                  <Grid item xs={5}>
                    <FormFieldDropDown
                      placeholder={t('article.select_type') ?? ''}
                      label={t('article.type') ?? ''}
                      name="type"
                      values={getArticleTypes()}
                    />
                  </Grid>
                  {showSection(values) && (
                    <Grid item xs={4}>
                      <FormFieldDropDown
                        placeholder={t('magazineSegment.select_section') ?? ''}
                        label={t('article.section') ?? ''}
                        name="section"
                        values={getArticleSections()}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormFieldText label={t('article.title')} name="title" />
                  </Grid>
                  {showSecondLang(values) && (
                    <Grid item xs={12}>
                      <FormFieldText label={t('article.titleLang')} name="titleLang" />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormFieldText label={t('article.frontTitle')} name="frontTitle" />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <FormFieldText label={t('article.language')} name="language" />
                  </Grid> */}
                  <Grid item xs={4}>
                    {user.admin && user?.communities.length == 0 && (
                      <FormFieldDropDown
                        placeholder={t('article.select_publisher') ?? ''}
                        label={t('article.publisher') ?? ''}
                        name="publisher"
                        values={communities.map((community) => ({ key: community.id, text: community.name }))}
                      />
                    )}
                    {user?.communities.length > 1 && (
                      <FormFieldDropDown
                        placeholder={t('article.select_publisher') ?? ''}
                        label={t('article.publisher') ?? ''}
                        name="publisher"
                        values={user.communities.map((community) => ({ key: community.id, text: community.community }))}
                      />
                    )}
                    {user.communities.length == 1 && (
                      <FormFieldDropDown
                        label={t('article.publisher') ?? ''}
                        name="publisher"
                        readonly
                        values={user.communities.map((community) => ({ key: community.id, text: community.community }))}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <FormFieldText label={t('article.editorNotes')} name="editorNotes" />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <FormFieldDateTime label={t('article.visibleDate')} name="visibleDate" />
                  </Grid>
                </Grid>

                <Grid container item xs={10} spacing={3}>
                  <Grid item xs={showTextsPhotos(values) ? 6 : 8}>
                    <FormFieldText
                      label={t(showTextsPhotos(values) ? 'article.texts' : 'article.author')}
                      name="author"
                    />
                  </Grid>
                  {showAuthorDescription(values) && (
                    <Grid container item xs={10} spacing={3}>
                      <Grid item xs={12}>
                        <FormFieldText
                          label={t(
                            values?.type == ArticleTypes.Editorial ? 'article.authorDescription' : 'article.authorInfo'
                          )}
                          name="authorDescription"
                        />
                      </Grid>
                    </Grid>
                  )}

                  {showSecondAuthor(values) && (
                    <>
                      <Grid item xs={4}>
                        <FormFieldText label={t('article.author')} name="secondAuthor" />
                      </Grid>
                      <Grid container item xs={10} spacing={3}>
                        <Grid item xs={12}>
                          <FormFieldText
                            label={t(
                              values?.type == ArticleTypes.Editorial
                                ? 'article.authorDescription'
                                : 'article.authorInfo'
                            )}
                            name="secondAuthorDescription"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {showTextsPhotos(values) && (
                    <Grid item xs={6}>
                      <FormFieldText label={t('article.photos')} name="photographer" />
                    </Grid>
                  )}
                  {!showTextsPhotos(values) && <Grid item xs={4}></Grid>}
                </Grid>
                {values?.type == ArticleTypes.BookReview && (
                  <>
                    {renderSubTitle('Kirja-arvion tiedot')}

                    <Grid container item xs={10} spacing={3}>
                      <Grid item xs={12}>
                        <FormFieldText label={t('article.bookTitle')} name="bookTitle" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormFieldText label={t('article.bookAuthor')} name="bookAuthor" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormFieldText label={t('article.bookPublisher')} name="bookPublisher" />
                      </Grid>
                    </Grid>
                  </>
                )}

                {renderSubTitle('Tekstisisältö')}

                <Grid container item xs={12} spacing={3}>
                  {showLead(values) && (
                    <Grid item xs={12}>
                      <FormFieldTextArea label={t('article.lead')} name="lead" rows={5} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="caption" color="GrayText" fontSize={14} ml={1}>
                      {t('article.text')}
                    </Typography>

                    <BundledEditor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={values?.text}
                      init={{
                        height: 500,
                        setup: (editor) => {
                          editor.on('change', () => {
                            setFieldValue('fakeDirty', Math.random());
                          });

                          return editorSetup(editor, false);
                        },
                      }}
                    />
                  </Grid>

                  {showSecondLang(values) && (
                    <Grid item xs={12}>
                      <Typography variant="caption" color="GrayText" fontSize={14} ml={1}>
                        {t('article.textLang')}
                      </Typography>

                      <BundledEditor
                        onInit={(evt, editor) => (editorLangRef.current = editor)}
                        initialValue={values?.textLang}
                        init={{
                          height: 500,
                          setup: (editor) => {
                            editor.on('change', () => {
                              setFieldValue('fakeDirty', Math.random());
                            });

                            return editorSetup(editor, false);
                          },
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Typography variant="caption" color="GrayText" fontSize={14} ml={1}>
                      {t('article.extraNotes')}
                    </Typography>
                    <BundledEditor
                      onInit={(_, editor) => (editorExtraNotesRef.current = editor)}
                      initialValue={values?.extraNotes}
                      init={{
                        height: 200,
                        menubar: false,
                        plugins: ['lists'],
                        toolbar: 'undo redo | bold italic | bullist numlist | addImage',
                        inline: false,
                        setup: (editor) => {
                          editor.on('change', () => {
                            setFieldValue('fakeDirty', Math.random());
                          });
                        },
                      }}
                    />
                  </Grid>
                  {renderDivider()}
                  <Grid item xs={12} mb={2}>
                    <FormFieldTextArea label={t('article.modifiedNotes')} name="modifiedNotes" rows={5} />
                  </Grid>
                </Grid>

                {showControlFlags(values) && (
                  <>
                    {renderSubTitle(t('article.controlFlags'))}

                    <Grid container item xs={10} spacing={1}>
                      {enableSingleShowFlags(values) && (
                        <Grid container item xs={12}>
                          <FormFieldSwitch name={`singleMediaFront`} label={t('article.singleMediaFront')} />
                        </Grid>
                      )}
                      <Grid container item xs={12}>
                        <FormFieldSwitch name={`hideFromMediaFront`} label={t('article.hideFromMediaFront')} />
                      </Grid>
                    </Grid>
                  </>
                )}

                <ArticleCitationsEdit />

                <ArticleImagesEdit />

                {magazineId == constants.Data.AjassaOnlineMagazineId.toString() && (
                  <ArticleSideStoriesEdit editorRefs={editorSideStoryRefs} />
                )}

                {renderSubTitle(t('article.internalNotes'))}

                <Grid container item xs={12}>
                  <Grid container item xs={12} mt={1}>
                    <FormFieldTextArea label="" name="internalNotes" rows={5} />
                  </Grid>
                </Grid>

                {renderSubTitle('Ryhmittelyt')}

                {/* Select categories, tags, user groups */}
                <Grid container item xs={12} spacing={3} mb={2} alignContent={'flex-start'}>
                  {magazineId == constants.Data.AjassaOnlineMagazineId.toString() && (
                    <Grid container item xs={6} spacing={3} alignContent={'flex-start'}>
                      {/* Select online magazines */}
                      <Grid item xs={12}>
                        <FormFieldCheckboxList
                          label={t('article.online_magazines')}
                          name="onlineMagazineIds"
                          listItems={onlineMagazines}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormFieldCheckboxList
                          label={t('target_groups')}
                          name="targetGroupIds"
                          listItems={targetGroups.filter((group: TargetGroup) => group.id != 2 && group.id != 6)}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container item xs={6} spacing={3} alignContent={'flex-start'}>
                    <Grid item xs={12}>
                      <Typography variant="h6" style={{ marginBottom: 16 }}>
                        {t('tags')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        maxHeight: '500px',
                        overflowY: 'scroll',
                      }}
                    >
                      <FormFieldCheckboxList
                        label=""
                        nameField="tag"
                        name="tagIds"
                        listItems={
                          magazineId == constants.Data.AjassaOnlineMagazineId.toString()
                            ? tags.filter((t) => !t.communityId)
                            : tags.filter(
                                (t) =>
                                  onlineMagazines.find((magazine) => magazine.id == magazineId).communityId ==
                                  t.communityId
                              )
                        }
                      />
                    </Grid>
                  </Grid>

                  {renderSubTitle('Julkaisutiedot')}

                  <Grid container item xs={12} spacing={3} mb={2} alignContent={'flex-start'}>
                    {/* publish or save as draft */}
                    <Grid container item xs={12} spacing={3} mt={1}>
                      <Grid item xs={4}>
                        <Grid style={{ backgroundColor: getColorByState(values?.imageState) }}>
                          <FormFieldDropDown
                            placeholder={t('article.select_image_state') ?? ''}
                            label={t('article.imageState') ?? ''}
                            name="imageState"
                            values={[
                              { key: 'notReady', value: 'Kuvat kesken' },
                              { key: 'ready', value: 'Kuvat valmis' },
                            ]}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        {values?.imageState && values?.imageState != '' && (
                          <IconButton onClick={() => setFieldValue('imageState', '')}>
                            <ClearIcon titleAccess="Poista kuvien tila" />
                          </IconButton>
                        )}
                      </Grid>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={4}>
                        <Grid style={{ backgroundColor: getColorByState(values?.editorState) }}>
                          <FormFieldDropDown
                            placeholder={t('article.select_editor_state') ?? ''}
                            label={t('article.editorState') ?? ''}
                            name="editorState"
                            values={[
                              { key: 'notReady', value: 'Selvitettävää' },
                              { key: 'ready', value: 'Artikkeli valmis' },
                            ]}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        {values?.editorState && values?.editorState != '' && (
                          <IconButton onClick={() => setFieldValue('editorState', '')}>
                            <ClearIcon titleAccess="Poista artikkelin tila" />
                          </IconButton>
                        )}
                      </Grid>
                      <Grid item xs={12} mb={2} mt={1}>
                        <FormFieldDateTime label={t('article.releaseDate')} name="releaseDate" />
                      </Grid>
                      <Grid item xs={12} mb={3} mt={1} justifyContent={'center'}>
                        <Grid
                          container
                          item
                          md={4}
                          xs={10}
                          justifyContent={'center'}
                          style={{
                            borderColor: '#cacaca',
                            borderWidth: 2,
                            borderRadius: 7,
                            borderStyle: 'solid',
                            padding: 10,
                          }}
                        >
                          <Grid item xs={6}>
                            {toggleBtn('draft', !values?.published)}
                          </Grid>
                          <Grid item xs={6}>
                            {toggleBtn('published', values?.published)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {renderSubTitle('Tallennus')}

                    <Grid item xs={12} mb={2} mt={1}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          submitForm();
                        }}
                        color="info"
                        disabled={!isValid || !dirty}
                        sx={{ marginRight: 2 }}
                      >
                        {t('save')}
                      </Button>

                      <Button
                        variant="text"
                        onClick={() => {
                          navigate(`/onlinemagazine/${magazineId}/articles`);
                        }}
                        color="info"
                      >
                        {t('common.close')}
                      </Button>
                    </Grid>
                    <Grid item xs={12} mb={2} mt={1}>
                      {Object.keys(errors).length > 0 && (
                        <Grid>
                          <Typography variant="caption" color="darkred" fontWeight={500}>
                            Artikkelin virheet
                          </Typography>
                        </Grid>
                      )}
                      {Object.keys(errors).map((key, index) => (
                        <Grid key={index}>
                          <Typography variant="caption" color="darkred">
                            {`${t(`article.${key}`)} : ${errors[key]}`}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Layout>
            <DialogBase
              title={t(previewDialog == 'mobile' ? 'article.preview_mobile' : 'article.preview_web')}
              onClose={() => setPreviewDialog(undefined)}
              onOk={() => setPreviewDialog(undefined)}
              show={!!previewDialog}
              hideCancel
              size={previewDialog == 'mobile' ? 'xs' : 'md'}
              okTitle={t('common.close') ?? ''}
              contentStyle={{
                backgroundColor: '#eaeaea',
                margin: 0,
                overflowY: 'scroll',
                padding: 0,
                maxWidth: `${mobileWidth}px`,
                maxHeight: `${(16 / 9) * mobileWidth}px`,
              }}
              hideActions
              style={{ maxWidth: `${mobileWidth}px` }}
            >
              <ArticlePreview article={values} communities={communities} />
            </DialogBase>
          </>
        );
      }}
    </Formik>
  );
});

export default ArticleEditScreen;
