import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { TableHead, TableRow, TableCell } from '@mui/material';
import { color } from '../../theme';

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${color.greyBorderLight}`,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '45% auto 10%',
    justifyContent: 'flex-start',
    width: '100%',
  },
  tableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    color: color.black,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    border: 0,
    paddingLeft: 0,
  },
});

interface Props {
  headerTitles: string[];
}

function TableHeader(props: Props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { headerTitles } = props;

  return (
    <TableHead className={classes.root}>
      <TableRow className={classes.row}>
        {headerTitles.map((title, index) => (
          <TableCell key={index} className={classes.tableHead}>
            {t(title)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
