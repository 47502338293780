import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useMst } from '../../model/Root';
import Layout from '../../components/Layout';
import Button from '../../components/Button/Button';
import TextInput from '../../components/FormComponents/TextInput';
import ErrorText from '../../components/ErrorText';
import { color } from '../../theme';
import { SubscriptionType } from '../../types';
import { SubscriptionTypeDefaults } from '../../utils/defaults';

const useStyles = makeStyles()({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: 700,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    maxWidth: 700,
    minWidth: 500,
    alignItems: 'flex-start',
  },
  inputTitle: {
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  input: {
    fontFamily: 'Ubuntu',
    width: '90%',
    maxWidth: 600,
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
    padding: 10,
    alignSelf: 'flex-start',
    margin: 0,
  },
  dropDown: {
    width: '90%',
    padding: 10,
    alignSelf: 'flex-start',
    alignItems: 'center',

    backgroundColor: '#F2F2F2',
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,

    color: color.black,
    fontFamily: 'Ubuntu',
  },
  defaultItem: {
    color: 'black',
    fontFamily: 'Ubuntu',
    fontSize: 14,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  selectText: {
    fontFamily: 'Ubuntu',
    color: color.black,
  },
});

const SubscriptionEditScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    subscriptionTypeStore: {
      selectedType,
      getSubscriptionType,
      addSubscriptionType,
      updateSubscriptionType,
      deleteSubscriptionType,
    },
  } = useMst();
  const { id } = useParams<{ id: string }>();
  const [result, setResult] = useState<SubscriptionType | null>();
  const [hasErrors, setHasErrors] = useState<boolean>();

  useEffect(() => {
    const fetchTypes = async () => {
      if (id !== 'create') {
        await getSubscriptionType(id);
        setResult(selectedType);
      }
    };
    fetchTypes();
  }, [getSubscriptionType, id, selectedType]);

  const onChange = (key: string, value: any) => {
    setResult((result: any | SubscriptionType | null) => {
      const tempResult = result ? result : SubscriptionTypeDefaults;
      return { ...tempResult, [key]: value };
    });
  };

  const save = async () => {
    if (!result) return;

    const isValid = result.name.length > 0 && result.type > 0 && result.price > 0;
    if (isValid) {
      if (id != 'create') {
        if (!selectedType) return null;
        await updateSubscriptionType(result);
      } else {
        await addSubscriptionType(result);
      }

      navigate('/subscriptions');
    }
    setHasErrors(!isValid);
  };

  const onDelete = async () => {
    if (!selectedType || !id) return;
    await deleteSubscriptionType(selectedType.id);
    navigate('/subscriptions');
  };

  type TypeForm = { id: number; title: string };
  const typeForms = [
    { id: 1, title: 'month' },
    { id: 2, title: 'year' },
    { id: 3, title: 'gift' },
    { id: 4, title: 'preview' },
  ];

  return (
    <Layout title={t(id !== 'create' ? 'subscription_type_edit' : 'subscription_type_add')}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <TextInput
            label="name"
            value={result?.name}
            type="name"
            placeholder="name"
            onChange={(key: string, value: string) => onChange(key, value)}
            style={{ width: '90%' }}
          />
          <h3 className={classes.inputTitle}>{t('subscription_type_type')}</h3>
          <Select
            className={classes.dropDown}
            value={result?.type ?? 0}
            onChange={({ target }) => onChange('type', target.value)}
            defaultValue={0}
          >
            <MenuItem value={0} className={classes.defaultItem} disabled>
              {t('select_subscription_type')}
            </MenuItem>
            {typeForms.map((form: TypeForm) => (
              <MenuItem key={form.id} value={form.id} className={classes.defaultItem}>
                {t(`subscription_form_${form.title}`)}
              </MenuItem>
            ))}
          </Select>
          <h3 className={classes.inputTitle}>{t('subscription_type_price')}</h3>
          <TextInput
            type="price"
            placeholder=""
            value={result?.price.toString()}
            onChange={(key: string, value: string) => onChange(key, Number.parseFloat(value))}
            style={{ width: 80 }}
            inputType="number"
          />
          <h3 className={classes.inputTitle}>{t('subscription_type_description')}</h3>
          <TextInput
            placeholder={t('subscription_type_description')}
            value={result?.description}
            type="description"
            onChange={(key: string, value: string) => onChange(key, value)}
            multiline
            style={{ width: 580 }}
          />
          <div className={classes.buttonContainer}>
            <Button onClick={save} text="save" />
            {id && <Button onClick={onDelete} text="delete" backgroundColor={color.deleteButton} />}
          </div>
        </div>
      </div>
      {hasErrors && <ErrorText text="subscription_save_error" style={{ fontSize: 12 }} />}
    </Layout>
  );
});

export default SubscriptionEditScreen;
