import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { DigiMagazine, DigiMagazineFile } from '../../types';
import { color } from '../../theme';
import TextInput from '../../components/FormComponents/TextInput';
import Button from '../../components/Button/Button';
import { DigiMagazineDefaults } from '../../utils/defaults';
import AddNewButton from '../../components/Button/AddButton';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import EditIcon from '../../static/edit.svg';
import DeleteIcon from '../../static/delete.svg';
import SaveIcon from '../../static/save.svg';
import UploadButton from '../../components/Button/UploadButton';
import ImageUploadButton from '../../components/Button/ImageUploadButton';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/FormComponents/FormFieldText';
import FormFieldTextArea from 'components/FormComponents/FormFieldTextArea';
import FormFieldCheckboxList from 'components/FormComponents/FormFieldCheckboxList';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';

const getValidationSchema = (t: (key: string) => string, values: DigiMagazine, image: File | undefined) => {
  if (!values.published) {
    const draftSchema = Yup.object().shape({
      name: Yup.string().required(t('common_required')),
      mediaTypeId: Yup.number().required(t('common_required')),
    });

    return draftSchema;
  }

  const digiMagazineSchema = Yup.object().shape({
    name: Yup.string().required(t('common_required')),
    image: Yup.string().test(
      'image-test',
      t('common_required'),
      (value) => values.published || (!!value && value?.length > 0) || !!image
    ),
    publisher: Yup.string().required(t('common_required')),
    mediaTypeId: Yup.number().required(t('common_required')),
    targetGroupIds: Yup.array().min(1, t('common_required')),
  });

  return digiMagazineSchema;
};

const DigiMagazineEditScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    targetGroupStore: { targetGroups, getTargetGroups },
    digiMagazineStore: {
      selectedDigiMagazine,
      getSelectedDigiMagazine,
      updateDigiMagazine,
      createDigiMagazine,
      loading,
    },
  } = useMst();
  const { id } = useParams<{ id: string }>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [currentDigiMagazineFileId, setCurrentDigiMagazineFileId] = useState(0);
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [digiMagazine, setDigiMagazine] = useState<DigiMagazine>(DigiMagazineDefaults);
  const [digiMagazineFiles, setDigiMagazineFiles] = useState<DigiMagazineFile[]>([]);
  const [digiMagazineFileName, setDigiMagazineFileName] = useState('');
  const [digiMagazineFileMagazineNumber, setDigiMagazineFileMagazineNumber] = useState('');
  const [digiMagazineFileImage, setDigiMagazineFileImage] = useState<File>();
  const [digiMagazineFileImagePreviewUrl, setDigiMagazineFileImagePreviewUrl] = useState<string | null>();
  const [digiMagazineFileReleaseDate, setDigiMagazineFileReleaseDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [uploadDialogVisible, setUploadDialogVisible] = useState<boolean>(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const messageRef = useRef<HTMLParagraphElement>(null);
  const counterRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const fetchLists = async () => {
      await getTargetGroups();
    };

    fetchLists();
  }, [getTargetGroups]);

  useEffect(() => {
    const fetchPage = async () => {
      if (id !== 'create') {
        await getSelectedDigiMagazine(id);
        if (selectedDigiMagazine?.image) {
          setImagePreviewUrl(selectedDigiMagazine?.image);
        }
        setDigiMagazine(selectedDigiMagazine);
        const digiMagazineFiles = selectedDigiMagazine?.files.map((s) => ({ ...s, state: '' })) ?? [];
        setDigiMagazineFiles(digiMagazineFiles);
      }
    };

    fetchPage();
  }, [getSelectedDigiMagazine, selectedDigiMagazine, id]);

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files) {
      const image = URL.createObjectURL(target.files[0]);
      setSelectedImage(target.files[0]);
      setImagePreviewUrl(image);
    }
  };

  const handleDigiMagazineFileImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files) {
      const image = URL.createObjectURL(target.files[0]);
      setDigiMagazineFileImage(target.files[0]);
      setDigiMagazineFileImagePreviewUrl(image);
    }
  };

  const handleDigiMagazineFileNameChange = (key: string, value: string) => {
    setDigiMagazineFileName(value);
  };

  const handleFilePick = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files);
      setCurrentFiles(fileArray);

      if (e.target.files.length == 1) {
        if (digiMagazineFileName == '') {
          const filenameWithoutExt = e.target.files[0].name.split('.').slice(0, -1).join('.');
          setDigiMagazineFileName(filenameWithoutExt);
        }
      } else {
        for (const file of e.target.files) {
          const filenameWithoutExt = file.name.split('.').slice(0, -1).join('.');
          digiMagazineFiles.push({
            name: filenameWithoutExt,
            file: file,
            magazineNumber: digiMagazineFileMagazineNumber,
            order: digiMagazineFiles.length == 0 ? 0 : digiMagazineFiles[digiMagazineFiles.length - 1].order + 1,
            src: file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            releaseDate: digiMagazineFileReleaseDate,
            published: true,
            state: 'new',
          });
          files.push(file);
        }

        setUploadDialogVisible(false);
      }
    }
  };

  const handleDigiMagazineFileSave = () => {
    if (currentDigiMagazineFileId == 0) return;

    if (currentFiles.length == 1) {
      // new chapter
      digiMagazineFiles.push({
        name: digiMagazineFileName,
        file: currentFiles[0],
        image: digiMagazineFileImage?.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        magazineNumber: digiMagazineFileMagazineNumber,
        order: digiMagazineFiles.length == 0 ? 0 : digiMagazineFiles[digiMagazineFiles.length - 1].order + 1,
        src: currentFiles[0].name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        published: true,
        releaseDate: digiMagazineFileReleaseDate,
        state: 'new',
      });
      // add files, but skip undefined if one
      setFiles([...files, currentFiles[0], digiMagazineFileImage].filter((item): item is File => !!item));
    } else {
      const index = digiMagazineFiles.findIndex((chapter) => chapter.id === currentDigiMagazineFileId);
      if (index > -1) {
        digiMagazineFiles[index].name = digiMagazineFileName;
        digiMagazineFiles[index].state = 'edited';
        digiMagazineFiles[index].published = true;
        digiMagazineFiles[index].magazineNumber = digiMagazineFileMagazineNumber;
        digiMagazineFiles[index].releaseDate = digiMagazineFileReleaseDate;
      }
    }
    setCurrentFiles([]);
    setDigiMagazineFileName('');
    setDigiMagazineFileMagazineNumber('');
    setDigiMagazineFileImage(undefined);
    setDigiMagazineFileReleaseDate(format(new Date(), 'yyyy-MM-dd'));
    setCurrentDigiMagazineFileId(0);
    setUploadDialogVisible(false);
  };

  const handleDigiMagazineFileEdit = (digiMagazineFile: DigiMagazineFile) => {
    setUploadDialogVisible(true);
    setCurrentDigiMagazineFileId(digiMagazineFile.id ?? 0);

    const index = getDigiMagazineFileIndex(digiMagazineFile);
    setDigiMagazineFileName(digiMagazineFiles[index].name);
    setDigiMagazineFileImagePreviewUrl(digiMagazineFiles[index].image);
    setDigiMagazineFileMagazineNumber(digiMagazineFiles[index].magazineNumber ?? '');
    setDigiMagazineFileReleaseDate(digiMagazineFiles[index].releaseDate ?? format(new Date(), 'yyyy-MM-dd'));
  };

  const handleDeleteFile = (digiMagazineFile: DigiMagazineFile) => {
    const index = getDigiMagazineFileIndex(digiMagazineFile);

    if (index > -1) {
      if ((digiMagazineFiles[index].id ?? 0) > 0) {
        // already saved to db, flag for delete
        digiMagazineFiles[index].state = 'deleted';
      } else {
        // just remove item from array, not stored to db
        digiMagazineFiles.splice(index, 1);
      }

      setRefreshPage(!refreshPage);
    }
  };

  const getDigiMagazineFileIndex = (digiMagazineFile: DigiMagazineFile): number => {
    const index = digiMagazineFiles.findIndex(
      (s) => s.id == digiMagazineFile.id && s.name == digiMagazineFile.name && s.order == digiMagazineFile.order
    );
    return index;
  };

  const saveDigiMagazine = async (digiMagazine: DigiMagazine) => {
    if (id !== 'create') {
      await updateDigiMagazine(digiMagazine, digiMagazineFiles, files, selectedImage);
    } else {
      const result = await createDigiMagazine(digiMagazine, digiMagazineFiles, files, selectedImage);
      navigate(`/digiMagazines/${result}`);
    }
  };

  const renderDigiMagazineFileUpload = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {t('digiMagazines.digiMagazineFiles')}
          </Typography>
        </Grid>
        {digiMagazineFiles.length == 0 && (
          <Grid item xs={9}>
            <Typography variant="body2" color="GrayText">
              {t('digiMagazines.no_digiMagazineFiles')}
            </Typography>
          </Grid>
        )}

        {digiMagazineFiles.length > 0 && (
          <Grid container style={{ marginLeft: 24 }}>
            <Grid container item xs={10} alignSelf={'center'}>
              <Grid item xs={2} alignSelf={'center'}>
                <Typography variant="body1" fontWeight={'bold'}>
                  {t('digiMagazines.release_date')}
                </Typography>
              </Grid>
              <Grid item xs={10} alignSelf={'center'}>
                <Typography variant="body1" fontWeight={'bold'}>
                  {t('common.name')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={2}></Grid>
          </Grid>
        )}

        {digiMagazineFiles
          .filter((c) => c.state != 'deleted')
          .sort((a, b) => {
            if (!a.releaseDate || !b.releaseDate) return 0;
            if (a.releaseDate > b.releaseDate) return 1;
            if (a.releaseDate < b.releaseDate) return -1;
            return 0;
          })
          .map((digiMagazineFile: DigiMagazineFile, index: number) => {
            return (
              <Grid
                container
                key={`${digiMagazineFile.id}_${index}`}
                style={{ borderBottom: '1px solid lightGray', marginLeft: 24 }}
              >
                <Grid container item xs={10} alignSelf={'center'}>
                  <Grid item xs={2} alignSelf={'center'}>
                    <Typography variant="body1">
                      {digiMagazineFile.releaseDate ? format(new Date(digiMagazineFile.releaseDate), 'dd.MM.yyyy') : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} alignSelf={'center'}>
                    <Typography variant="body1">{digiMagazineFile.name}</Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={2} justifyContent={'right'}>
                  <IconButton onClick={() => handleDigiMagazineFileEdit(digiMagazineFile)}>
                    <img src={EditIcon} alt="Edit" />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteFile(digiMagazineFile)}>
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}

        <Grid item xs={12}>
          <AddNewButton
            text={t('digiMagazines.add_digiMagazineFile')}
            onClick={() => {
              setCurrentDigiMagazineFileId(-1);
              setDigiMagazineFileName('');
              setDigiMagazineFileMagazineNumber('');
              setDigiMagazineFileImage(undefined);
              setDigiMagazineFileReleaseDate(format(new Date(), 'yyyy-MM-dd'));
              setUploadDialogVisible(!uploadDialogVisible);
            }}
          />
        </Grid>
        {uploadDialogVisible && (
          <Grid
            container
            item
            xs={10}
            spacing={1}
            style={{ border: 'solid 1px grey', borderRadius: 3, padding: 10, margin: 10 }}
          >
            {currentFiles.length <= 1 && (
              <>
                <Grid item xs={12}>
                  <TextInput
                    label={'digiMagazines.digiMagazineFile_name'}
                    value={digiMagazineFileName}
                    type={'name'}
                    placeholder={'digiMagazines.digiMagazineFile_name'}
                    onChange={handleDigiMagazineFileNameChange}
                    style={{ width: '80%' }}
                    multiline={false}
                  />
                </Grid>
                <Grid item xs={5} mt={2}>
                  <DatePicker
                    label={t('digiMagazines.digiMagazineFile_release_date')}
                    value={new Date(digiMagazineFileReleaseDate)}
                    onChange={(newValue: Date | null) => {
                      if (newValue != null && newValue.getFullYear() > 1900) {
                        setDigiMagazineFileReleaseDate(format(newValue, 'yyyy-MM-dd'));
                      }
                      if (newValue == null) {
                        setDigiMagazineFileReleaseDate(format(new Date(), 'yyyy-MM-dd'));
                      }
                    }}
                    disableMaskedInput
                    inputFormat="d.M.yyyy"
                    openTo={'day'}
                    views={['year', 'month', 'day']}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    renderInput={(props: any) => <TextField size="small" {...props} />}
                  />
                </Grid>
                {/* <Grid item xs={4} mt={2}>
                  <FormControl size="small" variant="outlined" fullWidth>
                    <TextInput
                      label={'digiMagazines.digiMagazineFile_magazineNumber'}
                      value={digiMagazineFileMagazineNumber}
                      type={'magazineNumber'}
                      placeholder={'digiMagazines.digiMagazineFile_magazineNumber'}
                      onChange={handleDigiMagazineFileNameChange}
                      style={{ width: '80%' }}
                      multiline={false}
                    />
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} mt={1}>
                  <Typography fontSize={14} fontWeight={'bold'} textTransform={'uppercase'} mb={1}>
                    {t('digiMagazines.digiMagazineFile_image')}
                  </Typography>
                  <ImageUploadButton
                    text={t('digiMagazines.upload_digiMagazineFile_image')}
                    name="digiMagazines.upload_digiMagazineFile_image"
                    handleImageSelection={handleDigiMagazineFileImageSelection}
                    imagePreviewUrl={digiMagazineFileImagePreviewUrl}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p ref={counterRef}></p>
                  <p ref={messageRef}></p>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {currentDigiMagazineFileId == -1 ? (
                <Grid item>
                  <UploadButton
                    buttonText="digiMagazines.upload_digiMagazineFile"
                    onChange={handleFilePick}
                    fileName={
                      currentFiles?.length === 1 ? currentFiles[0].name : currentFiles.length > 0 ? 'Useita' : ''
                    }
                    fileType=".pdf"
                  />
                </Grid>
              ) : (
                <Typography variant="caption" color="GrayText">
                  {t('digiMagazines.digiMagazineFile_edit_limited')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <IconButton onClick={handleDigiMagazineFileSave}>
                <img src={SaveIcon} alt="Save" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderHeader = () => {
    const modeText = id !== 'create' ? t('digiMagazines.edit') : t('digiMagazines.add');
    return loading ? t('digiMagazines.loading') : modeText;
  };

  if (loading) {
    return (
      <Layout title={renderHeader()}>
        <CircularProgress size={40} style={{ alignSelf: 'center', marginTop: 30, color: color.primaryButton }} />
        <Typography variant="h5">{t('digiMagazines.loading_text')}</Typography>
      </Layout>
    );
  }

  return (
    <Formik
      initialValues={digiMagazine}
      enableReinitialize
      onSubmit={(values: DigiMagazine) => {
        saveDigiMagazine(values);
      }}
      validationSchema={Yup.lazy((values: DigiMagazine) => getValidationSchema(t, values, selectedImage))}
    >
      {({ isValid, submitForm, setFieldValue }: FormikProps<DigiMagazine>) => {
        return (
          <Layout title={renderHeader()}>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <Typography
                  variant="h4"
                  color={digiMagazine?.published ? 'primary' : 'secondary'}
                  borderColor={digiMagazine?.published ? 'primary' : 'secondary'}
                  border={1}
                  borderRadius={2}
                  sx={{ padding: 1, fontSize: 18, width: 200, marginBottom: 2 }}
                >
                  {digiMagazine?.published ? t('published') : t('draft')}
                </Typography>
              </Grid>

              {/* Add/modify name, description, number and release date */}
              <Grid item xs={10}>
                <FormFieldText label={t('digiMagazines.name')} name="name" />
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={7}>
                  <FormFieldText label={t('digiMagazines.publisher')} name="publisher" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={12}>
                  <FormFieldTextArea label={t('digiMagazines.description')} name="description" rows={7} />
                </Grid>
              </Grid>

              {/* Upload cover image */}
              <Grid item xs={10}>
                <ImageUploadButton
                  text="digiMagazines.cover_image"
                  imagePreviewUrl={imagePreviewUrl}
                  handleImageSelection={handleImageSelection}
                />
              </Grid>

              {/* Upload digiMagazine digiMagazineFiles (pdf) */}
              <Grid container item xs={10} spacing={3} ml={3} mt={2}>
                {renderDigiMagazineFileUpload()}
              </Grid>
              {/* Select categories, user groups */}
              <Grid container item xs={12} spacing={3} mt={2}>
                <Grid container item xs={6} spacing={3} alignContent={'flex-start'}>
                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('target_groups')} name="targetGroupIds" listItems={targetGroups} />
                  </Grid>

                  {/* publish or save as draft */}
                  <Grid container item xs={10} spacing={3}>
                    <Button
                      text="save_draft"
                      onClick={() => {
                        setFieldValue('published', false);
                        submitForm();
                      }}
                      backgroundColor={color.secondaryButton}
                      width={220}
                      marginBottom={30}
                    />
                    <Button
                      text="publish"
                      onClick={() => {
                        setFieldValue('published', true);
                        submitForm();
                      }}
                      marginBottom={30}
                      disabled={!isValid}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={6} spacing={3}></Grid>
              </Grid>
            </Grid>
          </Layout>
        );
      }}
    </Formik>
  );
});

export default DigiMagazineEditScreen;
