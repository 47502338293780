import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fiFI as coreFiFI } from '@mui/material/locale';
import { fiFI } from '@mui/x-date-pickers/locales';
import '../i18n';
import { palette } from 'theme/palette';
import AppRoutes from './AppRoutes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import fiLocale from 'date-fns/locale/fi';

const App = () => {
  const theme = createTheme(
    {
      palette: {
        primary: { main: palette.green },
        secondary: { main: palette.amber },
      },
      typography: {
        fontFamily: 'Poppins',
      },
    },
    fiFI, // x-date-pickers translations
    coreFiFI // core translations
  );

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fiLocale}>
          <AppRoutes />
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
