import React from 'react';
import { TableCell, TableRow as MURow, IconButton } from '@mui/material';
import { color } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import EditIcon from '../../static/edit.svg';
import { UserRole } from '../../utils/constants';
import { User } from 'types';
import { getUserRole } from 'utils/userUtils';

const useStyles = makeStyles()({
  root: {
    borderBottom: `1px solid ${color.greyBorderLight}`,
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'flex-start',
    width: '100%',
    maxWidht: 1000,
  },
  cell: {
    display: 'flex',
    alignSelf: 'flex-start',
    fontSize: '18px',
    color: color.black,
    fontWeight: 400,
    lineHeight: '27px',
    padding: 0,
    border: 0,
    marginTop: 25,
    minWidth: 300,
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  cellEmail: {
    display: 'flex',
    alignSelf: 'flex-start',
    fontSize: '12px',
    color: color.black,
    fontWeight: 400,
    lineHeight: '27px',
    padding: 0,
    border: 0,
    marginTop: 25,
    minWidth: 300,
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  cellUser: {
    display: 'flex',
    alignSelf: 'flex-start',
    fontSize: '18px',
    color: color.black,
    fontWeight: 400,
    lineHeight: '27px',
    padding: 0,
    border: 0,
    marginTop: 25,
    minWidth: 200,
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  btnCell: {
    border: 0,
    minWidth: 100,
    maxWidth: 100,
  },
});

interface Props {
  user: User;
  userRole: UserRole;
}

function UsersTableRow({ user, userRole }: Props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const editRow = () => {
    if (userRole === UserRole.admin || userRole === UserRole.publisher || userRole === UserRole.creator) {
      navigate(`/admin-users/${user.id}`);
    } else if (userRole == UserRole.employee) {
      navigate(`/emp-users/${user.id}`);
    } else {
      navigate(`/portal-users/${user.id}`);
    }
  };

  return (
    <MURow className={classes.root}>
      <TableCell className={classes.cell}>{`${user.lastname}, ${user.firstname}`}</TableCell>
      <TableCell className={classes.cellEmail}>{`${user.email}`}</TableCell>
      <TableCell className={classes.cellUser}>{t(getUserRole(user))}</TableCell>
      <TableCell className={classes.btnCell}>
        <IconButton onClick={editRow}>
          <img src={EditIcon} alt="Edit" />
        </IconButton>
      </TableCell>
    </MURow>
  );
}

export default UsersTableRow;
