import { flow, types } from 'mobx-state-tree';
import {
  getDigiMagazines,
  getSingleDigiMagazine,
  addSingleDigiMagazine,
  updateSingleDigiMagazine,
  deleteSingleDigiMagazine,
} from '../services/digiMagazineApi';
import { composeError } from '../utils/transforms';
import { DigiMagazineResponse, DigiMagazine, TargetGroup, DigiMagazineFile } from '../types';
import { withRootStore } from './withRootStore';

const DigiMagazineFileModel = types.model('DigiMagazineFileModel', {
  id: types.number,
  name: types.string,
  image: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  published: types.optional(types.boolean, false),
  order: types.number,
  magazineNumber: types.maybeNull(types.string),
  releaseDate: types.maybeNull(types.string),
  releaseYear: types.maybeNull(types.number),
  src: types.maybeNull(types.string),
  state: types.optional(types.string, ''),
});

const DigiMagazineModel = types.model('DigiMagazineModel', {
  id: types.optional(types.number, -1),
  name: types.string,
  image: types.maybeNull(types.string),
  description: types.string,
  published: types.optional(types.boolean, false),
  publisher: types.string,
  mediaTypeId: types.optional(types.number, -1),
  communityId: types.maybeNull(types.number),
  targetGroupIds: types.optional(types.array(types.number), []),
  files: types.optional(types.array(DigiMagazineFileModel), []),
});

export const DigiMagazineStore = types
  .model({
    digiMagazines: types.optional(types.array(DigiMagazineModel), []),
    selectedDigiMagazine: types.maybeNull(DigiMagazineModel),
    loading: false,
  })
  .extend(withRootStore)
  .actions((self) => {
    const getAllDigiMagazines = flow(function* () {
      try {
        const response = yield getDigiMagazines();
        self.digiMagazines = response.data.map((digiMagazine: DigiMagazineResponse) => ({
          ...digiMagazine,
          // These are not used where getAllDigiMagazines is called
          // mediaTypeId: digiMagazine.mediaType.id,
          // categoryIds: digiMagazine.categories?.map((data: Category) => data.id),
          // targetGroupIds: digiMagazine.targetGroups?.map((data: TargetGroup) => data.id),
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const getSelectedDigiMagazine = flow(function* (id: string) {
      try {
        const response = yield getSingleDigiMagazine(id);

        self.selectedDigiMagazine = {
          ...response.data,
          mediaTypeId: response.data.mediaType?.id,
          communityId: response.data.community.id,
          targetGroupIds: response.data.targetGroups?.map((data: TargetGroup) => data.id),
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const createDigiMagazine = flow(function* (
      digiMagazine: DigiMagazine,
      digiMagazineFiles: DigiMagazineFile[],
      files: File[],
      image?: File
    ) {
      self.loading = true;
      try {
        const response = yield addSingleDigiMagazine(digiMagazine, digiMagazineFiles, files, image);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
        return response.data.id;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const updateDigiMagazine = flow(function* (
      digiMagazine: DigiMagazine,
      digiMagazineFiles: DigiMagazineFile[],
      files: File[],
      image?: File
    ) {
      self.loading = true;
      try {
        yield updateSingleDigiMagazine(digiMagazine, digiMagazineFiles, files, image);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const deleteDigiMagazine = flow(function* (id: string) {
      try {
        yield deleteSingleDigiMagazine(id);
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getAllDigiMagazines,
      getSelectedDigiMagazine,
      createDigiMagazine,
      updateDigiMagazine,
      deleteDigiMagazine,
    };
  });
