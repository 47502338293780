import React from 'react';
import { MenuItem, Select as MUSelect } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { color } from '../../theme';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  dropDown: {
    padding: 10,
    width: '90%',
    alignSelf: 'flex-start',
    alignItems: 'center',
    heigh: 'auto',

    backgroundColor: '#FFF',
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
  },
  defaultItem: {
    color: 'black',
    fontSize: 14,
  },
  item: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 20,
  },
  selectTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
});

type MenuItemType = {
  id: number;
  name: string;
};

interface SelectProps {
  data: MenuItemType[];
  value?: number | string;
  label: string;
  onChange: (key: string, value: string) => void;
  style?: React.CSSProperties;
}

export default function Select(props: SelectProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { data, value, onChange, label } = props;

  return (
    <div className={classes.container}>
      <h3 className={classes.selectTitle}>{t(label)}</h3>
      <MUSelect
        className={classes.dropDown}
        placeholder={t(label) ?? ''}
        value={value}
        onChange={({ target }) => onChange('publisher', target.value as string)}
        defaultValue={'none'}
        sx={{ textDecoration: 'none' }}
      >
        <MenuItem value={'none'} className={classes.defaultItem}>
          {t(label)}
        </MenuItem>
        {data.length > 0 &&
          data.map((item) => (
            <MenuItem key={item.id} value={item.id} className={classes.item}>
              {item.name}
            </MenuItem>
          ))}
      </MUSelect>
    </div>
  );
}
