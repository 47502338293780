import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import Table from '../../components/Table/Table';
import AddButton from '../../components/Button/AddButton';
import { useTranslation } from 'react-i18next';
import { DIGIMAGAZINE_TABLE } from '../../utils/constants';
import { Community, LinkRow, Row } from '../../types';
import AlertModal from '../../components/AlertModal';

const DigiMagazineScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    userStore: { communities, user, getCommunities },
    digiMagazineStore: { digiMagazines, getAllDigiMagazines, deleteDigiMagazine },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow>();
  const [result, setResult] = useState<Row[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const filterDigiMagazines = useCallback(
    (communities: Community[], ownCommunities: Community[]) => {
      const filtered = communities.filter((community: Community) => {
        return ownCommunities.some((el) => {
          return el.id === community.id;
        });
      });
      const filteredDigiMagazines = digiMagazines.filter((digiMagazine) => {
        return filtered.find(({ community }) => community === digiMagazine.publisher);
      });

      return filteredDigiMagazines;
    },
    [digiMagazines]
  );

  useEffect(() => {
    const getDigiMagazines = async () => {
      await getAllDigiMagazines();

      if (user?.admin) {
        const tempResult: Row[] =
          (digiMagazines?.length ?? 0) > 0
            ? digiMagazines.map((digiMagazine) => ({
                id: digiMagazine.id,
                name: digiMagazine.name,
                value: `${digiMagazine.name} - ${digiMagazine.publisher}`,
                secondValue: digiMagazine.published ? t('published') : t('draft'),
              }))
            : [];
        setResult(tempResult);
      } else {
        if (communities && user?.communities.length) {
          const filtered = filterDigiMagazines(communities, user.communities);
          const tempResult: Row[] =
            filtered && filtered.length > 0
              ? filtered.map((digiMagazine) => ({
                  id: digiMagazine.id,
                  name: digiMagazine.name,
                  value: `${digiMagazine.name} - ${digiMagazine.author}`,
                  secondValue: digiMagazine.published ? t('published') : t('draft'),
                }))
              : [];
          setResult(tempResult);
        }
      }
    };

    getDigiMagazines();
  }, [getAllDigiMagazines, getCommunities, user, communities, digiMagazines, t, filterDigiMagazines]);

  useEffect(() => {
    if (!selectedRow?.id || modalOpen) return;
    navigate(`/digiMagazines/${selectedRow.id}`);
  }, [selectedRow?.id, modalOpen, navigate]);

  const navigateTo = () => {
    navigate('/digiMagazines/create');
  };

  const deleteMagazine = async () => {
    if (selectedRow?.id) {
      await deleteDigiMagazine(selectedRow.id.toString());
      await getAllDigiMagazines();
      const tempResult: Row[] =
        digiMagazines.length > 0
          ? digiMagazines.map((digiMagazine) => ({
              id: digiMagazine.id,
              name: digiMagazine.name,
              value: `${digiMagazine.name} - ${digiMagazine.author}`,
              secondValue: digiMagazine.published ? t('published') : t('draft'),
            }))
          : [];
      setResult(tempResult);
      setSelectedRow(undefined);
    }
    setModalOpen(false);
  };

  const toggleDelete = (id: number) => {
    setModalOpen(true);
    setSelectedRow({ id: id });
  };

  const cancel = () => {
    setSelectedRow(undefined);
    setModalOpen(false);
  };

  return (
    <Layout title={t('digiMagazines.title')}>
      <AddButton onClick={navigateTo} text={t('digiMagazines.add_btn')} />
      <Table
        tableType={DIGIMAGAZINE_TABLE}
        data={result}
        setSelectedRow={(value) => setSelectedRow({ id: value?.id, value: value?.value })}
        selectedRow={selectedRow}
        onDelete={(id) => toggleDelete(id)}
      />
      <AlertModal
        open={modalOpen}
        title="digiMagazines.delete"
        onClose={() => setModalOpen(!modalOpen)}
        leftButtonPress={cancel}
        rightButtonPress={deleteMagazine}
      />
    </Layout>
  );
});

export default DigiMagazineScreen;
