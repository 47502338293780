import { AxiosResponse } from 'axios';
import { MagazineSegment, MagazineSegmentResponse } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  magazineSegments: {
    root: '/magazineSegments',
    forMagazine: '/magazineSegments/magazine/{id}',
    forMagazinePreview: '/magazineSegments/magazine-preview/{id}',
    id: '/magazineSegments/{id}',
  },
});

// MagazineSegment
export const getMagazineSegments = (id: string): Promise<AxiosResponse<MagazineSegmentResponse[]>> =>
  api.get(API_PATHS.magazineSegments.forMagazine.replace('{id}', id), {});

export const getMagazineSegmentsForPreview = (id: string): Promise<AxiosResponse<MagazineSegmentResponse[]>> =>
  api.get(API_PATHS.magazineSegments.forMagazinePreview.replace('{id}', id), {});

export const getSingleMagazineSegment = (id: string): Promise<MagazineSegmentResponse> =>
  api.get(API_PATHS.magazineSegments.id.replace('{id}', id), {});

export const addMagazineSegment = async (
  magazineSegment: MagazineSegment,
  imageFile?: File
): Promise<MagazineSegment> => {
  const formData = new FormData();

  Object.keys(magazineSegment).forEach((key) => {
    formData.append(key, magazineSegment[key as keyof MagazineSegment]?.toString() ?? '');
  });

  if (imageFile) formData.append('image', imageFile ?? magazineSegment.image);

  const result = await api.post(API_PATHS.magazineSegments.root, formData, { timeout: 360000 });

  return result.data;
};

export const updateSingleMagazineSegment = async (
  magazineSegment: MagazineSegment,
  imageFile?: File
): Promise<MagazineSegmentResponse> => {
  const formData = new FormData();

  Object.keys(magazineSegment).forEach((key) => {
    const value = magazineSegment[key as keyof MagazineSegment];
    value && formData.append(key, magazineSegment[key as keyof MagazineSegment]?.toString() ?? '');
  });

  const imageUpdated = formData.get('image') && !imageFile;
  if (!imageUpdated && imageFile) formData.append('image', imageFile);

  const result = await api.put(
    API_PATHS.magazineSegments.id.replace('{id}', magazineSegment.id?.toString() ?? '0'),
    formData,
    {
      timeout: 360000,
    }
  );

  return result.data;
};

export const deleteSingleMagazineSegment = (id: string): Promise<void> => {
  return api.delete(API_PATHS.magazineSegments.id.replace('{id}', id), {});
};
