export enum ArticleSections {
  Undefined = 'Undefined',
  News = 'News',
  LifeSkill = 'LifeSkill',
  SpiritualGrowth = 'SpiritualGrowth',
  Congregation = 'Congregation',
  People = 'People',
  Phenomena = 'Phenomena',
  Internationality = 'Internationality',
  Culture = 'Culture',
  Nostalgia = 'Nostalgia',
  Kids = 'Kids',
}
