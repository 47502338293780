import { AxiosError } from 'axios';

const apiErrorHandler = (error: AxiosError) => {
  if (error.response) {
    const {
      response: { status },
    } = error;

    switch (status) {
      case 401:
        break;
      case 400:
        break;
      case 500:
      case 404:
        break;
      default:
        break;
    }
  }
  throw error;
};

export default apiErrorHandler;
