import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import {
  Article,
  ArticleTypes,
  ArticleTypesEV,
  MagazineSegment,
  MagazineSegmentResponse,
  SegmentFeedTypes,
} from '../../types';
import { color } from '../../theme';
import { MagazineSegmentDefaults } from '../../utils/defaults';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldNumber from 'components/FormComponents/FormFieldNumber';
import FormFieldDropDown from 'components/FormComponents/FormFieldDropDown';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddNewButton from 'components/Button/AddButton';
import FormFieldCheckbox from 'components/FormComponents/FormFieldCheckbox';
import FormFieldDateTime from 'components/FormComponents/FormFieldDateTime';
import {
  addMagazineSegment,
  deleteSingleMagazineSegment,
  getMagazineSegments,
  updateSingleMagazineSegment,
} from 'services/magazineSegmentApi';
import { composeError } from 'utils/transforms';
import SegmentCard from './SegmentCard';
import DialogBase from 'components/common/DialogBase';
import MagazinePreview, { PreviewType } from './MagazinePreview';
import constants from 'config/constants';

const getValidationSchema = (t: (key: string) => string) => {
  return Yup.object().shape({
    articleType: Yup.string().required(t('common_required')),
  });
};

const MagazineSegmentEditScreen = observer(() => {
  const { t } = useTranslation();
  const {
    targetGroupStore: { getTargetGroups },
    onlineMagazineStore: { selectedOnlineMagazine, getSelectedOnlineMagazine },
    articleStore: { articlesForMagazine, getArticlesForMagazine },
    errorStore: { setError, setSuccess },
    userStore: { user },
  } = useMst();
  const { id } = useParams<{ id: string }>();
  const [selectedId, setSelectedId] = useState<number>();
  const [segmentRows, setSegmentRows] = useState<MagazineSegment[]>([]);
  const [reloadPage, setReloadPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [previewDialog, setPreviewDialog] = useState<PreviewType>();

  const getMagazineSegmentsForOnlineMagazine = useCallback(
    async (magazineId?: string) => {
      try {
        if (magazineId) {
          const response = await getMagazineSegments(magazineId);
          const magazineSegments = response.data.map((magazineSegment: MagazineSegmentResponse) => ({
            ...magazineSegment,
          }));
          setSegmentRows(magazineSegments);
        }
      } catch (err) {
        const error = composeError(err);
        setError(error.message);
        console.error(err);
        console.error(error);
      }
    },
    [setError]
  );

  useEffect(() => {
    const fetchPageData = async () => {
      await getTargetGroups();
      await getArticlesForMagazine(id);
      await getSelectedOnlineMagazine(id);
    };

    fetchPageData().then(async () => {
      await getMagazineSegmentsForOnlineMagazine(id);
      setLoading(false);
    });
  }, [getTargetGroups, getMagazineSegmentsForOnlineMagazine, id, getSelectedOnlineMagazine, getArticlesForMagazine]);

  const reloadSegments = async () => {
    // reload segments
    await getMagazineSegmentsForOnlineMagazine(id);
    setReloadPage(!reloadPage);
  };

  const saveMagazineSegment = async (segment: MagazineSegment) => {
    try {
      if (segment.id == -1) {
        await addMagazineSegment(segment);
      } else {
        await updateSingleMagazineSegment(segment);
      }
      setSuccess('save_success');
    } catch (err) {
      console.error(err);
      const error = composeError(err);
      setError(error.message);
    }

    await reloadSegments();
  };

  const saveMagazineSegmentsOrder = async (magazineSegments: MagazineSegment[]) => {
    try {
      magazineSegments.forEach((segment, index) => {
        segment.order = index;
        if (segment.id && segment.id > 0) {
          updateSingleMagazineSegment(segment);
        }
      });
      setSuccess('save_success');
    } catch (err) {
      console.error(err);
      const error = composeError(err);
      setError(error.message);
    }
  };

  const renderHeader = () => {
    const modeText = `${selectedOnlineMagazine?.name} ${t('magazineSegment.titleEdit')}`;
    return loading ? t('magazineSegment.loading') : modeText;
  };

  // a little function to help us with reordering the result
  const reorder = (list: MagazineSegment[], startIndex, endIndex): MagazineSegment[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(segmentRows, result.source.index, result.destination.index);
    setSegmentRows(newItems);
    saveMagazineSegmentsOrder(newItems);
  };

  const addRow = () => {
    const newSegment = MagazineSegmentDefaults;
    newSegment.publicationDate = new Date().toISOString();
    newSegment.order = segmentRows.length;
    newSegment.onlineMagazineId = parseInt(id ?? '-1');
    newSegment.name = '';

    segmentRows.push(newSegment);
    setSelectedId(-1);
  };

  const deleteRow = async (deleteId?: number) => {
    try {
      if (deleteId && deleteId > 0) {
        await deleteSingleMagazineSegment(deleteId.toString());
        setSuccess('delete_success');
      }

      const itemIndex = segmentRows.findIndex((item) => item.id == deleteId);
      if (itemIndex > 0) {
        segmentRows.splice(itemIndex, 1);
      }
    } catch (err) {
      console.error(err);
      const error = composeError(err);
      setError(error.message);
    }

    await reloadSegments();
    setSelectedId(undefined);
  };

  const selectRow = (id?: number) => {
    setSelectedId(id);
  };

  if (loading) {
    return (
      <Layout title={renderHeader()}>
        <CircularProgress size={40} style={{ alignSelf: 'center', marginTop: 30, color: color.primaryButton }} />
        <Typography variant="h5">{t('magazineSegment.loading_text')}</Typography>
      </Layout>
    );
  }

  return (
    <Layout title={renderHeader()} fullWidth>
      <Grid container>
        <Grid item xs={12} mb={3}>
          <Button variant="contained" onClick={() => setPreviewDialog('mobile')}>
            {t('magazineSegment.previewMobile')}
          </Button>
          <Button variant="contained" onClick={() => setPreviewDialog('web')} sx={{ marginLeft: 3 }}>
            {t('magazineSegment.previewWeb')}
          </Button>
        </Grid>
        <Grid item xs={6} mb={1}>
          <Typography variant="h5">{t('magazineSegment.segmentList')}</Typography>
        </Grid>
        <Grid item xs={3} mb={1} textAlign={'right'}>
          <AddNewButton text={t('magazineSegment.addNewSegment')} onClick={addRow} />
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={9}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Grid
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      background: snapshot.isDraggingOver ? '#ececff' : '#eeeeee',
                      padding: '1px 5px 1px 5px',
                      borderRadius: 5,
                    }}
                    padding={1}
                  >
                    {segmentRows?.map((row, index) => (
                      <Draggable key={row.id} draggableId={row.id?.toString()} index={index}>
                        {(provided) => (
                          <Grid
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            pl={0}
                            mr={2}
                          >
                            <SegmentCard
                              row={row}
                              onDelete={deleteRow}
                              onSelect={selectRow}
                              selected={selectedId == row.id}
                              articles={articlesForMagazine.filter(
                                (article) =>
                                  (row.articleType == ArticleTypes.SpeakingEngagements &&
                                    (article.type == ArticleTypes.Editorial ||
                                      article.type == ArticleTypes.Column ||
                                      article.type == ArticleTypes.PointOfView ||
                                      article.type == ArticleTypes.GuestPen)) ||
                                  (row.articleType == ArticleTypes.SmallStory &&
                                    (article.type == ArticleTypes.QuickVisit ||
                                      article.type == ArticleTypes.ChurchVisit)) ||
                                  (row.articleType == ArticleTypesEV.MainArticles &&
                                    (article.type == ArticleTypesEV.News ||
                                      article.type == ArticleTypesEV.Topical ||
                                      article.type == ArticleTypesEV.International ||
                                      article.type == ArticleTypesEV.ChangeStory ||
                                      article.type == ArticleTypesEV.EverydayHero ||
                                      article.type == ArticleTypesEV.MyStory ||
                                      article.type == ArticleTypesEV.Teaching ||
                                      article.type == ArticleTypesEV.CoWorkers ||
                                      article.type == ArticleTypesEV.ExpertsOpinion ||
                                      article.type == ArticleTypesEV.Anniversary ||
                                      article.type == ArticleTypesEV.Obituary)) ||
                                  article.type == row.articleType
                              )}
                            />
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      </Grid>
      {selectedId && segmentRows && (
        <Formik
          initialValues={segmentRows.find((item) => item.id == selectedId) ?? MagazineSegmentDefaults}
          enableReinitialize
          validateOnMount
          onSubmit={(values: MagazineSegment) => {
            saveMagazineSegment(values);
            setSelectedId(undefined);
          }}
          validationSchema={getValidationSchema(t)}
        >
          {({ values, isValid, submitForm, resetForm, setFieldValue }: FormikProps<MagazineSegment>) => {
            return (
              <Grid container spacing={2} padding={1} mt={2}>
                <Grid item xs={12} mb={1}>
                  <Divider />
                </Grid>
                <Grid item xs={12} mb={1}>
                  <Typography variant="caption" fontSize={16} fontWeight={'bold'}>
                    Etusivulohko
                  </Typography>
                </Grid>
                {/* Add/modify name, description, number and release date */}
                <Grid container item xs={10} spacing={2}>
                  <Grid item xs={4}>
                    <FormFieldDateTime label={t('magazineSegment.publication_date')} name="publicationDate" />
                  </Grid>
                  <Grid item xs={4}>
                    <FormFieldDateTime label={t('magazineSegment.removal_date')} name="removalDate" />
                  </Grid>
                </Grid>
                <Grid container item xs={10} spacing={2}>
                  <Grid item xs={10}>
                    <FormFieldDropDown
                      placeholder={t('magazineSegment.article_type') ?? ''}
                      label={t('article.type') ?? ''}
                      name="articleType"
                      values={
                        id == constants.Data.AjassaOnlineMagazineId.toString()
                          ? Object.keys(ArticleTypes).map((key) => ({
                              key: key,
                              value: `articleTypes.${key}`,
                            }))
                          : Object.keys(ArticleTypesEV)
                              .filter(
                                (type) =>
                                  type != ArticleTypesEV.News &&
                                  type != ArticleTypesEV.Topical &&
                                  type != ArticleTypesEV.International &&
                                  type != ArticleTypesEV.ChangeStory &&
                                  type != ArticleTypesEV.EverydayHero &&
                                  type != ArticleTypesEV.MyStory &&
                                  type != ArticleTypesEV.Teaching &&
                                  type != ArticleTypesEV.CoWorkers &&
                                  type != ArticleTypesEV.ExpertsOpinion &&
                                  type != ArticleTypesEV.Anniversary &&
                                  type != ArticleTypesEV.Obituary
                              )
                              .map((key) => ({
                                key: key,
                                value: `articleTypes_ev.${key}`,
                              }))
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={10} spacing={2}>
                  {values.articleType != ArticleTypes.LatestArticles &&
                    values.articleType != ArticleTypes.MostRedArticles && (
                      <Grid item xs={4}>
                        <FormFieldDropDown
                          placeholder={t('magazineSegment.feed_type') ?? ''}
                          label={t('magazineSegment.feed_type') ?? ''}
                          name="feedType"
                          values={Object.keys(SegmentFeedTypes).map((type) => ({
                            key: type,
                            value: `segmentFeedType.${type}`,
                          }))}
                        />
                      </Grid>
                    )}
                  {values.feedType != SegmentFeedTypes.Static && (
                    <Grid item xs={3}>
                      <FormFieldNumber label={t('magazineSegment.articleCount')} name="articleCount" />
                    </Grid>
                  )}
                  {values?.feedType == 'Newest' &&
                    values.articleType != ArticleTypes.LatestArticles &&
                    values.articleType != ArticleTypes.MostRedArticles && (
                      <Grid item xs={3}>
                        <FormFieldNumber label={t('magazineSegment.articleSkip')} name="articleSkip" />
                      </Grid>
                    )}
                  {values?.feedType == 'Static' && (
                    <Grid item xs={8}>
                      <FormFieldDropDown
                        placeholder={t('magazineSegment.single_article') ?? ''}
                        label={t('magazineSegment.single_article') ?? ''}
                        name="singleArticleId"
                        values={articlesForMagazine
                          .filter((a: Article) => a.type == values.articleType)
                          .map((a: Article) => ({ key: a.id, text: a.title }))}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container item xs={10} spacing={2}>
                  <Grid item xs={2}>
                    <FormFieldCheckbox name="mobile" label={t('magazineSegment.mobile')} />
                  </Grid>
                  <Grid item xs={2}>
                    <FormFieldCheckbox name="web" label={t('magazineSegment.web')} />
                  </Grid>
                </Grid>

                {/* publish or save as draft */}
                <Grid container item xs={12} spacing={2} ml={1} mt={1}>
                  <Button
                    onClick={() => {
                      setFieldValue('published', false);
                      submitForm();
                    }}
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                  >
                    {t('save')}
                  </Button>
                  <Button
                    variant="text"
                    style={{ color: 'GrayText', marginLeft: 16 }}
                    onClick={() => {
                      setSelectedId(undefined);
                      resetForm();
                    }}
                  >
                    {t('cancel')}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      )}
      <Grid item xs={12} mt={3}></Grid>
      <DialogBase
        title={t(previewDialog == 'mobile' ? 'magazineSegment.previewMobile' : 'magazineSegment.previewWeb')}
        onClose={() => setPreviewDialog(undefined)}
        onOk={() => setPreviewDialog(undefined)}
        show={!!previewDialog}
        hideCancel
        size={previewDialog == 'mobile' ? 'sm' : 'md'}
        okTitle={t('common.close') ?? ''}
        contentStyle={{ backgroundColor: '#eaeaea', margin: 0, overflowY: 'scroll', padding: 0 }}
        hideActions
      >
        <MagazinePreview type={previewDialog} articles={articlesForMagazine} onlineMagazineId={id} />
      </DialogBase>
    </Layout>
  );
});

export default MagazineSegmentEditScreen;
