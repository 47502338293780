import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import AddButton from '../../components/Button/AddButton';
import Table from '../../components/Table/Table';
import { Row } from '../../types';
import { COMMUNITY_TABLE } from '../../utils/constants';

const CommunitiesScreen = observer(() => {
  const { t } = useTranslation();
  const {
    userStore: { getCommunities, communities },
  } = useMst();
  const [communityResult, setCommunityResult] = useState<Row[]>([]);
  const [selectedRow, setSelectedRow] = useState<Row>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCommunities = async () => {
      await getCommunities();
      const tempCommunities: Row[] =
        communities && communities.length > 0
          ? communities.map((community) => ({
              id: community.id,
              value: community.community,
              name: community.name,
            }))
          : [];

      setCommunityResult(tempCommunities);
    };
    fetchCommunities();
  }, [communities, getCommunities]);

  useEffect(() => {
    if (!selectedRow?.id) return;
    navigate(`/communities/${selectedRow.id}`);
  }, [selectedRow?.id, navigate]);

  return (
    <Layout title={t('communities')}>
      <AddButton onClick={() => navigate('/communities/create')} text={t('community_add')} />
      <Table
        tableType={COMMUNITY_TABLE}
        data={communityResult.length > 0 ? communityResult : []}
        selectedRow={selectedRow}
        setSelectedRow={(value) => setSelectedRow({ id: Number(value?.id), value: value?.value })}
      />
    </Layout>
  );
});

export default CommunitiesScreen;
