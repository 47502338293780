import { Report, ReportTypes } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  reports: {
    reports: '/reports',
    type: '/reports/{type}',
  },
});

// Report
export const getSingleReport = (type: ReportTypes): Promise<Report> =>
  api.get(API_PATHS.reports.type.replace('{type}', type.toString()), {});
