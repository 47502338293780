import { flow, types } from 'mobx-state-tree';
import {
  getAudiobooks,
  getSingleAudiobook,
  addSingleAudiobook,
  updateSingleAudiobook,
  deleteSingleAudiobook,
} from '../services/audiobookApi';
import { composeError } from '../utils/transforms';
import { AudiobookResponse, Audiobook, Category, Tag, TargetGroup, Chapter } from '../types';
import { withRootStore } from './withRootStore';

const ChapterModel = types.model('ChapterModel', {
  id: types.number,
  name: types.string,
  src: types.string,
  chapterNumber: types.number,
  state: types.optional(types.string, ''),
});

const AudiobookModel = types.model('AudiobookModel', {
  id: types.optional(types.number, -1),
  name: types.string,
  image: types.maybeNull(types.string),
  description: types.string,
  releaseDate: types.maybeNull(types.string),
  author: types.string,
  publisher: types.string,
  releaseYear: types.maybeNull(types.number),
  language: types.maybeNull(types.string),
  reader: types.maybeNull(types.string),
  shopUrl: types.maybeNull(types.string),
  ebook: types.maybeNull(types.string),
  ebookStartSkip: types.maybeNull(types.number),
  ebookEndSkip: types.maybeNull(types.number),
  pdfBook: types.maybeNull(types.string),
  mediaTypeId: types.optional(types.number, -1),
  chapters: types.optional(types.array(ChapterModel), []),
  categoryIds: types.optional(types.array(types.number), []),
  tagIds: types.optional(types.array(types.number), []),
  targetGroupIds: types.optional(types.array(types.number), []),
  published: types.optional(types.boolean, false),
});

export const AudiobookStore = types
  .model({
    audiobooks: types.optional(types.array(AudiobookModel), []),
    selectedAudiobook: types.maybeNull(AudiobookModel),
    loading: false,
  })
  .extend(withRootStore)
  .actions((self) => {
    const getAllAudiobooks = flow(function* () {
      try {
        const response = yield getAudiobooks();
        self.audiobooks = response.data.map((book: AudiobookResponse) => ({
          ...book,
          // These are not used where getAllAudiobooks is called
          // mediaTypeId: book.mediaType.id,
          // categoryIds: book.categories?.map((data: Category) => data.id),
          // tagIds: book.tags?.map((data: Tag) => data.id),
          // targetGroupIds: book.targetGroups?.map((data: TargetGroup) => data.id),
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const getSelectedAudiobook = flow(function* (id: string) {
      try {
        const response = yield getSingleAudiobook(id);
        self.selectedAudiobook = {
          ...response.data,
          mediaTypeId: response.data.mediaType.id,
          chapters: response.data.chapters?.map((chapter: Chapter) => ({
            name: chapter.name,
            src: chapter.src,
            chapterNumber: chapter.chapterNumber,
            id: chapter.id,
          })),
          categoryIds: response.data.categories?.map((data: Category) => data.id),
          tagIds: response.data.tags?.map((data: Tag) => data.id),
          targetGroupIds: response.data.targetGroups?.map((data: TargetGroup) => data.id),
        };

        // Sort chapters
        self.selectedAudiobook?.chapters.sort((a, b) => a.chapterNumber - b.chapterNumber);
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const createAudiobook = flow(function* (
      audiobook: Audiobook,
      chapters: Chapter[],
      files: File[],
      image?: File,
      ebook?: File,
      pdfBook?: File
    ) {
      self.loading = true;
      try {
        yield addSingleAudiobook(audiobook, chapters, files, image, ebook, pdfBook);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const updateAudiobook = flow(function* (
      audiobook: Audiobook,
      chapters: Chapter[],
      files: File[],
      image?: File,
      ebook?: File,
      pdfBook?: File
    ) {
      self.loading = true;
      try {
        yield updateSingleAudiobook(audiobook, chapters, files, image, ebook, pdfBook);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const deleteAudiobook = flow(function* (id: string) {
      try {
        yield deleteSingleAudiobook(id);
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getAllAudiobooks,
      getSelectedAudiobook,
      createAudiobook,
      updateAudiobook,
      deleteAudiobook,
    };
  });
