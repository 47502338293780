declare global {
  interface Config extends EnvConfig, CommonConfig {}

  interface EnvConfig {
    APP_URL: string;
    API_URL: string;
    STAGE: string;
  }

  interface CommonConfig {
    NODE_ENV: string;
    MaxAttachmentSize: number;
  }
}

const dev: EnvConfig = {
  APP_URL: process.env.REACT_APP_APP_URL ?? 'http://localhost:3000',
  API_URL: process.env.REACT_APP_API_URL ?? 'http://localhost:4000',
  STAGE: process.env.REACT_APP_STAGE ?? 'test',
};

const prod: EnvConfig = {
  APP_URL: process.env.REACT_APP_APP_URL ?? '',
  API_URL: process.env.REACT_APP_API_URL ?? '',
  STAGE: process.env.REACT_APP_STAGE ?? 'production',
};

const common: CommonConfig = {
  NODE_ENV: process.env.NODE_ENV ?? 'development',
  MaxAttachmentSize: 10000000,
};

const envConfig = process.env.NODE_ENV === 'development' ? dev : prod;
const config: Config = {
  ...common,
  ...envConfig,
};

if (process.env.NODE_ENV === 'development') {
  console.log('config: ', config);
}

export default config;
