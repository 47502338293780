import { flow, types } from 'mobx-state-tree';
import {
  createSingleSubscriptionType,
  deleteSingleSubscriptionType,
  fetchSubscriptionTypes,
  getSingleSubscriptionType,
  updateSingleSubscriptionType,
} from '../services/subscriptionApi';
import { composeError } from '../utils/transforms';
import { SubscriptionType } from '../types';
import { withRootStore } from './withRootStore';
import { SubscriptionTypeDefaults } from 'utils/defaults';

const SubscriptionTypeModel = types.model('SubscriptionTypeModel', {
  id: types.number,
  name: types.string,
  type: types.number,
  description: types.optional(types.string, ''),
  price: types.number,
  active: types.boolean,
});

export const SubscriptionTypeStore = types
  .model({
    subscriptionTypes: types.optional(types.array(SubscriptionTypeModel), []),
    selectedType: types.optional(SubscriptionTypeModel, SubscriptionTypeDefaults),
    error: types.maybeNull(types.frozen()),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getSubscriptionTypes = flow(function* () {
      try {
        const response = yield fetchSubscriptionTypes();
        self.subscriptionTypes = response.data;
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const getSubscriptionType = flow(function* (id: string) {
      try {
        const response = yield getSingleSubscriptionType(id);
        self.selectedType = response.data;
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const addSubscriptionType = flow(function* (newType: SubscriptionType) {
      try {
        yield createSingleSubscriptionType(newType);
        self.rootStore.errorStore.setSuccess('save_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const updateSubscriptionType = flow(function* (type: SubscriptionType) {
      try {
        yield updateSingleSubscriptionType(type.id.toString(), type);
        self.rootStore.errorStore.setSuccess('save_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const deleteSubscriptionType = flow(function* (typeId: number) {
      try {
        yield deleteSingleSubscriptionType(typeId.toString());
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    return {
      getSubscriptionTypes,
      getSubscriptionType,
      addSubscriptionType,
      updateSubscriptionType,
      deleteSubscriptionType,
    };
  });
