import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import AddButton from '../../components/Button/AddButton';
import Table from '../../components/Table/Table';
import { SUBSCRIPTION_TYPE_TABLE } from '../../utils/constants';
import { Row, LinkRow } from '../../types';

const SubscriptionScreen = observer(() => {
  const { t } = useTranslation();
  const {
    subscriptionTypeStore: { subscriptionTypes, getSubscriptionTypes },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow>();
  const [result, setResult] = useState<Row[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPages = async () => {
      await getSubscriptionTypes();
      const tempResult: Row[] =
        subscriptionTypes.length > 0
          ? subscriptionTypes.map((type) => ({
              id: type.id,
              value: type.name,
              secondValue: type.price,
            }))
          : [];

      setResult(tempResult);
    };
    fetchPages();
  }, [getSubscriptionTypes, subscriptionTypes]);

  useEffect(() => {
    if (!selectedRow?.id) return;
    navigate(`/subscriptions/${selectedRow.id}`);
  }, [selectedRow?.id, navigate]);

  return (
    <Layout title={t('subscription_types')}>
      <AddButton onClick={() => navigate('/subscriptions/create')} text={t('add_new')} />
      <Table
        data={result.length > 0 ? result : []}
        tableType={SUBSCRIPTION_TYPE_TABLE}
        selectedRow={selectedRow}
        setSelectedRow={(value) => setSelectedRow({ id: value?.id, value: value?.value })}
      />
    </Layout>
  );
});

export default SubscriptionScreen;
