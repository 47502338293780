import { palette } from './palette';

export const color = {
  black: palette.black,
  white: palette.white,

  grayBackground: palette.lightGrey,
  secondGreyBackground: palette.secondLighGrey,
  primaryButton: palette.darkGreen,
  secondaryButton: palette.buttonSecondary,
  deleteButton: palette.red,

  greyText: palette.grey,
  greyBorder: palette.borderGrey,
  greyBorderLight: palette.lightBorderGrey,

  error: 'red',
  info: 'blue',
  warning: palette.amber,
  success: palette.green,
};
