import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { Article } from 'types';
import tilaajatahti from '../../static/ajassa_tilaajatahti.svg';
import { useTranslation } from 'react-i18next';
import constants from 'config/constants';

interface SubscribersTagProps {
  article: Article;
  center?: boolean;
}

const SubscribersTag: FC<SubscribersTagProps> = ({ article, center }: SubscribersTagProps) => {
  const { t } = useTranslation();

  return (
    <>
      {article.targetGroupIds?.includes(constants.Data.SubscriperTargetGroupId) && (
        <Grid container item xs={12} mt={2} mb={1} justifyContent={center ? 'center' : undefined}>
          <Grid item mr={1}>
            <img
              src={tilaajatahti}
              style={{
                paddingTop: 7,
                height: 13,
              }}
              alt="Tilaajatähti"
            />
          </Grid>
          <Grid item>
            <span className="articleSubscriberTagText">{t('article.subscriberOnly')}</span>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SubscribersTag;
