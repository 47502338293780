import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import Table from '../../components/Table/Table';
import AddButton from '../../components/Button/AddButton';
import { useTranslation } from 'react-i18next';
import { AUDIOBOOK_TABLE } from '../../utils/constants';
import { Community, LinkRow, Row } from '../../types';
import AlertModal from '../../components/AlertModal';

const AudiobookScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    audiobookStore: { audiobooks, getAllAudiobooks, deleteAudiobook },
    userStore: { communities, user, getCommunities },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow>();
  const [result, setResult] = useState<Row[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const filterAudiobooks = useCallback(
    (communities: Community[], ownCommunities: Community[]) => {
      const filtered = communities.filter((community: Community) => {
        return ownCommunities.some((el) => {
          return el.id === community.id;
        });
      });
      const filteredAudiobooks = audiobooks.filter((audiobook) => {
        return filtered.find(({ community }) => community === audiobook.publisher);
      });
      return filteredAudiobooks;
    },
    [audiobooks]
  );

  useEffect(() => {
    const getAudiobooks = async () => {
      await getAllAudiobooks();

      if (user?.admin) {
        const tempResult: Row[] =
          audiobooks.length > 0
            ? audiobooks.map((audiobook) => ({
                id: audiobook.id,
                name: audiobook.name,
                value: `${audiobook.name} - ${audiobook.author}`,
                secondValue: audiobook.published ? t('published') : t('draft'),
              }))
            : [];
        setResult(tempResult);
      } else {
        if (communities && user?.communities.length) {
          const filtered = filterAudiobooks(communities, user.communities);
          const tempResult: Row[] =
            filtered && filtered.length > 0
              ? filtered.map((audiobook) => ({
                  id: audiobook.id,
                  name: audiobook.name,
                  value: `${audiobook.name} - ${audiobook.author}`,
                  secondValue: audiobook.published ? t('published') : t('draft'),
                }))
              : [];
          setResult(tempResult);
        }
      }
    };

    getAudiobooks();
  }, [getAllAudiobooks, getCommunities, user, communities, audiobooks, t, filterAudiobooks]);

  useEffect(() => {
    if (!selectedRow?.id || modalOpen) return;
    navigate(`/audiobooks/${selectedRow.id}`);
  }, [selectedRow?.id, modalOpen, navigate]);

  const navigateTo = () => {
    navigate('/audiobooks/create');
  };

  const deleteBook = async () => {
    if (selectedRow?.id) {
      await deleteAudiobook(selectedRow.id.toString());
      await getAllAudiobooks();
      const tempResult: Row[] =
        audiobooks.length > 0
          ? audiobooks.map((audiobook) => ({
              id: audiobook.id,
              name: audiobook.name,
              value: `${audiobook.name} - ${audiobook.author}`,
              secondValue: audiobook.published ? t('published') : t('draft'),
            }))
          : [];
      setResult(tempResult);
      setSelectedRow(undefined);
    }
    setModalOpen(false);
  };

  const toggleDelete = (id: number) => {
    setModalOpen(true);
    setSelectedRow({ id: id });
  };

  const cancel = () => {
    setSelectedRow(undefined);
    setModalOpen(false);
  };

  return (
    <Layout title={t('audiobooks')}>
      <AddButton onClick={navigateTo} text={t('add_audiobook')} />
      <Table
        tableType={AUDIOBOOK_TABLE}
        data={result}
        setSelectedRow={(value) => setSelectedRow({ id: value?.id, value: value?.value })}
        selectedRow={selectedRow}
        onDelete={(id) => toggleDelete(id)}
      />
      <AlertModal
        open={modalOpen}
        title="delete_audiobook"
        onClose={() => setModalOpen(!modalOpen)}
        leftButtonPress={cancel}
        rightButtonPress={deleteBook}
      />
    </Layout>
  );
});

export default AudiobookScreen;
