import React, { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Layout from './Layout';

const LandingScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('common.landingScreen')}>
      <Box m={2} />
      <Grid container>
        <Grid container>
          <Typography color="GrayText">{t('common.landingTitle')} </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default LandingScreen;
