import { Subscription, SubscriptionType } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  subscriptions: {
    root: '/subscription',
    id: '/subscription/{id}',
    types: '/subscription/types',
    typesId: '/subscription/types/{id}',
    stripeSubscriptionId: '/subscription/external/{id}',
    products: '/subscription/products',
    cancel: '/subscription/{id}/cancel',
  },
});

// Subscription
export const fetchSubscriptions = (): Promise<Subscription[]> => api.get(API_PATHS.subscriptions.root, {});

export const getSingleSubscription = (id: string): Promise<Subscription> =>
  api.get(API_PATHS.subscriptions.id.replace('{id}', id));

export const fetchSubscriptionTypes = (): Promise<SubscriptionType[]> => api.get(API_PATHS.subscriptions.types, {});

export const getSingleSubscriptionType = (id: string): Promise<SubscriptionType> =>
  api.get(API_PATHS.subscriptions.typesId.replace('{id}', id));

export const createSingleSubscriptionType = (fields: SubscriptionType): Promise<SubscriptionType> =>
  api.post(API_PATHS.subscriptions.types, fields);

export const updateSingleSubscriptionType = (id: string, fields: SubscriptionType): Promise<SubscriptionType> =>
  api.put(API_PATHS.subscriptions.typesId.replace('{id}', id), fields);

export const deleteSingleSubscriptionType = (id: string): Promise<void> =>
  api.delete(API_PATHS.subscriptions.typesId.replace('{id}', id));

export const getUserStripeSubscriptions = (id: string): Promise<Subscription[]> =>
  api.get(API_PATHS.subscriptions.stripeSubscriptionId.replace('{id}', id));

export const getSubscriptionProducts = () => api.get(API_PATHS.subscriptions.products);

export const cancelUserSubscription = (subscriptionId: number): Promise<void> =>
  api.post(API_PATHS.subscriptions.cancel.replace('{id}', subscriptionId.toString()), {});
