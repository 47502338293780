import { flow, types } from 'mobx-state-tree';
import {
  getAllContentPages,
  getSingleContentPage,
  createSingleContentPage,
  updateSingleContentPage,
  deleteSingleContentPage,
} from '../services/api';
import { composeError } from '../utils/transforms';
import { ContentPageResponse, Category, Tag, TargetGroup, ContentPageRequest } from '../types';
import { withRootStore } from './withRootStore';

const ContentPageModel = types.model('ContentPageModel', {
  id: types.optional(types.number, -1),
  title: types.optional(types.string, ''),
  image: types.maybeNull(types.string),
  text: types.optional(types.string, ''),
  url: types.optional(types.string, ''),
  priorityIndex: types.optional(types.number, -1),
  published: types.optional(types.boolean, false),
  categories: types.optional(types.array(types.number), []),
  tags: types.optional(types.array(types.number), []),
  targetGroups: types.optional(types.array(types.number), []),
});

export const ContentPageStore = types
  .model({
    pages: types.optional(types.array(ContentPageModel), []),
    selectedPage: types.optional(ContentPageModel, {}),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getPages = flow(function* () {
      try {
        const response = yield getAllContentPages();
        const pages = response.data.map((data: ContentPageResponse) => ({
          ...data,
          text: data.text,
          image: data?.image,
          categories: data.categories?.map((data: Category) => data.id),
          tags: data.tags?.map((data: Tag) => data.id),
          targetGroups: data.targetGroups?.map((data: TargetGroup) => data.id),
        }));
        self.pages = pages;
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const getSinglePage = flow(function* (id: string) {
      try {
        const response = yield getSingleContentPage(id);
        self.selectedPage = {
          ...response.data,
          title: response.data.title,
          image: response.data?.image,
          text: response.data?.text,
          url: response.data.url,
          priorityIndex: response.data.priorityIndex,
          published: response.data.published,
          categories: response.data.categories.map((data: Category) => data.id),
          tags: response.data.tags.map((data: Tag) => data.id),
          targetGroups: response.data.targetGroup.map((data: TargetGroup) => data.id),
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const createContentPage = flow(function* (page: ContentPageRequest, image: any) {
      try {
        yield createSingleContentPage({ ...page }, image);
        self.rootStore.errorStore.setSuccess('save_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    const updateContentPage = flow(function* (id: string, page: ContentPageRequest, image: any) {
      try {
        yield updateSingleContentPage(id, { ...page }, image);
        self.rootStore.errorStore.setSuccess('save_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    const deleteContentPage = flow(function* (id: string) {
      try {
        yield deleteSingleContentPage(id);
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getPages,
      getSinglePage,
      createContentPage,
      updateContentPage,
      deleteContentPage,
    };
  });
