import React, { FC, ReactNode } from 'react';
import { Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material';
import { useField } from 'formik';

interface FormFieldCheckboxProps {
  label: string | ReactNode;
  name: string;
  readonly?: boolean;
  autoFocus?: boolean;
  color?: 'error' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
}

const FormFieldCheckbox: FC<FormFieldCheckboxProps> = ({
  label,
  name,
  readonly,
  autoFocus,
  color,
}: FormFieldCheckboxProps) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    helpers.setValue(event.target.checked);
  };

  return (
    <FormControl error={!!meta.error}>
      <FormControlLabel
        id={field.name}
        control={
          <Checkbox
            autoFocus={autoFocus}
            name={field.name}
            checked={field.value ?? false}
            onChange={handleChange}
            onBlur={field.onBlur}
            color={color ?? 'primary'}
          />
        }
        label={label}
        disabled={readonly}
      />
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default FormFieldCheckbox;
