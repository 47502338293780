import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { Podcast, Episode } from '../../types';
import { color } from '../../theme';
import TextInput from '../../components/FormComponents/TextInput';
import Button from '../../components/Button/Button';
import { PodcastDefaults } from '../../utils/defaults';
import AddNewButton from '../../components/Button/AddButton';
import { Grid, IconButton, TextField, Typography, colors } from '@mui/material';
import EditIcon from '../../static/edit.svg';
import DeleteIcon from '../../static/delete.svg';
import SaveIcon from '../../static/save.svg';
import UploadButton from '../../components/Button/UploadButton';
import ImageUploadButton from '../../components/Button/ImageUploadButton';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/FormComponents/FormFieldText';
import FormFieldDate from 'components/FormComponents/FormFieldDate';
import FormFieldTextArea from 'components/FormComponents/FormFieldTextArea';
import FormFieldCheckboxList from 'components/FormComponents/FormFieldCheckboxList';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import FormFieldNumber from 'components/FormComponents/FormFieldNumber';

const getValidationSchema = (t: (key: string) => string, values: Podcast, image: File | undefined) => {
  if (!values.published) {
    const draftSchema = Yup.object().shape({
      name: Yup.string().required(t('common_required')),
      mediaTypeId: Yup.number().required(t('common_required')),
    });

    return draftSchema;
  }

  const podcastSchema = Yup.object().shape({
    name: Yup.string().required(t('common_required')),
    image: Yup.string().test(
      'image-test',
      t('common_required'),
      (value) => values.published || (!!value && value?.length > 0) || !!image
    ),
    //      (value) => values.reason !== 99 || (!!value && value?.length > 0)
    publisher: Yup.string().required(t('common_required')),
    releaseDate: Yup.string().required(t('common_required')),
    language: Yup.string().required(t('common_required')),
    mediaTypeId: Yup.number().required(t('common_required')),
    targetGroupIds: Yup.array().min(1, t('common_required')),
  });

  return podcastSchema;
};

const PodcastEditScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    targetGroupStore: { targetGroups, getTargetGroups },
    podcastStore: {
      selectedPodcast,
      getSelectedPodcast,
      updatePodcast,
      createPodcast,
      loading,
      checkRssFeedUrl,
      rssFeed,
    },
  } = useMst();
  const { id } = useParams<{ id: string }>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [currentEpisodeId, setCurrentEpisodeId] = useState(0);
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [podcast, setPodcast] = useState<Podcast>(PodcastDefaults);
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const [episodeName, setEpisodeName] = useState('');
  const [episodeNotes, setEpisodeNotes] = useState('');
  const [episodeReleaseDate, setEpisodeReleaseDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [uploadDialogVisible, setUploadDialogVisible] = useState<boolean>(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const messageRef = useRef<HTMLDivElement>(null);
  const counterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchLists = async () => {
      await getTargetGroups();
    };

    fetchLists();
  }, [getTargetGroups]);

  useEffect(() => {
    const fetchPage = async () => {
      if (id !== 'create') {
        await getSelectedPodcast(id);
        if (selectedPodcast?.image) {
          setImagePreviewUrl(selectedPodcast?.image);
        }
        setPodcast(selectedPodcast);
        const episodes = selectedPodcast?.episodes.map((s) => ({ ...s, state: '' })) ?? [];
        setEpisodes(episodes);
      }
    };

    fetchPage();
  }, [getSelectedPodcast, selectedPodcast, id]);

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target?.files) {
      const image = URL.createObjectURL(target.files[0]);
      setSelectedImage(target.files[0]);
      setImagePreviewUrl(image);
    }
  };

  const handleEpisodeNameChange = (key: string, value: string) => {
    setEpisodeName(value);
  };

  const handleEpisodeNotesChange = (key: string, value: string) => {
    setEpisodeNotes(value);
  };

  const handleFilePick = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files);
      setCurrentFiles(fileArray);

      if (e.target.files.length == 1) {
        if (episodeName == '') {
          const filenameWithoutExt = e.target.files[0].name.split('.').slice(0, -1).join('.');
          setEpisodeName(filenameWithoutExt);
        }
      } else {
        for (const file of e.target.files) {
          const filenameWithoutExt = file.name.split('.').slice(0, -1).join('.');
          episodes.push({
            name: filenameWithoutExt,
            file: file,
            order: episodes.length == 0 ? 0 : episodes[episodes.length - 1].order + 1,
            src: file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            notes: episodeNotes,
            releaseDate: episodeReleaseDate,
            state: 'new',
          });
          files.push(file);
        }

        setCurrentFiles([]);
        setEpisodeName('');
        setEpisodeNotes('');
        setEpisodeReleaseDate(format(new Date(), 'yyyy-MM-dd'));
        setCurrentEpisodeId(0);
        setUploadDialogVisible(false);
      }
    }
  };

  const handleEpisodeSave = () => {
    if (currentEpisodeId == 0) return;

    if (currentFiles.length == 1) {
      // new chapter
      episodes.push({
        name: episodeName,
        file: currentFiles[0],
        image: null,
        order: episodes.length == 0 ? 0 : episodes[episodes.length - 1].order + 1,
        src: currentFiles[0].name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        notes: episodeNotes,
        releaseDate: episodeReleaseDate,
        state: 'new',
      });
      setFiles([...files, currentFiles[0]]);
    } else {
      const index = episodes.findIndex((chapter) => chapter.id === currentEpisodeId);
      if (index > -1) {
        episodes[index].name = episodeName;
        episodes[index].state = 'edited';
        episodes[index].notes = episodeNotes;
        episodes[index].releaseDate = episodeReleaseDate;
      }
    }
    setCurrentFiles([]);
    setEpisodeName('');
    setEpisodeNotes('');
    setEpisodeReleaseDate(format(new Date(), 'yyyy-MM-dd'));
    setCurrentEpisodeId(0);
    setUploadDialogVisible(false);
  };

  const handleEpisodeEdit = (episode: Episode) => {
    setUploadDialogVisible(true);
    setCurrentEpisodeId(episode.id ?? 0);

    const index = getEpisodeIndex(episode);
    setEpisodeName(episodes[index].name);
    setEpisodeNotes(episodes[index].notes);
    setEpisodeReleaseDate(episodes[index].releaseDate ?? format(new Date(), 'yyyy-MM-dd'));
  };

  const handleDeleteFile = (episode: Episode) => {
    const index = getEpisodeIndex(episode);

    if (index > -1) {
      if ((episodes[index].id ?? 0) > 0) {
        // already saved to db, flag for delete
        episodes[index].state = 'deleted';
      } else {
        // just remove item from array, not stored to db
        episodes.splice(index, 1);
      }

      setRefreshPage(!refreshPage);
    }
  };

  const getEpisodeIndex = (episode: Episode): number => {
    const index = episodes.findIndex((s) => s.id == episode.id && s.name == episode.name && s.order == episode.order);
    return index;
  };

  // const changeFileOrder = (episode: Episode, direction: string) => {
  //   // comparison made with all values, in order find existing and new episodes
  //   // TODO deleted ones are not correctly handled
  //   const index = getEpisodeIndex(episode);

  //   if (index > -1) {
  //     if (direction === 'up') {
  //       if (episodes[index].order < 1) return;

  //       episodes[index].order = episodes[index].order - 1;
  //       episodes[index - 1].order = episodes[index - 1].order + 1;
  //     }

  //     if (direction === 'down') {
  //       if (episodes[index].order > episodes.length) return;

  //       episodes[index].order = episodes[index].order + 1;
  //       episodes[index + 1].order = episodes[index + 1].order - 1;
  //     }
  //   }
  //   // Sort episodes
  //   episodes.sort((a, b) => a.order - b.order);

  //   setRefreshPage(!refreshPage);
  // };

  const savePodcast = async (podcast: Podcast) => {
    if (id !== 'create') {
      await updatePodcast(podcast, episodes, files, selectedImage);
    } else {
      await createPodcast(podcast, episodes, files, selectedImage);
    }
    navigate('/podcasts');
  };

  const renderEpisodeUpload = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {t('podcasts_episodes')}
          </Typography>
        </Grid>
        {episodes.length == 0 && (
          <Grid item xs={9}>
            <Typography variant="body2" color="GrayText">
              {t('podcasts_no_episodes')}
            </Typography>
          </Grid>
        )}

        {episodes
          .filter((c) => c.state != 'deleted')
          .sort((a, b) => {
            if (!a.releaseDate || !b.releaseDate) return 0;
            if (a.releaseDate > b.releaseDate) return 1;
            if (a.releaseDate < b.releaseDate) return -1;
            return 0;
          })
          .map((episode: Episode, index: number) => {
            return (
              <Grid
                container
                key={`${episode.id}_${index}`}
                style={{ borderBottom: '1px solid lightGray', marginLeft: 24 }}
              >
                <Grid container item xs={10} alignSelf={'center'}>
                  <Grid item xs={2} alignSelf={'center'}>
                    <Typography variant="body1">
                      {episode.releaseDate ? format(new Date(episode.releaseDate), 'dd.MM.yyyy') : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} alignSelf={'center'}>
                    <Typography variant="body1">{episode.name}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleEpisodeEdit(episode)}>
                    <img src={EditIcon} alt="Edit" />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteFile(episode)}>
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <AddNewButton
            text={t('podcasts_add_episode')}
            onClick={() => {
              setCurrentEpisodeId(-1);
              setEpisodeName('');
              setEpisodeNotes('');
              setEpisodeReleaseDate(format(new Date(), 'yyyy-MM-dd'));
              setUploadDialogVisible(!uploadDialogVisible);
            }}
          />
        </Grid>
        {uploadDialogVisible && (
          <Grid
            container
            item
            xs={10}
            spacing={1}
            style={{ border: 'solid 1px grey', borderRadius: 3, padding: 10, margin: 10 }}
          >
            {currentFiles.length <= 1 && (
              <>
                <Grid item xs={12}>
                  <TextInput
                    label={'podcasts_episode_name'}
                    value={episodeName}
                    type={'name'}
                    placeholder={'podcasts_episode_name'}
                    onChange={handleEpisodeNameChange}
                    style={{ width: '80%' }}
                    multiline={false}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <DatePicker
                    label={t('podcasts_episode_release_date')}
                    value={new Date(episodeReleaseDate)}
                    onChange={(newValue: Date | null) => {
                      if (newValue != null && newValue.getFullYear() > 1900) {
                        setEpisodeReleaseDate(format(newValue, 'yyyy-MM-dd'));
                      }
                      if (newValue == null) {
                        setEpisodeReleaseDate(format(new Date(), 'yyyy-MM-dd'));
                      }
                    }}
                    disableMaskedInput
                    inputFormat="d.M.yyyy"
                    openTo={'day'}
                    views={['year', 'month', 'day']}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    renderInput={(props: any) => <TextField size="small" {...props} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={'podcasts_episode_notes'}
                    value={episodeNotes}
                    type={'notes'}
                    placeholder={'podcasts_episode_notes'}
                    onChange={handleEpisodeNotesChange}
                    style={{ width: '80%' }}
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p ref={counterRef}></p>
                  <p ref={messageRef}></p>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {currentEpisodeId == -1 ? (
                <Grid item>
                  <UploadButton
                    buttonText="podcasts_upload_episode"
                    onChange={handleFilePick}
                    fileName={
                      currentFiles?.length === 1 ? currentFiles[0].name : currentFiles.length > 0 ? 'Useita' : ''
                    }
                    fileType=".mp3"
                  />
                </Grid>
              ) : (
                <Typography variant="caption" color="GrayText">
                  {t('podcasts_episode_edit_limited')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <IconButton onClick={handleEpisodeSave}>
                <img src={SaveIcon} alt="Save" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderHeader = () => {
    const modeText = id !== 'create' ? t('podcasts_edit') : t('podcasts_add');
    return loading ? t('podcasts_loading') : modeText;
  };

  if (loading) {
    return (
      <Layout title={renderHeader()}>
        <CircularProgress size={40} style={{ alignSelf: 'center', marginTop: 30, color: color.primaryButton }} />
        <Typography variant="h5">{t('podcasts_loading_text')}</Typography>
      </Layout>
    );
  }

  return (
    <Formik
      initialValues={podcast}
      enableReinitialize
      onSubmit={(values: Podcast) => {
        savePodcast(values);
      }}
      validationSchema={Yup.lazy((values: Podcast) => getValidationSchema(t, values, selectedImage))}
    >
      {({ isValid, submitForm, setFieldValue, values }: FormikProps<Podcast>) => {
        const checkRssFeed = async () => {
          if (!!values.rssFeed && values.rssFeed.length > 0) {
            const url: string = values.rssFeed ?? '';

            await checkRssFeedUrl(url);

            if (rssFeed) {
              setFieldValue('name', rssFeed.title);
              setFieldValue('description', rssFeed.description);
              if (rssFeed.language == 'fi') {
                setFieldValue('language', 'Suomi');
              }
              setFieldValue('image', rssFeed.image);
              setFieldValue('publisher', rssFeed.publisher);
            }
          }
        };

        return (
          <Layout title={renderHeader()}>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <Typography
                  variant="h4"
                  color={podcast?.published ? 'primary' : 'secondary'}
                  borderColor={podcast?.published ? 'primary' : 'secondary'}
                  border={1}
                  borderRadius={2}
                  sx={{ padding: 1, fontSize: 18, width: 200, marginBottom: 2 }}
                >
                  {podcast?.published ? t('published') : t('draft')}
                </Typography>
              </Grid>

              {/* Add/modify name, description, number and release date */}
              <Grid item xs={10}>
                <FormFieldText label={t('podcasts_name')} name="name" />
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={4}>
                  <FormFieldText label={t('podcasts_author')} name="author" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldText label={t('podcasts_publisher')} name="publisher" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldDate label={t('podcasts_release_date')} name="releaseDate" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={4}>
                  <FormFieldText label={t('podcasts_language')} name="language" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldNumber label={t('podcasts_order')} name="order" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={12}>
                  <FormFieldTextArea label={t('podcasts_description')} name="description" rows={7} />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldText label={t('podcasts_web_url')} name="webUrl" />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={8}>
                    <FormFieldText label={t('podcasts_rss_feed')} name="rssFeed" />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      onClick={checkRssFeed}
                      text={'Tarkista'}
                      small
                      backgroundColor={colors.blueGrey[600]}
                      disabled={(values?.rssFeed?.length ?? 0) == 0}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Upload cover image */}
              <Grid item xs={10}>
                <ImageUploadButton
                  text="podcasts_cover_image"
                  imagePreviewUrl={imagePreviewUrl}
                  handleImageSelection={handleImageSelection}
                />
              </Grid>

              {/* Upload podcast episodes (MP3) */}
              {(values?.rssFeed?.length ?? 0) == 0 && (
                <Grid container item xs={10} spacing={3} ml={3}>
                  {renderEpisodeUpload()}
                </Grid>
              )}
              {(values?.rssFeed?.length ?? 0) > 0 && (
                <Grid container item xs={10} spacing={3} ml={1}>
                  <Typography color="GrayText">RSS Feed käytössä - jaksojen lisäys estetty</Typography>
                </Grid>
              )}
              {/* Select categories, user groups */}
              <Grid container item xs={12} spacing={3} mt={2}>
                <Grid container item xs={6} spacing={3} alignContent={'flex-start'}>
                  {/* <FormFieldCategories categories={categories} name="categoryIds" mediaType={MediaTypes.Podcast} /> */}

                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('target_groups')} name="targetGroupIds" listItems={targetGroups} />
                  </Grid>

                  {/* publish or save as draft */}
                  <Grid container item xs={10} spacing={3}>
                    <Button
                      text="save_draft"
                      onClick={() => {
                        setFieldValue('published', false);
                        submitForm();
                      }}
                      backgroundColor={color.secondaryButton}
                      width={220}
                      marginBottom={30}
                    />
                    <Button
                      text="publish"
                      onClick={() => {
                        setFieldValue('published', true);
                        submitForm();
                      }}
                      marginBottom={30}
                      disabled={!isValid}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={6} spacing={3}></Grid>
              </Grid>
            </Grid>
          </Layout>
        );
      }}
    </Formik>
  );
});

export default PodcastEditScreen;
