import LoginScreen from '../containers/LoginScreen';
import UsersScreen from '../containers/Users/UsersScreen';
import UserSettingsScreen from '../containers/UserSettingsScreen';
import AppItemScreen from '../containers/AppItemScreen';
import TargetGroupScreen from '../containers/TargetGroup/TargetGroupScreen';
import TargetGroupEditScreen from '../containers/TargetGroup/TargetGroupEditScreen';
import NavigationScreen from '../containers/NavigationScreen';
import CollectionScreen from '../containers/Collection/CollectionScreen';
import CollectionEditScreen from '../containers/Collection/CollectionEditScreen';
import UsersEditScreen from '../containers/Users/UsersEditScreen';
import CommunitiesScreen from '../containers/Communities/CommunitiesScreen';
import CommunitiesEditScreen from '../containers/Communities/CommunitiesEditScreen';
import PromotionScreen from '../containers/Promotion/PromotionScreen';
import EditPromotionScreen from '../containers/Promotion/EditPromotionScreen';
import ContentPageEditScreen from '../containers/Content/ContentPageEditScreen';
import ContentPageScreen from '../containers/Content/ContentPageScreen';
import ArticlePageScreen from '../containers/ArticlePages/ArticlePageScreen';
import ArticlePageEditScreen from '../containers/ArticlePages/ArticlePageEditScreen';
import NewspaperScreen from '../containers/Newspaper/NewspaperScreen';
import NewspaperEditScreen from '../containers/Newspaper/NewspaperEditScreen';
import AudiobookScreen from '../containers/Audiobook/AudiobookScreen';
import AudiobookEditScreen from '../containers/Audiobook/AudiobookEditScreen';
import SubscriptionScreen from 'containers/Subscription/SubscriptionScreen';
import SubscriptionEditScreen from 'containers/Subscription/SubscriptionEditScreen';
import ResetPasswordScreen from 'containers/Auth/ResetPasswordScreen';
import { observer } from 'mobx-react-lite';
import { useMst } from '../model/Root';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PageNotFound from './PageNotFound';
import AlbumEditScreen from 'containers/Music/AlbumEditScreen';
import AlbumScreen from 'containers/Music/AlbumScreen';
import TagsScreen from 'containers/TagsScreen';
import CategoriesScreen from 'containers/CategoriesScreen';
import PodcastScreen from 'containers/Podcast/PodcastScreen';
import PodcastEditScreen from 'containers/Podcast/PodcastEditScreen';
import MagazineScreen from 'containers/Magazine/MagazineScreen';
import MagazineEditScreen from 'containers/Magazine/MagazineEditScreen';
import ProgramScreen from 'containers/Program/ProgramScreen';
import ProgramEditScreen from 'containers/Program/ProgramEditScreen';
import ReportScreen from 'containers/Reports/ReportScreen';
import ArticleScreen from 'containers/Article/ArticleScreen';
import ArticleEditScreen from 'containers/Article/ArticleEditScreen';
import OnlineMagazineEditScreen from 'containers/OnlineMagazine/OnlineMagazineEditScreen';
import OnlineMagazineScreen from 'containers/OnlineMagazine/OnlineMagazineScreen';
import MagazineSegmentEditScreen from 'containers/MagazineSegment/MagazineSegmentEditScreen';
import DigiMagazineEditScreen from 'containers/DigiMagazine/DigiMagazineEditScreen';
import DigiMagazineScreen from 'containers/DigiMagazine/DigiMagazineScreen';
import LandingScreen from './LandingScreen';

const routes = [
  { path: '/', component: LandingScreen },
  { path: '/login', component: LoginScreen },
  { path: '/portal-users', component: UsersScreen },
  { path: '/portal-users/:userId', component: UsersEditScreen },
  { path: '/admin-users', component: UsersScreen },
  { path: '/admin-users/:userId', component: UsersEditScreen },
  { path: '/change-password', component: UserSettingsScreen },
  { path: '/emp-users', component: UsersScreen },
  { path: '/emp-users/:userId', component: UsersEditScreen },
  { path: '/communities', component: CommunitiesScreen },
  { path: '/communities/:communityId', component: CommunitiesEditScreen },
  { path: '/categories', component: CategoriesScreen },
  { path: '/tags', component: TagsScreen },
  { path: '/header-footer', component: AppItemScreen },
  { path: '/targets-groups', component: TargetGroupScreen },
  { path: '/targets-groups/:groupId', component: TargetGroupEditScreen },
  { path: '/promotion', component: PromotionScreen },
  { path: '/promotion/:promotionId', component: EditPromotionScreen },
  { path: '/navigation', component: NavigationScreen },
  { path: '/collection', component: CollectionScreen },
  { path: '/collection/:id', component: CollectionEditScreen },
  { path: '/content/:id', component: ContentPageEditScreen },
  { path: '/content', component: ContentPageScreen },
  { path: '/article-pages/:id', component: ArticlePageEditScreen },
  { path: '/article-pages', component: ArticlePageScreen },
  { path: '/newspaper', component: NewspaperScreen },
  { path: '/newspaper/:id', component: NewspaperEditScreen },
  { path: '/audiobooks', component: AudiobookScreen },
  { path: '/audiobooks/:id', component: AudiobookEditScreen },
  { path: '/subscriptions', component: SubscriptionScreen },
  { path: '/subscriptions/:id', component: SubscriptionEditScreen },
  { path: '/reset-password', component: ResetPasswordScreen },
  { path: '/reset-password/:resetToken', component: ResetPasswordScreen },
  { path: '/albums', component: AlbumScreen },
  { path: '/albums/:id', component: AlbumEditScreen },
  { path: '/podcasts', component: PodcastScreen },
  { path: '/podcasts/:id', component: PodcastEditScreen },
  { path: '/magazines', component: MagazineScreen },
  { path: '/magazines/:id', component: MagazineEditScreen },
  { path: '/programs', component: ProgramScreen },
  { path: '/programs/:id', component: ProgramEditScreen },
  { path: '/reports', component: ReportScreen },
  { path: '/onlinemagazine/:magazineId/articles', component: ArticleScreen },
  { path: '/onlinemagazine/:magazineId/articles/:id', component: ArticleEditScreen },
  { path: '/onlinemagazines', component: OnlineMagazineScreen },
  { path: '/onlinemagazines/:id', component: OnlineMagazineEditScreen },
  { path: '/magazine-segments/:id', component: MagazineSegmentEditScreen },
  { path: '/digimagazines', component: DigiMagazineScreen },
  { path: '/digimagazines/:id', component: DigiMagazineEditScreen },
];

const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((item) => {
        return (
          <Route
            key={item.path}
            path={item.path}
            element={
              <RequireAuth>
                <item.component />
              </RequireAuth>
            }
          />
        );
      })}
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/pageNotFound" element={<PageNotFound />} />
      <Route element={<PageNotFound />} />
    </Routes>
  );
};

const RequireAuth = observer(({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { errorStore } = useMst();
  const { error, clearError } = errorStore;

  // Handle unauthorized errors
  if (location.pathname.startsWith('/reset-password')) {
    // allow go through
    return children;
  }

  if (error && error.key === 'unauthorized') {
    clearError();
    return <Navigate to="/" />;
  }

  return children;
});

export default AppRoutes;
