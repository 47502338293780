import React, { useState, useRef, FC } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, convertToPixelCrop } from 'react-image-crop';
import { canvasPreview, useDebounceEffect } from './ImageHelpers';
import 'react-image-crop/dist/ReactCrop.css';
import LoadButton from 'components/common/LoadButton';
import { useTranslation } from 'react-i18next';
import { Button, Grid, MenuItem, Select, SelectChangeEvent, Slider, Typography } from '@mui/material';
import { CropType } from './ArticleImagesEdit';

const aspectsArray = [
  { key: '9_16', value: 4 },
  { key: '16_9', value: 5 },
  { key: '3_2', value: 1 },
  { key: '4_3', value: 8 },
  { key: '2_3', value: 7 },
  { key: '3_4', value: 9 },
  { key: '1_1', value: 6 },
  { key: '16_9_etu_2', value: 2 },
  { key: '16_9_etu_3', value: 3 },
  { key: 'circle', value: 11 },
  { key: '0', value: 10 },
  { key: '12_6', value: 12 },
];

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface ImageCropperProps {
  storeImage: (file: File, index: number, circular: boolean) => void;
  imageIndex: number;
  onCancel: () => void;
  cropType: CropType;
}

const ImageCropper: FC<ImageCropperProps> = ({ storeImage, onCancel, cropType, imageIndex }: ImageCropperProps) => {
  const { t } = useTranslation();
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef('');
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [filename, setFilename] = useState('');
  const [imageResolution, setImageResolution] = useState<number | undefined>();

  let defaultCrop = 1;

  if (cropType == 'square') {
    defaultCrop = 6;
  } else if (cropType == 'share') {
    defaultCrop = 12;
  }

  const [selectedAspect, setSelectedAspect] = useState(defaultCrop);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(e.target.files[0]);
      setFilename(e.target.files[0].name);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (getAspectValue(selectedAspect)) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, getAspectValue(selectedAspect)));

      const image = imgRef.current;
      setImageResolution(image?.naturalWidth);
    }
  };

  const onDownloadCropClick = async () => {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist');
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    //    type: 'image/png',
    const blob = await offscreen.convertToBlob({
      type: 'image/jpeg',
      quality: 0.9,
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);
    const croppedfile: File = new File([blob], filename);

    storeImage(croppedfile, imageIndex, selectedAspect == 11);
  };

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, 0);
      }
    },
    100,
    [completedCrop, scale]
  );

  const getAspectValue = (aspectNumber: number) => {
    let aspectValue;
    switch (aspectNumber) {
      case 1:
        aspectValue = 3 / 2;
        break;
      case 2:
        aspectValue = 8 / 9;
        break;
      case 3:
        aspectValue = 5.333333 / 9;
        break;
      case 4:
        aspectValue = 9 / 16;
        break;
      case 5:
        aspectValue = 16 / 9;
        break;
      case 6:
        aspectValue = 1;
        break;
      case 7:
        aspectValue = 2 / 3;
        break;
      case 8:
        aspectValue = 4 / 3;
        break;
      case 9:
        aspectValue = 3 / 4;
        break;
      case 10:
        aspectValue = undefined;
        break;
      case 11:
        aspectValue = 1;
        break;
      case 12:
        aspectValue = 12 / 6.3;
        break;
    }

    return aspectValue;
  };

  const handleAspectClick = (event: SelectChangeEvent<unknown>) => {
    const aspectNumber = Number(event.target.value);

    setSelectedAspect(aspectNumber);

    if (imgRef.current) {
      const { width, height } = imgRef.current;
      const newCrop = centerAspectCrop(width, height, getAspectValue(aspectNumber));
      setCrop(newCrop);
      // Updates the preview
      setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} mt={1} mb={1}>
          <input type="file" accept="image/*" onChange={onSelectFile} style={{ height: 35, fontSize: 16 }} />
        </Grid>
        {imageResolution && (imageResolution ?? 0) < 800 && (
          <Grid item xs={12} mb={2}>
            <Typography variant="caption" color="darkred" fontSize={14}>
              {t('article.imageTooSmallResolution')}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography>Zoom:</Typography>
          <Slider
            defaultValue={100}
            valueLabelFormat={(value) => `${(value / 100).toPrecision(2)}x`}
            valueLabelDisplay="on"
            max={200}
            min={10}
            disabled={!imgSrc}
            onChange={(_, value) => {
              setScale(Number(value) / 100);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>{t('article.imageAspect')}</Typography>
          <Select
            size="small"
            style={{ minWidth: 350 }}
            labelId={`select-aspect`}
            variant="outlined"
            onChange={handleAspectClick}
            disabled={!imgSrc || cropType != 'all'}
            value={selectedAspect}
          >
            {aspectsArray
              .filter(
                (item) =>
                  cropType == '' ||
                  (cropType == 'all' && item.value != 12) ||
                  (cropType == 'square' && item.value == 6) ||
                  (cropType == 'share' && item.value == 12)
              )
              .map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  <Typography>{t(`article.imageAspect_${item.key}`)}</Typography>
                </MenuItem>
              ))}
          </Select>
        </Grid>
      </Grid>
      <Grid item mt={3}>
        <Typography variant="h6">{t('article.imagePreview')}</Typography>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={getAspectValue(selectedAspect)}
            minHeight={10}
            minWidth={10}
            circularCrop={selectedAspect == 11 ? true : undefined}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              style={{ transform: `scale(${scale})`, maxHeight: 600 }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
        {!imgSrc && (
          <Typography variant="caption" color="GrayText">
            {t('article.imageSelectOne')}
          </Typography>
        )}
      </Grid>
      <Grid item mt={3}>
        <Typography variant="h6">{t('article.imageCroppedPreview')}</Typography>
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
              borderRadius: selectedAspect == 11 ? '50%' : undefined,
              //maxHeight: 200,
            }}
          />
        )}
        {!completedCrop && (
          <Typography variant="caption" color="GrayText">
            {t('article.imageSelectOne')}
          </Typography>
        )}
      </Grid>
      <Grid item mt={3}>
        <LoadButton
          disabled={!completedCrop}
          onClick={onDownloadCropClick}
          style={{ marginRight: 16 }}
          color={'primary'}
        >
          {t('common.use')}
        </LoadButton>
        <Button
          type="button"
          onClick={() => {
            onCancel();
          }}
          color="secondary"
        >
          {t('cancel')}
        </Button>
      </Grid>
    </>
  );
};

export default ImageCropper;
