import { types } from 'mobx-state-tree';

const ErrorModel = types.model({
  key: types.string,
  message: types.string,
});

const SuccessModel = types.model({
  key: types.string,
  message: types.string,
});

export const ErrorStore = types
  .model({
    error: types.maybeNull(ErrorModel),
    success: types.maybeNull(SuccessModel),
  })
  .actions((self) => {
    const setError = (msg: string) => {
      self.error = { key: 'error', message: msg };
    };

    const setSuccess = (msg: string) => {
      self.success = { key: 'success', message: msg };
    };

    const clearError = () => {
      self.error = null;
      self.success = null;
    };

    return {
      setError,
      setSuccess,
      clearError,
    };
  });
