import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IconButton, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { color } from '../../theme';
import { Link, Logo, CollectionPage } from '../../types';
import TextInput from '../FormComponents/TextInput';
import ErrorText from '../ErrorText';
import SaveIcon from 'static/save_icon.svg';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '85%',
    marginTop: '60px',
    border: '1px solid #B7B7B7',
    padding: 20,
  },
  inputTitle: {
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  inputTitleUrl: {
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
    marginBottom: 0,
  },
  button: {
    alignSelf: 'flex-start',
    marginTop: 20,
    marginLeft: -10,
  },
  dropDown: {
    width: '95%',
    padding: 10,
    alignSelf: 'flex-start',
    alignItems: 'center',

    backgroundColor: '#F2F2F2',
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
  },
  defaultItem: {
    color: 'black',
    fontFamily: 'Ubuntu',
    fontSize: 14,
  },
  item: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 20,
  },
  logo: {
    width: 30,
  },
});

interface LinkFormProps {
  onChange: (type: string, value: string) => void;
  onSave: (value: number | string | null) => void;
  link: Link | null;
  linkLogos?: Logo[];
  addIcon?: boolean;
  pages?: CollectionPage[];
  isNav?: boolean;
}

export default function LinkForm(props: LinkFormProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { link, onChange, onSave, addIcon, linkLogos, pages, isNav } = props;
  const [hasErrors, setHasErrors] = useState(false);

  const validateUrl = () => {
    return (link?.url.length ?? 0) > 0;
  };

  const save = () => {
    const isValid =
      ((link?.placement === 0 && link.isNavigation) || validateUrl() || link?.collectionPageId !== null) &&
      (link?.text?.length ?? 0) > 0;
    if (isValid) {
      onSave(link?.id ?? null);
    }
    setHasErrors(!isValid);
  };

  const renderTextField = (label: string, type: string, placeholder: string) => (
    <TextInput
      label={label}
      value={link ? link[type as keyof Link]?.toString() : ''}
      type={type}
      placeholder={placeholder}
      onChange={(key, value) => onChange(key, value)}
      style={{ width: '90%' }}
    />
  );

  const renderUrlAndPage = () => {
    if (!isNav) {
      return renderTextField('link_url', 'url', 'link_url');
    }

    if (isNav && link?.placement === 0) return;

    return (
      <>
        <h3 className={classes.inputTitleUrl}>{t('link_page')}</h3>
        {pages && pages.length > 0 && (
          <Select
            className={classes.dropDown}
            style={{ marginTop: 20 }}
            placeholder={t('select_collection_page') ?? ''}
            value={link?.collectionPageId?.toString() || 'none'}
            onChange={({ target }) => onChange('collectionPageId', target.value as string)}
            defaultValue={'none'}
            sx={{ textDecoration: 'none' }}
          >
            <MenuItem value={'none'} className={classes.defaultItem}>
              {t('select_collection_page')}
            </MenuItem>
            {pages.map((page) => (
              <MenuItem key={page.id} value={page.id} className={classes.item}>
                {page.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </>
    );
  };

  return (
    <div className={classes.container}>
      {renderTextField('link_text', 'text', 'link_text')}
      {renderUrlAndPage()}
      {addIcon && linkLogos && (
        <>
          <h3 className={classes.inputTitle}>{t('link_logo')}</h3>
          <Select
            className={classes.dropDown}
            placeholder={t('link_logo') ?? ''}
            value={link?.logo || 'none'}
            onChange={({ target }) => onChange('logo', target.value as string)}
            defaultValue={'none'}
            sx={{ textDecoration: 'none' }}
          >
            <MenuItem value={'none'} className={classes.defaultItem}>
              {t('link_logo')}
            </MenuItem>
            {linkLogos.map((logo, index) => (
              <MenuItem key={index} value={logo.value} className={classes.item}>
                <img src={logo.path} className={classes.logo} />
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {hasErrors && <ErrorText text="link_save_error" style={{ fontSize: 12 }} />}
      <IconButton className={classes.button} onClick={save}>
        <img src={SaveIcon} />
      </IconButton>
    </div>
  );
}
