import React, { FC, CSSProperties } from 'react';

export interface SvgIconProps {
  width?: number;
  height?: number;
  fill?: string;
  icon: string;
  style?: CSSProperties;
}

const SvgIcon: FC<SvgIconProps> = ({ width, height, fill, icon, style }: SvgIconProps) => {
  return <img src={icon} style={{ color: fill, width: width, height: height, ...style }} />;
};

export default SvgIcon;
