export const AUTH_TOKEN = 'AUTH_TOKEN';

export function readStorage(key: string) {
  if (window.localStorage) {
    const item = window.localStorage.getItem(key);
    if (item != null) return JSON.parse(item);
  }
  return null;
}

export function writeStorage(key: string, value: string) {
  if (window.localStorage) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}

export function deleteStorage(key: string) {
  if (window.localStorage) {
    window.localStorage.removeItem(key);
  }
}
