import { flow, types } from 'mobx-state-tree';
import {
  getOnlineMagazines,
  getSingleOnlineMagazine,
  addSingleOnlineMagazine,
  updateSingleOnlineMagazine,
  deleteSingleOnlineMagazine,
} from '../services/onlineMagazineApi';
import { composeError } from '../utils/transforms';
import { OnlineMagazineResponse, OnlineMagazine } from '../types';
import { withRootStore } from './withRootStore';

const OnlineMagazineModel = types.model('OnlineMagazineModel', {
  id: types.optional(types.number, -1),
  name: types.string,
  image: types.maybeNull(types.string),
  description: types.optional(types.string, ''),
  published: types.boolean,
  publisher: types.optional(types.string, ''),
  releaseDate: types.maybeNull(types.string),
  tipEmail: types.maybeNull(types.string),
  editorialStaff: types.maybeNull(types.string),
  customerService: types.maybeNull(types.string),
  sendOpinion: types.maybeNull(types.string),
  communityId: types.number,
});

export const OnlineMagazineStore = types
  .model({
    onlineMagazines: types.optional(types.array(OnlineMagazineModel), []),
    selectedOnlineMagazine: types.maybeNull(OnlineMagazineModel),
    loading: false,
  })
  .extend(withRootStore)
  .actions((self) => {
    const getAllOnlineMagazines = flow(function* () {
      try {
        const response = yield getOnlineMagazines();
        self.onlineMagazines = response.data.map((onlineMagazine: OnlineMagazineResponse) => ({
          ...onlineMagazine,
          communityId: onlineMagazine?.community?.id,
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const getSelectedOnlineMagazine = flow(function* (id: string) {
      try {
        const response = yield getSingleOnlineMagazine(id);

        self.selectedOnlineMagazine = {
          ...response.data,
          communityId: response.data?.community?.id,
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const createOnlineMagazine = flow(function* (onlineMagazine: OnlineMagazine, image?: File) {
      self.loading = true;
      try {
        yield addSingleOnlineMagazine(onlineMagazine, image);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const updateOnlineMagazine = flow(function* (onlineMagazine: OnlineMagazine, image?: File) {
      self.loading = true;
      try {
        yield updateSingleOnlineMagazine(onlineMagazine, image);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const deleteOnlineMagazine = flow(function* (id: string) {
      try {
        yield deleteSingleOnlineMagazine(id);
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getAllOnlineMagazines,
      getSelectedOnlineMagazine,
      createOnlineMagazine,
      updateOnlineMagazine,
      deleteOnlineMagazine,
    };
  });
