import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Article, ArticleTypes, ArticleTypesEV, MagazineSegment, SegmentFeedTypes } from '../../types';
import { Button, Chip, Grid, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { format } from 'date-fns';
import ArticleLine from './ArticleLine';
import DialogBase from 'components/common/DialogBase';

export const getColorByType = (type: string) => {
  switch (type) {
    case ArticleTypesEV.Topical:
    case ArticleTypes.BigStory:
      return '#efbd63';
    case ArticleTypesEV.News:
    case ArticleTypes.ImageStory:
      return '#c5ffca';
    case ArticleTypesEV.International:
    case ArticleTypes.SmallStory:
      return '#c0e0ff';
    case ArticleTypesEV.ChangeStory:
    case ArticleTypes.SmallTextStory:
      return '#f5f5cc';
    case ArticleTypesEV.EverydayHero:
    case ArticleTypes.BookReview:
      return 'tan';
    case ArticleTypesEV.ColumnEV:
    case ArticleTypes.Column:
      return '#f1abfe';
    case ArticleTypesEV.MyStory:
    case ArticleTypes.Opinion:
      return '#cec887';
    case ArticleTypesEV.ExpertsOpinion:
    case ArticleTypes.PointOfView:
      return '#efadc3';
    case ArticleTypesEV.YouthPerspective:
    case ArticleTypes.QuickVisit:
      return 'linen';
    case ArticleTypesEV.EditorialEV:
    case ArticleTypes.Editorial:
      return '#dedede';
    case ArticleTypesEV.Teaching:
    case ArticleTypes.GuestPen:
      return '#dabafa';
    case ArticleTypes.MostRedArticles:
      return '#8a9ebf';
    case ArticleTypes.LatestArticles:
      return '#8abf9e';
    case ArticleTypesEV.CoWorkers:
    case ArticleTypes.Blog:
      return 'lightblue';
    case ArticleTypesEV.MainArticles:
      return 'lightblue';
  }

  return 'white';
};

interface SegmentCardProps {
  row: MagazineSegment;
  selected?: boolean;
  onDelete?: (id?: number) => void;
  onSelect?: (id?: number) => void;
  articles?: Article[];
}

const SegmentCard: FC<SegmentCardProps> = ({ row, onDelete, onSelect, selected, articles }: SegmentCardProps) => {
  const { t } = useTranslation();
  const background = getColorByType(row.articleType);
  const [openDialogId, setOpenDialogId] = useState<number>();

  return (
    <Grid
      container
      margin={1}
      pl={2}
      pt={1}
      pb={1}
      onClick={() => {
        if (row.id) {
          onSelect?.(row.id);
        }
      }}
      style={{
        background: background,
        outline: selected ? '#43a047 solid 3px' : undefined,
        borderRadius: 5,
      }}
    >
      <Grid container item xs={12} borderBottom={1} borderColor={'silver'}>
        {row.mobile && (
          <Grid item mr={2}>
            <Chip label={t('magazineSegment.mobile')} size="small" color="warning" style={{ borderRadius: 8 }} />
          </Grid>
        )}
        {row.web && (
          <Grid item mr={2}>
            <Chip label={t('magazineSegment.web')} size="small" color="info" style={{ borderRadius: 8 }} />
          </Grid>
        )}
        {row.feedType == SegmentFeedTypes.Static && (
          <Grid item>
            <Typography variant="caption" color="CaptionText">
              {format(new Date(row.publicationDate), 'd.M.yyyy')}
            </Typography>
          </Grid>
        )}
        {row.removalDate && (
          <Grid item ml={2}>
            <Typography variant="caption" color="darkred" fontSize={12} fontWeight={600}>
              {t('article.removalDateTitle')}: {format(new Date(row.removalDate), 'd.M.yyyy')}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} paddingTop={1}>
        <Grid container item xs={11}>
          <Typography variant="caption" fontSize={16} fontWeight={600}>
            {t(`articleTypes.${row.articleType}`, t(`articleTypes_ev.${row.articleType}`))}
            {row.articleType == ArticleTypes.SmallStory && (
              <>
                /{t(`articleTypes.${ArticleTypes.QuickVisit}`)}/{t(`articleTypes.${ArticleTypes.ChurchVisit}`)}
              </>
            )}
          </Typography>{' '}
          <Typography variant="caption" fontSize={12} mt={0.5} ml={2}>
            Artikkeleita: {row.articleCount} kpl {row.articleSkip > 0 ? ` - Ohitus: ${row.articleSkip}` : ''}
          </Typography>{' '}
          <Typography variant="caption" color="darkred" fontSize={12} fontWeight={600} mt={0.5} ml={2}>
            {row.feedType == SegmentFeedTypes.Static ? 'Kiinteä artikkeli' : ''}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button
            onClick={(event) => {
              setOpenDialogId(row.id);
              event.stopPropagation();
            }}
          >
            <ClearIcon color="error" />
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item ml={1}>
          {row.feedType == SegmentFeedTypes.Newest &&
            articles
              ?.slice(row.articleSkip, row.articleSkip + row.articleCount)
              .map((article) => <ArticleLine key={article.id} article={article} background={background} />)}
          {row.feedType == SegmentFeedTypes.Static && (
            <ArticleLine
              article={articles?.find((article) => article.id == row.singleArticleId)}
              background={background}
            />
          )}
        </Grid>
      </Grid>
      <DialogBase
        title={t('magazineSegment.confirmDelete')}
        onCancel={() => setOpenDialogId(undefined)}
        onOk={() => onDelete?.(openDialogId)}
        show={!!openDialogId}
        warning
      >
        <Grid container spacing={3} sx={{ pt: 1 }} style={{ paddingBottom: 0 }}>
          <Grid item xs={10}>
            <Typography>{t('magazineSegment.confirmDeleteText')}</Typography>
          </Grid>
        </Grid>
      </DialogBase>
    </Grid>
  );
};

export default SegmentCard;
