import React from 'react';
import { Button as MUButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { color } from '../../theme';

const useStyles = makeStyles()({
  button: {
    marginRight: 20,
    paddingVertical: 8,
    paddingHorizontal: 12,
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'uppercase',
    color: color.white,
    backgroundColor: color.primaryButton,
    padding: 10,
    '&:hover': {
      backgroundColor: color.primaryButton,
    },
    '&:disabled': {
      backgroundColor: 'grey !important',
      color: color.black,
    },
  },
  button_small: {
    marginRight: 10,
    fontSize: 13,
    color: color.white,
    minHeight: 40,
    '&:disabled': {
      backgroundColor: 'grey !important',
      color: color.black,
    },
  },
});

interface Props {
  text: string;
  onClick: () => void;
  backgroundColor?: string;
  width?: number;
  marginBottom?: number;
  marginTop?: number;
  disabled?: boolean;
  small?: boolean;
}
export default function Button(props: Props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { text, backgroundColor, width, disabled, onClick, marginBottom, marginTop } = props;

  return (
    <MUButton
      onClick={onClick}
      className={props.small ? classes.button_small : classes.button}
      style={{
        backgroundColor: backgroundColor ?? color.primaryButton,
        width: width ?? 100,
        marginBottom: marginBottom ?? 0,
        marginTop: props.small ? 0 : marginTop ?? 30,
      }}
      disabled={disabled}
    >
      {t(text)}
    </MUButton>
  );
}
