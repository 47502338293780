import { makeStyles } from 'tss-react/mui';
import { Table, TableBody, TableCell, TableContainer, TableRow, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from '../../types';
import DeleteIcon from '../../static/delete_icon.svg';
import DownIcon from '../../static/down_icon.svg';
import UpIcon from '../../static/up_icon.svg';
import EditIcon from '../../static/edit_icon.svg';
import './style.css';
import SvgIcon from 'components/SvgIcon';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
  },
  tableTitle: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  table: {
    width: '100%',
  },
  tableRow: {
    borderTop: '1px solid #E2E2E2',
    borderBottom: '1px solid #E2E2E2',
    width: '100%',
    height: 40,
  },
  textCell: {
    width: '60%',
    fontWeight: 'normal',
    fontSize: 16,
  },
  icon: {
    width: 17,
    height: 17,
  },
  button: {
    padding: 5,
    borderRadius: 0,
  },
  placementButton: {
    padding: 0,
    borderRadius: 0,
    margin: 0,
  },
  actionBtnCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    border: 'none',
  },
  placementBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
    padding: 0,
    marginLeft: 3,
  },
  empty: {
    width: 40,
    backgroundColor: 'red',
    color: 'green',
  },
});

interface LinkTableProps {
  links: Link[];
  onEdit: (id: number | string) => void;
  onDelete: (id: number | string) => void;
  onOrderChange: (id: number | string, direction: string) => void;
  isNavigation?: boolean;
}

const createTableData = (links: Link[]) => {
  return links.map((link) => ({
    id: link.id,
    placement: link.placement,
    text: link.text,
    url: link.url,
  }));
};

export default function LinkTable(props: LinkTableProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { onEdit, onDelete, onOrderChange, links, isNavigation } = props;
  const rows = links && links.length > 0 ? createTableData(links) : null;
  const sortedRows = rows?.sort((a, b) => a.placement - b.placement);

  if (!sortedRows) return null;
  return (
    <div className={classes.container}>
      <TableContainer>
        <h3 className={classes.tableTitle}>{t('links')}</h3>
        <Table className={classes.table} padding="none">
          <TableBody>
            {sortedRows.map((row, index) => (
              <TableRow key={row.id} className={classes.tableRow}>
                <TableCell className={classes.textCell} scope="row">
                  {row.text}
                </TableCell>
                <TableCell className={classes.actionBtnCell}>
                  <IconButton onClick={() => onEdit(row.id)} className={classes.button}>
                    <img src={EditIcon} className={classes.icon} />
                  </IconButton>
                  {isNavigation && row.placement === 0 ? (
                    <div className={classes.empty} />
                  ) : (
                    <>
                      <IconButton onClick={() => onDelete(row.id)} className={classes.button}>
                        <img src={DeleteIcon} className={classes.icon} />
                      </IconButton>
                      <div className={classes.placementBtnContainer}>
                        <span className="parent-span-up">
                          <IconButton
                            disabled={index === 0}
                            onClick={() => onOrderChange(row.id, 'up')}
                            className={classes.placementButton}
                          >
                            <SvgIcon icon={UpIcon} />
                          </IconButton>
                        </span>
                        <span className="parent-span-down">
                          <IconButton
                            disabled={index === sortedRows.length - 1}
                            onClick={() => onOrderChange(row.id, 'down')}
                            className={classes.placementButton}
                          >
                            <SvgIcon icon={DownIcon} />
                          </IconButton>
                        </span>
                      </div>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
