import { Podcast } from 'types/Podcast';
import {
  Link,
  CollectionPage,
  User,
  Promotion,
  ContentPage,
  ArticlePage,
  Newspaper,
  Audiobook,
  SubscriptionType,
  Album,
  Magazine,
  Program,
  MediaTypes,
  Article,
  OnlineMagazine,
  MagazineSegment,
  ArticleTypes,
  SegmentFeedTypes,
  ArticleSections,
  DigiMagazine,
  DigiMagazineFile,
} from '../types';
import constants from 'config/constants';

export const LinkDefaults: Link = {
  id: -1,
  url: '',
  text: '',
  placement: -1,
  logo: null,
  collectionPageId: null,
  isNavigation: null,
  isHeader: null,
};

export const CollectionPageDefaults: CollectionPage = {
  id: -1,
  name: '',
  url: '',
  mediaType: null,
};

export const ContentPageDefaults: ContentPage = {
  id: -1,
  title: '',
  text: '',
  url: '',
  priorityIndex: 1,
  published: false,
};

export const ArticlePageDefaults: ArticlePage = {
  id: -1,
  title: '',
  lead: '',
  caption: '',
  text: '',
  url: '',
  priorityIndex: 1,
  published: false,
  releaseDate: '',
};

export const UserDefaults: User = {
  id: null,
  firstname: '',
  lastname: '',
  email: '',
  address: '',
  postalcode: '',
  city: '',
  country: '',
  phone: '',
  admin: false,
  publisher: false,
  creator: false,
  portal: false,
  employee: false,
  newsletter: false,
  empNewsletter: false,
  permission: false,
  tester: false,
  communityIds: [],
  subscriptionIds: [],
};

export const PromotionDefaults: Promotion = {
  id: null,
  name: '',
  url: '',
  placement: 0,
};

export const NewspaperDefaults: Newspaper = {
  id: -1,
  name: '',
  description: '',
  number: '',
  releaseDate: '',
  thumbnailUrl: '',
  published: false,
  publisher: '',
  mediaTypeId: MediaTypes.Newspaper,
};

export const AudiobookDefaults: Audiobook = {
  id: -1,
  name: '',
  description: '',
  releaseDate: '',
  author: '',
  published: false,
  publisher: '',
  releaseYear: 0,
  reader: '',
  language: 'Suomi',
  mediaTypeId: MediaTypes.Audiobook,
  image: '',
  ebook: '',
  pdfBook: '',
};

export const SubscriptionTypeDefaults: SubscriptionType = {
  id: -1,
  name: '',
  type: 0,
  description: '',
  price: 0,
  active: false,
};

export const AlbumDefaults: Album = {
  id: -1,
  name: '',
  description: '',
  releaseDate: '',
  artist: '',
  published: false,
  publisher: '',
  releaseYear: 0,
  acdCode: '',
  language: '',
  mediaTypeId: MediaTypes.Music,
  image: '',
};

export const PodcastDefaults: Podcast = {
  id: -1,
  name: '',
  description: '',
  releaseDate: '',
  author: '',
  published: false,
  publisher: '',
  language: '',
  mediaTypeId: MediaTypes.Podcast,
  image: '',
  order: 99,
};

export const MagazineDefaults: Magazine = {
  id: -1,
  name: '',
  image: '',
  description: '',
  published: false,
  publisher: '',
  releaseDate: '',
  language: '',
  mediaTypeId: MediaTypes.Magazine,
  ePub: '',
  eMagazine: '',
};

export const ProgramDefaults: Program = {
  id: -1,
  name: '',
  description: '',
  releaseDate: '',
  author: '',
  published: false,
  publisher: '',
  language: '',
  mediaTypeId: MediaTypes.Program,
  image: '',
  order: 99,
};

export const ArticleDefaults: Article = {
  id: -1,
  title: '',
  lead: '',
  text: '',
  releaseDate: '',
  modifiedDate: '',
  visibleDate: '',
  published: false,
  language: '',
  type: ArticleTypes.Undefined,
  section: ArticleSections.Undefined,
  hideFromMediaFront: false,
  singleMediaFront: false,
  targetGroupIds: [constants.Data.NotLoggedInTargetGroupId],
};

export const OnlineMagazineDefaults: OnlineMagazine = {
  id: -1,
  name: '',
  releaseDate: '',
  published: false,
  image: '',
};

export const MagazineSegmentDefaults: MagazineSegment = {
  id: -1,
  name: '',
  publicationDate: '',
  published: true,
  image: '',
  articleType: ArticleTypes.Undefined,
  articleCount: 1,
  feedType: SegmentFeedTypes.Newest,
  articleSkip: 0,
  order: 99,
  mobile: true,
  web: true,
};

export const DigiMagazineDefaults: DigiMagazine = {
  id: -1,
  name: '',
  image: '',
  description: '',
  published: false,
  publisher: '',
  mediaTypeId: MediaTypes.DigiMagazine,
};

export const DigiMagazineFileDefaults: DigiMagazineFile = {
  id: -1,
  name: '',
  image: '',
  description: '',
  published: false,
  magazineNumber: '',
  releaseDate: '',
  releaseYear: 0,
  src: '',
  order: 0,
};
