import { flow, types } from 'mobx-state-tree';
import {
  getSingleArticle,
  addSingleArticle,
  updateSingleArticle,
  deleteSingleArticle,
  getArticlesByMagazineId,
} from '../services/articleApi';
import { composeError } from '../utils/transforms';
import { ArticleResponse, Article, TargetGroup, Tag, Community } from '../types';
import { withRootStore } from './withRootStore';
import { OnlineMagazine } from 'types/OnlineMagazine';

const ArticleSideStoryModel = types.model('ArticleSideStoryModel', {
  id: types.optional(types.number, -1),
  tag: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  author: types.maybeNull(types.string),
});

const ArticleImageModel = types.model('ArticleImageModel', {
  id: types.optional(types.number, -1),
  tag: types.string,
  src: types.string,
  caption: types.optional(types.string, ''),
  photographer: types.optional(types.string, ''),
  main: types.boolean,
  top: types.boolean,
  topWeb: types.boolean,
  front: types.boolean,
  full: types.boolean,
  left: types.boolean,
  right: types.boolean,
  middle: types.boolean,
  carousel: types.boolean,
  link: types.boolean,
  share: types.boolean,
  circular: types.boolean,
});

const ArticleVideoModel = types.model('ArticleVideoModel', {
  id: types.optional(types.number, -1),
  tag: types.string,
  src: types.string,
  duration: types.optional(types.number, 0),
  front: types.boolean,
});

const ArticleModel = types.model('ArticleModel', {
  id: types.optional(types.number, -1),
  title: types.string,
  frontTitle: types.maybeNull(types.string),
  lead: types.string,
  text: types.string,
  titleLang: types.maybeNull(types.string),
  frontTitleLang: types.maybeNull(types.string),
  leadLang: types.maybeNull(types.string),
  textLang: types.maybeNull(types.string),
  photographer: types.maybeNull(types.string),
  published: types.boolean,
  author: types.maybeNull(types.string),
  authorDescription: types.maybeNull(types.string),
  secondAuthor: types.maybeNull(types.string),
  secondAuthorDescription: types.maybeNull(types.string),
  publisher: types.optional(types.string, ''),
  bookAuthor: types.maybeNull(types.string),
  bookPublisher: types.maybeNull(types.string),
  bookTitle: types.maybeNull(types.string),
  language: types.string,
  type: types.string,
  section: types.maybeNull(types.string),
  releaseDate: types.string,
  modifiedDate: types.maybeNull(types.union(types.string, types.null)),
  visibleDate: types.string,
  priorityIndex: types.maybeNull(types.number),
  hideFromMediaFront: types.boolean,
  singleMediaFront: types.boolean,
  modifiedNotes: types.maybeNull(types.string),
  editorNotes: types.maybeNull(types.string),
  editorState: types.maybeNull(types.string),
  imageState: types.maybeNull(types.string),
  extraNotes: types.maybeNull(types.string),
  internalNotes: types.maybeNull(types.string),
  citations: types.maybeNull(types.array(types.string)),
  images: types.optional(types.array(ArticleImageModel), []),
  videos: types.optional(types.array(ArticleVideoModel), []),
  sideStories: types.optional(types.array(ArticleSideStoryModel), []),
  tagIds: types.optional(types.array(types.number), []),
  targetGroupIds: types.optional(types.array(types.number), []),
  communityIds: types.optional(types.array(types.number), []),
  onlineMagazineIds: types.optional(types.array(types.number), []),
});

export const ArticleStore = types
  .model({
    articles: types.optional(types.array(ArticleModel), []),
    articlesForMagazine: types.optional(types.array(ArticleModel), []),
    selectedArticle: types.maybeNull(ArticleModel),
    loading: false,
    initLoading: false,
  })
  .extend(withRootStore)
  .actions((self) => {
    const getSelectedArticle = flow(function* (id: string) {
      try {
        self.initLoading = true;
        const response = yield getSingleArticle(id);

        self.selectedArticle = {
          ...response.data,
          modifiedDate: new Date().toISOString(),
          tagIds: response.data.tags?.map((data: Tag) => data.id),
          targetGroupIds: response.data.targetGroups?.map((data: TargetGroup) => data.id),
          communityIds: response.data.communities?.map((data: Community) => data.id),
          onlineMagazineIds: response.data.onlineMagazines?.map((data: OnlineMagazine) => data.id),
        };
        self.initLoading = false;
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const createArticle = flow(function* (article: Article) {
      self.loading = true;
      try {
        const response = yield addSingleArticle(article);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
        return response.data.id;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const updateArticle = flow(function* (article: Article) {
      self.loading = true;
      try {
        yield updateSingleArticle(article);
        self.rootStore.errorStore.setSuccess('save_success');
        yield getSelectedArticle(article.id.toString());
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const deleteArticle = flow(function* (id: string) {
      try {
        yield deleteSingleArticle(id);
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    const getArticlesForMagazine = flow(function* (id: string) {
      try {
        const response = yield getArticlesByMagazineId(id);
        self.articlesForMagazine = response.data.map((article: ArticleResponse) => ({
          ...article,
          targetGroupIds: article.targetGroups?.map((data: TargetGroup) => data.id),
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    return {
      getSelectedArticle,
      createArticle,
      updateArticle,
      deleteArticle,
      getArticlesForMagazine,
    };
  });
