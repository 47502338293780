import React, { FC } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { Article, ArticleSections, ArticleTypes, ArticleTypesEV, Community } from 'types';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import SubscribersTag from 'containers/MagazineSegment/SubscribersTag';
import { format } from 'date-fns';
import quoteImage from 'static/sitaatti.svg';
import hyvasanomaImage from 'static/hyvä-sanoma-logo.png';
import paimenplusImage from 'static/paimenplus-logo.png';
import ristinvoittoImage from 'static/ristin-voitto-logo.png';
import tilaajatahti from 'static/ajassa_tilaajatahti.svg';
import share from 'static/ajassa_share_iphone.svg';
import like from 'static/ajassa_like.svg';
import { palette } from 'theme/palette';

interface ArticlePreviewProps {
  article?: Article;
  communities?: Community[];
}

const ArticlePreview: FC<ArticlePreviewProps> = ({ article, communities }: ArticlePreviewProps) => {
  if (!article) {
    return null;
  }

  if (article.type == ArticleTypes.BigStory) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleTitleWithLead article={article} story />
        <ArticleTopImage article={article} />
        <SubscribersTag article={article} />
        <ArticleDetails article={article} communities={communities} />
        <BodyText article={article} disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.SmallStory) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleTitleWithLead article={article} story />
        <ArticleTopImage article={article} />
        <SubscribersTag article={article} />
        <ArticleDetails article={article} communities={communities} />
        <BodyText article={article} disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.SmallTextStory) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleTitleWithLead article={article} story />
        <SubscribersTag article={article} />
        <ArticleDetails article={article} communities={communities} />
        <BodyText article={article} disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.ImageStory) {
    return (
      <Grid container mb={2} style={{ background: 'white' }}>
        <ArticleTopImage article={article} />
        <Grid container p={3} style={{ background: 'white' }}>
          <ArticleTitleWithLead article={article} large />
          <ShareAndFavourite center />
          <ArticleDetails article={article} communities={communities} imageStory />
          <SubscribersTag article={article} center />
          <BodyText article={article} columnQuote disableDropCap />
          <ArticleModifiedDate article={article} />
          <ArticleDivider />
        </Grid>
      </Grid>
    );
  }

  if (article.type == ArticleTypes.Editorial) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleBlockHeader article={article} communities={communities} />
        <ArticleEditoraTitle article={article} />
        <ArticleColumnDate article={article} />
        <ArticlePersonImage article={article} circle />
        <ArticleAuthorInfo article={article} />
        <BodyText article={article} columnQuote disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.Column) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleBlockHeader article={article} communities={communities} />
        <ArticleColumnTitle article={article} />
        <ArticleColumnDate article={article} />
        <ArticleAuthorWithInfo article={article} />
        <BodyText article={article} columnQuote disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.PointOfView) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleBlockHeader article={article} communities={communities} />
        <ArticleColumnTitle article={article} />
        <ArticleColumnDate article={article} />
        <ArticleAuthorWithInfo article={article} />
        <BodyText article={article} columnQuote disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.Opinion) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleBlockHeader article={article} communities={communities} />
        <ArticleColumnTitle article={article} />
        <ArticleColumnDate article={article} />
        <BodyText article={article} disableDropCap />
        <ArticleAuthorSignature article={article} />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.BookReview) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleBlockHeader article={article} />
        <ArticleColumnTitle article={article} />
        <ArticleBookReview article={article} />
        <SubscribersTag article={article} />
        <ArticleDetails article={article} communities={communities} />
        <BodyText article={article} columnQuote disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.QuickVisit || article.type == ArticleTypes.ChurchVisit) {
    return (
      <Grid container pl={3} pr={3} pb={3} style={{ background: 'white' }}>
        <ArticleBlockHeader article={article} />
        <ArticleTitleWithLead article={article} />
        <ArticleTopImage article={article} />
        <ArticleDetails article={article} communities={communities} imageStory />
        <SubscribersTag article={article} center />
        <BodyText article={article} columnQuote disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (article.type == ArticleTypes.GuestPen) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleBlockHeader article={article} />
        <ArticleColumnTitle article={article} />
        <ArticleColumnDate article={article} />
        <ArticleAuthorWithInfo article={article} />
        <BodyText article={article} columnQuote disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (
    article.type == ArticleTypesEV.News ||
    article.type == ArticleTypesEV.Topical ||
    article.type == ArticleTypesEV.International ||
    article.type == ArticleTypesEV.ChangeStory ||
    article.type == ArticleTypesEV.EverydayHero ||
    article.type == ArticleTypesEV.MyStory ||
    article.type == ArticleTypesEV.ExpertsOpinion ||
    article.type == ArticleTypesEV.Teaching ||
    article.type == ArticleTypesEV.CoWorkers ||
    article.type == ArticleTypesEV.Anniversary ||
    article.type == ArticleTypesEV.Obituary
  ) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleTitleWithLead article={article} story />
        <ArticleTopImage article={article} />
        <SubscribersTag article={article} />
        <ArticleDetails article={article} communities={communities} />
        <BodyText article={article} disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  if (
    article.type == ArticleTypesEV.EditorialEV ||
    article.type == ArticleTypesEV.ColumnEV ||
    article.type == ArticleTypesEV.YouthPerspective
  ) {
    return (
      <Grid container p={3} mb={2} style={{ background: 'white' }}>
        <ArticleBlockHeader article={article} communities={communities} />
        <ArticleEditoraTitle article={article} />
        <ArticleColumnDate article={article} />
        <ArticlePersonImage article={article} circle />
        <ArticleAuthorInfo article={article} />
        <BodyText article={article} columnQuote disableDropCap />
        <ArticleModifiedDate article={article} />
        <ShareAndFavourite />
        <ArticleDivider />
      </Grid>
    );
  }

  return null;
};

export default ArticlePreview;

const ArticleTopImage: FC<ArticlePreviewProps> = ({ article }: ArticlePreviewProps) => {
  const { t } = useTranslation();

  const topImage = article?.images?.find((image) => image.main && image.top);

  return (
    <>
      <Grid item xs={12} style={{ marginLeft: -24, marginRight: -24 }}>
        <img
          src={topImage?.src}
          className="articleImageTop"
          crossOrigin="anonymous"
          alt="Artikkelin kuva"
          width={585}
          style={{ maxWidth: 585 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid item className="articleImageCaption" mt={-0.5}>
          {topImage?.caption}
          {topImage?.photographer && (
            <>
              {' '}
              <span className="articleImagePhotographer">
                {t('article.imagePhoto')}&nbsp;{topImage?.photographer}
              </span>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

interface ArticlePersonImageProps {
  article: Article;
  circle?: boolean;
}

const ArticlePersonImage: FC<ArticlePersonImageProps> = ({ article, circle }: ArticlePersonImageProps) => {
  const mainImage = article.images?.[0];

  return (
    <Grid container item xs={12} justifyContent={'center'} mt={2} mb={1}>
      <img
        src={mainImage?.src ?? ''}
        style={{
          width: 170,
          height: 170,
          borderRadius: circle ? 90 : undefined,
        }}
        alt="Henkilökuva"
        crossOrigin="anonymous"
      />
    </Grid>
  );
};

const ArticleAuthorWithInfo: FC<ArticlePersonImageProps> = ({ article, circle }: ArticlePersonImageProps) => {
  const mainImage = article.images?.[0];
  const secondMainImage = (article.images?.length ?? 0) > 1 && article.images?.[1];

  return (
    <Grid container item xs={12} justifyContent={'start'} mt={2} mb={1}>
      <Grid item xs={5}>
        <img
          src={mainImage?.src ?? ''}
          style={{
            width: 130,
            height: 130,
            borderRadius: circle ? 90 : undefined,
          }}
          alt="Henkilökuva"
          crossOrigin="anonymous"
        />
      </Grid>
      <Grid container item xs={6} justifyContent={'center'}>
        <Grid item xs={12} mt={2}>
          <span className="articleBlockAuthor">{article?.author}</span>
        </Grid>
        <Grid item xs={12} mt={2} className="articleBlockAuthorDescription">
          {article?.authorDescription}
        </Grid>
      </Grid>
      {article.secondAuthor && secondMainImage && (
        <>
          <Grid item xs={5} mt={2}>
            <img
              src={secondMainImage?.src ?? ''}
              style={{
                width: 130,
                height: 130,
                borderRadius: circle ? 90 : undefined,
              }}
              alt="Henkilökuva"
              crossOrigin="anonymous"
            />
          </Grid>
          <Grid container item xs={6} justifyContent={'center'} mt={2}>
            <Grid item xs={12} mt={2}>
              <span className="articleBlockAuthor">{article?.secondAuthor}</span>
            </Grid>
            <Grid item xs={12} mt={2} className="articleBlockAuthorDescription">
              {article?.secondAuthorDescription}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

interface ArticleTitleWithLeadProps {
  article: Article;
  large?: boolean;
  story?: boolean;
}

const ArticleTitleWithLead: FC<ArticleTitleWithLeadProps> = ({ article, large, story }: ArticleTitleWithLeadProps) => {
  return (
    <>
      <Grid item xs={12} mt={1} mb={3} textAlign={story ? 'start' : 'center'}>
        <Typography
          fontFamily={story ? 'PT Serif' : 'Noto Serif'}
          fontSize={large ? 48 : story ? 32 : 24}
          fontWeight={'bold'}
          lineHeight={large ? '55px' : story ? '42px' : '33px'}
        >
          {article?.title}
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign={story ? 'start' : 'center'} mb={2}>
        <Typography
          fontFamily={story ? 'PT Serif' : 'Noto Serif'}
          fontSize={large ? 18 : story ? 19 : 16}
          fontWeight={story ? 400 : 600}
          lineHeight={large ? '26px' : '22px'}
          ml={story ? 0 : 2}
          mr={story ? 0 : 2}
        >
          {article?.lead}
        </Typography>
      </Grid>
    </>
  );
};

const ArticleBlockHeader: FC<ArticlePreviewProps> = ({ article, communities }: ArticlePreviewProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container item xs={12} justifyContent={'center'} textAlign={'center'} p={1} mt={1}>
        <Typography
          fontFamily={'Poppins'}
          fontSize={13}
          fontWeight={'bold'}
          textTransform={'uppercase'}
          style={{ letterSpacing: 2.5 }}
        >
          {t(`articleTypes.${article?.type}`)}
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent={'center'} textAlign={'center'}>
        {communities && <ArticleCommunityName article={article} communities={communities} />}
      </Grid>
    </>
  );
};

const ArticleBookReview: FC<ArticlePreviewProps> = ({ article }: ArticlePreviewProps) => {
  const bookImage = article?.images?.find((image) => image.main);

  return (
    <>
      <Grid item xs={12} mt={3} textAlign={'center'}>
        <Typography fontFamily={'Noto Serif'} fontSize={16} fontWeight={600} lineHeight={'22px'}>
          {article?.bookAuthor}:
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign={'center'}>
        <Typography fontFamily={'Noto Serif'} fontSize={16} fontWeight={600} lineHeight={'22px'}>
          {article?.bookTitle}
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign={'center'} mt={1}>
        <Typography
          fontFamily={'Poppins'}
          fontSize={13}
          fontWeight={'bold'}
          ml={2}
          mr={2}
          style={{ textTransform: 'uppercase' }}
        >
          {article?.bookPublisher}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={4}>
        <img src={bookImage?.src} className="articleImage" crossOrigin="anonymous" alt="Kirjan kansikuva" />
      </Grid>
    </>
  );
};

interface BodyTextProps {
  article: Article;
  columnQuote?: boolean;
  disableDropCap?: boolean;
}

const BodyText: FC<BodyTextProps> = ({ article, columnQuote: column, disableDropCap }: BodyTextProps) => {
  const { t } = useTranslation();
  let articleText = article?.text ?? '';

  article.images?.sort((a, b) => {
    if ((a.tag.length ?? 0) < (b.tag.length ?? 0)) return 1;
    if ((a.tag.length ?? 0) > (b.tag.length ?? 0)) return -1;
    return 0;
  });

  article?.images?.forEach((image) => {
    let replaceText = '<div class="articleImageCaption">';
    replaceText += `<img class="articleImage" src=${image.src} alt="Artikkelin kuva" crossorigin="anonymous" />`;
    if ((image.caption?.length ?? 0) > 0) {
      replaceText += image.caption;
      replaceText += ' ';
    }
    if ((image.photographer?.length ?? 0) > 0) {
      const photographerText = `${t('article.imagePhoto')}&nbsp;${image.photographer}`;
      replaceText += `<span class="articleImagePhotographer">${photographerText}</span>`;
    }
    replaceText += '</div>';

    articleText = articleText.replace(image.tag, replaceText);
  });

  const quoteStyle = column ? 'articleQuoteSecondaryText' : 'articleQuoteText';
  const quoteImageStyle = column ? 'articleQuoteSecondaryImage' : 'articleQuoteImage';
  const quoteDiv = `<div class="${quoteStyle}"><div><img class="${quoteImageStyle}" src=${quoteImage} alt="" /></div>`;
  article?.citations?.forEach((citate, index) => {
    articleText = articleText.replace(`nosto_${index + 1}`, quoteDiv + citate + '</div>');
  });

  if (!disableDropCap) {
    const wrapperStyle = article.type == ArticleTypes.ImageStory ? 'articleWrapperImage' : 'articleWrapper';
    articleText = `<div class="${wrapperStyle}">${articleText}</div>`;
  }

  const sanitizedText = DOMPurify.sanitize(articleText);
  const extraNotesText = article.extraNotes && DOMPurify.sanitize(article.extraNotes);

  return (
    <Grid item xs={12}>
      <div className="articleBodyText" dangerouslySetInnerHTML={{ __html: sanitizedText }}></div>
      {extraNotesText && (
        <div className="articleExtraNotesText" dangerouslySetInnerHTML={{ __html: extraNotesText }}></div>
      )}
    </Grid>
  );
};

const ShareAndFavourite: FC<{ center?: boolean }> = ({ center }: { center?: boolean }) => {
  return (
    <Grid container justifyContent={center ? 'center' : 'start'}>
      <Grid item mr={1}>
        <img
          src={share}
          style={{
            paddingTop: 5,
            height: 28,
          }}
          alt="Jaa"
        />
      </Grid>
      <Grid item>
        <img
          src={like}
          style={{
            paddingTop: 5,
            height: 28,
          }}
          alt="Oma lista"
        />
      </Grid>
    </Grid>
  );
};

interface ArticleDetailsProps {
  article: Article;
  communities?: Community[];
  imageStory?: boolean;
}

const ArticleDetails: FC<ArticleDetailsProps> = ({ article, communities, imageStory }: ArticleDetailsProps) => {
  const { t } = useTranslation();

  if (imageStory) {
    return (
      <Grid container spacing={0.2} mt={1} justifyContent={'center'}>
        <Grid item xs={12} textAlign={'center'} mb={2}>
          <Typography fontFamily={'Poppins'} fontSize={12} fontWeight={700}>
            <ArticleCommunityName article={article} communities={communities} large={imageStory} />
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontFamily={'Poppins'}
            fontSize={12}
            fontWeight={600}
            style={{ textTransform: 'uppercase' }}
            letterSpacing={0.5}
          >
            {`${t('article.texts')}: ${article?.author}`}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          <Typography
            fontFamily={'Poppins'}
            fontSize={12}
            fontWeight={600}
            style={{ textTransform: 'uppercase' }}
            letterSpacing={0.5}
          >
            {`${t('article.photos')}: ${article?.photographer}`}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={0.2} mb={1} mt={0} alignItems={'center'}>
      {article?.section && article.section != ArticleSections.Undefined && (
        <>
          <Grid item>
            <Typography
              fontFamily={'Poppins'}
              fontSize={14}
              fontWeight={'bold'}
              style={{ textTransform: 'uppercase' }}
              letterSpacing={0.6}
            >
              {t(`articleSections.${article.section}`)}
            </Typography>
          </Grid>
          <Grid item ml={0.7} mr={0.7}>
            |
          </Grid>
        </>
      )}
      <Grid item>
        <Typography fontFamily={'Poppins'} fontSize={14} fontWeight={'bold'}>
          <ArticleCommunityName article={article} communities={communities} />
        </Typography>
      </Grid>
      <Grid item xs={12} mt={-0.5}>
        <Typography fontFamily={'Poppins'} fontSize={14} fontWeight={'bold'} letterSpacing={0.5}>
          {article?.author}
        </Typography>
      </Grid>
      <Grid item mt={-1}>
        <Typography variant="caption" fontSize={10} fontWeight={500}>
          {format(new Date(article?.visibleDate ?? article?.releaseDate ?? ''), 'd.M.yyyy H:mm')}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ArticleModifiedDate: FC<ArticlePreviewProps> = ({ article }: ArticlePreviewProps) => {
  const { t } = useTranslation();
  const modified = (article?.modifiedNotes?.length ?? 0) > 0;

  if (article && modified) {
    return (
      <Grid item xs={12} mt={1} mb={2} style={{ lineHeight: 0.9 }}>
        <Typography variant="caption" fontSize={13} fontWeight={400}>
          {t('common.modified')} {format(new Date(article.modifiedDate ?? ''), 'd.M.yyyy')} klo{' '}
          {format(new Date(article.modifiedDate ?? ''), 'H:mm')}
        </Typography>
        <br />
        <Typography variant="caption" fontSize={13} fontWeight={400} lineHeight={'20px'}>
          {article.modifiedNotes}
        </Typography>
      </Grid>
    );
  }
  return null;
};

const ArticleEditoraTitle: FC<ArticlePreviewProps> = ({ article }: ArticlePreviewProps) => {
  return (
    <Grid item xs={12} mt={1} textAlign={'center'}>
      <div className="articleEditoralTitle">{article?.title}</div>
    </Grid>
  );
};

const ArticleColumnTitle: FC<ArticlePreviewProps> = ({ article }: ArticlePreviewProps) => {
  return (
    <Grid item xs={12} mt={1} textAlign={'center'}>
      <div className="articleBlockTitle">{article?.title}</div>
    </Grid>
  );
};

const ArticleColumnDate: FC<ArticlePreviewProps> = ({ article }: ArticlePreviewProps) => {
  const { t } = useTranslation();

  if (article?.targetGroupIds?.includes(4)) {
    return (
      <Grid container item xs={12} mt={2} justifyContent={'center'}>
        <Grid item xs={5} textAlign={'right'}>
          <span className="articleBlockDate">
            {format(new Date(article?.visibleDate ?? article?.releaseDate ?? ''), 'd.M.yyyy')}
          </span>
        </Grid>
        <Grid item ml={1} mr={1}>
          |
        </Grid>
        <Grid container item xs={5}>
          <Grid item mr={1}>
            <img
              src={tilaajatahti}
              style={{
                paddingTop: 5,
                height: 16,
              }}
              alt="Tilaajatähti"
            />
          </Grid>
          <Grid item>
            <span className="articleSubscriberTagText">{t('article.subscriberOnly')}</span>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} mt={1} textAlign={'center'}>
      <Typography fontSize={10} fontWeight={500}>
        {format(new Date(article?.visibleDate ?? article?.releaseDate ?? ''), 'd.M.yyyy')}
      </Typography>
    </Grid>
  );
};

const ArticleAuthorInfo: FC<ArticlePreviewProps> = ({ article }: ArticlePreviewProps) => {
  return (
    <>
      <Grid container item xs={12} justifyContent={'center'} mt={1}>
        <span className="articleBlockAuthor">{article?.author}</span>
      </Grid>
      <Grid
        container
        item
        xs={12}
        textAlign={'center'}
        mt={2}
        ml={3}
        mr={3}
        mb={2}
        className="articleBlockAuthorDescription"
      >
        {article?.authorDescription}
      </Grid>
    </>
  );
};

const ArticleAuthorSignature: FC<ArticlePreviewProps> = ({ article }: ArticlePreviewProps) => {
  return (
    <>
      <Grid container item xs={12}>
        <Typography fontSize={15} fontWeight={600} textTransform={'uppercase'} letterSpacing={1.0}>
          {article?.author}
        </Typography>
      </Grid>
      {article?.authorDescription && (
        <Grid container item xs={12} mb={2}>
          <Typography fontSize={13} letterSpacing={1} lineHeight={'16px'}>
            {article.authorDescription}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} bgcolor={palette.lightGrey} mt={2} mb={2} p={1}>
        <Grid item xs={12}>
          <Typography fontFamily={'Noto Serif'} fontWeight={700} fontSize={20}>
            Lähetä mielipide
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography fontSize={13} lineHeight={'18px'}>
            Palstalla julkaistaan lukijoiden lähettämiä kirjoituksia. Niiden toivepituus on noin 2 000 merkkiä
            välilyönteineen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography fontSize={13} lineHeight={'18px'}>
            Tekstin loppuun tulee merkitä kirjoittajan nimi ja asuin paikkakunta. Lisäksi toimitukselle tulee lähettää
            tekstin mukana kirjoittajan omat yhteystiedot.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography fontSize={13} lineHeight={'18px'}>
            Toimituksella on oikeus tarvitaessa tiivistää kirjoituksia ja otsikoida niitä uudelleen.
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography fontSize={13} lineHeight={'18px'}>
            Kirjoitukset lähetetään sähköpostilla osoitteeseen
          </Typography>
          <Typography fontSize={13} lineHeight={'18px'} fontWeight={600}>
            toimitus@aikamedia.fi
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography fontSize={13} lineHeight={'18px'}>
            tai kirjeessä osoitteeseen
          </Typography>
          <Typography fontSize={13} lineHeight={'18px'}>
            Aikamedia/RV-lehti,
          </Typography>
          <Typography fontSize={13} lineHeight={'18px'}>
            Aikakeskus, Koulutie 11,
          </Typography>
          <Typography fontSize={13} lineHeight={'18px'}>
            01260 Vantaa
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

interface ArticleCommunityNameProps {
  article?: Article;
  communities?: Community[];
  large?: boolean;
}

const ArticleCommunityName: FC<ArticleCommunityNameProps> = ({
  article,
  communities,
  large,
}: ArticleCommunityNameProps) => {
  const communityName = communities?.find((comm) => comm.id == article?.publisher)?.community;
  let communityImage;
  let communityImageHeight;
  let communityPadding;

  if (communityName?.toLowerCase() == 'paimenplus') {
    communityImage = paimenplusImage;
    communityImageHeight = large ? 14 : 12;
    communityPadding = 0.4;
  }

  if (communityName?.toLowerCase() == 'hyvä sanoma') {
    communityImage = hyvasanomaImage;
    communityImageHeight = large ? 16 : 14;
    communityPadding = 1.0;
  }

  if (communityName?.toLowerCase() == 'ristin voitto') {
    communityImage = ristinvoittoImage;
    communityImageHeight = large ? 13 : 11;
    communityPadding = 0.5;
  }

  return (
    <Grid pt={communityPadding}>
      {communityImage ? (
        <img src={communityImage} height={communityImageHeight} style={{ paddingBottom: 2 }} alt="" />
      ) : (
        communityName
      )}
    </Grid>
  );
};

const ArticleDivider: FC = () => {
  return (
    <Grid item xs={12} mt={2} mb={1}>
      <Divider style={{ height: 3, backgroundColor: 'black', borderRadius: 3 }} />
    </Grid>
  );
};
