import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { Input, Select, MenuItem } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useMst } from '../../model/Root';
import Layout from '../../components/Layout';
import Button from '../../components/Button/Button';
import CheckboxGroup from '../../components/FormComponents/CheckboxGroup';
import TextInput from '../../components/FormComponents/TextInput';
import ErrorText from '../../components/ErrorText';
import { color } from '../../theme';
import { CollectionPage, SelectedCategory, MediaType } from '../../types';
import { CollectionPageDefaults } from '../../utils/defaults';
import config from 'config/config';

const useStyles = makeStyles()({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: 700,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    maxWidth: 700,
    minWidth: 500,
    alignItems: 'flex-start',
  },
  inputTitle: {
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  input: {
    fontFamily: 'Ubuntu',
    width: '90%',
    maxWidth: 600,
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
    padding: 10,
    alignSelf: 'flex-start',
    margin: 0,
  },
  dropDown: {
    width: '90%',
    padding: 10,
    alignSelf: 'flex-start',
    alignItems: 'center',

    backgroundColor: '#F2F2F2',
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,

    color: color.black,
    fontFamily: 'Ubuntu',
  },
  defaultItem: {
    color: 'black',
    fontFamily: 'Ubuntu',
    fontSize: 14,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  selectText: {
    fontFamily: 'Ubuntu',
    color: color.black,
  },
});

const CollectionEditScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    collectionPageStore: { selectedPage, getCollectionPage, savePage, updatePage, deletePage },
    categoryStore: { categories, getCategories },
    mediaStore: { getMedias, medias },
  } = useMst();
  const { id }: any = useParams();
  const [result, setResult] = useState<CollectionPage | null>();
  const [selectedCategories, setSelectedCategories] = useState<SelectedCategory[]>([]);
  const [hasErrors, setHasErrors] = useState<boolean>();

  useEffect(() => {
    const fetchMedias = async () => {
      await getMedias();
      await getCategories();
      if (id !== 'create') {
        await getCollectionPage(id);
        setResult(selectedPage);
        categories.forEach((category) => {
          const isFound = selectedPage?.categories?.find((c: number) => c === category.id);
          if (isFound) {
            setSelectedCategories((prev) => ({ ...prev, [category.name]: isFound }));
          } else {
            setSelectedCategories((prev) => ({ ...prev, [category.name]: isFound }));
          }
        });
      } else {
        if (!categories) return;
        const selected: SelectedCategory[] = categories.map((cat: any) => ({ [cat.name]: false }));
        setSelectedCategories(selected);
      }
    };
    fetchMedias();
  }, [getMedias, getCategories, categories, setSelectedCategories, getCollectionPage, id, selectedPage]);

  const onChange = (key: string, value: any) => {
    setResult((result: any | CollectionPage | null) => {
      const tempResult = result ? result : CollectionPageDefaults;
      return { ...tempResult, [key]: value };
    });
  };

  const getUrl = (urlTitle?: string) => {
    const baseUrl = `${config.APP_URL}/koontisivu/`;
    let pageName = urlTitle ? urlTitle.replace(/\s/g, '-') : '';
    pageName = pageName.toLowerCase().replaceAll('ä', 'a');
    return baseUrl.concat(pageName);
  };

  const onCategoriesChange = (name: string, checked: boolean) => {
    if (!name) return;
    setSelectedCategories({ ...selectedCategories, [name]: checked });
  };

  const save = async () => {
    if (!result) return;

    const tempCategories: number[] = categories
      .filter((cat: any) => {
        if (!cat.name) return null;
        if (selectedCategories[cat.name]) {
          return cat.id;
        }
      })
      .map((c) => c.id);

    const isValid = result?.name.length && result.mediaType;
    if (isValid) {
      const page: any = {
        ...result,
        url: getUrl(result?.name),
        categories: tempCategories,
      };
      if (id) {
        if (!selectedPage) return null;
        await updatePage(selectedPage.id, page);
      } else {
        await savePage(page);
      }

      navigate('/collection');
    }
    setHasErrors(!isValid);
  };

  const onDelete = async () => {
    if (!selectedPage || !id) return;
    await deletePage(selectedPage.id);
    navigate('/collection');
  };

  return (
    <Layout title={t(id !== 'create' ? 'edit_collection_page' : 'create_collection_page')}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <TextInput
            label="name"
            value={result?.name}
            type="name"
            placeholder="name"
            onChange={(key: string, value: string) => onChange(key, value)}
            style={{ width: '90%' }}
          />
          <h3 className={classes.inputTitle}>{t('url')}</h3>
          <Input
            placeholder={t('url') ?? ''}
            value={getUrl(result?.name)}
            className={classes.input}
            sx={{ textDecoration: 'none' }}
            disabled
          />
          <h3 className={classes.inputTitle}>{t('select_media')}</h3>
          <Select
            className={classes.dropDown}
            placeholder={t('media') ?? ''}
            value={result?.mediaType?.toString() || 'none'}
            onChange={({ target }) => onChange('mediaType', target.value)}
            defaultValue={'none'}
            sx={{ textDecoration: 'none' }}
            size="small"
          >
            <MenuItem value={'none'} className={classes.defaultItem} disabled>
              {t('select_media')}
            </MenuItem>
            {medias.map((media: MediaType) => (
              <MenuItem key={media.id} value={media.id} className={classes.defaultItem}>
                {media.media}
              </MenuItem>
            ))}
          </Select>
          <div className={classes.buttonContainer}>
            <Button onClick={save} text="save" />
            {id && <Button onClick={onDelete} text="delete" backgroundColor={color.deleteButton} />}
          </div>
        </div>
        <div className={classes.contentContainer}>
          <CheckboxGroup
            label="select_categories"
            type="category"
            data={categories ?? []}
            selectedData={selectedCategories}
            onChange={(key, name, checked) => onCategoriesChange(name, checked)}
            isBlack={false}
          />
        </div>
      </div>
      {hasErrors && <ErrorText text="page_save_error" style={{ fontSize: 12 }} />}
    </Layout>
  );
});

export default CollectionEditScreen;
