import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { Community } from '../../types/User';
import EditRow from '../../components/EditRow';

const CommunitiesEditScreen = observer(() => {
  const { t } = useTranslation();
  const { communityId } = useParams<{ communityId: string }>();
  const navigate = useNavigate();
  const {
    userStore: { getCommunityById, updateCommunity, createCommunity, deleteCommunity },
  } = useMst();
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState(false);
  const [community, setCommunity] = useState<Community>();

  const onChange = (newValue: string) => {
    setCommunity({ ...community, community: newValue });
  };
  const createHandler = async () => {
    if (community && community.community?.length > 0) {
      await createCommunity(community);
      navigate('/communities');
      return;
    }
    setError(community?.community.length === 0);
  };

  const updateHandler = async () => {
    if (community && community.community?.length > 0) {
      await updateCommunity(communityId, community);
      navigate('/communities');
    }
  };

  const onDelete = async () => {
    if (communityId === 'create') return;
    await deleteCommunity(communityId);
    navigate('/communities');
  };

  useEffect(() => {
    const getCommunity = async () => {
      if (communityId !== 'create') {
        setIsEdit(true);
        await getCommunityById(communityId).then((data: Community) => setCommunity(data));
      }
    };
    getCommunity();
  }, [getCommunityById, communityId]);

  return (
    <Layout title={t(isEdit ? 'community_edit' : 'community_create')}>
      <EditRow
        isEdit={isEdit}
        value={community?.community ?? ''}
        onChange={onChange}
        onCreate={createHandler}
        onUpdate={updateHandler}
        onDelete={onDelete}
        error={error}
        errorText={'comunity_save_error'}
        header={'commnunity_name'}
      />
    </Layout>
  );
});

export default CommunitiesEditScreen;
