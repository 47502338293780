export const palette = {
  white: '#FFFFFF',
  black: '#000000',
  grey: '#333333',

  lightGrey: '#F2F2F2',
  secondLighGrey: '#E0E0E0',
  borderGrey: '#757575',
  lightBorderGrey: '#E5E5E5',

  darkGreen: '#3A8300',

  buttonSecondary: '#0073A5',

  amber: '#FFA000',
  green: '#43a047',
  red: '#b30000',
};
