import { flow, types } from 'mobx-state-tree';
import {
  getAllArticlePages,
  getSingleArticlePage,
  createSingleArticlePage,
  updateSingleArticlePage,
  deleteSingleArticlePage,
} from '../services/api';
import { composeError } from '../utils/transforms';
import { Category, Tag, TargetGroup, ArticlePageResponse, Community, ArticlePageRequest, Newspaper } from '../types';
import { withRootStore } from './withRootStore';

const ArticlePageModel = types.model('ArticlePageModel', {
  id: types.optional(types.number, -1),
  title: types.optional(types.string, ''),
  caption: types.optional(types.string, ''),
  lead: types.optional(types.string, ''),
  image: types.maybeNull(types.string),
  text: types.optional(types.string, ''),
  url: types.optional(types.string, ''),
  priorityIndex: types.optional(types.number, -1),
  published: types.optional(types.boolean, false),
  categories: types.optional(types.array(types.number), []),
  tags: types.optional(types.array(types.number), []),
  targetGroups: types.optional(types.array(types.number), []),
  communities: types.optional(types.array(types.number), []),
  newspapers: types.optional(types.array(types.number), []),
  releaseDate: types.optional(types.string, ''),
  author: types.optional(types.string, ''),
  publisher: types.optional(types.string, ''),
});

export const ArticlePageStore = types
  .model({
    articles: types.optional(types.array(ArticlePageModel), []),
    selectedPage: types.optional(ArticlePageModel, {}),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getArticles = flow(function* () {
      try {
        const response = yield getAllArticlePages();
        const articles = response.data.map((data: ArticlePageResponse) => ({
          ...data,
          text: data.text,
          image: data?.image,
          author: data.author ?? '',
          publisher: data.publisher ?? '',
          categories: data.categories?.map((data: Category) => data.id),
          tags: data.tags?.map((data: Tag) => data.id),
          targetGroups: data.targetGroups?.map((data: TargetGroup) => data.id),
          communities: data.communities?.map((data: Community) => data.id),
          newspapers: data.newspaper?.map((data: Newspaper) => data.id),
        }));
        self.articles = articles;
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const getSingleArticle = flow(function* (id: string) {
      try {
        const response = yield getSingleArticlePage(id);
        self.selectedPage = {
          ...response.data,
          author: response.data.author ?? '',
          publisher: response.data.publisher ?? '',
          categories: response.data.categories.map((data: Category) => data.id),
          tags: response.data.tags.map((data: Tag) => data.id),
          targetGroups: response.data.targetGroups.map((data: TargetGroup) => data.id),
          communities: response.data.communities.map((data: Community) => data.id),
          newspapers: response.data.newspaper?.map((data: Newspaper) => data.id),
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const createArticlePage = flow(function* (page: ArticlePageRequest, image: any) {
      try {
        yield createSingleArticlePage({ ...page }, image);
        self.rootStore.errorStore.setSuccess('save_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    const updateArticlePage = flow(function* (id: string, page: ArticlePageRequest, image: any) {
      try {
        yield updateSingleArticlePage(id, { ...page }, image);
        self.rootStore.errorStore.setSuccess('save_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    const deleteArticlePage = flow(function* (id: string) {
      try {
        yield deleteSingleArticlePage(id);
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getArticles,
      getSingleArticle,
      createArticlePage,
      updateArticlePage,
      deleteArticlePage,
    };
  });
