import { getRoot, IStateTreeNode } from 'mobx-state-tree';
import { RootInstance } from './Root';

/**
 * Adds a rootStore property to the node for a convenient
 * and strongly typed way for stores to access other stores.
 *
 * NOTE: When using this e.g. `types.extend(withRootStore)`, because of some TypeScript fuckery,
 * you need to explicitly specify return types of all actions and views for the model.
 */
export const withRootStore = (self: IStateTreeNode) => ({
  views: {
    /**
     * The root store.
     */
    // eslint-disable-next-line
    // @ts-ignore
    get rootStore(): RootInstance {
      return getRoot<RootInstance>(self);
    },
  },
});
