import { ContentPage, ArticlePage } from '../types';
import { convertFromHTML } from 'draft-js';

export const validatePage = (pageData: ContentPage | ArticlePage, selectedImage: File | null) => {
  const isImageValid = !!pageData?.image?.length || selectedImage !== null;
  return (
    isImageValid &&
    pageData.text &&
    pageData.title.length > 0 &&
    pageData.text.length > 0 &&
    convertFromHTML(pageData.text).contentBlocks.length > 0 &&
    pageData.priorityIndex >= 0 &&
    pageData.priorityIndex <= 100
  );
};
