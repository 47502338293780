import { useState } from 'react';
import { useMst } from '../model/Root';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';
import { Link, Navigate } from 'react-router-dom';
import { color } from '../theme';
import LoginForm from '../components/Login/LoginForm';
import Notification from '../components/Notification';
import logo from '../static/aikamedia_logo.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()({
  container: {
    backgroundColor: color.grayBackground,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
  },
  imgContainer: {
    flexDirection: 'row',
    flex: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    backgroundColor: color.white,
    width: '100%',
    height: '110%',
  },
  formContainer: {
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: color.grayBackground,
    width: '100%',
    height: '100%',
  },
  logo: {
    marginBottom: '46px',
  },
  resetPassword: {
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: color.grayBackground,
    width: '100%',
    marginTop: 40,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'normal',
    color: color.black,
    maxHeight: 70,
  },
  emptyFooter: {
    flexDirection: 'row',
    flex: 4,
    display: 'flex',
  },
});

const LoginScreen = observer(() => {
  const { classes } = useStyles();
  const {
    userStore: { token, user, userLogin },
    errorStore: { error, success, clearError },
  } = useMst();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hasErrors, setHasErrors] = useState(false);
  const { t } = useTranslation();

  const emailChange = (value: string) => setEmail(value);
  const passwordChange = (value: string) => setPassword(value);

  const handleLogin = () => {
    if (email.length === 0 || password.length === 0) {
      setHasErrors(true);
      return;
    }
    setHasErrors(false);
    userLogin({ email, password });
  };

  if (token && user) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img src={logo} alt="aikamedia" className={classes.logo} />
      </div>
      <div className={classes.formContainer}>
        <LoginForm
          email={email}
          password={password}
          setEmail={emailChange}
          setPassword={passwordChange}
          onSubmit={handleLogin}
          hasErrors={hasErrors}
        />
        <Notification error={error} success={success} onClose={clearError} />
      </div>
      <div className={classes.resetPassword}>
        <Link style={{ textDecoration: 'none' }} to={'/reset-password'}>
          {t('reset_password')}
        </Link>
      </div>
      <div className={classes.emptyFooter} />
    </div>
  );
});

export default LoginScreen;
