import { flow, types } from 'mobx-state-tree';
import { getAlbums, getSingleAlbum, addSingleAlbum, updateSingleAlbum, deleteSingleAlbum } from '../services/albumApi';
import { composeError } from '../utils/transforms';
import { AlbumResponse, Album, Category, TargetGroup, Song } from '../types';
import { withRootStore } from './withRootStore';

const SongModel = types.model('SongModel', {
  id: types.number,
  name: types.string,
  src: types.string,
  order: types.number,
  author: types.string,
  state: types.optional(types.string, ''),
});

const AlbumModel = types.model('AlbumModel', {
  id: types.optional(types.number, -1),
  name: types.string,
  image: types.maybeNull(types.string),
  description: types.string,
  releaseDate: types.maybeNull(types.string),
  artist: types.string,
  publisher: types.string,
  releaseYear: types.maybeNull(types.number),
  language: types.maybeNull(types.string),
  acdCode: types.maybeNull(types.string),
  shopUrl: types.maybeNull(types.string),
  mediaTypeId: types.optional(types.number, -1),
  songs: types.optional(types.array(SongModel), []),
  categoryIds: types.optional(types.array(types.number), []),
  targetGroupIds: types.optional(types.array(types.number), []),
  published: types.optional(types.boolean, false),
});

export const AlbumStore = types
  .model({
    albums: types.optional(types.array(AlbumModel), []),
    selectedAlbum: types.maybeNull(AlbumModel),
    loading: false,
  })
  .extend(withRootStore)
  .actions((self) => {
    const getAllAlbums = flow(function* () {
      try {
        const response = yield getAlbums();
        self.albums = response.data.map((album: AlbumResponse) => ({
          ...album,
          // These are not used where getAllAlbums is called
          // mediaTypeId: album.mediaType.id,
          // categoryIds: album.categories?.map((data: Category) => data.id),
          // targetGroupIds: album.targetGroups?.map((data: TargetGroup) => data.id),
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const getSelectedAlbum = flow(function* (id: string) {
      try {
        const response = yield getSingleAlbum(id);
        self.selectedAlbum = {
          ...response.data,
          mediaTypeId: response.data.mediaType.id,
          songs: response.data.songs?.map((song: Song) => ({
            name: song.name,
            src: song.src,
            order: song.order,
            author: song.author,
            id: song.id,
          })),
          categoryIds: response.data.categories?.map((data: Category) => data.id),
          targetGroupIds: response.data.targetGroups?.map((data: TargetGroup) => data.id),
        };

        // Sort songs
        self.selectedAlbum?.songs.sort((a, b) => a.order - b.order);
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const createAlbum = flow(function* (album: Album, songs: Song[], files: File[], image?: File) {
      self.loading = true;
      try {
        yield addSingleAlbum(album, songs, files, image);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const updateAlbum = flow(function* (album: Album, songs: Song[], files: File[], image?: File) {
      self.loading = true;
      try {
        yield updateSingleAlbum(album, songs, files, image);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const deleteAlbum = flow(function* (id: string) {
      try {
        yield deleteSingleAlbum(id);
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getAllAlbums,
      getSelectedAlbum,
      createAlbum,
      updateAlbum,
      deleteAlbum,
    };
  });
