import { flow, types } from 'mobx-state-tree';
import {
  fetchPromotions,
  getSinglePromotion,
  deleteSinglePromotion,
  updateSinglePromotion,
  createPromotion,
} from '../services/api';
import { composeError } from '../utils/transforms';
import { withRootStore } from './withRootStore';
import { Promotion } from '../types/Pages';

export const PromotionModel = types.model('PromotionModel', {
  id: types.maybeNull(types.number),
  name: types.string,
  text: types.optional(types.string, ''),
  promotionText: types.optional(types.string, ''),
  url: types.string,
  placement: types.optional(types.number, 0),
  image: types.maybeNull(types.string),
});

export const PromotionStore = types
  .model({
    promotions: types.optional(types.array(PromotionModel), []),
    singlePromotion: types.maybeNull(PromotionModel),
    placements: types.optional(types.array(types.number), []),
    error: types.maybeNull(types.frozen()),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getPromotions = flow(function* () {
      try {
        const response = yield fetchPromotions();
        self.promotions = response.data.map((response: Promotion) => ({
          ...response,
          promotionText: response.promotionText || '',
        }));

        self.placements = response.data.map((response: Promotion) => response.placement);
      } catch (err: any) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const getPromotionById = flow(function* (id: string) {
      try {
        const response = yield getSinglePromotion(id);
        self.singlePromotion = { ...response.data, promotionText: response.data.promotionText || '' };
      } catch (err: any) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const deletePromotion = flow(function* (id: string) {
      try {
        yield deleteSinglePromotion(id);
        self.rootStore.errorStore.setSuccess('delete_success');
        getPromotions();
      } catch (err: any) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const createNewPromotion = flow(function* (promotion: Promotion, image: any) {
      try {
        yield createPromotion(promotion, image);
        self.rootStore.errorStore.setSuccess('save_success');
        getPromotions();
      } catch (err: any) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const updatePromotion = flow(function* (promotion: Promotion, image: any) {
      try {
        yield updateSinglePromotion(promotion, image);
        self.rootStore.errorStore.setSuccess('save_success');
        getPromotions();
      } catch (err: any) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    return {
      getPromotions,
      getPromotionById,
      deletePromotion,
      updatePromotion,
      createNewPromotion,
    };
  });
