import { Button, Typography } from '@mui/material';
import { color } from '../../theme';
import plus from 'static/plus.svg';

interface Props {
  text: string;
  onClick?: () => void;
}
export default function AddNewButton(props: Props) {
  const { text, onClick } = props;

  return (
    <Button variant="text" onClick={onClick}>
      <img src={plus} alt="add" style={{ height: 15 }} />
      <Typography color={color.greyText} ml={1}>
        {text}
      </Typography>
    </Button>
  );
}
