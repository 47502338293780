import React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ErrorText from '../ErrorText';

interface LoginProps {
  email: string;
  password: string;
  setEmail: (value: string) => void;
  setPassword: (value: string) => void;
  onSubmit: () => void;
  hasErrors?: boolean;
}
const LoginForm = (props: LoginProps) => {
  const { t } = useTranslation();
  const { email, setEmail, password, setPassword, onSubmit, hasErrors } = props;

  return (
    <Grid
      container
      spacing={2}
      mt={2}
      direction={'column'}
      justifyContent={'flext-start'}
      alignItems={'center'}
      style={{ width: '40%' }}
    >
      <Grid item>
        <TextField
          value={email}
          name={'username'}
          onChange={(e) => setEmail(e.target.value)}
          type={'text'}
          variant="outlined"
          placeholder={t('username') ?? ''}
          autoFocus
          style={{ minWidth: '440px', backgroundColor: 'white' }}
        />
      </Grid>
      <Grid item>
        <TextField
          value={password}
          name={'password'}
          onChange={(e) => setPassword(e.target.value)}
          type={'password'}
          variant="outlined"
          placeholder={t('password') ?? ''}
          style={{ minWidth: '440px', backgroundColor: 'white' }}
        />
      </Grid>
      <Grid item>
        {hasErrors && <ErrorText text="email_or_passowrd_missing" style={{ fontSize: 12 }} />}
        <Button
          variant="contained"
          onClick={onSubmit}
          type="submit"
          style={{ height: 44, minWidth: '440px', color: 'white' }}
        >
          {t('signin_button')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
