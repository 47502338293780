import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Article, ArticleImage, ArticleTypes } from '../../types';
import { Grid, Typography, Button, IconButton, Divider } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import FormFieldText from 'components/FormComponents/FormFieldText';
import ClearIcon from '@mui/icons-material/Clear';
import FormFieldCheckbox from 'components/FormComponents/FormFieldCheckbox';
import ImageCropper from './ImageCropper';
import DialogBase from 'components/common/DialogBase';
import FormFieldSwitch from 'components/FormComponents/FormFieldSwitch';
import full from '../../static/article_image_full.png';
import two from '../../static/article_image_two.png';
import three from '../../static/article_image_three.png';
import carousel from '../../static/article_image_carousel.png';
import { renderSubTitle } from './ArticleEditScreen';
import AddIcon from '@mui/icons-material/Add';

export type CropType = '' | 'all' | 'square' | 'share';

const ArticleImagesEdit: FC = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<Article>();
  const [cropState, setCropState] = useState<{ imageCropDialog: CropType; imageIndex: number }>({
    imageCropDialog: '',
    imageIndex: -1,
  });

  const handleArticleImageSelection = (file?: File, storeIndex?: number, circular?: boolean) => {
    if (file && storeIndex != undefined) {
      const image = URL.createObjectURL(file);

      // uniquify file name and take only 100 first characters
      const fileNameNormalized = `${Date.now()}_${file.name}`.normalize('NFD').replace(/[\u017e-\ufffc]/g, '');
      const fileNameUnique = fileNameNormalized.substring(0, Math.min(fileNameNormalized.length, 100));

      const modificationState = values?.images?.[storeIndex].id ? 'edited' : 'new';
      setFieldValue(`images.${storeIndex}.file`, file);
      setFieldValue(`images.${storeIndex}.srcPreview`, image);
      setFieldValue(`images.${storeIndex}.src`, fileNameUnique);
      setFieldValue(`images.${storeIndex}.state`, modificationState);
      setFieldValue(`images.${storeIndex}.circular`, circular);

      setCropState({ imageCropDialog: '', imageIndex: -1 });
    }
  };

  const createImageTag = (index: number) => {
    return `kuva_${index.toString()}`;
  };

  const getNextImageTag = (images?: ArticleImage[]) => {
    let nextIndex = 1;

    if (!images) {
      return createImageTag(nextIndex);
    }

    let nextTag = '';
    while (nextTag.length == 0) {
      const tryTag = createImageTag(nextIndex);
      if (images.find((img) => img.tag == tryTag) == undefined) {
        // not used, take it
        nextTag = tryTag;
      }
      nextIndex++;
    }

    return nextTag;
  };

  const getEmptyImage = (src?: string, state?: string, link?: boolean, share?: boolean, tag?: string) => {
    return {
      src: src,
      caption: '',
      photographer: '',
      main: false,
      top: false,
      topWeb: false,
      front: false,
      full: false,
      left: false,
      right: false,
      middle: false,
      carousel: false,
      link: link ?? false,
      share: share ?? false,
      tag: tag ?? getNextImageTag(values.images),
      state: state ?? 'new',
    };
  };

  return (
    <FieldArray name="images">
      {({ push, remove }) => (
        <>
          {renderSubTitle(t('article.images'), t('article.addImage'), () => {
            push(getEmptyImage());
          })}

          <Grid item xs={12}>
            {(values?.images?.length ?? 0) == 0 ? (
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="caption" color="GrayText">
                    {t('article.noImages')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="text"
                    color="info"
                    onClick={() => {
                      push(
                        getEmptyImage(
                          'https://aikamediastorage.blob.core.windows.net/public-images/artikkelit/linkkikuva_ajassa-lehti.jpg',
                          'edit',
                          true,
                          false,
                          'kuva_1'
                        )
                      );
                      push(
                        getEmptyImage(
                          'https://aikamediastorage.blob.core.windows.net/public-images/artikkelit/jakokuva_ajassa-lehti.jpg',
                          'edit',
                          false,
                          true,
                          'kuva_2'
                        )
                      );
                    }}
                  >
                    <AddIcon fontSize="small" />
                    {t('article.addTextArticleImages')}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <>
                {values?.images?.map((image, index) => (
                  <Grid container key={image.tag}>
                    <Grid
                      container
                      item
                      xs={12}
                      style={{
                        backgroundColor: image.state == 'deleted' ? '#ffaaaa' : '#eaeaea',
                        padding: '1px 8px',
                        borderRadius: 3,
                      }}
                    >
                      <Grid item xs={10} alignSelf={'center'}>
                        <Typography style={{ textDecoration: image.state == 'deleted' ? 'line-through' : 'normal' }}>
                          {image.tag}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} textAlign={'right'}>
                        {image.state != 'deleted' && (
                          <IconButton
                            onClick={() => {
                              if (!image.id) {
                                remove(index);
                              } else {
                                setFieldValue(`images.${index}.state`, 'deleted');
                              }
                            }}
                            size="small"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                    {image.state == 'deleted' && <Grid container item xs={10} m={2}></Grid>}
                    <Grid container item xs={10} mt={1} display={image.state == 'deleted' ? 'none' : 'default'}>
                      <Grid container item xs={10} mt={1}>
                        <Typography variant="body1" color="GrayText">
                          {t('article.imageUsage')}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} alignItems={'flex-start'} mt={1}>
                        <Grid item xs={3}>
                          <FormFieldSwitch name={`images.${index}.front`} label={t('article.imageFront')} />
                        </Grid>
                        <Grid container item xs={9} spacing={1} mt={1} pl={2}>
                          {values?.images?.[index]?.front && (
                            <Grid container>
                              <Grid container item xs={3}>
                                <Grid item xs={12} textAlign={'center'}>
                                  <img src={full} width={100} alt="" />
                                </Grid>
                                <Grid item xs={12} textAlign={'center'} pl={4}>
                                  <FormFieldCheckbox label="" name={`images.${index}.full`} />
                                </Grid>
                              </Grid>
                              <Grid container item xs={3}>
                                <Grid item xs={10} textAlign={'center'}>
                                  <img src={two} width={100} alt="" />
                                </Grid>
                                <Grid item xs={6} textAlign={'center'} pl={5}>
                                  <FormFieldCheckbox label="" name={`images.${index}.left`} />
                                </Grid>
                                <Grid item xs={6} textAlign={'center'} pr={7}>
                                  <FormFieldCheckbox label="" name={`images.${index}.right`} />
                                </Grid>
                              </Grid>
                              <Grid container item xs={3}>
                                <Grid item xs={10} textAlign={'center'}>
                                  <img src={three} width={100} alt="" />
                                </Grid>
                                <Grid item xs={4} textAlign={'center'} pl={3}>
                                  <FormFieldCheckbox label="" name={`images.${index}.left`} />
                                </Grid>
                                <Grid item xs={4} textAlign={'center'} pl={1}>
                                  <FormFieldCheckbox label="" name={`images.${index}.middle`} />
                                </Grid>
                                <Grid item xs={4} textAlign={'center'} pr={5} ml={-1}>
                                  <FormFieldCheckbox label="" name={`images.${index}.right`} />
                                </Grid>
                              </Grid>
                              <Grid container item xs={3}>
                                <Grid item xs={10} textAlign={'center'}>
                                  <img src={carousel} height={40} alt="" />
                                </Grid>
                                <Grid item xs={4} textAlign={'center'} pl={7}>
                                  <FormFieldCheckbox label="" name={`images.${index}.carousel`} />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={3}>
                          <FormFieldSwitch name={`images.${index}.main`} label={t('article.imageArticle')} />
                        </Grid>
                        <Grid container item xs={9} spacing={2}>
                          {values?.images?.[index]?.main && (
                            <>
                              <Grid item xs={12}>
                                <FormFieldCheckbox label={t('article.imageTop')} name={`images.${index}.top`} />
                                <FormFieldCheckbox label={t('article.imageTopWeb')} name={`images.${index}.topWeb`} />
                              </Grid>
                              <Grid item xs={12}>
                                <FormFieldText label={t('article.imageCaption')} name={`images.${index}.caption`} />
                              </Grid>
                              <Grid item xs={12}>
                                <FormFieldText
                                  label={t('article.imagePhotographer')}
                                  name={`images.${index}.photographer`}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={3}>
                            <FormFieldSwitch name={`images.${index}.link`} label={t('article.imageLink')} />
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={3}>
                            <FormFieldSwitch name={`images.${index}.share`} label={t('article.imageShare')} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container item xs={10} mt={2} mb={3}>
                        <Grid item>
                          {image.src && (
                            <img
                              src={image.srcPreview ?? image.src ?? ''}
                              style={{ borderRadius: image.circular ? '50%' : undefined }}
                              alt="Artikkelin kuva"
                              crossOrigin="anonymous"
                              height={150}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <Button
                            variant="outlined"
                            color="info"
                            size="small"
                            onClick={() => {
                              let crop: CropType = 'all';
                              if (values?.images?.[index]?.link) {
                                crop = 'square';
                              } else if (values?.images?.[index]?.share) {
                                crop = 'share';
                              } else if (values?.images?.[index]?.front && values?.type == ArticleTypes.SmallStory) {
                                crop = 'square';
                              } else if (values?.images?.[index]?.carousel) {
                                crop = 'square';
                              }
                              setCropState({ imageCropDialog: crop, imageIndex: index });
                            }}
                          >
                            <Typography variant="caption" fontSize={14} sx={{ textTransform: 'uppercase' }}>
                              {t('article.selectAndCropImage')}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <DialogBase
                      title={t('article.imageZoomAndCrop')}
                      onClose={() => setCropState({ imageCropDialog: '', imageIndex: -1 })}
                      onOk={() => setCropState({ imageCropDialog: '', imageIndex: -1 })}
                      show={cropState.imageCropDialog != ''}
                      size={'lg'}
                      contentStyle={{ overflowY: 'scroll' }}
                      hideActions
                    >
                      <ImageCropper
                        storeImage={(file: File, storeIndex: number, circular: boolean) =>
                          handleArticleImageSelection(file, storeIndex, circular)
                        }
                        onCancel={() => setCropState({ imageCropDialog: '', imageIndex: -1 })}
                        cropType={cropState.imageCropDialog}
                        imageIndex={cropState.imageIndex}
                      />
                    </DialogBase>
                  </Grid>
                ))}
                <Divider style={{ border: '#cacaca solid 1px', width: '100%' }} />
                <Grid item mt={3}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: '#6688aa' }}
                    onClick={() => push(getEmptyImage())}
                  >
                    <AddIcon fontSize="small" />
                    {t('article.addImage')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </FieldArray>
  );
};

export default ArticleImagesEdit;
