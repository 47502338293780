import React from 'react';
import { Table, TableBody, TableCell, TableRow, Input, TableHead } from '@mui/material';
import Button from './Button/Button';
import ErrorText from './ErrorText';
import { color } from '../theme';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  root: {
    '&.MuiTable-root': {
      width: '100%',
      maxWidth: 1000,
    },
  },
  row: {
    borderBottom: `1px solid ${color.greyBorderLight}`,
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: 400,
  },
  cell: {
    display: 'flex',
    justifySelf: 'center',
    fontSize: '18px',
    fontFamily: 'Ubuntu',
    fontWeight: 'normal',
    lineHeight: '27px',
    padding: 10,
    border: 0,
    marginTop: 25,
  },
  input: {
    borderBottomWidth: 1,
    marginLeft: 10,
  },
  button: {
    backgroundColor: color.primaryButton,
    color: color.white,
    fontFamily: 'Ubuntu',
    fontWeight: 'normal',
    marginTop: '40px',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  tableHead: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: color.black,
    fontFamily: 'Ubuntu',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '21px',
    border: 0,
    paddingLeft: 0,
    width: '100%',
  },
  deleteButton: {
    backgroundColor: color.deleteButton,
    color: color.white,
    fontFamily: 'Ubuntu',
    fontWeight: 'normal',
    marginTop: '40px',
    marginLeft: '20px',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
    width: '100px',
  },
});

interface Props {
  value: string;
  isEdit: boolean;
  error: boolean;
  errorText: string;
  header: string;
  onChange: (value: string) => void;
  onUpdate?: () => void;
  onDelete?: () => void;
  onCreate?: () => void;
}

function EditTable(props: Props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { value, errorText, header, onChange, onUpdate, onCreate, isEdit, onDelete, error } = props;

  const renderTableHead = () => (
    <TableRow>
      <TableCell className={classes.tableHead}>{t(header)}</TableCell>
    </TableRow>
  );

  const renderRows = () => (
    <TableRow className={classes.row}>
      <TableCell className={classes.cell}>
        <Input
          className={classes.input}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          autoFocus
          sx={{ textDecoration: 'none' }}
        />
      </TableCell>
    </TableRow>
  );

  const onClick = () => {
    if (isEdit) {
      onUpdate && onUpdate();
    } else {
      onCreate && onCreate();
    }
  };

  return (
    <div className={classes.tableContainer}>
      <Table className={classes.root}>
        <TableHead>{renderTableHead()}</TableHead>
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {error && <ErrorText text={errorText} style={{ fontSize: 12 }} />}
      <div className={classes.buttonContainer}>
        <Button onClick={onClick} text="save" />
        {isEdit && onDelete && <Button onClick={onDelete} text="delete" backgroundColor={color.deleteButton} />}
      </div>
    </div>
  );
}

export default EditTable;
