import { useContext, createContext } from 'react';
import { types, Instance } from 'mobx-state-tree';
import { UserStore } from './UserStore';
import { CategoryStore } from './CategoryStore';
import { ErrorStore } from './ErrorStore';
import { AppStore } from './AppStore';
import { NavigationStore } from './NavigationStore';
import { TargetGroupStore } from './TargetGroupStore';
import { CollectionPageStore } from './CollectionPageStore';
import { ContentPageStore } from './ContentPageStore';
import { MediaStore } from './MediaStore';
import { PromotionStore } from './PromotionStore';
import { ArticlePageStore } from './ArticlePageStore';
import { NewspaperStore } from './NewspaperStore';
import { AudiobookStore } from './AudiobookStore';
import { SubscriptionTypeStore } from './SubscriptionTypeStore';
import { AlbumStore } from './AlbumStore';
import { TagStore } from './TagStore';
import { PodcastStore } from './PodcastStore';
import { MagazineStore } from './MagazineStore';
import { ProgramStore } from './ProgramStore';
import { ReportStore } from './ReportStore';
import { ArticleStore } from './ArticleStore';
import { OnlineMagazineStore } from './OnlineMagazineStore';
import { MagazineSegmentStore } from './MagazineSegmentStore';
import { DigiMagazineStore } from './DigiMagazineStore';

const RootModel = types.model('RootStore', {
  userStore: UserStore,
  errorStore: ErrorStore,
  appStore: AppStore,
  categoryStore: CategoryStore,
  tagStore: TagStore,
  targetGroupStore: TargetGroupStore,
  navigationStore: NavigationStore,
  collectionPageStore: CollectionPageStore,
  mediaStore: MediaStore,
  promotionStore: PromotionStore,
  contentPageStore: ContentPageStore,
  articlePageStore: ArticlePageStore,
  newspaperStore: NewspaperStore,
  audiobookStore: AudiobookStore,
  subscriptionTypeStore: SubscriptionTypeStore,
  albumStore: AlbumStore,
  podcastStore: PodcastStore,
  magazineStore: MagazineStore,
  programStore: ProgramStore,
  reportStore: ReportStore,
  articleStore: ArticleStore,
  onlineMagazineStore: OnlineMagazineStore,
  magazineSegmentStore: MagazineSegmentStore,
  digiMagazineStore: DigiMagazineStore,
});

export const rootStore = RootModel.create({
  userStore: {},
  errorStore: {},
  appStore: {},
  categoryStore: {},
  tagStore: {},
  targetGroupStore: {},
  navigationStore: {},
  collectionPageStore: {},
  mediaStore: {},
  promotionStore: {},
  contentPageStore: {},
  articlePageStore: {},
  newspaperStore: {},
  audiobookStore: {},
  subscriptionTypeStore: {},
  albumStore: {},
  podcastStore: {},
  magazineStore: {},
  programStore: {},
  reportStore: {},
  articleStore: {},
  onlineMagazineStore: {},
  magazineSegmentStore: {},
  digiMagazineStore: {},
});

//makeInspectable(rootStore)

// eslint-disable-next-line
// @ts-ignore
export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const StoreProvider = RootStoreContext.Provider;
export function useMst() {
  const store = useContext(RootStoreContext);

  if (!store) {
    throw new Error('No context for store');
  }

  return store;
}
