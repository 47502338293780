import { flow, types } from 'mobx-state-tree';
import {
  getMagazineSegments,
  deleteSingleMagazineSegment,
  addMagazineSegment,
  updateSingleMagazineSegment,
} from '../services/magazineSegmentApi';
import { composeError } from '../utils/transforms';
import { MagazineSegmentResponse, MagazineSegment } from '../types';
import { withRootStore } from './withRootStore';

const MagazineSegmentModel = types.model('MagazineSegmentModel', {
  id: types.optional(types.number, -1),
  name: types.string,
  image: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  published: types.boolean,
  order: types.number,
  publicationDate: types.string,
  removelDate: types.maybeNull(types.string),
  articleType: types.string,
  articleSection: types.maybeNull(types.string),
  articleCount: types.number,
  feedType: types.string,
  articleSkip: types.number,
  singleArticleId: types.maybeNull(types.number),
  onlineMagazineId: types.number,
  mobile: types.boolean,
  web: types.boolean,
});

export const MagazineSegmentStore = types
  .model({
    magazineSegments: types.optional(types.array(MagazineSegmentModel), []),
    loading: false,
  })
  .extend(withRootStore)
  .actions((self) => {
    const getMagazineSegmentsForOnlineMagazine = flow(function* (id: string) {
      try {
        const response = yield getMagazineSegments(id);
        self.magazineSegments = response.data.map((magazineSegment: MagazineSegmentResponse) => ({
          ...magazineSegment,
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const addNewMagazineSegment = function (onlineMagazineId: number) {
      self.magazineSegments.push({
        id: -1,
        name: '',
        publicationDate: new Date().toISOString(),
        published: true,
        image: '',
        articleType: 'Undefined',
        articleCount: 1,
        feedType: 'Newest',
        articleSkip: 0,
        order: self.magazineSegments.length,
        mobile: true,
        web: true,
        onlineMagazineId: onlineMagazineId,
      });
    };

    const createMagazineSegment = flow(function* (magazineSegment: MagazineSegment, image?: File) {
      self.loading = true;
      try {
        yield addMagazineSegment(magazineSegment, image);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const updateMagazineSegment = flow(function* (magazineSegment: MagazineSegment, image?: File) {
      self.loading = true;
      try {
        yield updateSingleMagazineSegment(magazineSegment, image);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const deleteMagazineSegment = flow(function* (id: number) {
      try {
        if (id > 0) {
          yield deleteSingleMagazineSegment(id.toString());
          self.rootStore.errorStore.setSuccess('delete_success');
        }

        const item = self.magazineSegments.find((item) => item.id == id);
        if (item) {
          self.magazineSegments.remove(item);
        }
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    const saveMagazineSegmentsOrder = function (magazineSegments: MagazineSegment[]) {
      self.loading = true;
      try {
        magazineSegments.forEach((segment, index) => {
          segment.order = index;
          updateSingleMagazineSegment(segment);
        });
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    };

    return {
      getMagazineSegmentsForOnlineMagazine,
      createMagazineSegment,
      updateMagazineSegment,
      deleteMagazineSegment,
      addNewMagazineSegment,
      saveMagazineSegmentsOrder,
    };
  });
