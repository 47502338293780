import { flow, types, Instance } from 'mobx-state-tree';
import { getAllLinks, addMultipleLinks, updateMultipleLinks, deleteLink } from '../services/api';
import { composeError } from '../utils/transforms';
import { Link, LinkResponse } from '../types';
import { withRootStore } from './withRootStore';

const LinkModel = types.model('LinkModel', {
  text: types.maybeNull(types.string),
  url: types.optional(types.string, ''),
  placement: types.optional(types.number, 0),
  id: types.number,
  logo: types.maybeNull(types.string),
  isNavigation: types.maybeNull(types.boolean),
  isHeader: types.maybeNull(types.boolean),
  collectionPageId: types.maybeNull(types.number),
});

export type LinkType = Instance<typeof LinkModel>;
export const NavigationStore = types
  .model({
    links: types.optional(types.array(LinkModel), []),
    error: types.maybeNull(types.frozen()),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getLinks = flow(function* () {
      try {
        const response = yield getAllLinks();
        self.links = response.data.map((r: LinkResponse) => ({
          ...r,
          collectionPageId: r.collectionPage?.id,
          url: r.url || '',
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const addLinks = flow(function* (newLinks: Link[]) {
      try {
        yield addMultipleLinks(newLinks);
        self.rootStore.errorStore.setSuccess('save_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const updateLinks = flow(function* (links: Link[]) {
      try {
        yield updateMultipleLinks(links);
        self.rootStore.errorStore.setSuccess('save_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const deleteLinks = flow(function* (linkId: number) {
      try {
        yield deleteLink(linkId.toString());
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    return {
      getLinks,
      addLinks,
      updateLinks,
      deleteLinks,
    };
  });
