import React, { FC, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Article } from '../../types';
import { Grid, Typography, IconButton, Divider } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import FormFieldText from 'components/FormComponents/FormFieldText';
import FormFieldDropDown from 'components/FormComponents/FormFieldDropDown';
import { Editor as TinyMCEEditor } from 'tinymce';
import BundledEditor from 'components/common/BundledEditor';
import ClearIcon from '@mui/icons-material/Clear';
import { ArticleSideStoryTypes } from 'types/ArticleSideStoryTypes';
import { editorSetup, renderSubTitle } from './ArticleEditScreen';

interface ArticleSideStoriesEditProps {
  editorRefs: MutableRefObject<TinyMCEEditor[]>;
}

const ArticleSideStoriesEdit: FC<ArticleSideStoriesEditProps> = ({ editorRefs }: ArticleSideStoriesEditProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<Article>();
  return (
    <FieldArray name="sideStories">
      {({ push }) => (
        <>
          {renderSubTitle(t('article.sideStories'), t('article.addSideStories'), () =>
            push({ tag: `kainalo_${((values?.sideStories?.length ?? 0) + 1).toString()}` })
          )}

          <Grid item xs={12}>
            {(values?.sideStories?.length ?? 0) == 0 && (
              <Typography variant="caption" color="GrayText">
                {t('article.noSideStories')}
              </Typography>
            )}
            <Grid container>
              {(values?.sideStories?.length ?? 0) > 0 &&
                values.sideStories
                  ?.filter((story) => story.state != 'deleted')
                  .map((sideStory, index) => (
                    <Grid container key={sideStory.tag} mt={2} mb={2}>
                      {index > 0 && (
                        <Grid item xs={12} mb={2}>
                          <Divider />
                        </Grid>
                      )}
                      <Grid item mt={2} mr={1} xs={1}>
                        <Typography>{sideStory.tag}</Typography>
                      </Grid>
                      <Grid container item xs={10} mt={1}>
                        <Grid container item spacing={1}>
                          <Grid item xs={6}>
                            <FormFieldDropDown
                              placeholder={t('article.select_sideStoryType') ?? ''}
                              label={t('article.sideStoryType') ?? ''}
                              name={`sideStories.${index}.type`}
                              values={Object.keys(ArticleSideStoryTypes)
                                .filter((key) => key != ArticleSideStoryTypes.Undefined)
                                .map((key) => ({
                                  key: key,
                                  value: `articleSideStoryTypes.${key}`,
                                }))}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormFieldText label={t('article.sideStoryTitle')} name={`sideStories.${index}.title`} />
                          </Grid>
                          <Grid item xs={6}>
                            <FormFieldText label={t('article.sideStoryAuthor')} name={`sideStories.${index}.author`} />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="caption" color="GrayText" fontSize={13} ml={1}>
                              {t('article.sideStoryText')}
                            </Typography>

                            <BundledEditor
                              onInit={(_, editor) => (editorRefs.current[index] = editor)}
                              initialValue={sideStory?.text}
                              init={{
                                height: 300,
                                menubar: false,
                                plugins: ['lists'],
                                toolbar: 'undo redo | bold italic | bullist numlist |  addImage  | fixStyles',
                                inline: false,
                                setup: (editor) => {
                                  return editorSetup(editor, true);
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setFieldValue(`sideStories.${index}.state`, 'deleted');
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
        </>
      )}
    </FieldArray>
  );
};

export default ArticleSideStoriesEdit;
