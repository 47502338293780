import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { color } from '../../theme';
import { makeStyles } from 'tss-react/mui';
import AddButton from '../Button/AddButton';
import Checkbox from '../CheckBox';
import TextInput from './TextInput';
import SaveIcon from '../../static/save_icon.svg';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  createConteiner: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${color.greyBorder}`,
    borderRadius: 4,
    width: '100%',
    maxWidth: 400,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  button: {
    alignSelf: 'flex-start',
    marginTop: 20,
    marginLeft: -10,
  },
});

interface GroupProps {
  type: string;
  data: any[];
  selectedData: any[];
  onChange: (key: string, name: string, checked: boolean) => void;
  label?: string;
  style?: React.CSSProperties;
  newValue?: string;
  addButtonText?: string;
  onNewChange?: (key: string, value: string) => void;
  onSaveNew?: (key: string) => void;
  isBlack?: boolean;
}

export default function CheckboxGroup(props: GroupProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { type, data, selectedData, onChange, label, onNewChange, onSaveNew, addButtonText, newValue, isBlack } = props;
  const [addNew, setAddNew] = useState(false);

  const save = (type: string) => {
    if (!onSaveNew) return;
    onSaveNew(type);
    setAddNew(!addNew);
  };

  const renderCreate = (onCreateChange: (key: string, value: string) => void) => (
    <div className={classes.createConteiner}>
      <h3 className={classes.title}>{t(type)}</h3>
      <TextInput placeholder={type} type={type} value={newValue} onChange={onCreateChange} style={{ width: '90%' }} />
      <IconButton className={classes.button} onClick={() => save(type)}>
        <img src={SaveIcon} alt="save" />
      </IconButton>
    </div>
  );

  return (
    <div className={classes.container}>
      {label && <h3 className={classes.title}>{t(label)}</h3>}
      <Checkbox
        data={data}
        selectedData={selectedData}
        onChange={(value: any) => onChange(type, value.name, value.checked)}
        isBlack={isBlack ?? true}
      />
      {onNewChange && (
        <>
          <AddButton onClick={() => setAddNew(!addNew)} text={t(addButtonText ?? '')} />
          {addNew && renderCreate(onNewChange)}
        </>
      )}
    </div>
  );
}
