import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import Table from '../../components/Table/Table';
import AddButton from '../../components/Button/AddButton';
import { useTranslation } from 'react-i18next';
import { MAGAZINE_TABLE } from '../../utils/constants';
import { Community, LinkRow, Row } from '../../types';
import AlertModal from '../../components/AlertModal';

const MagazineScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    magazineStore: { magazines, getAllMagazines, deleteMagazine },
    userStore: { communities, user, getCommunities },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow>();
  const [result, setResult] = useState<Row[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const magazineMapper = useCallback(
    (magazine) => ({
      id: magazine.id,
      name: magazine.name,
      value: `${magazine.name}`,
      secondValue: magazine.published ? t('published') : t('draft'),
    }),
    [t]
  );

  const filterMagazines = useCallback(
    (communities: Community[], ownCommunities: Community[]) => {
      const filtered = communities.filter((community: Community) => {
        return ownCommunities.some((el) => {
          return el.id === community.id;
        });
      });
      const filteredMagazines = magazines.filter((magazine) => {
        return filtered.find(({ community }) => community === magazine.publisher);
      });
      return filteredMagazines;
    },
    [magazines]
  );

  useEffect(() => {
    const getMagazines = async () => {
      await getAllMagazines();

      if (user?.admin) {
        const tempResult: Row[] = magazines.length > 0 ? magazines.map(magazineMapper) : [];
        setResult(tempResult);
      } else if (communities && user?.communities.length) {
        const filtered = filterMagazines(communities, user.communities);
        const tempResult: Row[] = filtered && filtered.length > 0 ? filtered.map(magazineMapper) : [];
        setResult(tempResult);
      }
    };

    getMagazines();
  }, [getAllMagazines, getCommunities, user, communities, magazines, t, filterMagazines, magazineMapper]);

  useEffect(() => {
    if (!selectedRow?.id || modalOpen) return;
    navigate(`/magazines/${selectedRow.id}`);
  }, [selectedRow?.id, modalOpen, navigate]);

  const navigateTo = () => {
    navigate('/magazines/create');
  };

  const deleteBook = async () => {
    if (selectedRow?.id) {
      await deleteMagazine(selectedRow.id.toString());
      await getAllMagazines();
      const tempResult: Row[] = magazines.length > 0 ? magazines.map(magazineMapper) : [];
      setResult(tempResult);
      setSelectedRow(undefined);
    }
    setModalOpen(false);
  };

  const toggleDelete = (id: number) => {
    setModalOpen(true);
    setSelectedRow({ id: id });
  };

  const cancel = () => {
    setSelectedRow(undefined);
    setModalOpen(false);
  };

  return (
    <Layout title={t('magazine.title')}>
      <AddButton onClick={navigateTo} text={t('magazine.add_btn')} />
      <Table
        tableType={MAGAZINE_TABLE}
        data={result}
        setSelectedRow={(value) => setSelectedRow({ id: value?.id, value: value?.value })}
        selectedRow={selectedRow}
        onDelete={(id) => toggleDelete(id)}
      />
      <AlertModal
        open={modalOpen}
        title="magazine.delete"
        onClose={() => setModalOpen(!modalOpen)}
        leftButtonPress={cancel}
        rightButtonPress={deleteBook}
      />
    </Layout>
  );
});

export default MagazineScreen;
