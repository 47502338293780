import React, { FC } from 'react';
import { useField } from 'formik';
import { FormHelperText, TextField } from '@mui/material';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';

interface FormFieldDateProps {
  label: string;
  name: string;
  readonly?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  autoFocus?: boolean;
  maxDate?: Date;
  minDate?: Date;
}

const FormFieldDate: FC<FormFieldDateProps> = ({
  label,
  name,
  readonly,
  disableFuture,
  disablePast,
  autoFocus,
  maxDate,
  minDate,
}: FormFieldDateProps) => {
  const [field, meta, helpers] = useField(name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isValidDate = (d: any) => {
    if (Object.prototype.toString.call(d) === '[object Date]') {
      // it is a date
      if (isNaN(d)) {
        // date object is not valid
        return false;
      } else {
        // date object is valid
        return true;
      }
    } else {
      // not a date object
      return false;
    }
  };

  return (
    <>
      <DatePicker
        label={label}
        disableFuture={disableFuture}
        disablePast={disablePast}
        value={field.value ?? null}
        onChange={(newValue: Date | null) => {
          if (newValue != null && isValidDate(newValue) && newValue.getFullYear() > 1900) {
            helpers.setValue(format(newValue, 'yyyy-MM-dd'));
          }
          if (newValue == null) {
            helpers.setValue(newValue);
          }
        }}
        disabled={readonly}
        disableMaskedInput
        inputFormat="d.M.yyyy"
        autoFocus={autoFocus}
        maxDate={maxDate}
        minDate={minDate}
        openTo={'day'}
        views={['year', 'month', 'day']}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderInput={(props: any) => <TextField size="small" {...props} error={!!meta.error} />}
      />
      {meta.error && (
        <FormHelperText sx={{ pl: 2 }} error={!!meta.error}>
          {meta.error}
        </FormHelperText>
      )}
    </>
  );
};

FormFieldDate.displayName = 'FormFieldDate';

export default FormFieldDate;
