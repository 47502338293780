import React, { useEffect } from 'react';
import { Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { color } from '../theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { USERS_ITEMS, SETTINGS_ITEMS, MEDIA_ITEMS, REPORT_ITEMS } from '../utils/constants';
import { NavigationItems } from '../types';
import versionInfo from '../config/versionInfo.json';

import logo from '../static/logo_small.svg';
import UserIcon from '../static/users.svg';
import SettingsIcon from '../static/settings.svg';
import ArticlesIcon from '../static/articles2.svg';
import MediaIcon from '../static/media.svg';
import ReportIcon from '../static/reports.svg';
import { useMst } from '../model/Root';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const Sidebar = observer(() => {
  const { t } = useTranslation();
  const {
    userStore: { user, hasCommunityAccess },
    onlineMagazineStore: { onlineMagazines, getAllOnlineMagazines },
  } = useMst();
  const navigate = useNavigate();

  useEffect(() => {
    if (onlineMagazines.length == 0) {
      getAllOnlineMagazines();
    }
  }, [getAllOnlineMagazines, onlineMagazines]);

  const getDisabled = (disabled: boolean, role: string): boolean => {
    if (!user) return true;

    if (user.admin) return false; // allow all

    if (disabled) return disabled;

    switch (role) {
      case 'admin':
        return user.creator || user.publisher;
      case 'publisher':
        return user.creator;
      default:
        return false;
    }
  };

  const shouldRender = (items?: NavigationItems[]): boolean => {
    let hasAccess = false;

    items?.forEach((item) => {
      if (getDisabled(item.disabled, item.role) == false) {
        hasAccess = true;
      }
    });
    return hasAccess;
  };

  const renderSubItem = (items: NavigationItems[]) =>
    items.map((item, index) => {
      if (getDisabled(item.disabled, item.role) == false) {
        return (
          <ListItemButton
            disableGutters
            key={index}
            disabled={getDisabled(item.disabled, item.role)}
            onClick={() => navigate(item.path)}
          >
            <ListItemText inset>
              <Typography variant="body1" fontSize={18} color={'grey'}>
                {t(item.title)}
              </Typography>
            </ListItemText>
          </ListItemButton>
        );
      }
      return null;
    });

  const renderItem = (title: string, icon: any, subItems?: NavigationItems[]) => {
    if (!shouldRender(subItems)) {
      return null;
    }

    return (
      <>
        <ListItem disableGutters style={{ marginTop: 3, padding: 0 }}>
          <Grid container>
            <Grid item xs={2}>
              <ListItemIcon>
                <img src={icon} alt={t(title) ?? ''} />
              </ListItemIcon>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5">{t(title)}</Typography>
            </Grid>
            {subItems && <List>{renderSubItem(subItems)}</List>}
          </Grid>
        </ListItem>
        <Divider style={{ marginBottom: 8 }} />
      </>
    );
  };

  return (
    <Grid
      container
      style={{
        zIndex: 10,
        justifyContent: 'flex-start',
        minHeight: 'calc(100vh - 30px)',
        backgroundColor: color.grayBackground,
        overflow: 'hidden',
      }}
    >
      <Grid item>
        <Grid item pt={2} m={2} style={{ backgroundColor: 'white', borderRadius: 5 }} textAlign={'center'}>
          <Link to={'/'}>
            <img src={logo} style={{ height: 60 }} alt="" />
          </Link>
        </Grid>
        <List sx={{ marginLeft: 3 }}>
          {renderItem('users', UserIcon, USERS_ITEMS)}
          {renderItem('media', MediaIcon, MEDIA_ITEMS)}

          <ListItem disableGutters style={{ marginTop: 3, padding: 0 }}>
            <Grid container>
              <Grid item xs={2}>
                <ListItemIcon>
                  <img src={ArticlesIcon} alt={t('article.header') ?? ''} />
                </ListItemIcon>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h5">{t('article.header')}</Typography>
              </Grid>
              <List>
                {onlineMagazines?.map((magazine) => {
                  if (hasCommunityAccess(magazine.communityId)) {
                    return (
                      <ListItemButton
                        key={magazine.id}
                        disableGutters
                        onClick={async () => {
                          navigate(`/onlinemagazine/${magazine.id}/articles`);
                        }}
                      >
                        <ListItemText inset>
                          <Typography variant="body1" fontSize={18} color={'grey'}>
                            {t(magazine.name)}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    );
                  }
                  return null;
                })}
              </List>
            </Grid>
          </ListItem>
          <Divider style={{ marginBottom: 8 }} />

          {renderItem('reports.title', ReportIcon, REPORT_ITEMS)}
          {/* {renderItem('pages', PagesIcon, PAGES_ITEMS)} */}
          {renderItem('settings', SettingsIcon, SETTINGS_ITEMS)}
        </List>
        <Grid item textAlign={'center'}>
          <Typography
            variant="body2"
            color={'GrayText'}
          >{`Versio ${versionInfo.versiontag} (build: ${versionInfo.buildtag})`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Sidebar;
