import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import AddButton from '../../components/Button/AddButton';
import Table from '../../components/Table/Table';
import { Row, LinkRow } from '../../types';
import { TARGET_GROUP_TABLE } from '../../utils/constants';

const TargetGroupScreen = observer(() => {
  const { t } = useTranslation();
  const {
    targetGroupStore: { targetGroups, getTargetGroups },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow>();
  const [result, setResult] = useState<Row[]>([]);
  const navigate = useNavigate();

  const getTargetGroupTypeName = useCallback(
    (type: number) => {
      let key = 'undefined';

      switch (type) {
        case 1:
          key = 'unregistered';
          break;
        case 2:
          key = 'registered';
          break;
        case 3:
          key = 'employee';
          break;
        case 4:
          key = 'subscriber';
          break;
        case 5:
          key = 'preview';
          break;
      }

      return t(`target_group_type_${key}`);
    },
    [t]
  );

  useEffect(() => {
    const getGroups = async () => {
      await getTargetGroups();
      const tempResult: Row[] =
        targetGroups.length > 0
          ? targetGroups.map((group) => ({
              id: group.id,
              value: group.name,
              secondValue: getTargetGroupTypeName(group.type),
            }))
          : [];

      setResult(tempResult);
    };
    getGroups();
  }, [getTargetGroups, navigate, targetGroups, getTargetGroupTypeName]);

  useEffect(() => {
    getTargetGroups();
  }, [getTargetGroups]);

  useEffect(() => {
    if (!selectedRow?.id) return;
    navigate(`/targets-groups/${selectedRow.id}`);
  }, [selectedRow?.id, navigate]);

  return (
    <Layout title={t('target_groups')}>
      <AddButton onClick={() => navigate('/targets-groups/create')} text={t('add_group')} />
      <Table
        tableType={TARGET_GROUP_TABLE}
        data={result.length > 0 ? result : []}
        selectedRow={selectedRow}
        setSelectedRow={(value) => setSelectedRow({ id: value?.id, value: value?.value })}
      />
    </Layout>
  );
});

export default TargetGroupScreen;
