import { useMst } from '../model/Root';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Notification from './Notification';
import { Container, Grid, Typography } from '@mui/material';
import { color } from 'theme';

interface LayoutProps {
  title: string;
  children: any;
  fullWidth?: boolean;
}

const Layout = observer((props: LayoutProps) => {
  const {
    userStore: { token, user, userLogout },
    errorStore: { error, clearError, success },
  } = useMst();
  const { children, title, fullWidth } = props;

  const onLogout = () => {
    userLogout();
    return <Navigate to="/login" />;
  };

  // Check that user is not logged out
  if (!user || !token) {
    return <Navigate to="/login" />;
  }

  return (
    <Container maxWidth={false} style={{ padding: 0 }}>
      <Grid container>
        <Header user={user} logoutHandler={onLogout} />
        <Grid container item style={{ backgroundColor: color.grayBackground, maxWidth: 280, minWidth: 280 }}>
          <Sidebar />
        </Grid>
        <Grid container item xs={fullWidth ? 9 : 8} mt={2} alignContent={'flex-start'}>
          <Grid item xs={12} ml={4} mb={4}>
            <Typography variant="h3">{title}</Typography>
          </Grid>
          <Grid item xs={12} ml={4}>
            {children}
          </Grid>
          <Notification error={error} success={success} onClose={clearError} />
        </Grid>
      </Grid>
    </Container>
  );
});

export default Layout;
