import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useMst } from '../../model/Root';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { color } from '../../theme';
import Button from 'components/Button/Button';
import ErrorText from 'components/ErrorText';
import TextInput from 'components/FormComponents/TextInput';
import logo from '../../static/aikamedia_logo.png';

const useStyles = makeStyles()({
  container: {
    backgroundColor: color.grayBackground,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
  },
  imgContainer: {
    flexDirection: 'row',
    flex: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    backgroundColor: color.white,
    width: '100%',
    height: '110%',
  },
  formContainer: {
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: color.grayBackground,
    width: '100%',
    height: '100%',
  },
  logo: {
    marginBottom: '46px',
  },
  pwdContainer: {
    width: '50%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    alignSelf: 'flex-center',
    textTransform: 'uppercase',
    marginTop: 40,
    marginBottom: 20,
  },
  emptyFooter: {
    flexDirection: 'row',
    flex: 3,
    display: 'flex',
  },
});

const ResetPasswordScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resetToken } = useParams<{ resetToken: string }>();
  const {
    userStore: { changePasswordWithToken, sendPasswordResetLink },
  } = useMst();
  const [password, setPassword] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);

  useEffect(() => {
    // TODO validate reset token if any
  });

  const validatePassword = () => {
    if (!password || !confirmPassword) {
      setErrorText(t('password_missing'));
      return false;
    }

    if (password !== confirmPassword) {
      setErrorText(t('passwords_not_matching'));
      return false;
    }

    if (password.length < 12) {
      setErrorText(t('password_too_short'));
      return false;
    }
    const characters = /(?=.*[a-z])(?=.*[A-Z])(?=.*[\d]).*/g;

    if (!password.match(characters)) {
      setErrorText(t('password_missing_characters'));
      return false;
    }
    setErrorText(null);
    return true;
  };

  const validateEmail = () => {
    if (!email || email.length < 6) {
      setErrorText(t('email_missing'));
      return false;
    }

    setErrorText(null);
    return true;
  };

  const save = async () => {
    const isValid = validatePassword();
    if (!isValid || !password) return;
    await changePasswordWithToken(password, resetToken);
    navigate('/');
    setPassword(null);
  };

  const sendResetLink = async () => {
    const isValid = validateEmail();
    if (!isValid || !email) return;
    await sendPasswordResetLink(email);
    navigate('/');
    setEmail(null);
  };

  const onChange = (key: string, value: string) => {
    if (key === 'password') {
      setPassword(value);
    } else if (key === 'confirm_password') {
      setConfirmPassword(value);
    } else {
      setEmail(value);
    }
  };

  const renderTextField = (label: string, value: string | null, type: string, placeholder: string) => (
    <TextInput
      label={label}
      value={value}
      type={type}
      placeholder={placeholder}
      onChange={(key: string, value: string) => onChange(key, value)}
      style={{ width: '90%', backgroundColor: 'white' }}
      inputType={type === 'email' ? 'email' : 'password'}
    />
  );

  if (resetToken && resetToken.length > 0) {
    return (
      <div className={classes.container}>
        <div className={classes.imgContainer}>
          <img src={logo} alt="aikamedia" className={classes.logo} />
        </div>
        <div className={classes.formContainer}>
          <div className={classes.pwdContainer}>
            <div className={classes.title}>{t('reset_password_title')}</div>
            {renderTextField('new_password', password, 'password', 'new_password')}
            {renderTextField('new_password_again', confirmPassword, 'confirm_password', 'new_password_again')}
            <Button onClick={save} text="save_password" width={500} />
            {errorText && <ErrorText text={errorText} style={{ fontSize: 12 }} />}
          </div>
        </div>
        <div className={classes.emptyFooter} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img src={logo} alt="aikamedia" className={classes.logo} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.pwdContainer}>
          <div className={classes.title}>{t('forgotten_password_title')}</div>
          {renderTextField('email', email, 'email', 'email')}
          <Button onClick={sendResetLink} text="send_reset_link" width={500} />
          {errorText && <ErrorText text={errorText} style={{ fontSize: 12 }} />}
        </div>
      </div>
      <div className={classes.emptyFooter} />
    </div>
  );
});

export default ResetPasswordScreen;
