import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { Album, MediaTypes, Song } from '../../types';
import { color } from '../../theme';
import TextInput from '../../components/FormComponents/TextInput';
import Button from '../../components/Button/Button';
import { AlbumDefaults } from '../../utils/defaults';
import AddNewButton from '../../components/Button/AddButton';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '../../static/edit.svg';
import DeleteIcon from '../../static/delete.svg';
import DownIcon from '../../static/down_icon.svg';
import UpIcon from '../../static/up_icon.svg';
import SaveIcon from '../../static/save.svg';
import UploadButton from '../../components/Button/UploadButton';
import ImageUploadButton from '../../components/Button/ImageUploadButton';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/FormComponents/FormFieldText';
import FormFieldDate from 'components/FormComponents/FormFieldDate';
import FormFieldNumber from 'components/FormComponents/FormFieldNumber';
import FormFieldTextArea from 'components/FormComponents/FormFieldTextArea';
import FormFieldCheckboxList from 'components/FormComponents/FormFieldCheckboxList';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile, toBlobURL } from '@ffmpeg/util';

const getValidationSchema = (t: (key: string) => string, published: boolean) => {
  if (!published) {
    const draftSchema = Yup.object().shape({
      name: Yup.string().required(t('common_required')),
      mediaTypeId: Yup.number().required(t('common_required')),
    });

    return draftSchema;
  }

  const albumSchema = Yup.object().shape({
    name: Yup.string().required(t('common_required')),
    artist: Yup.string().required(t('common_required')),
    publisher: Yup.string().required(t('common_required')),
    image: Yup.string().required(t('common_required')),
    releaseDate: Yup.string().required(t('common_required')),
    releaseYear: Yup.number().required(t('common_required')),
    language: Yup.string().required(t('common_required')),
    acdCode: Yup.string().required(t('common_required')),
    mediaTypeId: Yup.number().required(t('common_required')),
    categoryIds: Yup.array().min(1, t('common_required')),
    targetGroupIds: Yup.array().min(1, t('common_required')),
  });

  return albumSchema;
};

const AlbumEditScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    categoryStore: { categories, getCategories },
    targetGroupStore: { targetGroups, getTargetGroups },
    albumStore: { selectedAlbum, getSelectedAlbum, updateAlbum, createAlbum, loading },
  } = useMst();
  const { id } = useParams<{ id: string }>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [currentSongId, setCurrentSongId] = useState(0);
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [album, setAlbum] = useState<Album>(AlbumDefaults);
  const [songs, setSongs] = useState<Song[]>([]);
  const [songName, setSongName] = useState('');
  const [songAuthor, setSongAuthor] = useState('');
  const [uploadDialogVisible, setUploadDialogVisible] = useState<boolean>(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const ffmpegRef = useRef(new FFmpeg());
  const messageRef = useRef<HTMLDivElement>(null);
  const counterRef = useRef<HTMLDivElement>(null);

  const load = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.1/dist/umd';
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('progress', ({ progress }) => {
      if (messageRef.current) {
        messageRef.current.innerHTML = `${(progress * 100).toFixed(2)} %`;
      }
    });
    // toBlobURL is used to bypass CORS issue, urls with the same domain can be used directly.
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
      workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
      //thread: true,
    });
  };

  useEffect(() => {
    const fetchLists = async () => {
      await getCategories(MediaTypes.Music);
      await getTargetGroups();
    };

    fetchLists();
  }, [getCategories, getTargetGroups]);

  useEffect(() => {
    const fetchPage = async () => {
      if (id !== 'create') {
        await getSelectedAlbum(id);
        if (selectedAlbum?.image) {
          setImagePreviewUrl(selectedAlbum?.image);
        }
        setAlbum(selectedAlbum);
        const songs = selectedAlbum?.songs.map((s) => ({ ...s, state: '' })) ?? [];
        setSongs(songs);
      }
    };

    fetchPage();
    load(); // load ffmpeg library
  }, [getSelectedAlbum, selectedAlbum, id]);

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target?.files) {
      const image = URL.createObjectURL(target.files[0]);
      setSelectedImage(target.files[0]);
      setImagePreviewUrl(image);
    }
  };

  const handleSongNameChange = (key: string, value: string) => {
    setSongName(value);
  };

  const handleSongAuthorChange = (key: string, value: string) => {
    setSongAuthor(value);
  };

  const handleFilePick = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files);
      setCurrentFiles(fileArray);

      if (e.target.files.length == 1) {
        if (songName == '') {
          const filenameWithoutExt = e.target.files[0].name.split('.').slice(0, -1).join('.');
          setSongName(filenameWithoutExt);
        }
      } else {
        for (let index = 0; index < e.target.files.length; index++) {
          const file = e.target.files[index];
          const filenameWithoutExt = file.name.split('.').slice(0, -1).join('.');
          songs.push({
            name: filenameWithoutExt,
            file: file,
            order: songs.length == 0 ? 0 : songs[songs.length - 1].order + 1,
            src: file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            author: songAuthor,
            state: 'new',
          });
          files.push(file);
        }

        setCurrentFiles([]);
        setSongName('');
        setSongAuthor('');
        setCurrentSongId(0);
        setUploadDialogVisible(false);
      }
    }
  };

  const handleFileWavPick = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileCount: number = e.target.files.length;

      for (let index = 0; index < e.target.files.length; index++) {
        if (counterRef.current) {
          counterRef.current.innerHTML = `${index + 1}/${fileCount}`;
        }

        const file = e.target.files[index];
        const filenameWithoutExt = file.name.split('.').slice(0, -1).join('.');
        const filenameStripped = filenameWithoutExt.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const filenameWav = filenameStripped + '.wav';
        const filenameMp3 = filenameStripped + '.mp3';

        const ffmpeg = ffmpegRef.current;
        await ffmpeg.writeFile(filenameWav, await fetchFile(file));
        await ffmpeg.exec(['-i', filenameWav, filenameMp3]);
        const data: any = await ffmpeg.readFile(filenameMp3);
        const newFile = new File([data.buffer], filenameMp3, { type: 'audio/mpeg' });

        songs.push({
          name: filenameWithoutExt,
          file: newFile,
          order: songs.length == 0 ? 0 : songs[songs.length - 1].order + 1,
          src: filenameMp3,
          author: songAuthor,
          state: 'new',
        });

        files.push(newFile);
      }

      setCurrentFiles([]);
      setSongName('');
      setSongAuthor('');
      setCurrentSongId(0);
      setUploadDialogVisible(false);
    }
  };

  const handleSongSave = () => {
    if (currentSongId == 0) return;

    if (currentFiles.length == 1) {
      // new chapter
      songs.push({
        name: songName,
        file: currentFiles[0],
        order: songs.length == 0 ? 0 : songs[songs.length - 1].order + 1,
        src: currentFiles[0].name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        author: songAuthor,
        state: 'new',
      });
      setFiles([...files, currentFiles[0]]);
    } else {
      const index = songs.findIndex((chapter) => chapter.id === currentSongId);
      if (index > -1) {
        songs[index].name = songName;
        songs[index].state = 'edited';
        songs[index].author = songAuthor;
      }
    }
    setCurrentFiles([]);
    setSongName('');
    setSongAuthor('');
    setCurrentSongId(0);
    setUploadDialogVisible(false);
  };

  const handleSongEdit = (song: Song) => {
    setUploadDialogVisible(true);
    setCurrentSongId(song.id ?? 0);

    const index = getSongIndex(song);
    setSongName(songs[index].name);
    setSongAuthor(songs[index].author);
  };

  const handleDeleteFile = (song: Song) => {
    const index = getSongIndex(song);

    if (index > -1) {
      if ((songs[index].id ?? 0) > 0) {
        // already saved to db, flag for delete
        songs[index].state = 'deleted';
      } else {
        // just remove item from array, not stored to db
        songs.splice(index, 1);
      }

      setRefreshPage(!refreshPage);
    }
  };

  const getSongIndex = (song: Song): number => {
    const index = songs.findIndex((s) => s.id == song.id && s.name == song.name && s.order == song.order);
    return index;
  };

  const changeFileOrder = (song: Song, direction: string) => {
    // comparison made with all values, in order find existing and new songs
    // TODO deleted ones are not correctly handled
    const index = getSongIndex(song);

    if (index > -1) {
      if (direction === 'up') {
        if (songs[index].order < 1) return;

        songs[index].order = songs[index].order - 1;
        songs[index - 1].order = songs[index - 1].order + 1;
      }

      if (direction === 'down') {
        if (songs[index].order > songs.length) return;

        songs[index].order = songs[index].order + 1;
        songs[index + 1].order = songs[index + 1].order - 1;
      }
    }
    // Sort songs
    songs.sort((a, b) => a.order - b.order);

    setRefreshPage(!refreshPage);
  };

  const saveAlbum = async (album: Album) => {
    if (id !== 'create') {
      await updateAlbum(album, songs, files, selectedImage);
    } else {
      await createAlbum(album, songs, files, selectedImage);
    }
    navigate('/albums');
  };

  const renderSongUpload = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {t('albums_songs')}
          </Typography>
        </Grid>
        {songs.length == 0 && (
          <Grid item xs={9}>
            <Typography variant="body2" color="GrayText">
              {t('albums_no_songs')}
            </Typography>
          </Grid>
        )}

        {songs
          .filter((c) => c.state != 'deleted')
          .map((song: Song, index: number) => {
            return (
              <Grid
                container
                key={`${song.id}_${index}`}
                style={{ borderBottom: '1px solid lightGray', marginLeft: 24 }}
              >
                <Grid item xs={9} alignSelf={'center'}>
                  <Grid item xs={12} alignSelf={'center'}>
                    <Typography variant="body1">{song.name}</Typography>
                  </Grid>
                  <Grid item xs={12} alignSelf={'center'}>
                    <Typography variant="body2" fontSize={11}>
                      {song.author}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleSongEdit(song)}>
                    <img src={EditIcon} alt="Edit" />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteFile(song)}>
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Grid item xs={12}>
                    <span className="parent-span-up">
                      <IconButton
                        disabled={index === 0}
                        hidden={index === 0}
                        onClick={() => changeFileOrder(song, 'up')}
                        style={{
                          borderRadius: 0,
                          margin: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <img src={UpIcon} alt="Up" />
                      </IconButton>
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <span className="parent-span-down">
                      <IconButton
                        disabled={index === songs.length - 1}
                        hidden={index === songs.length - 1}
                        onClick={() => changeFileOrder(song, 'down')}
                        style={{
                          borderRadius: 0,
                          margin: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <img src={DownIcon} alt="Down" />
                      </IconButton>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <AddNewButton
            text={t('albums_add_song')}
            onClick={() => {
              setCurrentSongId(-1);
              setSongName('');
              setSongAuthor('');
              setUploadDialogVisible(!uploadDialogVisible);
            }}
          />
        </Grid>
        {uploadDialogVisible && (
          <Grid
            container
            item
            xs={10}
            spacing={1}
            style={{ border: 'solid 1px grey', borderRadius: 3, padding: 10, margin: 10 }}
          >
            {currentFiles.length <= 1 && (
              <>
                <Grid item xs={12}>
                  <TextInput
                    label={'albums_song_name'}
                    value={songName}
                    type={'name'}
                    placeholder={'albums_song_name'}
                    onChange={handleSongNameChange}
                    style={{ width: '80%' }}
                    multiline={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={'albums_song_author'}
                    value={songAuthor}
                    type={'author'}
                    placeholder={'albums_song_author'}
                    onChange={handleSongAuthorChange}
                    style={{ width: '80%' }}
                    multiline={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p ref={counterRef}></p>
                  <p ref={messageRef}></p>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {currentSongId == -1 ? (
                <>
                  <Grid item>
                    <UploadButton
                      buttonText="albums_upload_song"
                      onChange={handleFilePick}
                      fileName={
                        currentFiles?.length === 1 ? currentFiles[0].name : currentFiles.length > 0 ? 'Useita' : ''
                      }
                      fileType=".mp3"
                    />
                  </Grid>
                  <Grid item>
                    <UploadButton
                      buttonText="albums_upload_song_wav"
                      onChange={handleFileWavPick}
                      fileName={
                        currentFiles?.length === 1 ? currentFiles[0].name : currentFiles.length > 0 ? 'Useita' : ''
                      }
                      fileType=".wav"
                    />
                  </Grid>
                </>
              ) : (
                <Typography variant="caption" color="GrayText">
                  {t('albums_song_edit_limited')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <IconButton onClick={handleSongSave}>
                <img src={SaveIcon} alt="Save" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderHeader = () => {
    const modeText = id !== 'create' ? t('albums_edit') : t('albums_add');
    return loading ? t('albums_loading') : modeText;
  };

  if (loading) {
    return (
      <Layout title={renderHeader()}>
        <CircularProgress size={40} style={{ alignSelf: 'center', marginTop: 30, color: color.primaryButton }} />
        <Typography variant="h5">{t('albums_loading_text')}</Typography>
      </Layout>
    );
  }

  return (
    <Formik
      initialValues={album}
      enableReinitialize
      onSubmit={(values: Album) => {
        saveAlbum(values);
      }}
      validationSchema={Yup.lazy((values: Album) => getValidationSchema(t, values.published))}
    >
      {({ isValid, submitForm, setFieldValue }: FormikProps<Album>) => {
        return (
          <Layout title={renderHeader()}>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <Typography
                  variant="h4"
                  color={album?.published ? 'primary' : 'secondary'}
                  borderColor={album?.published ? 'primary' : 'secondary'}
                  border={1}
                  borderRadius={2}
                  sx={{ padding: 1, fontSize: 18, width: 200, marginBottom: 2 }}
                >
                  {album?.published ? t('published') : t('draft')}
                </Typography>
              </Grid>

              {/* Add/modify name, description, number and release date */}
              <Grid item xs={10}>
                <FormFieldText label={t('albums_name')} name="name" />
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={4}>
                  <FormFieldText label={t('albums_artist')} name="artist" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldText label={t('albums_publisher')} name="publisher" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldDate label={t('albums_release_date')} name="releaseDate" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={4}>
                  <FormFieldText label={t('albums_acd_code')} name="acdCode" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldText label={t('albums_language')} name="language" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldNumber label={t('albums_release_year')} name="releaseYear" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={12}>
                  <FormFieldTextArea label={t('albums_description')} name="description" rows={7} />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldText label={t('albums_shop_url')} name="shopUrl" />
                </Grid>
              </Grid>

              {/* Upload cover image */}
              <Grid item xs={10}>
                <ImageUploadButton
                  text="albums_cover_image"
                  imagePreviewUrl={imagePreviewUrl}
                  handleImageSelection={handleImageSelection}
                />
              </Grid>

              {/* Upload album songs (MP3) */}
              <Grid container item xs={10} spacing={3} ml={3}>
                {renderSongUpload()}
              </Grid>

              {/* Select categories, user groups */}
              <Grid container item xs={12} spacing={3} mt={2}>
                <Grid container item xs={6} spacing={3} alignContent={'flex-start'}>
                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('categories')} name="categoryIds" listItems={categories} />
                  </Grid>

                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('target_groups')} name="targetGroupIds" listItems={targetGroups} />
                  </Grid>

                  {/* publish or save as draft */}
                  <Grid container item xs={10} spacing={3}>
                    <Button
                      text="save_draft"
                      onClick={() => {
                        setFieldValue('published', false);
                        submitForm();
                      }}
                      backgroundColor={color.secondaryButton}
                      width={220}
                      marginBottom={30}
                    />
                    <Button
                      text="publish"
                      onClick={() => {
                        setFieldValue('published', true);
                        submitForm();
                      }}
                      marginBottom={30}
                      disabled={!isValid}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Layout>
        );
      }}
    </Formik>
  );
});

export default AlbumEditScreen;
