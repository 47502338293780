import { flow, types } from 'mobx-state-tree';
import { fetchCategories, createNewCategory, categoryUpdate, categoryDelete } from '../services/api';
import { composeError } from '../utils/transforms';
import { withRootStore } from './withRootStore';
import { Category, MediaTypes } from '../types';

export const CategoryStateObject = types.model('CategoryStateObject', {
  id: types.optional(types.number, -1),
  name: types.optional(types.string, ''),
  value: types.optional(types.string, ''),
  image: types.optional(types.string, ''),
  communityId: types.maybeNull(types.number),
});

export const CategoryStore = types
  .model({
    categories: types.optional(types.array(CategoryStateObject), []),
    error: types.frozen(),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getCategories = flow(function* (mediatype: MediaTypes) {
      try {
        const response = yield fetchCategories(mediatype);
        self.categories = response.data.map((cat: Category) => ({
          id: cat.id,
          name: cat.name,
          image: cat.image ?? '',
          mediaType: cat.mediaTypeId,
          value: cat.name,
          communityId: cat.community?.id,
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.error = error;
        console.error(err);
        console.error(error);
      }
    });

    const createCategory = flow(function* (category: Category) {
      try {
        yield createNewCategory(category);
        self.rootStore.errorStore.setSuccess('save_success');
        getCategories(category.mediaTypeId);
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.error = error;
        console.error(err);
        console.error(error);
      }
    });

    const updateCategory = flow(function* (id: number, category: Category) {
      try {
        yield categoryUpdate(id, category);
        self.rootStore.errorStore.setSuccess('save_success');
        getCategories(category.mediaTypeId);
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.error = error;
        console.error(err);
        console.error(error);
      }
    });

    const deleteCategory = flow(function* (id: number, mediatype: MediaTypes) {
      try {
        yield categoryDelete(id);
        self.rootStore.errorStore.setSuccess('delete_success');
        getCategories(mediatype);
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.error = error;
        console.error(err);
        console.error(error);
      }
    });

    return {
      getCategories,
      createCategory,
      updateCategory,
      deleteCategory,
    };
  });
