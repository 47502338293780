import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { Program, Video } from '../../types';
import { color } from '../../theme';
import TextInput from '../../components/FormComponents/TextInput';
import Button from '../../components/Button/Button';
import { ProgramDefaults } from '../../utils/defaults';
import AddNewButton from '../../components/Button/AddButton';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import EditIcon from '../../static/edit.svg';
import DeleteIcon from '../../static/delete.svg';
import SaveIcon from '../../static/save.svg';
import UploadButton from '../../components/Button/UploadButton';
import ImageUploadButton from '../../components/Button/ImageUploadButton';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/FormComponents/FormFieldText';
import FormFieldTextArea from 'components/FormComponents/FormFieldTextArea';
import FormFieldCheckboxList from 'components/FormComponents/FormFieldCheckboxList';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import FormFieldNumber from 'components/FormComponents/FormFieldNumber';

const getValidationSchema = (t: (key: string) => string, values: Program, image: File | undefined) => {
  if (!values.published) {
    const draftSchema = Yup.object().shape({
      name: Yup.string().required(t('common_required')),
      mediaTypeId: Yup.number().required(t('common_required')),
    });

    return draftSchema;
  }

  const programSchema = Yup.object().shape({
    name: Yup.string().required(t('common_required')),
    image: Yup.string().test(
      'image-test',
      t('common_required'),
      (value) => values.published || (!!value && value?.length > 0) || !!image
    ),
    //      (value) => values.reason !== 99 || (!!value && value?.length > 0)
    publisher: Yup.string().required(t('common_required')),
    releaseDate: Yup.string().required(t('common_required')),
    language: Yup.string().required(t('common_required')),
    mediaTypeId: Yup.number().required(t('common_required')),
    targetGroupIds: Yup.array().min(1, t('common_required')),
  });

  return programSchema;
};

const ProgramEditScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    targetGroupStore: { targetGroups, getTargetGroups },
    programStore: { selectedProgram, getSelectedProgram, updateProgram, createProgram, loading },
  } = useMst();
  const { id } = useParams<{ id: string }>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [program, setProgram] = useState<Program>(ProgramDefaults);
  const [videos, setVideos] = useState<Video[]>([]);
  const [videoName, setVideoName] = useState('');
  const [videoNotes, setVideoNotes] = useState('');
  const [videoSeason, setVideoSeason] = useState(1);
  const [videoImage, setVideoImage] = useState<File>();
  const [videoImagePreviewUrl, setVideoImagePreviewUrl] = useState<string | null>();
  const [videoReleaseDate, setVideoReleaseDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [uploadDialogVisible, setUploadDialogVisible] = useState<boolean>(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const messageRef = useRef<HTMLParagraphElement>(null);
  const counterRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const fetchLists = async () => {
      await getTargetGroups();
    };

    fetchLists();
  }, [getTargetGroups]);

  useEffect(() => {
    const fetchPage = async () => {
      if (id !== 'create') {
        await getSelectedProgram(id);
        if (selectedProgram?.image) {
          setImagePreviewUrl(selectedProgram?.image);
        }
        setProgram(selectedProgram);
        const videos = selectedProgram?.videos.map((s) => ({ ...s, state: '' })) ?? [];
        setVideos(videos);
      }
    };

    fetchPage();
  }, [getSelectedProgram, selectedProgram, id]);

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files) {
      const image = URL.createObjectURL(target.files[0]);
      setSelectedImage(target.files[0]);
      setImagePreviewUrl(image);
    }
  };

  const handleVideoImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files) {
      const image = URL.createObjectURL(target.files[0]);
      setVideoImage(target.files[0]);
      setVideoImagePreviewUrl(image);
    }
  };

  const handleVideoNameChange = (key: string, value: string) => {
    setVideoName(value);
  };

  const handleVideoNotesChange = (key: string, value: string) => {
    setVideoNotes(value);
  };

  const handleFilePick = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files);
      setCurrentFiles(fileArray);

      if (e.target.files.length == 1) {
        if (videoName == '') {
          const filenameWithoutExt = e.target.files[0].name.split('.').slice(0, -1).join('.');
          setVideoName(filenameWithoutExt);
        }
      } else {
        for (const file of e.target.files) {
          const filenameWithoutExt = file.name.split('.').slice(0, -1).join('.');
          videos.push({
            name: filenameWithoutExt,
            file: file,
            season: videoSeason,
            order: videos.length == 0 ? 0 : videos[videos.length - 1].order + 1,
            src: file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            notes: videoNotes,
            releaseDate: videoReleaseDate,
            state: 'new',
          });
          files.push(file);
        }

        setUploadDialogVisible(false);
      }
    }
  };

  const handleVideoSave = () => {
    if (currentVideoId == 0) return;

    if (currentFiles.length == 1) {
      // new chapter
      videos.push({
        name: videoName,
        file: currentFiles[0],
        image: videoImage?.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        season: videoSeason,
        order: videos.length == 0 ? 0 : videos[videos.length - 1].order + 1,
        src: currentFiles[0].name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        notes: videoNotes,
        releaseDate: videoReleaseDate,
        state: 'new',
      });
      // add files, but skip undefined if one
      setFiles([...files, currentFiles[0], videoImage].filter((item): item is File => !!item));
    } else {
      const index = videos.findIndex((chapter) => chapter.id === currentVideoId);
      if (index > -1) {
        videos[index].name = videoName;
        videos[index].state = 'edited';
        videos[index].notes = videoNotes;
        videos[index].season = videoSeason;
        videos[index].releaseDate = videoReleaseDate;
      }
    }
    setCurrentFiles([]);
    setVideoName('');
    setVideoNotes('');
    setVideoSeason(getLatestVideoSeason());
    setVideoImage(undefined);
    setVideoReleaseDate(format(new Date(), 'yyyy-MM-dd'));
    setCurrentVideoId(0);
    setUploadDialogVisible(false);
  };

  const handleVideoEdit = (video: Video) => {
    setUploadDialogVisible(true);
    setCurrentVideoId(video.id ?? 0);

    const index = getVideoIndex(video);
    setVideoName(videos[index].name);
    setVideoNotes(videos[index].notes);
    setVideoImagePreviewUrl(videos[index].image);
    setVideoSeason(videos[index].season);
    setVideoReleaseDate(videos[index].releaseDate ?? format(new Date(), 'yyyy-MM-dd'));
  };

  const handleDeleteFile = (video: Video) => {
    const index = getVideoIndex(video);

    if (index > -1) {
      if ((videos[index].id ?? 0) > 0) {
        // already saved to db, flag for delete
        videos[index].state = 'deleted';
      } else {
        // just remove item from array, not stored to db
        videos.splice(index, 1);
      }

      setRefreshPage(!refreshPage);
    }
  };

  const getVideoIndex = (video: Video): number => {
    const index = videos.findIndex((s) => s.id == video.id && s.name == video.name && s.order == video.order);
    return index;
  };

  const getVideoSeasons = (): number[] => {
    const max = getLatestVideoSeason();
    return Array.from({ length: max }, (x, i) => i + 1);
  };

  const getLatestVideoSeason = (): number => {
    const max = videos.map((s) => s.season).reduce((a, b) => Math.max(a, b), -Infinity);
    return max;
  };

  const saveProgram = async (program: Program) => {
    if (id !== 'create') {
      await updateProgram(program, videos, files, selectedImage);
    } else {
      await createProgram(program, videos, files, selectedImage);
    }
    navigate('/programs');
  };

  const renderVideoUpload = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {t('programs.videos')}
          </Typography>
        </Grid>
        {videos.length == 0 && (
          <Grid item xs={9}>
            <Typography variant="body2" color="GrayText">
              {t('programs.no_videos')}
            </Typography>
          </Grid>
        )}

        <Grid container style={{ marginLeft: 24 }}>
          <Grid container item xs={10} alignSelf={'center'}>
            <Grid item xs={2} alignSelf={'center'}>
              <Typography variant="body1" fontWeight={'bold'}>
                {t('programs.release_date')}
              </Typography>
            </Grid>
            <Grid item xs={2} alignSelf={'center'}>
              <Typography variant="body1" fontWeight={'bold'}>
                {t('programs.season')}
              </Typography>
            </Grid>
            <Grid item xs={8} alignSelf={'center'}>
              <Typography variant="body1" fontWeight={'bold'}>
                {t('common.name')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={2}></Grid>
        </Grid>

        {videos
          .filter((c) => c.state != 'deleted')
          .sort((a, b) => {
            if (!a.releaseDate || !b.releaseDate) return 0;
            if (a.releaseDate > b.releaseDate) return 1;
            if (a.releaseDate < b.releaseDate) return -1;
            return 0;
          })
          .map((video: Video, index: number) => {
            return (
              <Grid
                container
                key={`${video.id}_${index}`}
                style={{ borderBottom: '1px solid lightGray', marginLeft: 24 }}
              >
                <Grid container item xs={10} alignSelf={'center'}>
                  <Grid item xs={2} alignSelf={'center'}>
                    <Typography variant="body1">
                      {video.releaseDate ? format(new Date(video.releaseDate), 'dd.MM.yyyy') : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} alignSelf={'center'}>
                    <Typography variant="body1">{video.season}</Typography>
                  </Grid>
                  <Grid item xs={8} alignSelf={'center'}>
                    <Typography variant="body1">{video.name}</Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={2} justifyContent={'right'}>
                  <IconButton onClick={() => handleVideoEdit(video)}>
                    <img src={EditIcon} alt="Edit" />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteFile(video)}>
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <AddNewButton
            text={t('programs.add_video')}
            onClick={() => {
              setCurrentVideoId(-1);
              setVideoName('');
              setVideoNotes('');
              setVideoSeason(getLatestVideoSeason());
              setVideoImage(undefined);
              setVideoReleaseDate(format(new Date(), 'yyyy-MM-dd'));
              setUploadDialogVisible(!uploadDialogVisible);
            }}
          />
        </Grid>
        {uploadDialogVisible && (
          <Grid
            container
            item
            xs={10}
            spacing={1}
            style={{ border: 'solid 1px grey', borderRadius: 3, padding: 10, margin: 10 }}
          >
            {currentFiles.length <= 1 && (
              <>
                <Grid item xs={12}>
                  <TextInput
                    label={'programs.video_name'}
                    value={videoName}
                    type={'name'}
                    placeholder={'programs.video_name'}
                    onChange={handleVideoNameChange}
                    style={{ width: '80%' }}
                    multiline={false}
                  />
                </Grid>
                <Grid item xs={5} mt={2}>
                  <DatePicker
                    label={t('programs.video_release_date')}
                    value={new Date(videoReleaseDate)}
                    onChange={(newValue: Date | null) => {
                      if (newValue != null && newValue.getFullYear() > 1900) {
                        setVideoReleaseDate(format(newValue, 'yyyy-MM-dd'));
                      }
                      if (newValue == null) {
                        setVideoReleaseDate(format(new Date(), 'yyyy-MM-dd'));
                      }
                    }}
                    disableMaskedInput
                    inputFormat="d.M.yyyy"
                    openTo={'day'}
                    views={['year', 'month', 'day']}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    renderInput={(props: any) => <TextField size="small" {...props} />}
                  />
                </Grid>
                <Grid item xs={4} mt={2}>
                  <FormControl size="small" variant="outlined" fullWidth>
                    <InputLabel variant="outlined">{t('programs.video_season')}</InputLabel>
                    <Select
                      style={{ minWidth: 150 }}
                      variant="outlined"
                      onChange={(event) => {
                        setVideoSeason(parseInt(event.target.value as string));
                      }}
                      value={videoSeason}
                      name="season"
                      label={t('programs.video_season')}
                      displayEmpty
                    >
                      {getVideoSeasons()
                        .filter((item) => item != null)
                        .map((item, index) => (
                          <MenuItem key={index + '_' + item} value={item}>
                            {`${t('programs.season')} ${item}`}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Typography fontSize={14} fontWeight={'bold'} textTransform={'uppercase'} mb={1}>
                    {t('programs.video_image')}
                  </Typography>
                  <ImageUploadButton
                    text={t('programs.upload_video_image')}
                    name="programs.upload_video_image"
                    handleImageSelection={handleVideoImageSelection}
                    imagePreviewUrl={videoImagePreviewUrl}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label={'programs.video_notes'}
                    value={videoNotes}
                    type={'notes'}
                    placeholder={'programs.video_notes'}
                    onChange={handleVideoNotesChange}
                    style={{ width: '80%' }}
                    multiline={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p ref={counterRef}></p>
                  <p ref={messageRef}></p>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {currentVideoId == -1 ? (
                <Grid item>
                  <UploadButton
                    buttonText="programs.upload_video"
                    onChange={handleFilePick}
                    fileName={
                      currentFiles?.length === 1 ? currentFiles[0].name : currentFiles.length > 0 ? 'Useita' : ''
                    }
                    fileType=".mp4"
                  />
                </Grid>
              ) : (
                <Typography variant="caption" color="GrayText">
                  {t('programs.video_edit_limited')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <IconButton onClick={handleVideoSave}>
                <img src={SaveIcon} alt="Save" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderHeader = () => {
    const modeText = id !== 'create' ? t('programs.edit') : t('programs.add');
    return loading ? t('programs.loading') : modeText;
  };

  if (loading) {
    return (
      <Layout title={renderHeader()}>
        <CircularProgress size={40} style={{ alignSelf: 'center', marginTop: 30, color: color.primaryButton }} />
        <Typography variant="h5">{t('programs.loading_text')}</Typography>
      </Layout>
    );
  }

  return (
    <Formik
      initialValues={program}
      enableReinitialize
      onSubmit={(values: Program) => {
        saveProgram(values);
      }}
      validationSchema={Yup.lazy((values: Program) => getValidationSchema(t, values, selectedImage))}
    >
      {({ isValid, submitForm, setFieldValue }: FormikProps<Program>) => {
        return (
          <Layout title={renderHeader()}>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <Typography
                  variant="h4"
                  color={program?.published ? 'primary' : 'secondary'}
                  borderColor={program?.published ? 'primary' : 'secondary'}
                  border={1}
                  borderRadius={2}
                  sx={{ padding: 1, fontSize: 18, width: 200, marginBottom: 2 }}
                >
                  {program?.published ? t('published') : t('draft')}
                </Typography>
              </Grid>

              {/* Add/modify name, description, number and release date */}
              <Grid item xs={10}>
                <FormFieldText label={t('programs.name')} name="name" />
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={4}>
                  <FormFieldText label={t('programs.author')} name="author" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldText label={t('programs.publisher')} name="publisher" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={4}>
                  <FormFieldText label={t('programs.language')} name="language" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldNumber label={t('programs.order')} name="order" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={12}>
                  <FormFieldTextArea label={t('programs.description')} name="description" rows={7} />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldText label={t('programs.web_url')} name="webUrl" />
                </Grid>
              </Grid>

              {/* Upload cover image */}
              <Grid item xs={10}>
                <ImageUploadButton
                  text="programs.cover_image"
                  imagePreviewUrl={imagePreviewUrl}
                  handleImageSelection={handleImageSelection}
                />
              </Grid>

              {/* Upload program videos (MP3) */}
              <Grid container item xs={10} spacing={3} ml={3}>
                {renderVideoUpload()}
              </Grid>
              {/* Select categories, user groups */}
              <Grid container item xs={12} spacing={3} mt={2}>
                <Grid container item xs={6} spacing={3} alignContent={'flex-start'}>
                  {/* <FormFieldCategories categories={categories} name="categoryIds" mediaType={MediaTypes.Program} /> */}

                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('target_groups')} name="targetGroupIds" listItems={targetGroups} />
                  </Grid>

                  {/* publish or save as draft */}
                  <Grid container item xs={10} spacing={3}>
                    <Button
                      text="save_draft"
                      onClick={() => {
                        setFieldValue('published', false);
                        submitForm();
                      }}
                      backgroundColor={color.secondaryButton}
                      width={220}
                      marginBottom={30}
                    />
                    <Button
                      text="publish"
                      onClick={() => {
                        setFieldValue('published', true);
                        submitForm();
                      }}
                      marginBottom={30}
                      disabled={!isValid}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={6} spacing={3}></Grid>
              </Grid>
            </Grid>
          </Layout>
        );
      }}
    </Formik>
  );
});

export default ProgramEditScreen;
