import React, { useState, useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import Table from '../../components/Table/Table';
import AddButton from '../../components/Button/AddButton';
import { useTranslation } from 'react-i18next';
import { USER_TABLE } from '../../utils/constants';
import { Row, LinkRow } from '../../types';
import { getUserRoleByLocation, getUserTitleByLocation, getUserUrlByLocation } from 'utils/userUtils';
import { Grid, TextField, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const UsersScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = window.location;
  const {
    userStore: { users, getUsersByRole },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow>();
  const [filter, setFilter] = useState('');

  useEffect(() => {
    getUsersByRole(getUserRoleByLocation(pathname));
  }, [getUsersByRole, pathname]);

  const navigateTo = () => {
    const baseUrl = getUserUrlByLocation(pathname);
    navigate(`${baseUrl}/create`);
  };

  return (
    <Layout title={t(getUserTitleByLocation(pathname))}>
      <Grid container>
        <Grid item md={6}>
          <AddButton onClick={navigateTo} text={t('add_new_user')} />
        </Grid>
        <Grid item md={6}>
          <TextField
            placeholder={'Haku'}
            value={filter}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFilter(event.target.value);
            }}
            size="small"
          />
          {filter && (
            <IconButton onClick={() => setFilter('')}>
              <ClearIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Table
        tableType={USER_TABLE}
        data={
          users.length > 0
            ? users.filter(
                (user) =>
                  user.firstname?.toLowerCase().includes(filter.toLowerCase()) ||
                  user.lastname?.toLowerCase().includes(filter.toLowerCase()) ||
                  user.email?.toLowerCase().includes(filter.toLowerCase())
              )
            : []
        }
        selectedRow={selectedRow}
        setSelectedRow={(value) => setSelectedRow({ id: value?.id, value: value?.value })}
        isUserTable
        userRole={getUserRoleByLocation(pathname)}
      />
    </Layout>
  );
});

export default UsersScreen;
