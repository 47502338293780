import React, { useState, useEffect, useCallback } from 'react';
import { FormControlLabel, Grid, Button, Typography, Checkbox, Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import { color } from '../../theme';
import { useTranslation } from 'react-i18next';
import { User } from '../../types';
import { UserDefaults } from '../../utils/defaults';
import { UserRole } from '../../utils/constants';
import { Form, Formik, FormikProps } from 'formik';
import FormFieldText from 'components/FormComponents/FormFieldText';
import * as Yup from 'yup';
import FormFieldCheckbox from 'components/FormComponents/FormFieldCheckbox';
import FormFieldCheckboxList from 'components/FormComponents/FormFieldCheckboxList';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import UserSubscriptions from './UserSubscriptions';
import UserStripeSubscriptions from './UserStripeSubscriptions';

const getValidationSchema = (t: (key: string) => string) => {
  return Yup.object().shape({
    firstname: Yup.string().required(t('common_required')),
    lastname: Yup.string().required(t('common_required')),
    email: Yup.string().required(t('common_required')),
  });
};

const UsersEditScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const { pathname } = window.location;
  const isEmployee = pathname.includes('emp');
  const isPortal = pathname.includes('portal');
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>(UserDefaults);
  const {
    userStore: {
      singleUser,
      communities,
      getUserById,
      updateUser,
      createUser,
      deleteUser,
      getCommunities,
      stripeSubscriptions,
      getStripeSubscriptions,
      products,
      getProducts,
    },
  } = useMst();

  const getUser = useCallback(
    async (id?: string) => {
      if (!id) return;

      setIsEdit(true);
      await getUserById(id);
      await getStripeSubscriptions(id);
      setSelectedUser(singleUser);
    },
    [getUserById, getStripeSubscriptions, singleUser]
  );

  useEffect(() => {
    if (userId !== 'create') {
      getUser(userId);
    }
  }, [userId, getUser]);

  useEffect(() => {
    getCommunities();
    getProducts();
  }, [getCommunities, getProducts]);

  const getUserUrl = () => {
    if (isEmployee) return '/emp-users';
    if (isPortal) return '/portal-users';

    return '/admin-users';
  };

  const saveUser = async (values: User) => {
    if (userId == 'create') {
      await createUser(values);
    } else {
      await updateUser(userId, values);
    }

    navigate(getUserUrl());
  };

  const deleteHandler = async () => {
    if (selectedUser?.id) {
      await deleteUser(selectedUser.id, isEmployee ? UserRole.employee : UserRole.admin);
      navigate(getUserUrl());
    }
  };

  const reloadUser = async () => {
    await getUser(userId);
  };

  const renderRadioGroup = () => (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Typography fontSize={18} fontWeight={'bold'}>
          {t('user_role')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormFieldCheckbox name="admin" label={t('admin')} />
      </Grid>
      <Grid item xs={12}>
        <FormFieldCheckbox name="publisher" label={t('publisher')} />
      </Grid>
      <Grid item xs={12}>
        <FormFieldCheckbox name="creator" label={t('creator')} />
      </Grid>
      <Grid item xs={12}>
        <FormFieldCheckbox name="employee" label={t('employee')} />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          id={'portal'}
          control={<Checkbox checked={true} color={'primary'} />}
          label={t('portal')}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <FormFieldCheckbox name="subscriber" label={t('subscriber')} />
        <br />
        <Typography variant="caption" color="graytext">
          {t('subscriber_helptext')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormFieldCheckbox name="tester" label={t('tester')} />
        <br />
        <Typography variant="caption" color="graytext">
          {t('tester_helptext')}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderPermissionRadioGroup = () => (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Typography fontSize={18} fontWeight={'bold'}>
          {t('permissions')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormFieldCheckbox name="permission" label={t('permission')} />
      </Grid>
      <Grid item xs={12}>
        <FormFieldCheckbox name="marketing" label={t('marketing')} />
      </Grid>
      <Grid item xs={12}>
        <FormFieldCheckbox name="newsletter" label={t('newsletter')} />
      </Grid>
      <Grid item xs={12}>
        <FormFieldCheckbox name="empNewsletter" label={t('empNewsletter')} />
      </Grid>
    </Grid>
  );

  const renderHeader = () => {
    let userRole = 'admin'; // bad default!!
    if (isEmployee) {
      userRole = 'employee';
    } else if (isPortal) {
      userRole = 'portal';
    }

    return t(`${userRole}_users_${isEdit ? 'edit' : 'add'}`);
  };

  if (!selectedUser) return null;
  return (
    <Formik
      initialValues={{
        ...selectedUser,
        communityIds: selectedUser.communities?.filter((item) => (item.id ?? 0) > 0).map((item) => item.id ?? 0) ?? [],
      }}
      enableReinitialize
      onSubmit={(values: User) => {
        saveUser(values);
      }}
      validationSchema={getValidationSchema(t)}
    >
      {({ dirty, isValid, values }: FormikProps<User>) => {
        return (
          <Form>
            <Layout title={renderHeader()} fullWidth>
              <Grid container spacing={2}>
                <Grid container spacing={2} item md={6} pr={4}>
                  <Grid item xs={12}>
                    <FormFieldText name="firstname" label={t('first_name')} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormFieldText name="lastname" label={t('last_name')} />
                  </Grid>
                  <Grid item xs={11}>
                    <FormFieldText name="email" label={t('email')} />
                  </Grid>
                  <Grid item xs={1} mt={1}>
                    {values.emailVerified ? (
                      <DoneIcon color="success" titleAccess={t('emailVerified') ?? ''} />
                    ) : (
                      <BlockIcon color="warning" titleAccess={t('emailNotVerified') ?? ''} />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormFieldText name="phone" label={t('phone')} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormFieldText name="address" label={t('address')} />
                  </Grid>
                  <Grid item xs={4}>
                    <FormFieldText name="postalcode" label={t('postalcode')} />
                  </Grid>
                  <Grid item xs={8}>
                    <FormFieldText name="city" label={t('city')} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormFieldText name="country" label={t('country')} />
                  </Grid>
                </Grid>
                <Grid container item md={6}>
                  <FormFieldCheckboxList
                    label={t('communities')}
                    name="communityIds"
                    listItems={communities.map((item) => ({ id: item.id, name: item.name }))}
                  />
                </Grid>
                <Grid container item md={6} style={{ alignContent: 'flex-start' }}>
                  {renderRadioGroup()}
                </Grid>
                <Grid container item md={6} style={{ alignContent: 'flex-start' }}>
                  {renderPermissionRadioGroup()}
                </Grid>
                <Grid container item xs={12} spacing={2} mt={2}>
                  <Grid item>
                    <Button variant="contained" type="submit" disabled={!dirty || !isValid}>
                      {t('save')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={deleteHandler} style={{ backgroundColor: color.deleteButton, color: color.white }}>
                      {t('delete')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item mt={3}>
                <Divider />
              </Grid>
              {singleUser?.subscriptions && selectedUser.id && (
                <UserSubscriptions subscriptions={singleUser?.subscriptions} reloadUser={reloadUser} />
              )}
              {stripeSubscriptions && (
                <UserStripeSubscriptions
                  subscriptions={stripeSubscriptions}
                  products={products}
                  customerId={singleUser?.customerId}
                />
              )}
            </Layout>
          </Form>
        );
      }}
    </Formik>
  );
});

export default UsersEditScreen;
