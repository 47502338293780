import { FormControlLabel, FormGroup, Checkbox as MUCheckbox } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { color } from '../theme';

const useStyles = makeStyles()({
  rootBlack: {
    color: color.black,
    '&$checked': {
      color: color.black,
    },
  },
  rootGreen: {
    color: color.primaryButton,
    '&$checked': {
      color: color.primaryButton,
    },
  },
  container: {
    height: '100%',
    width: '55%',
    marginTop: 5,
    marginBottom: 5,
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'flex-start',
    maxWidth: 30,
    color: color.black,
    fontWeight: 400,
    fontSize: '14px',
    '&$checked': {
      color: color.black,
    },
  },
  checked: {},
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: color.black,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    marginTop: 15,
    width: '40%',
    minWidth: 500,
  },
  labelText: {
    color: color.black,
  },
});

interface Props {
  data: any;
  selectedData: any[];
  isBlack?: boolean;
  onChange: (value: any) => void;
}

function Checkbox(props: Props) {
  const { classes } = useStyles();
  const { data, selectedData, onChange, isBlack } = props;

  return (
    <div className={classes.container}>
      <FormGroup row={false} className={classes.formGroup}>
        {data.map((data: any, index: number) => (
          <FormControlLabel
            key={index}
            classes={{
              label: classes.labelText,
            }}
            control={
              <MUCheckbox
                checked={!!selectedData[data.name]}
                onChange={(e: any) => onChange(e.target)}
                name={data.name}
                className={isBlack ? classes.rootBlack : classes.rootGreen}
              />
            }
            label={data.name}
          />
        ))}
      </FormGroup>
    </div>
  );
}

export default Checkbox;
