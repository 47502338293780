import { flow, types } from 'mobx-state-tree';
import { getSingleReport } from '../services/reportApi';
import { composeError } from '../utils/transforms';
import { ReportTypes } from '../types';
import { withRootStore } from './withRootStore';

const ReportHeaderModel = types.model('ReportHeaderdModel', {
  title: types.string,
});

const ReportFieldModel = types.model('ReportFieldModel', {
  value: types.union(types.string, types.boolean, types.number, types.null),
});

const ReportRowModel = types.model('ReportRowModel', {
  fields: types.array(ReportFieldModel),
});

const ReportModel = types.model('ReportModel', {
  name: types.string,
  headers: types.array(ReportHeaderModel),
  rows: types.array(ReportRowModel),
});

export const ReportStore = types
  .model({
    selectedReport: types.maybeNull(ReportModel),
    loading: false,
  })
  .extend(withRootStore)
  .actions((self) => {
    const getReport = flow(function* (type: ReportTypes) {
      try {
        const response = yield getSingleReport(type);
        self.selectedReport = {
          ...response.data,
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    return {
      getReport,
    };
  });
