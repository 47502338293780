import { flow, types } from 'mobx-state-tree';
import {
  getNewspapers,
  getSingleNewspaper,
  addSingleNewspaper,
  updateSingleNewspaper,
  deleteSingleNewspaper,
} from '../services/api';
import { composeError } from '../utils/transforms';
import { NewspaperResponse, Newspaper, Category, Tag, TargetGroup } from '../types';
import { withRootStore } from './withRootStore';

const NewspaperModel = types.model('NewspaperModel', {
  id: types.number,
  name: types.string,
  description: types.string,
  number: types.string,
  releaseDate: types.string,
  thumbnailUrl: types.string,
  publisher: types.string,
  mediaTypeId: types.maybeNull(types.number),
  categoryIds: types.optional(types.array(types.number), []),
  tagIds: types.optional(types.array(types.number), []),
  targetGroupIds: types.optional(types.array(types.number), []),
  published: types.optional(types.boolean, false),
});

export const NewspaperStore = types
  .model({
    newspapers: types.optional(types.array(NewspaperModel), []),
    selectedNewspaper: types.maybeNull(NewspaperModel),
    loading: false,
    mediaType: -1,
  })
  .extend(withRootStore)
  .actions((self) => {
    const getAllNewspapers = flow(function* () {
      try {
        const response = yield getNewspapers();
        self.newspapers = response.data.map((response: NewspaperResponse) => ({
          ...response,
          mediaType: response.mediaType || -1,
          number: response.number.toString(),
          categoryIds: response.categories?.map((data: Category) => data.id),
          tagIds: response.tags?.map((data: Tag) => data.id),
          targetGroupIds: response.targetGroups?.map((data: TargetGroup) => data.id),
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const getSelectedNewspaper = flow(function* (id: string) {
      try {
        const response = yield getSingleNewspaper(id);

        self.selectedNewspaper = {
          ...response.data,
          mediaTypeId: self.mediaType,
          number: response.data.number.toString(),
          categoryIds: response.data.categories?.map((data: Category) => data.id),
          tagIds: response.data.tags?.map((data: Tag) => data.id),
          targetGroupIds: response.data.targetGroups?.map((data: TargetGroup) => data.id),
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const createNewspaper = flow(function* (newspaper: Newspaper, file: File) {
      self.loading = true;
      try {
        yield addSingleNewspaper(newspaper, file);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const updateNewspaper = flow(function* (newspaper: Newspaper, file: File | null) {
      self.loading = true;
      try {
        yield updateSingleNewspaper(newspaper, file);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const deleteNewspaper = flow(function* (id: string) {
      try {
        yield deleteSingleNewspaper(id);
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getAllNewspapers,
      getSelectedNewspaper,
      createNewspaper,
      updateNewspaper,
      deleteNewspaper,
    };
  });
