import { Magazine } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  magazines: {
    magazines: '/magazines',
    id: '/magazines/{id}',
  },
});

// Magazine
export const getMagazines = (): Promise<Magazine[]> => api.get(API_PATHS.magazines.magazines, {});

export const getSingleMagazine = (id: string): Promise<Magazine> =>
  api.get(API_PATHS.magazines.id.replace('{id}', id), {});

export const addSingleMagazine = (
  magazine: Magazine,
  imageFile?: File,
  ePubFile?: File,
  eMagazineFile?: File
): Promise<Magazine> => {
  const formData = new FormData();

  Object.keys(magazine).forEach((key) => {
    formData.append(key, magazine[key as keyof Magazine]?.toString() ?? '');
  });

  if (imageFile) formData.append('image', imageFile ?? magazine.image);
  if (ePubFile) formData.append('epub', ePubFile ?? magazine.ePub);
  if (eMagazineFile) formData.append('emagazine', eMagazineFile ?? magazine.eMagazine);

  return api.post(API_PATHS.magazines.magazines, formData, { timeout: 360000 });
};

export const updateSingleMagazine = (
  magazine: Magazine,
  imageFile?: File,
  ePubFile?: File,
  eMagazineFile?: File
): Promise<Magazine> => {
  const formData = new FormData();

  Object.keys(magazine).forEach((key) => {
    const value = magazine[key as keyof Magazine];
    value && formData.append(key, magazine[key as keyof Magazine]?.toString() ?? '');
  });

  const imageUpdated = formData.get('image') && !imageFile;
  if (!imageUpdated && imageFile) formData.append('image', imageFile);

  const ePubUpdated = formData.get('epub') && !ePubFile;
  if (!ePubUpdated && ePubFile) formData.append('epub', ePubFile);

  const eMagazineUpdated = formData.get('emagazine') && !eMagazineFile;
  if (!eMagazineUpdated && eMagazineFile) formData.append('emagazine', eMagazineFile);

  return api.put(API_PATHS.magazines.magazines, formData, { timeout: 360000 });
};

export const deleteSingleMagazine = (id: string): Promise<void> => {
  return api.delete(API_PATHS.magazines.id.replace('{id}', id), {});
};
