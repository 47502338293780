/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { Editor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
import 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';

// Content styles, including inline UI like fake cursors
import 'tinymce/skins/content/default/content';
import 'tinymce/skins/ui/oxide/content';

export default function BundledEditor(props) {
  const { init, ...rest } = props;
  return (
    <Editor
      init={{
        ...init,
        license_key: 'gpl',
        menubar: false,
        plugins: ['image', 'link', 'lists', 'searchreplace', 'wordcount'],
        toolbar: 'undo redo | styles | bold italic | bullist numlist | addCitation | addImage | addSideStory',
        style_formats_merge: false,
        style_formats: [
          { title: 'Leipäteksti', block: 'p' },
          { title: 'Väliotsikko', block: 'h1' },
          { title: 'Väliotsikko alareunauutinen', block: 'h4' },
          { title: 'Teeman aloitus', inline: 'tt' },
        ],
        inline: false,
        content_style: [
          'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700',
          "p { font-family:'PT Serif',serif; font-size:18px } " +
            'h1 { font-family: "PT Serif",serif; font-size: 22px; font-weight: 700; text-transform: uppercase } ' +
            'h4 { font-family: "PT Serif",serif; font-size: 20px; font-weight: 700; text-transform: uppercase } ' +
            'tt { font-family: "Poppins", monospace; font-size: 16px; text-transform: uppercase; font-weight: 400; }',
        ].join('\n'),
      }}
      {...rest}
    />
  );
}
