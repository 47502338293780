import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText, Tooltip, Typography } from '@mui/material';
import { useField } from 'formik';
import { KeyValue } from 'types';
import { useTranslation } from 'react-i18next';

interface FormFieldDropDownProps {
  label?: string;
  name: string;
  values: KeyValue[];
  placeholder?: string;
  disabledValues?: number | string | (number | string)[];
  hiddenValues?: (number | string)[];
  autoFocus?: boolean;
  readonly?: boolean;
}

const FormFieldDropDown: FC<FormFieldDropDownProps> = ({
  label,
  name,
  values,
  placeholder,
  disabledValues,
  hiddenValues,
  autoFocus,
  readonly,
}: FormFieldDropDownProps) => {
  const [field, meta] = useField(name);
  const { t } = useTranslation();
  const DEFAULT = 'no_set';

  const isDisabled = (key?: string | number): boolean => {
    if (!disabledValues || !key) return false;

    if (Array.isArray(disabledValues)) {
      return disabledValues.indexOf(key) !== -1;
    }

    return disabledValues === key;
  };

  const menuItemText = (item: KeyValue) => <Typography>{item.value ? t(item.value) : item.text}</Typography>;
  return (
    <FormControl size="small" variant="outlined" fullWidth error={!!meta.error}>
      <InputLabel id={`select-${name}`} variant="outlined">
        {label}
      </InputLabel>
      <Select
        style={{ minWidth: 150 }}
        labelId={`select-${name}`}
        variant="outlined"
        onBlur={field.onBlur}
        onChange={field.onChange}
        value={field.value ?? 0}
        name={field.name}
        autoFocus={autoFocus}
        disabled={readonly}
        label={label}
        displayEmpty
      >
        {placeholder && (
          <MenuItem disabled key={0} value={0}>
            {t(placeholder)}
          </MenuItem>
        )}
        {values
          .filter((item) => item.key !== DEFAULT)
          .filter((item) => !item.key || !hiddenValues || !hiddenValues?.includes(item.key))
          .map((item, index) => (
            <MenuItem key={index} value={item.key} disabled={isDisabled(item.key)}>
              {(item.tooltip?.length ?? 0) > 0 ? (
                <Tooltip title={<>{item.tooltip}</>} arrow placement="right">
                  {menuItemText(item)}
                </Tooltip>
              ) : (
                menuItemText(item)
              )}
            </MenuItem>
          ))}
      </Select>
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export default FormFieldDropDown;
