import { flow, types } from 'mobx-state-tree';
import {
  getAllCollectionPages,
  getSinglePage,
  updateCollectionPages,
  createNewCollectionPage,
  deleteCollectionPage,
} from '../services/api';
import { composeError } from '../utils/transforms';
import { CollectionPage, CollectionPageResponse, Category } from '../types';
import { withRootStore } from './withRootStore';

const CollectionPageModel = types.model('CollectionPageModel', {
  id: types.optional(types.number, -1),
  text: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  url: types.optional(types.string, ''),
  categories: types.maybeNull(types.array(types.number)),
  mediaType: types.maybeNull(types.number),
});

export const CollectionPageStore = types
  .model({
    pages: types.optional(types.array(CollectionPageModel), []),
    selectedPage: types.optional(CollectionPageModel, {}),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getPages = flow(function* () {
      try {
        const response = yield getAllCollectionPages();
        const pages = response.data.map((data: CollectionPageResponse) => ({
          ...data,
          text: data.name,
          mediaType: data.mediaType?.id,
          categories: data.categories.map((data: Category) => data.id!),
        }));

        self.pages = pages;
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const getCollectionPage = flow(function* (id: string) {
      try {
        const response = yield getSinglePage(id);

        self.selectedPage = {
          ...response.data,
          mediaType: response.data.mediaType?.id,
          categories: response.data.categories.map((data: Category) => data.id!),
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const updatePage = flow(function* (id: number, page: CollectionPage) {
      try {
        yield updateCollectionPages(id.toString(), {
          ...page,
          mediaTypeId: page.mediaType,
          categorieIds: page.categories,
        });
        self.rootStore.errorStore.setSuccess('save_success');
        getPages();
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const savePage = flow(function* (page: CollectionPage) {
      try {
        yield createNewCollectionPage({ ...page, mediaTypeId: page.mediaType, categorieIds: page.categories });
        self.rootStore.errorStore.setSuccess('save_success');
        getPages();
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    const deletePage = flow(function* (id: number) {
      try {
        yield deleteCollectionPage(id.toString());
        self.rootStore.errorStore.setSuccess('delete_success');
        getPages();
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getCollectionPage,
      getPages,
      updatePage,
      savePage,
      deletePage,
    };
  });
