import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMst } from '../model/Root';
import { color } from '../theme';
import Layout from '../components/Layout';
import AddButton from '../components/Button/AddButton';
import LinkTable from '../components/Link/LinkTable';
import LinkForm from '../components/Link/LinkForm';
import ErrorText from '../components/ErrorText';
import { Link } from '../types';
import { LinkDefaults } from '../utils/defaults';

import { NAV_LINK_LOGOS, HEADER_LINK_LOGOS } from '../utils/constants';

const useStyles = makeStyles()({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: 700,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    maxWidth: 700,
    minWidth: 500,
    alignItems: 'flex-start',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: 'auto',
    height: 60,
    objectFit: 'scale-down',
  },
  imageButton: {
    padding: 0,
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 18,
    textDecorationLine: 'underline',
    textTransform: 'none',
    marginTop: 10,
  },
  imageButtonIcon: {
    marginLeft: 5,
    width: 15,
    height: 15,
  },
  inputTitle: {
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  input: {
    fontFamily: 'Ubuntu',
    width: '90%',
    maxWidth: 600,
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
    padding: 10,
    alignSelf: 'flex-start',
    margin: 0,
  },
  addressContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    maxWidth: 600,
    justifyContent: 'space-between',
    padding: 0,
    margin: 0,
  },
  smallInputContainer: {
    width: '90%',
    maxWidth: 250,
    padding: 0,
    alignSelf: 'center',
  },
  smallInput: {
    width: '100%',
    fontFamily: 'Ubuntu',
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
    padding: 10,
    alignSelf: 'center',
    margin: 0,
  },
  button: {
    width: 100,
    marginTop: 30,
    fontFamily: 'Ubuntu',
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'uppercase',
    color: 'white',
    backgroundColor: color.primaryButton,
    padding: 10,
    '&:hover': {
      backgroundColor: color.primaryButton,
    },
    '&:disabled': {
      backgroundColor: color.grayBackground,
      color: color.black,
    },
  },
  linkHeader: {
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    fontSize: 24,
  },
});

const NavigationScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    navigationStore: { links, getLinks, addLinks, deleteLinks, updateLinks },
    collectionPageStore: { pages, getPages },
  } = useMst();

  const [editNavLink, setEditNavLink] = useState(false);
  const [addNewNavLink, setAddNewNavLink] = useState(false);
  const [selectedLink, setSelectedLink] = useState<Link | null>();
  const [editSubNavLink, setEditSubNavLink] = useState(false);
  const [addNewSubNavLink, setAddNewSubNavLink] = useState(false);
  const [result, setResult] = useState<Link[] | null>();
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    const getItems = async () => {
      await getLinks();
      await getPages();
      setResult(links);
    };

    getItems();
  }, [getLinks, getPages, links]);

  const addNewLink = (isNavLink: boolean) => {
    if (editNavLink || editSubNavLink) {
      setEditNavLink(!editNavLink);
      setEditSubNavLink(!editSubNavLink);
    }

    if (isNavLink) {
      setAddNewNavLink(!addNewNavLink);
      if (addNewSubNavLink) setAddNewSubNavLink(!addNewSubNavLink);
    } else {
      setAddNewSubNavLink(!addNewSubNavLink);
      if (addNewNavLink) setAddNewNavLink(!addNewNavLink);
    }
    setSelectedLink(null);
  };

  const editLink = (linkId: number | string, isNavLink: boolean) => {
    if (!result) return;
    if (isNavLink) {
      setEditNavLink(!editNavLink);
    } else {
      setEditSubNavLink(!editSubNavLink);
    }
    const selected = result.find((link) => link.id === linkId);
    setSelectedLink(selected);
  };

  const onLinkChange = (value: string, newValue: string) => {
    setSelectedLink((link: Link | null | undefined) => {
      const tempLink = link ? link : LinkDefaults;
      return {
        ...tempLink,
        [value]: (value === 'logo' || value === 'collectionPageId') && newValue === 'none' ? null : newValue,
      };
    });
  };

  const saveLink = (linkId: number | null | string | undefined, isNavLink: boolean) => {
    // check that link has text and url
    if (!selectedLink || !selectedLink?.text?.length) {
      setHasErrors(true);
      return;
    }
    setHasErrors(false);
    setEditNavLink(false);
    setEditSubNavLink(false);
    setAddNewNavLink(false);
    setAddNewSubNavLink(false);

    const tempLinks = result ? [...result] : [];
    if (linkId && linkId !== -1) {
      const index = tempLinks.findIndex((link) => link.id === linkId);
      tempLinks[index] = {
        ...selectedLink,
        updated: true,
      };

      setResult(tempLinks);
      setSelectedLink(null);
      return tempLinks;
    } else {
      const linkGroup = result
        ? result.filter((link) => (isNavLink && link.isNavigation) || (!isNavLink && link.isHeader))
        : [];
      const tempLinks = [
        ...(result ?? []),
        {
          ...selectedLink,
          placement: linkGroup.length > 0 ? linkGroup.length : 0,
          isNavigation: isNavLink,
          isHeader: !isNavLink,
          id: `new-${result?.length ?? '0'}`,
        },
      ];
      setSelectedLink(null);
      setResult(tempLinks);
      return tempLinks;
    }
  };

  const removeLink = async (linkId: number | string) => {
    if (!result) return;
    if (typeof linkId !== 'string') {
      await deleteLinks(linkId);
    }

    const deletedLink = result.find((link) => link.id === linkId);
    if (!deletedLink) return;
    const linksCopy = [...result];
    const deletedIndex = linksCopy.findIndex((link) => link.id === linkId);
    linksCopy.splice(deletedIndex, 1);

    // Get link group (header/navigation) and sort by placement
    const linkGroup = linksCopy
      .filter((link) => (deletedLink.isNavigation && link.isNavigation) || (deletedLink.isHeader && link.isHeader))
      .sort((a, b) => a.placement - b.placement);

    // Fix placement
    linkGroup.forEach((tempLink, index) => {
      if (tempLink.placement !== index) {
        tempLink = {
          ...tempLink,
          placement: index,
          updated: true,
        };
      }
    });

    //combine and remove dublicates
    const set = new Set();
    const tempLinks: Link[] = linkGroup.concat(linksCopy).filter((item) => {
      const alreadyHas = set.has(item.id);
      set.add(item.id);

      return !alreadyHas;
    });
    setResult(tempLinks);
  };

  const changeLinkOrder = async (linkId: number | string, direction: string) => {
    if (!result) return;
    const willMove = result.find((link) => link.id === linkId);
    if (!willMove) return;
    // Get link group (header/navigation) and sort by placement
    const linkGroup = result
      .filter((link) => (willMove.isNavigation && link.isNavigation) || (willMove.isNavigation && link.isHeader))
      .sort((a, b) => a.placement - b.placement);

    if (direction === 'up' && willMove.placement > 1) {
      const index = linkGroup.findIndex((link) => link.id === linkId);
      if (index === -1) return;
      linkGroup[index] = {
        ...linkGroup[index],
        placement: linkGroup[index].placement - 1,
        updated: true,
      };

      linkGroup[index - 1] = {
        ...linkGroup[index - 1],
        placement: linkGroup[index - 1].placement + 1,
        updated: true,
      };
    }

    if (direction === 'down' && willMove.placement < linkGroup.length - 1) {
      const index = linkGroup.findIndex((link) => link.id === linkId);

      linkGroup[index] = {
        ...linkGroup[index],
        placement: linkGroup[index].placement + 1,
        updated: true,
      };

      linkGroup[index + 1] = {
        ...linkGroup[index + 1],
        placement: linkGroup[index + 1].placement - 1,
        updated: true,
      };
    }

    const set = new Set();
    const tempLinks: Link[] = linkGroup.concat(result).filter((item) => {
      const alreadyHas = set.has(item.id);
      set.add(item.id);

      return !alreadyHas;
    });
    setResult(tempLinks);
  };

  const save = async () => {
    let tempResult = result ? [...result] : [];
    if (addNewSubNavLink || editSubNavLink) {
      tempResult = saveLink(selectedLink?.id, false) ?? [];
    }

    if (addNewNavLink || editNavLink) {
      tempResult = saveLink(selectedLink?.id, true) ?? [];
    }

    // get updated result
    const updatedLinks = tempResult
      ? tempResult.filter((link) => link.updated === true && typeof link.id !== 'string')
      : [];
    if (updatedLinks.length > 0) {
      await updateLinks(updatedLinks);
    }
    // get New result
    const newLinks = tempResult ? tempResult.filter((link) => typeof link.id === 'string') : [];
    if (newLinks.length > 0) {
      await addLinks(newLinks);
    }
    await getLinks();
    setResult(links);
  };

  return (
    <Layout title={t('navigation')}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <h3 className={classes.linkHeader}>{t('main_menu_links')}</h3>
          <LinkTable
            links={result ? result.filter((link) => link.isNavigation) : []}
            onEdit={(linkId) => editLink(linkId, true)}
            onDelete={(linkId) => removeLink(linkId)}
            onOrderChange={(linkId, direction) => changeLinkOrder(linkId, direction)}
            isNavigation={true}
          />
          <AddButton onClick={() => addNewLink(true)} text={t('add_link')} />
          {(editNavLink || addNewNavLink) && (
            <LinkForm
              link={selectedLink ?? null}
              onChange={(value, newValue) => onLinkChange(value, newValue)}
              onSave={(linkId) => saveLink(linkId, true)}
              addIcon={true}
              linkLogos={NAV_LINK_LOGOS}
              pages={pages ?? null}
              isNav={true}
            />
          )}
          <Button className={classes.button} onClick={save}>
            {t('save')}
          </Button>
          {hasErrors && <ErrorText text="link_save_error" style={{ fontSize: 12 }} />}
        </div>
        <div className={classes.contentContainer}>
          <h3 className={classes.linkHeader}>{t('sub_menu_links')}</h3>
          <LinkTable
            links={result ? result.filter((link) => link.isHeader) : []}
            onEdit={(linkId) => editLink(linkId, false)}
            onDelete={(linkId) => removeLink(linkId)}
            onOrderChange={(linkId, direction) => changeLinkOrder(linkId, direction)}
          />
          <AddButton onClick={() => addNewLink(false)} text={t('add_link')} />
          {(editSubNavLink || addNewSubNavLink) && (
            <LinkForm
              link={selectedLink ?? null}
              onChange={(value, newValue) => onLinkChange(value, newValue)}
              onSave={(linkId) => saveLink(linkId, false)}
              addIcon={true}
              linkLogos={HEADER_LINK_LOGOS}
            />
          )}
        </div>
      </div>
    </Layout>
  );
});

export default NavigationScreen;
