import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useMst } from '../model/Root';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { color } from '../theme';
import Layout from '../components/Layout';
import Button from '../components/Button/Button';
import ErrorText from '../components/ErrorText';
import TextInput from '../components/FormComponents/TextInput';
import { Community } from '../types';

const useStyles = makeStyles()({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: 700,
  },
  pwdContainer: {
    width: '60%',
  },
  communitiesContainer: {
    width: '30%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  list: {
    listStyleType: 'none',
    color: color.black,
    marginTop: 2,
  },
});

const UserSettingsScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    userStore: { getMe, changePassword, user },
  } = useMst();
  const [password, setPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);

  useEffect(() => {
    getMe();
  }, [getMe]);

  const validatePassword = () => {
    if (!password || !confirmPassword) {
      setErrorText(t('password_missing'));
      return false;
    }

    if (password !== confirmPassword) {
      setErrorText(t('passwords_not_matching'));
      return false;
    }

    if (password.length < 12) {
      setErrorText(t('password_too_short'));
      return false;
    }
    const characters = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*/g;

    if (!password.match(characters)) {
      setErrorText(t('password_missing_characters'));
      return false;
    }
    setErrorText(null);
    return true;
  };

  const save = async () => {
    const isValid = validatePassword();
    if (!isValid || !password) return;
    await changePassword(password);
    navigate('/');
    setPassword(null);
  };

  const onChange = (key: string, value: string) => {
    if (!value) return;
    if (key === 'password') {
      setPassword(value);
    } else setConfirmPassword(value);
  };

  const renderTextField = (label: string, value: string | null, type: string, placeholder: string) => (
    <TextInput
      label={label}
      value={value}
      type={type}
      placeholder={placeholder}
      onChange={(key: string, value: string) => onChange(key, value)}
      style={{ width: '90%' }}
      inputType="password"
    />
  );

  return (
    <Layout title={t('user_settings')}>
      <div className={classes.wrapper}>
        <div className={classes.pwdContainer}>
          {renderTextField('new_password', password, 'password', 'new_password')}
          {renderTextField('new_password_again', confirmPassword, 'confirm_password', 'new_password_again')}
          <Button onClick={save} text="save_password" width={500} />
          {errorText && <ErrorText text={errorText} style={{ fontSize: 12 }} />}
        </div>
        <div className={classes.communitiesContainer}>
          <h3 className={classes.title}>{t('communities')}</h3>
          {user?.communities.length &&
            user.communities.map((community: Community, index) => (
              <li key={index} className={classes.list}>
                {community.community}
              </li>
            ))}
        </div>
      </div>
    </Layout>
  );
});

export default UserSettingsScreen;
