import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { TargetGroup } from '../../types';
import EditTable from '../../components/EditRow';

const TargetGroupEditScreen = observer(() => {
  const { t } = useTranslation();
  const {
    targetGroupStore: { targetGroup, getSingleGroup, createNewGroup, updateGroup, deleteGroup },
  } = useMst();

  const { groupId } = useParams<{ groupId: string }>();
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState<TargetGroup>();
  const [error, setError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const getGroup = async () => {
      if (groupId !== 'create') {
        setIsEdit(true);
        await getSingleGroup(groupId);
        setSelectedGroup(targetGroup);
      }
    };
    getGroup();
  }, [getSingleGroup, targetGroup, groupId]);

  const onChange = (newValue: string) => {
    setSelectedGroup((result) => ({
      ...result,
      name: newValue,
    }));
  };

  const createHandler = async () => {
    if (selectedGroup && selectedGroup.name.length > 0) {
      await createNewGroup(selectedGroup);
      navigate('/targets-groups');
      return;
    }
    setError(targetGroup?.name.length === 0);
  };

  const updateHandler = async () => {
    if (selectedGroup && selectedGroup.name.length) {
      await updateGroup(selectedGroup);
      navigate('/targets-groups');
      return;
    }
    setError(selectedGroup !== undefined && selectedGroup.name.length > 0);
  };

  const deleteHandler = async () => {
    if (!selectedGroup || !selectedGroup.id) return;
    await deleteGroup(selectedGroup.id);
    navigate('/targets-groups');
    return;
  };

  return (
    <Layout title={t(isEdit ? 'target_groups_edit' : 'target_groups_create')}>
      <EditTable
        isEdit={isEdit}
        value={selectedGroup?.name ?? ''}
        onChange={onChange}
        onUpdate={updateHandler}
        onDelete={deleteHandler}
        onCreate={createHandler}
        error={error}
        errorText={'target_group_save_error'}
        header={'target_groups'}
      />
    </Layout>
  );
});

export default TargetGroupEditScreen;
