import React, { FC } from 'react';
import { Article } from '../../types';
import { Grid, Typography } from '@mui/material';

interface ArticleLineProps {
  article?: Article;
  background: string;
}

const ArticleLine: FC<ArticleLineProps> = ({ article, background }: ArticleLineProps) => {
  return (
    <Grid
      item
      xs={10}
      pl={1}
      mb={1}
      style={{
        backgroundColor: background == 'white' ? 'whitesmoke' : 'white',
        borderRadius: 3,
      }}
    >
      <Typography variant="body1" fontSize={14} color={'#330077'}>
        {article?.title}
      </Typography>
    </Grid>
  );
};

export default ArticleLine;
