import React, { ChangeEvent } from 'react';
import { TableCell, TableRow, IconButton, Input } from '@mui/material';
import { color } from '../../theme';
import { makeStyles } from 'tss-react/mui';
import SaveIcon from '../../static/save.svg';
import CancelIcon from '../../static/delete.svg';

const useStyles = makeStyles()({
  root: {
    borderBottom: `1px solid ${color.greyBorderLight}`,
    display: 'flex',
    justifyContent: 'space-between',
    justifyItems: 'flex-start',
  },
  cell: {
    display: 'flex',
    fontSize: '18px',
    fontWeight: 'normal',
    lineHeight: '27px',
    padding: 0,
    border: 0,
    marginTop: 25,
    width: '100%',
    alignItems: 'flex-start',
  },
  btnCell: {
    width: '10%',
    maxWidth: 200,
    border: 'none',
  },
  input: {
    borderBottomWidth: 1,
    width: '100%',
  },
  delete: {
    color: color.black,
  },
});

interface Props {
  value: string;
  onChange: (value: string) => void;
  onSave: () => void;
  onCancel: () => void;
}

function EditTableRow(props: Props) {
  const { classes } = useStyles();
  const { value, onChange, onSave, onCancel } = props;

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.cell}>
        <Input
          sx={{ textDecoration: 'none' }}
          className={classes.input}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
          autoFocus
        ></Input>
      </TableCell>
      <TableCell className={classes.btnCell}>
        <>
          <IconButton onClick={onSave}>
            <img src={SaveIcon} alt="Edit" />
          </IconButton>
          <IconButton onClick={onCancel}>
            <img src={CancelIcon} alt="onCancel" />
          </IconButton>
        </>
      </TableCell>
    </TableRow>
  );
}

export default EditTableRow;
