import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import AddButton from '../../components/Button/AddButton';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';
import MediaList from '../../components/common/MediaList';
import { Row } from 'types';

const OnlineMagazineScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    onlineMagazineStore: { onlineMagazines, getAllOnlineMagazines, deleteOnlineMagazine },
    userStore: { communities, user, getCommunities },
  } = useMst();
  const [result, setResult] = useState<Row[]>();

  const onlineMagazineMapper = useCallback(
    (onlineMagazine) => ({
      id: onlineMagazine.id,
      name: onlineMagazine.name,
      value: onlineMagazine.published ? t('published') : t('draft'),
      secondValue: `${onlineMagazine.name}`,
    }),
    [t]
  );

  useEffect(() => {
    const getOnlineMagazines = async () => {
      await getAllOnlineMagazines();
      await getCommunities();

      if (user?.admin) {
        const tempResult: Row[] = onlineMagazines.length > 0 ? onlineMagazines.map(onlineMagazineMapper) : [];
        setResult(tempResult);
      } else if (communities && user?.communities.length) {
        const tempResult: Row[] = onlineMagazines
          .filter((magazine) => user.communities.some((community) => community.id == magazine.communityId))
          .map(onlineMagazineMapper);
        setResult(tempResult);
      }
    };

    getOnlineMagazines();
  }, [getAllOnlineMagazines, getCommunities, user, communities, onlineMagazines, t, onlineMagazineMapper]);

  const addOnlineMagazine = () => {
    navigate('/onlineMagazines/create');
  };

  const onEditOnlineMagazine = async (id: number) => {
    navigate(`/onlineMagazines/${id}`);
  };

  const onModifyFrontPage = async (id: number) => {
    navigate(`/magazine-segments/${id}`);
  };

  const onDeleteOnlineMagazine = async (id: number) => {
    await deleteOnlineMagazine(id.toString());
    await getAllOnlineMagazines();
    const tempResult: Row[] = onlineMagazines.length > 0 ? onlineMagazines.map(onlineMagazineMapper) : [];
    setResult(tempResult);
  };

  return (
    <Layout title={t('onlineMagazine.title')}>
      <Grid container item xs={12}>
        <Grid item>
          <AddButton onClick={addOnlineMagazine} text={t('onlineMagazine.add_btn')} />
        </Grid>
        {!result && (
          <Grid container item xs={12} m={2}>
            <CircularProgress />
          </Grid>
        )}
        {result && (
          <Grid container item xs={12} mt={2}>
            <MediaList
              rows={result}
              headers={['visibility', 'onlineMagazine.name']}
              onDelete={onDeleteOnlineMagazine}
              onEdit={onEditOnlineMagazine}
              onExtraAction={onModifyFrontPage}
              extraActionTitle="onlineMagazine.modify_front_page"
            />
          </Grid>
        )}
      </Grid>
    </Layout>
  );
});

export default OnlineMagazineScreen;
