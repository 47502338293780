import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { SelectedCategory, Newspaper, NewOrganizingValues, Category, Tag, TargetGroup, MediaTypes } from '../../types';
import { color } from '../../theme';
import TextInput from '../../components/FormComponents/TextInput';
import DateInput from '../../components/FormComponents/DateInput';
import UploadButton from '../../components/Button/UploadButton';
import CheckboxGroup from '../../components/FormComponents/CheckboxGroup';
import Select from '../../components/FormComponents/Select';
import Button from '../../components/Button/Button';
import { NewspaperDefaults } from '../../utils/defaults';

const useStyles = makeStyles()({
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '85%',
    justifyContent: 'space-between',
    padding: 0,
    marginTop: 30,
  },
  organizingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  previewImage: {
    width: 100,
    marginTop: 20,
    marginBottom: 20,
  },
  infoText: {
    width: '100%',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 'normal',
    color: color.black,
  },
  errorText: {
    width: '100%',
    textAlign: 'left',
    fontSize: 15,
    fontWeight: 'normal',
    color: color.error,
  },
});

const NewspaperEditScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    categoryStore: { categories, getCategories, createCategory },
    tagStore: { tags, getTags, createTag },
    targetGroupStore: { targetGroups, getTargetGroups },
    newspaperStore: { selectedNewspaper, getSelectedNewspaper, updateNewspaper, createNewspaper, loading, mediaType },
    userStore: { user, communities, getCommunities },
  } = useMst();
  const { id }: any = useParams();
  const [result, setResult] = useState<Newspaper>(NewspaperDefaults);
  const [file, setFile] = useState<File | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<SelectedCategory[]>([]);
  const [selectedTags, setSelectedTags] = useState<SelectedCategory[]>([]);
  const [selectedTargetGroups, setSelectedTargetGroups] = useState<SelectedCategory[]>([]);
  const [newOrganizingValue, setNewOrganizingValue] = useState<NewOrganizingValues>();
  const [hasErrors, setHasErrors] = useState<boolean>();
  const [canPublish, setCanPublish] = useState<boolean>(false);

  const checkPublish = useCallback(() => {
    if (!user) return null;
    if (user.admin) {
      setCanPublish(true);
      return;
    }

    if (user.creator) {
      setCanPublish(false);
      return;
    }

    if (user && user.communities.length) {
      const selected = user.communities.find(({ community }: { community: string }) => community === result.publisher);
      setCanPublish(!!selected);
    }
  }, [setCanPublish, user, result.publisher]);

  useEffect(() => {
    const fetchPage = async () => {
      await getCategories(MediaTypes.Newspaper);
      await getTags(MediaTypes.Newspaper);
      await getTargetGroups();
      await getCommunities();
      if (id !== 'create') {
        await getSelectedNewspaper(id);
        if (selectedNewspaper) setResult(selectedNewspaper);

        categories.forEach((category) => {
          if (!selectedNewspaper?.categoryIds?.length) return;
          const isFound = !!selectedNewspaper.categoryIds.find((el) => el === category.id);
          setSelectedCategories((prev) => ({ ...prev, [category.name]: isFound }) as SelectedCategory[]);
        });

        tags.forEach((tag) => {
          if (!selectedNewspaper?.tagIds?.length) return;
          const isFound = !!selectedNewspaper.tagIds.find((el) => el === tag.id);
          setSelectedTags((prev) => ({ ...prev, [tag.tag]: isFound }) as SelectedCategory[]);
        });

        targetGroups.forEach((group) => {
          if (!selectedNewspaper?.targetGroupIds?.length) return;
          const isFound = !!selectedNewspaper.targetGroupIds.find((el) => el === group.id);
          setSelectedTargetGroups((prev) => ({ ...prev, [group.name]: isFound }) as SelectedCategory[]);
        });
      } else {
        setSelectedCategories(
          categories.map((category: Category) => (category.name ? { [category.name]: false } : null))
        );
        setSelectedTags(tags.map((tag: Tag) => (tag.tag ? { [tag.tag]: false } : null)));
        setSelectedTargetGroups(targetGroups.map((group: TargetGroup) => ({ [group.name]: false })));
      }
    };

    fetchPage();
    checkPublish();
  }, [
    getSelectedNewspaper,
    getCategories,
    getTags,
    getTargetGroups,
    selectedNewspaper,
    getCommunities,
    categories,
    id,
    targetGroups,
    tags,
    checkPublish,
  ]);

  useEffect(() => {
    checkPublish();
  }, [result.publisher, checkPublish]);

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const onChange = (key: string, value: string) => {
    setResult((result: Newspaper) => {
      if (key === 'publisher') {
        const selected = communities.find((community) => community.id === Number(value));
        return { ...result, [key]: selected?.community } as Newspaper;
      }
      return { ...result, [key]: value } as Newspaper;
    });
  };

  const onNewOrganizingChange = (key: string, value: string) => {
    setNewOrganizingValue({ [key]: value });
  };

  const saveTagOrCategory = async (key: string) => {
    if (!newOrganizingValue?.[key]?.length) return null;
    if (key === 'tag') {
      await createTag({ tag: newOrganizingValue[key] });
    } else if (key === 'category') {
      await createCategory({ name: newOrganizingValue[key] });
    }
  };

  const getSelectedCheckboxes = (checkBoxData: any[], selectedData: any[], key: string): number[] => {
    return checkBoxData
      .filter((data: any) => {
        if (!data[key]) return null;
        if (selectedData[data[key]]) {
          return data.id;
        }
      })
      .map((selected) => selected.id);
  };

  const save = async (publish: boolean) => {
    if (!result) return;
    const mediaTypeId = mediaType;
    const isValid =
      (file || result.thumbnailUrl.length) &&
      result.name.length &&
      result.releaseDate.length &&
      result.number.length &&
      result.publisher;

    if (isValid) {
      const tempCategories = getSelectedCheckboxes(categories, selectedCategories, 'name');

      const tempTags = getSelectedCheckboxes(tags, selectedTags, 'tag');
      const tempTargetGroups = getSelectedCheckboxes(targetGroups, selectedTargetGroups, 'targetGroup');
      const tempNewspaper = {
        ...result,
        published: publish,
        mediaTypeId,
        targetGroupIds: tempTargetGroups,
        tagIds: tempTags,
        categoryIds: tempCategories,
      };
      if (id !== 'create') {
        await updateNewspaper(tempNewspaper, file);
      } else {
        await createNewspaper(tempNewspaper, file);
      }
      navigate('/newspaper');
      setFile(null);
    }
    setHasErrors(!isValid);
  };

  const onCheckBoxGroupChange = (type: string, name: string | null, checked: boolean | null) => {
    if (!name) return;
    switch (type) {
      case 'category':
        setSelectedCategories({ ...selectedCategories, [name]: checked });
        break;
      case 'tag':
        setSelectedTags({ ...selectedTags, [name]: checked });
        break;
      case 'targetGroup':
        setSelectedTargetGroups({ ...selectedTargetGroups, [name]: checked });
        break;
    }
  };

  const renderHeader = () => {
    return loading ? t('newspaper_loading') : id !== 'create' ? t('newspaper_edit') : t('newspaper_add');
  };

  const renderTextInput = (label: string, key: string) => (
    <TextInput
      label={label}
      value={result ? result[key as keyof Newspaper]?.toString() : ''}
      type={key}
      placeholder={label}
      onChange={onChange}
      style={{ width: '80%' }}
    />
  );

  const renderCheckBoxGroup = (
    type: string,
    data: any[],
    selectedData: SelectedCategory[],
    addNew: boolean,
    label?: string,
    newOrganizingValue?: string,
    addButtonText?: string
  ) => (
    <CheckboxGroup
      label={label}
      type={type}
      data={data}
      selectedData={selectedData}
      onChange={onCheckBoxGroupChange}
      newValue={newOrganizingValue}
      onNewChange={addNew ? onNewOrganizingChange : undefined}
      onSaveNew={addNew ? saveTagOrCategory : undefined}
      addButtonText={addButtonText}
    />
  );

  const getSelected = () => {
    const selected = communities.find(({ community }) => community === result.publisher);
    return selected?.id ?? 'none';
  };

  const getSelectData = () => {
    return communities.map((community) => ({ name: community.community, id: community.id }));
  };

  if (!result) return null;

  if (loading) {
    return (
      <Layout title={renderHeader()}>
        <CircularProgress size={40} style={{ alignSelf: 'center', marginTop: 30, color: color.primaryButton }} />
        <p className={classes.infoText}>{t('newspaper_loading_text')}</p>
      </Layout>
    );
  }
  return (
    <Layout title={renderHeader()}>
      {/* Add/modify name, description, number and release date */}
      {renderTextInput('newspaper_name', 'name')}
      {renderTextInput('newspaper_description', 'description')}
      <div className={classes.inputContainer}>
        {communities && communities.length && (
          <Select label="select_publisher" data={getSelectData()} onChange={onChange} value={getSelected()} />
        )}
        {renderTextInput('newspaper_number', 'number')}
        <DateInput
          label="release_date"
          value={result.releaseDate}
          type="releaseDate"
          placeholder="release_date"
          onChange={onChange}
          style={{ width: '80%' }}
        />
      </div>
      {/* Upload newspaper file (PDF) */}
      <UploadButton
        buttonText="download_newspaper"
        onChange={handleFileChange}
        fileName={file?.name}
        fileType=".pdf"
        style={{ marginTop: 30 }}
      />
      {result.thumbnailUrl.length > 0 && !file && <img src={result.thumbnailUrl} className={classes.previewImage} />}
      {/* Select categories, tags, user groups */}
      <div className={classes.organizingContainer}>
        {renderCheckBoxGroup(
          'category',
          categories,
          selectedCategories,
          true,
          'categories',
          newOrganizingValue?.category,
          'add_category'
        )}
        {renderCheckBoxGroup('tag', tags, selectedTags, true, 'tags', newOrganizingValue?.tag, 'add_category')}
      </div>
      {renderCheckBoxGroup('targetGroup', targetGroups, selectedTargetGroups, false, 'target_groups')}
      {/* publish or save as draft */}
      <div>
        <Button
          text="save_draft"
          onClick={() => save(false)}
          backgroundColor={color.secondaryButton}
          width={220}
          marginBottom={30}
        />
        <Button text="publish" onClick={() => save(true)} marginBottom={30} disabled={!canPublish} />
      </div>
      {hasErrors && <p className={classes.errorText}>{t('newspaper_save_error')}</p>}
    </Layout>
  );
});
export default NewspaperEditScreen;
