import { FormikErrors } from 'formik';
import mammoth from 'mammoth';
import { parse } from 'node-html-parser';
import { Article, Community } from 'types';

export const fixStyles = (content: string) => {
  let subTitlesExists = false;

  const root = parse(content);

  root.querySelectorAll('h1.Vliotsikko').forEach(() => {
    subTitlesExists = true;
  });

  if (!subTitlesExists) {
    // fix start of the text
    const firstChild = root.querySelector('p:first-child');
    if (firstChild) {
      const newFirstChild = parse('<p>');
      newFirstChild.rawTagName = 'p';
      newFirstChild.classList.add('Leipiseis');

      const spaceIndex = firstChild.innerText.indexOf(' ');
      const firstWord = firstChild.innerText.slice(0, spaceIndex);
      const firstRest = firstChild.innerText.slice(spaceIndex);
      const themeStart = parse(`<tt>${firstWord}</tt>${firstRest}`);

      const firstReplacement = newFirstChild.set_content(themeStart);

      firstChild.replaceWith(firstReplacement);
    }

    root.querySelectorAll('p.Leipiseis>strong:first-child').forEach((row) => {
      const header = parse('<tt>');
      header.rawTagName = 'tt';
      const newHeader = header.set_content(row.childNodes);
      row.replaceWith(newHeader);
    });
  }

  // Fix subtitles
  root.querySelectorAll('p:has(span > strong)').forEach((row) => {
    const innerText = row.childNodes?.[0]?.childNodes?.[0]?.childNodes?.[0]?.innerText ?? '';

    if (innerText && innerText[0] >= '1' && innerText[0] <= '9') {
      const header = parse('<h1>');
      header.rawTagName = 'h1';
      const newHeader = header.set_content(innerText);
      row.replaceWith(newHeader);
    }
  });

  // Fix font family
  root.querySelectorAll('span[style="font-family: Courier New, serif;"]').forEach((row) => {
    const span = parse('<span>');
    span.rawTagName = 'span';
    const newSpan = span.set_content(row.childNodes);
    row.replaceWith(newSpan);
  });

  // remove links
  root.querySelectorAll('a').forEach((row) => {
    row.remove();
  });

  // Fix bullets points
  // root
  //   .querySelectorAll('p[style="margin-left: 36.0pt; text-indent: -18.0pt; mso-list: l0 level1 lfo1;"]')
  //   .forEach((row) => {
  //     const li = parse('<li>');
  //     li.rawTagName = 'li';
  //     const newLi = li.set_content(row.childNodes[1]);
  //     row.replaceWith(newLi);
  //   });

  return root.toString();
};

export const parseImportedWordDocument = async (
  file: File,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void | FormikErrors<Article>>,
  communities: Community[]
) => {
  const arrayBuffer = await file.arrayBuffer();

  // set title
  const titleTextOptions = {
    styleMap: [
      "p[style-name='Otsikko 01'] => ",
      "p[style-name='Otsikko 02'] => ",

      "p[style-name='Ingressi'] => !",
      "p[style-name='Palluralista'] => !",
      "p[style-name='Numerolista'] => !",
      "p[style-name='Leipis'] => !",
      "p[style-name='Leipis eis'] => !",
      "r[style-name='kursiivi'] => !",
      "r[style-name='Lihavointi'] => !",
      "p[style-name='Otsikko_alareunan_uutiset'] => !",
      "p[style-name='Väliotsikko'] => !",
      "p[style-name='Heading 3'] => !",
      "p[style-name='Comment'] => !",
      "p[style-name='Teksti ja kuva alkuun'] => !",
      "p[style-name='Nosto'] => !",
      "p[style-name='Kuvateksti'] => !",
      "p[style-name='Otsikko lyhyesti'] => !",
      "p[style-name='Teksti ja kuva oik'] => !",
      "p[style-name='Titteli_mielipide'] => !",
      "p[style-name='Teksti ja kuva vas'] => !",
      "p[style-name='Titteli'] => !",
      "p[style-name='OHJE'] => !",
    ],
    includeDefaultStyleMap: false,
  };

  const titleTextResult = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, titleTextOptions);

  setFieldValue('title', titleTextResult?.value ?? '', false);
  setFieldValue('frontTitle', titleTextResult?.value ?? '', false);

  // set lead
  const leadTextOptions = {
    styleMap: [
      "p[style-name='Ingressi'] => ",

      "p[style-name='Palluralista'] => !",
      "p[style-name='Numerolista'] => !",
      "p[style-name='Otsikko 02'] => !",
      "p[style-name='Leipis'] => !",
      "p[style-name='Leipis eis'] => !",
      "r[style-name='kursiivi'] => !",
      "r[style-name='Lihavointi'] => !",
      "p[style-name='Otsikko_alareunan_uutiset'] => !",
      "p[style-name='Väliotsikko'] => !",
      "p[style-name='Heading 3'] => !",
      "p[style-name='Otsikko 01'] => !",
      "p[style-name='Comment'] => !",
      "p[style-name='Teksti ja kuva alkuun'] => !",
      "p[style-name='Nosto'] => !",
      "p[style-name='Kuvateksti'] => !",
      "p[style-name='Otsikko lyhyesti'] => !",
      "p[style-name='Teksti ja kuva oik'] => !",
      "p[style-name='Titteli_mielipide'] => !",
      "p[style-name='Teksti ja kuva vas'] => !",
      "p[style-name='Titteli'] => !",
      "p[style-name='OHJE'] => !",
    ],
    includeDefaultStyleMap: false,
  };

  const leadTextResult = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, leadTextOptions);

  setFieldValue('lead', leadTextResult.value, false);

  // set author
  const authorOptions = {
    styleMap: [
      "p[style-name='Teksti ja kuva vas'] => ",
      "p[style-name='Teksti ja kuva alkuun'] => ",
      "p[style-name='Teksti ja kuva oik'] => ",

      "p[style-name='Ingressi'] => !",
      "p[style-name='Palluralista'] => !",
      "p[style-name='Numerolista'] => !",
      "p[style-name='Otsikko 01'] => !",
      "p[style-name='Otsikko 02'] => !",
      "p[style-name='Otsikko_alareunan_uutiset'] => !",
      "p[style-name='Otsikko lyhyesti'] => !",
      "p[style-name='Väliotsikko'] => !",
      "p[style-name='Leipis'] => !",
      "p[style-name='Leipis eis'] => !",
      "r[style-name='kursiivi'] => !",
      "r[style-name='Lihavointi'] => !",
      "p[style-name='Comment'] => !",
      "p[style-name='Nosto'] => !",
      "p[style-name='Kuvateksti'] => !",
      "p[style-name='Titteli_mielipide'] => !",
      "p[style-name='Titteli'] => !",
      "p[style-name='OHJE'] => !",
    ],
    includeDefaultStyleMap: false,
  };

  const authorResult = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, authorOptions);

  setFieldValue('author', authorResult.value, false);

  // set author
  const authorDescriptionOptions = {
    styleMap: [
      "p[style-name='Titteli_mielipide'] => ",
      "p[style-name='Titteli'] => ",

      "p[style-name='Ingressi'] => !",
      "p[style-name='Palluralista'] => !",
      "p[style-name='Numerolista'] => !",
      "p[style-name='Otsikko 01'] => !",
      "p[style-name='Otsikko 02'] => !",
      "p[style-name='Otsikko_alareunan_uutiset'] => !",
      "p[style-name='Otsikko lyhyesti'] => !",
      "p[style-name='Väliotsikko'] => !",
      "p[style-name='Leipis'] => !",
      "p[style-name='Leipis eis'] => !",
      "r[style-name='kursiivi'] => !",
      "r[style-name='Lihavointi'] => !",
      "p[style-name='Teksti ja kuva vas'] => !",
      "p[style-name='Teksti ja kuva alkuun'] => !",
      "p[style-name='Teksti ja kuva oik'] => !",
      "p[style-name='Comment'] => !",
      "p[style-name='Nosto'] => !",
      "p[style-name='Kuvateksti'] => !",
      "p[style-name='OHJE'] => !",
    ],
    includeDefaultStyleMap: false,
  };

  const authorDescriptionResult = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, authorDescriptionOptions);

  setFieldValue('authorDescription', authorDescriptionResult.value, false);

  // set body text
  const bodyTextOptions = {
    styleMap: [
      "p[style-name='Leipis'] => p.leipis:fresh",
      "p[style-name='Leipis eis'] => p.Leipiseis:fresh",
      "r[style-name='kursiivi'] => em:fresh",
      "r[style-name='Lihavointi'] => strong:fresh",
      "p[style-name='Otsikko_alareunan_uutiset'] => h4.Otsikkoalareunanuutiset:fresh",
      "p[style-name='Väliotsikko'] => h1.Vliotsikko:fresh",
      "p[style-name='Heading 3'] => h4:fresh",
      "p[style-name='Otsikko lyhyesti'] => h4:fresh",
      "p[style-name='Palluralista'] => ul > li:fresh",
      "p[style-name='Numerolista'] => ol > li:fresh",

      "p[style-name='Otsikko 01'] => !",
      "p[style-name='Otsikko 02'] => !",
      "p[style-name='Comment'] => !",
      "p[style-name='Ingressi'] => !",
      "p[style-name='Teksti ja kuva alkuun'] => !",
      "p[style-name='Nosto'] => !",
      "p[style-name='Kuvateksti'] => !",
      "p[style-name='Teksti ja kuva oik'] => !",
      "p[style-name='Titteli_mielipide'] => !",
      "p[style-name='Teksti ja kuva vas'] => !",
      "p[style-name='Titteli'] => !",
      "p[style-name='OHJE'] => !",
    ],
    includeDefaultStyleMap: true,
  };

  const bodyTextResult = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, bodyTextOptions);

  // fix styles before storing value
  setFieldValue('text', fixStyles(bodyTextResult.value), false);

  if (bodyTextResult.messages) {
    console.warn(bodyTextResult.messages);
  }

  // set citations
  const citationsOptions = {
    styleMap: [
      "p[style-name='Nosto'] => p:fresh",

      "p[style-name='Otsikko 01'] => !",
      "p[style-name='Ingressi'] => !",
      "p[style-name='Palluralista'] => !",
      "p[style-name='Numerolista'] => !",
      "p[style-name='Otsikko 02'] => !",
      "p[style-name='Leipis'] => !",
      "p[style-name='Leipis eis'] => !",
      "r[style-name='kursiivi'] => !",
      "r[style-name='Lihavointi'] => !",
      "p[style-name='Otsikko_alareunan_uutiset'] => !",
      "p[style-name='Väliotsikko'] => !",
      "p[style-name='Heading 3'] => !",
      "p[style-name='Comment'] => !",
      "p[style-name='Teksti ja kuva alkuun'] => !",
      "p[style-name='Kuvateksti'] => !",
      "p[style-name='Otsikko lyhyesti'] => !",
      "p[style-name='Teksti ja kuva oik'] => !",
      "p[style-name='Titteli_mielipide'] => !",
      "p[style-name='Teksti ja kuva vas'] => !",
      "p[style-name='Titteli'] => !",
      "p[style-name='OHJE'] => !",
    ],
    includeDefaultStyleMap: false,
  };

  const citationsResult = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, citationsOptions);

  const root = parse(citationsResult.value);
  root.querySelectorAll('p').forEach((row, index) => {
    setFieldValue(`citations.${index}`, row.innerText);
  });

  // set internal notes
  const internalNotesOptions = {
    styleMap: [
      "p[style-name='OHJE'] => ",

      "p[style-name='Otsikko 01'] => !",
      "p[style-name='Ingressi'] => !",
      "p[style-name='Palluralista'] => !",
      "p[style-name='Numerolista'] => !",
      "p[style-name='Otsikko 02'] => !",
      "p[style-name='Leipis'] => !",
      "p[style-name='Leipis eis'] => !",
      "r[style-name='kursiivi'] => !",
      "r[style-name='Lihavointi'] => !",
      "p[style-name='Otsikko_alareunan_uutiset'] => !",
      "p[style-name='Väliotsikko'] => !",
      "p[style-name='Heading 3'] => !",
      "p[style-name='Comment'] => !",
      "p[style-name='Teksti ja kuva alkuun'] => !",
      "p[style-name='Nosto'] => !",
      "p[style-name='Kuvateksti'] => !",
      "p[style-name='Otsikko lyhyesti'] => !",
      "p[style-name='Teksti ja kuva oik'] => !",
      "p[style-name='Titteli_mielipide'] => !",
      "p[style-name='Teksti ja kuva vas'] => !",
      "p[style-name='Titteli'] => !",
    ],
    includeDefaultStyleMap: false,
  };

  const internalNotesResult = await mammoth.convertToHtml({ arrayBuffer: arrayBuffer }, internalNotesOptions);

  setFieldValue('internalNotes', internalNotesResult.value, false);

  if (file.name?.toUpperCase().startsWith('RV')) {
    const rv = communities.find((item) => item.community.includes('Ristin'));
    if (rv) {
      setFieldValue('publisher', rv.id, false);
    }
  }
};
