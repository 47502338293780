import { flow, types } from 'mobx-state-tree';
import { getGroups, getGroupById, createGroup, updateSingleGroup, deleteSingleGroup } from '../services/api';
import { composeError } from '../utils/transforms';
import { withRootStore } from './withRootStore';
import { TargetGroup } from '../types';

export const TargetGroupModel = types.model('TargetGroupModel', {
  id: types.optional(types.number, -1),
  name: types.optional(types.string, ''),
  value: types.optional(types.string, ''),
  type: types.optional(types.number, 0),
});

export const TargetGroupStore = types
  .model({
    targetGroups: types.optional(types.array(TargetGroupModel), []),
    targetGroup: types.optional(TargetGroupModel, {}),
    error: types.frozen(),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getTargetGroups = flow(function* () {
      try {
        const response = yield getGroups();
        self.targetGroups = response.data
          .filter((group) => group.type != 3 && group.type != 5)
          .map((response: TargetGroup) => ({
            id: response.id,
            name: response.name,
            value: response.name,
            type: response.type,
          }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const getSingleGroup = flow(function* (id: string) {
      try {
        const response = yield getGroupById(id);
        self.targetGroup = {
          id: response.data.id,
          name: response.data.name,
          value: response.data.name,
          type: response.data.type,
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const createNewGroup = flow(function* (group: TargetGroup) {
      try {
        yield createGroup(group);
        self.rootStore.errorStore.setSuccess('save_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const updateGroup = flow(function* (group: TargetGroup) {
      try {
        yield updateSingleGroup(group);
        self.rootStore.errorStore.setSuccess('save_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    const deleteGroup = flow(function* (id: number) {
      try {
        yield deleteSingleGroup(id.toString());
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(err);
      }
    });

    return {
      getTargetGroups,
      getSingleGroup,
      createNewGroup,
      updateGroup,
      deleteGroup,
    };
  });
