import React, { useState } from 'react';
import { Table as MUTable, TableRow as MUTableRow, TableBody, TablePagination } from '@mui/material';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import InputTableRow from './InputTableRow';
import EditTableRow from './EditTableRow';
import UsersTableRow from './UsersTableRow';
import { makeStyles } from 'tss-react/mui';
import { Row, LinkRow, TableType, User } from '../../types';
import { UserRole } from '../../utils/constants';
import TableFooter from '@mui/material/TableFooter';

const useStyles = makeStyles()({
  tableContainer: {
    display: 'flex',
    marginTop: '20px',
  },
  root: {
    '&.MuiTable-root': {
      width: '100%',
      maxWidth: 1000,
    },
  },
});

interface Props {
  tableType: TableType;
  data: Row[];
  selectedRow?: Row | LinkRow | null;
  addNew?: boolean;
  isUserTable?: boolean;
  userRole?: UserRole;
  setSelectedRow?: (row: Row | LinkRow | null) => void;
  onDelete?: (id: number) => void;
  onSave?: () => void;
  onAddNew?: () => void;
}

function Table({
  tableType,
  data,
  selectedRow,
  addNew,
  isUserTable,
  userRole,
  setSelectedRow,
  onDelete,
  onSave,
  onAddNew,
}: Props) {
  const { classes } = useStyles();
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 10000;
  //  tableType.dataType === TableDataTypes.category || tableType.dataType === TableDataTypes.tag ? 5 : 10;

  const renderRows = () =>
    data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
      return (
        <TableRow
          key={item.id}
          value={item.value ?? ''}
          secondValue={item.secondValue ?? ''}
          showDelete={tableType.showDelete}
          onDelete={onDelete ? () => onDelete(item.id ?? 0) : undefined}
          onClick={() => (setSelectedRow ? setSelectedRow({ id: item.id, value: item.value }) : null)}
        />
      );
    });

  const renderEditableTable = () => {
    if (!setSelectedRow || !onSave || !onDelete || !onAddNew) return;
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
      if (selectedRow && selectedRow.id === item.id) {
        return (
          <EditTableRow
            key={item.id}
            value={selectedRow.value ?? item.value ?? ''}
            onChange={(value) => setSelectedRow({ id: item.id, value: value })}
            onSave={() => onSave()}
            onCancel={() => setSelectedRow(null)}
          />
        );
      }
      return (
        <TableRow
          key={item.id}
          value={item.value ?? ''}
          secondValue={item.secondValue ?? ''}
          showDelete={tableType.showDelete}
          onClick={() => setSelectedRow({ id: item.id, value: item.value })}
          onDelete={() => onDelete(item.id ?? 0)}
        />
      );
    });
  };

  const renderAddNewRow = () => {
    if (!setSelectedRow || !onSave || !onDelete || !onAddNew) return;
    return (
      <InputTableRow
        value={selectedRow?.value ?? ''}
        onChange={(value: string) => setSelectedRow({ value })}
        onSave={() => onSave()}
        onCancel={onAddNew}
      />
    );
  };

  const renderUserTableRows = () => {
    if (!userRole) return;
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
      return <UsersTableRow key={index} user={item as User} userRole={userRole} />;
    });
  };

  return (
    <div className={classes.tableContainer}>
      <MUTable className={classes.root}>
        <TableHeader headerTitles={tableType.headerTitles} />
        <TableBody>
          {tableType.isEditable ? renderEditableTable() : isUserTable ? renderUserTableRows() : renderRows()}
        </TableBody>
        {addNew && renderAddNewRow()}
        <TableFooter>
          <MUTableRow>
            <TablePagination
              style={{ borderBottomWidth: 0 }}
              rowsPerPageOptions={[]}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, newPage) => setPage(newPage)}
              labelDisplayedRows={({ to, count }) => `${to} / ${count !== -1 ? count : `+${to}`}`}
            />
          </MUTableRow>
        </TableFooter>
      </MUTable>
    </div>
  );
}

export default Table;
