import { flow, types } from 'mobx-state-tree';
import { fetchAppItems, updateAppItem, updateLinkOrder, addNewLink, updateLink, deleteLink } from '../services/api';
import { composeError } from '../utils/transforms';
import { AppItem, Link, LinkResponse } from '../types';
import { withRootStore } from './withRootStore';

const FooterLinkModel = types.model({
  text: types.maybeNull(types.string),
  url: types.optional(types.string, ''),
  placement: types.optional(types.number, 0),
  id: types.number,
});

export const AppModel = types.model('AppModel', {
  logo: types.optional(types.string, ''),
  appName: types.optional(types.string, ''),
  address: types.optional(types.string, ''),
  postalcode: types.optional(types.string, ''),
  city: types.optional(types.string, ''),
  phone: types.optional(types.string, ''),
  businessId: types.optional(types.string, ''),
  footerLinks: types.array(FooterLinkModel),
});

export const AppStore = types
  .model({
    appItem: types.maybeNull(AppModel),
    error: types.maybeNull(types.frozen()),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getAppItems = flow(function* () {
      try {
        const response = yield fetchAppItems();
        return {
          logo: response.data.logo,
          appName: response.data.appName,
          address: response.data.address,
          postalcode: response.data.postalcode,
          city: response.data.city,
          phone: response.data.phone,
          businessId: response.data.businessId,
          footerLinks: response.data.footerLinks,
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const updateAppItems = flow(function* (appItem: AppItem, image: File) {
      try {
        yield updateAppItem(appItem, image);
        self.rootStore.errorStore.setSuccess('update_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const updateFooterLinkOrder = flow(function* (linkId: number | string, direction: string) {
      try {
        yield updateLinkOrder(linkId.toString(), direction);
        self.rootStore.errorStore.setSuccess('update_link_order_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const addNewFooterLink = flow(function* (newLink: LinkResponse) {
      try {
        yield addNewLink(newLink);
        self.rootStore.errorStore.setSuccess('new_footer_link_added');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const updateFooterLink = flow(function* (linkId: number | string, link: Link) {
      try {
        yield updateLink(linkId.toString(), link);
        self.rootStore.errorStore.setSuccess('update_footer_link_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });
    const deleteFooterLink = flow(function* (linkId: number | string) {
      try {
        yield deleteLink(linkId.toString());
        self.rootStore.errorStore.setSuccess('footer_link_delete_success');
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    return {
      getAppItems,
      updateAppItems,
      updateFooterLinkOrder,
      addNewFooterLink,
      updateFooterLink,
      deleteFooterLink,
    };
  });
