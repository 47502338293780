import { AxiosResponse } from 'axios';
import { OnlineMagazine, OnlineMagazineResponse } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  onlineMagazines: {
    root: '/onlineMagazines',
    id: '/onlineMagazines/{id}',
  },
});

// OnlineMagazine
export const getOnlineMagazines = (): Promise<AxiosResponse<OnlineMagazineResponse[]>> =>
  api.get(API_PATHS.onlineMagazines.root, {});

export const getSingleOnlineMagazine = (id: string): Promise<OnlineMagazineResponse> =>
  api.get(API_PATHS.onlineMagazines.id.replace('{id}', id), {});

export const addSingleOnlineMagazine = (
  onlineMagazine: OnlineMagazine,
  imageFile?: File
): Promise<OnlineMagazineResponse> => {
  const formData = new FormData();

  Object.keys(onlineMagazine).forEach((key) => {
    formData.append(key, onlineMagazine[key as keyof OnlineMagazine]?.toString() ?? '');
  });

  if (imageFile) formData.append('image', imageFile ?? onlineMagazine.image);

  return api.post(API_PATHS.onlineMagazines.root, formData, { timeout: 360000 });
};

export const updateSingleOnlineMagazine = (
  onlineMagazine: OnlineMagazine,
  imageFile?: File
): Promise<OnlineMagazineResponse> => {
  const formData = new FormData();

  Object.keys(onlineMagazine).forEach((key) => {
    const value = onlineMagazine[key as keyof OnlineMagazine];
    value && formData.append(key, onlineMagazine[key as keyof OnlineMagazine]?.toString() ?? '');
  });

  const imageUpdated = formData.get('image') && !imageFile;
  if (!imageUpdated && imageFile) formData.append('image', imageFile);

  return api.put(API_PATHS.onlineMagazines.id.replace('{id}', onlineMagazine.id?.toString() ?? '0'), formData, {
    timeout: 360000,
  });
};

export const deleteSingleOnlineMagazine = (id: string): Promise<void> => {
  return api.delete(API_PATHS.onlineMagazines.id.replace('{id}', id), {});
};
