import React from 'react';
import { useTranslation } from 'react-i18next';
import { color } from '../../theme';
import { makeStyles } from 'tss-react/mui';
import { NewspaperArticle } from '../../types';
import Multiselect from 'multiselect-react-dropdown';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 50,
  },
  selectTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
});

interface SelectProps {
  data: NewspaperArticle[];
  selectedData: NewspaperArticle[];
  label: string;
  onChange: (selections: NewspaperArticle[]) => void;
}

export default function Select(props: SelectProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { data, selectedData, onChange, label } = props;

  return (
    <div className={classes.container}>
      <h3 className={classes.selectTitle}>{t(label)}</h3>
      <Multiselect
        displayValue="name"
        groupBy="publisher"
        options={data}
        selectedValues={selectedData}
        onSelect={(selections: NewspaperArticle[]) => onChange(selections)}
        onRemove={(selections: NewspaperArticle[]) => onChange(selections)}
        placeholder="Valitse lehti"
        style={{
          searchBox: { minHeight: '55px', borderColor: color.greyBorderLight, display: 'flex' },
          multiselectContainer: {
            fontSize: 16,
          },
          chips: { backgroundColor: color.greyBorderLight, color: color.black },
        }}
      />
    </div>
  );
}
