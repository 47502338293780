import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Products, StripeSubscription } from '../../types';
import { format } from 'date-fns';

interface UserStripeSubscriptionsProps {
  subscriptions?: StripeSubscription[];
  customerId?: string;
  products?: Products[];
}

const UserStripeSubscriptions: FC<UserStripeSubscriptionsProps> = ({
  subscriptions,
  customerId,
  products,
}: UserStripeSubscriptionsProps) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} mt={3} mb={1}>
        <Typography variant="h5">{`${t('stripe')} ${t('subscriptions')}`}</Typography>
        <Typography variant="caption">{`${t('customer_id')}: ${customerId ?? '-'}`}</Typography>
      </Grid>
      <Grid container item xs={12} fontWeight={'bold'}>
        <Grid item xs={12} md={2}>
          Tilaustyyppi
        </Grid>
        <Grid item xs={12} md={1}>
          Tila
        </Grid>
        <Grid item xs={12} md={2}>
          Tilausjakso
        </Grid>
        <Grid item xs={12} md={1}>
          Hinta
        </Grid>
      </Grid>
      {(!subscriptions || subscriptions.length == 0) && (
        <Grid container item xs={12}>
          <Grid item xs={12} md={2}>
            <Typography fontWeight={500}>{t('no_subcsriptions')}</Typography>
          </Grid>
        </Grid>
      )}
      {subscriptions?.map((sub) => (
        <Grid container item xs={12} key={sub.id}>
          <Grid item xs={12} md={2}>
            <Typography fontWeight={500}>{products?.find((item) => item.id == sub.productId)?.name}</Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            {sub.status}
          </Grid>
          <Grid item xs={12} md={2}>
            {format(new Date(sub.currentPeriodStart), 'd.M.yyyy')} -{' '}
            {format(new Date(sub.currentPeriodEnd), 'd.M.yyyy')}
          </Grid>
          <Grid item xs={12} md={1}>
            {`${sub.amount / 100} ${sub.interval == 'month' ? 'e/kk' : 'e/vuosi'}`}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default UserStripeSubscriptions;
