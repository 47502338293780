import { Album, Song } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  albums: {
    albums: '/albums',
    id: '/albums/{id}',
  },
});

// Album
export const getAlbums = (): Promise<Album[]> => api.get(API_PATHS.albums.albums, {});

export const getSingleAlbum = (id: string): Promise<Album> => api.get(API_PATHS.albums.id.replace('{id}', id), {});

export const addSingleAlbum = (album: Album, songs: Song[], files: File[], imageFile?: File): Promise<Album> => {
  const formData = new FormData();

  songs?.forEach((song) => formData.append('songs', JSON.stringify(song)));
  files?.forEach((file) => {
    formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  });

  Object.keys(album)
    .filter((k) => k !== 'songs')
    .forEach((key) => {
      formData.append(key, album[key as keyof Album]?.toString() ?? '');
    });

  if (imageFile) formData.append('image', imageFile ?? album.image);

  return api.post(API_PATHS.albums.albums, formData, { timeout: 360000 });
};

export const updateSingleAlbum = (album: Album, songs: Song[], files: File[], imageFile?: File): Promise<Album> => {
  const formData = new FormData();

  songs?.forEach((song) => formData.append('songs', JSON.stringify(song)));
  files?.forEach((file) => {
    formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  });

  Object.keys(album)
    .filter((k) => k !== 'songs')
    .forEach((key) => {
      const value = album[key as keyof Album];
      value && formData.append(key, album[key as keyof Album]?.toString() ?? '');
    });

  const imageUpdated = formData.get('image') && !imageFile;
  if (!imageUpdated && imageFile) formData.append('image', imageFile);

  return api.put(API_PATHS.albums.albums, formData, { timeout: 360000 });
};

export const deleteSingleAlbum = (id: string): Promise<void> => {
  return api.delete(API_PATHS.albums.id.replace('{id}', id), {});
};
