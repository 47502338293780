export class Settings {
  static readonly IdleTimeoutSeconds: number = 30 * 60;
  static readonly IdleTimeoutLogoutSeconds: number = 60;
}

export class Data {
  static readonly SubscriperTargetGroupId: number = 4;
  static readonly NotLoggedInTargetGroupId: number = 1;
  static readonly AjassaOnlineMagazineId: number = 1;
}

export default {
  Settings,
  Data,
};
