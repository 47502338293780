import { AxiosError, HttpStatusCode } from 'axios';
import { CLIENT_ERROR, CLIENT_CONNECTION_ERRORS } from './constants';

type Error = {
  key?: string;
  message?: string;
};

// Return formatted error object based on API response
export const composeError = (response: any) => {
  const error: Error = {};
  const axiosError = response as AxiosError;

  if (axiosError) {
    response.status = axiosError.response?.status;
  }

  if (response.status === HttpStatusCode.Unauthorized) {
    error.key = 'unauthorized';
    error.message = 'loginerror';
    window.location.pathname = '/login'; // force login again
  } else if (response.status === HttpStatusCode.TooManyRequests) {
    error.key = 'too_many_requests';
    error.message = 'too_many_requests_error_message';
  } else if (response.status === HttpStatusCode.BadRequest) {
    error.key = 'client_error';
    error.message = (axiosError.response?.data as any).error ?? '';
  } else if (response.problem === CLIENT_ERROR) {
    error.key = 'client_error';
    error.message = 'general_error_message';
  } else if (CLIENT_CONNECTION_ERRORS.includes(response.problem)) {
    error.key = 'network_error';
    error.message = 'general_error_message';
  } else {
    error.key = 'server_error';
    error.message = 'general_error_message';
  }
  return error;
};
