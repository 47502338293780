import { flow, types } from 'mobx-state-tree';
import {
  getMagazines,
  getSingleMagazine,
  addSingleMagazine,
  updateSingleMagazine,
  deleteSingleMagazine,
} from '../services/magazineApi';
import { composeError } from '../utils/transforms';
import { MagazineResponse, Magazine, Category, Tag, TargetGroup } from '../types';
import { withRootStore } from './withRootStore';

const MagazineModel = types.model('MagazineModel', {
  id: types.optional(types.number, -1),
  name: types.string,
  image: types.maybeNull(types.string),
  description: types.string,
  published: types.optional(types.boolean, false),
  publisher: types.string,
  releaseDate: types.maybeNull(types.string),
  language: types.maybeNull(types.string),
  shopUrl: types.maybeNull(types.string),
  ePub: types.maybeNull(types.string),
  eMagazine: types.maybeNull(types.string),
  mediaTypeId: types.optional(types.number, -1),
  categoryIds: types.optional(types.array(types.number), []),
  tagIds: types.optional(types.array(types.number), []),
  targetGroupIds: types.optional(types.array(types.number), []),
});

export const MagazineStore = types
  .model({
    magazines: types.optional(types.array(MagazineModel), []),
    selectedMagazine: types.maybeNull(MagazineModel),
    loading: false,
  })
  .extend(withRootStore)
  .actions((self) => {
    const getAllMagazines = flow(function* () {
      try {
        const response = yield getMagazines();
        self.magazines = response.data.map((magazine: MagazineResponse) => ({
          ...magazine,
          // These are not used where getAllMagazines is called
          // mediaTypeId: magazine.mediaType.id,
          // categoryIds: magazine.categories?.map((data: Category) => data.id),
          // tagIds: magazine.tags?.map((data: Tag) => data.id),
          // targetGroupIds: magazine.targetGroups?.map((data: TargetGroup) => data.id),
        }));
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const getSelectedMagazine = flow(function* (id: string) {
      try {
        const response = yield getSingleMagazine(id);
        self.selectedMagazine = {
          ...response.data,
          mediaTypeId: response.data.mediaType.id,
          categoryIds: response.data.categories?.map((data: Category) => data.id),
          tagIds: response.data.tags?.map((data: Tag) => data.id),
          targetGroupIds: response.data.targetGroups?.map((data: TargetGroup) => data.id),
        };
      } catch (err) {
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        console.error(error);
      }
    });

    const createMagazine = flow(function* (magazine: Magazine, image?: File, ePub?: File, eMagazine?: File) {
      self.loading = true;
      try {
        yield addSingleMagazine(magazine, image, ePub, eMagazine);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const updateMagazine = flow(function* (magazine: Magazine, image?: File, ePub?: File, eMagazine?: File) {
      self.loading = true;
      try {
        yield updateSingleMagazine(magazine, image, ePub, eMagazine);
        self.rootStore.errorStore.setSuccess('save_success');
        self.loading = false;
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
        self.loading = false;
      }
    });

    const deleteMagazine = flow(function* (id: string) {
      try {
        yield deleteSingleMagazine(id);
        self.rootStore.errorStore.setSuccess('delete_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getAllMagazines,
      getSelectedMagazine,
      createMagazine,
      updateMagazine,
      deleteMagazine,
    };
  });
