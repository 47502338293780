import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import Layout from '../../components/Layout';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Typography, Divider, Table, TableRow, TableCell, TableBody } from '@mui/material';
import { ReportTypes } from 'types/ReportTypes';
import { Check, ContentCopy } from '@mui/icons-material';
import copy from 'copy-to-clipboard';

const ReportScreen = observer(() => {
  const { t } = useTranslation();
  const {
    reportStore: { selectedReport, getReport },
    errorStore: { setSuccess },
  } = useMst();

  const tableValue = (value: any) => {
    if (typeof value == 'string' && RegExp(/(\d){4}-(\d){2}-(\d){2}T(\d){2}:(\d){2}:(\d){2}.(\d){3}Z+/).exec(value)) {
      return new Date(value).toLocaleString('fi');
    }
    return typeof value == 'boolean' ? value ? <Check /> : null : value;
  };

  const titleValue = (value: any): string => {
    if (typeof value == 'string' && RegExp(/(\d){4}-(\d){2}-(\d){2}T(\d){2}:(\d){2}:(\d){2}.(\d){3}Z+/).exec(value)) {
      return new Date(value).toLocaleString('fi');
    }
    return typeof value == 'boolean' ? (value ? 'valittu' : 'ei valittu') : value;
  };

  return (
    <Layout title={t('reports.title')}>
      <Grid container>
        <Grid container item xs={12} mb={3} spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                getReport(ReportTypes.UserStatistics);
              }}
            >
              Käyttäjätilasto
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                getReport(ReportTypes.Subscriptions);
              }}
            >
              Tilausmäärät
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                getReport(ReportTypes.SubscriptionTypes);
              }}
            >
              Tilaustyyppien määrät
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                getReport(ReportTypes.NewsletterEmails);
              }}
            >
              Uutiskirjeen tilanneet
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                getReport(ReportTypes.EmpNewsletterEmails);
              }}
            >
              Työntekijäuutiskirjeen tilanneet
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                getReport(ReportTypes.MarketingEmails);
              }}
            >
              Markkinointiviestiluvat
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                getReport(ReportTypes.PermissionEmails);
              }}
            >
              Tietojen markkinointiluovutus luvat
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                getReport(ReportTypes.TestGroupReport);
              }}
            >
              Testiryhmän jäsenet
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                getReport(ReportTypes.Feedback);
              }}
            >
              Palauteet tilauksen peruneilta
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                getReport(ReportTypes.MediaUsageReport);
              }}
            >
              Median käyttömäärät
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                getReport(ReportTypes.SupportCodeReport);
              }}
            >
              Kampanjakoodiraportti
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={3}>
          <Typography variant="caption" color="GrayText">
            {t('reports.resultsNotAccurate')}
          </Typography>
        </Grid>
        <Grid item xs={12} mb={3}>
          <Divider />
        </Grid>
        {!selectedReport && (
          <Grid item xs={12}>
            <Typography variant="caption">{t('reports.noneSelected')}</Typography>
          </Grid>
        )}
        {selectedReport && (
          <>
            <Grid item xs={12} mb={2}>
              <Typography variant="h5">{t(`reports.${selectedReport.name}`)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    {selectedReport.headers.map((h, index) => (
                      <TableCell key={h.title}>
                        <Typography variant="caption">{t(`reports.${h.title}`)}</Typography>{' '}
                        {h.title == 'email' && (
                          <ContentCopy
                            fontSize="small"
                            onClick={() => {
                              const emails = selectedReport.rows.map((r) => r.fields[index].value);
                              // copy to clipboard
                              copy(emails.join(','), {
                                format: 'text/plain',
                                onCopy: () => {
                                  setSuccess('reports.email_copy_success');
                                },
                              });
                            }}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  {selectedReport.rows.map((r, index) => (
                    <TableRow key={index}>
                      {r.fields.map((f, findex) => (
                        <TableCell key={findex + index}>
                          <Typography title={titleValue(f.value)}>{tableValue(f.value)}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </>
        )}
      </Grid>
    </Layout>
  );
});

export default ReportScreen;
