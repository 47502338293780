export enum MediaTypes {
  MediaFile = 0,
  Article = 1,
  Music = 2,
  Newspaper = 3,
  Book = 4,
  Audiobook = 5,
  Video = 6,
  Blog = 7,
  Podcast = 8,
  Episode = 9,
  Magazine = 10,
  Program = 11,
  OnlineMagazine = 12,
  DigiMagazine = 13,
  DigiMagazineNumber = 14,
}
