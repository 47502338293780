import React, { useState, useEffect, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { Audiobook, Chapter, MediaTypes } from '../../types';
import { color } from '../../theme';
import TextInput from '../../components/FormComponents/TextInput';
import Button from '../../components/Button/Button';
import { AudiobookDefaults } from '../../utils/defaults';
import AddNewButton from '../../components/Button/AddButton';
import { Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '../../static/edit.svg';
import DeleteIcon from '../../static/delete.svg';
import DownIcon from '../../static/down_icon.svg';
import UpIcon from '../../static/up_icon.svg';
import SaveIcon from '../../static/save.svg';
import UploadButton from '../../components/Button/UploadButton';
import ImageUploadButton from '../../components/Button/ImageUploadButton';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/FormComponents/FormFieldText';
import FormFieldDate from 'components/FormComponents/FormFieldDate';
import FormFieldNumber from 'components/FormComponents/FormFieldNumber';
import FormFieldTextArea from 'components/FormComponents/FormFieldTextArea';
import FormFieldCheckboxList from 'components/FormComponents/FormFieldCheckboxList';
import FileUploadButton from 'components/Button/FileUploadButton';

const getValidationSchema = (t: (key: string) => string, values: Audiobook) => {
  if (!values.published) {
    const draftSchema = Yup.object().shape({
      name: Yup.string().required(t('common_required')),
      mediaTypeId: Yup.number().required(t('common_required')),
    });

    return draftSchema;
  }

  const audiobookSchema = Yup.object().shape({
    name: Yup.string().required(t('common_required')),
    author: Yup.string().required(t('common_required')),
    publisher: Yup.string().required(t('common_required')),
    description: Yup.string().required(t('common_required')),
    image: Yup.string().required(t('common_required')),
    releaseDate: Yup.string().required(t('common_required')),
    releaseYear: Yup.number().required(t('common_required')),
    language: Yup.string().required(t('common_required')),
    mediaTypeId: Yup.number().required(t('common_required')),
    categoryIds: Yup.array().min(1, t('common_required')),
    targetGroupIds: Yup.array().min(1, t('common_required')),
    ebookStartSkip: Yup.number()
      .nullable()
      .test('skip-start-test', t('common_required'), (value) => !values.ebook || (value != undefined && value >= 0)),
    ebookEndSkip: Yup.number()
      .nullable()
      .test('skip-end-test', t('common_required'), (value) => !values.ebook || (value != undefined && value >= 0)),
  });

  return audiobookSchema;
};

const AudiobookEditScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    categoryStore: { categories, getCategories },
    tagStore: { tags, getTags },
    targetGroupStore: { targetGroups, getTargetGroups },
    audiobookStore: { selectedAudiobook, getSelectedAudiobook, updateAudiobook, createAudiobook, loading },
  } = useMst();
  const { id } = useParams<{ id: string }>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [selectedEbookFile, setSelectedEbookFile] = useState<File>();
  const [selectedPdfBookFile, setSelectedPdfBookFile] = useState<File>();
  const [currentChapterId, setCurrentChapterId] = useState(0);
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [audiobook, setAudiobook] = useState<Audiobook>(AudiobookDefaults);
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [chapterName, setChapterName] = useState('');
  const [uploadDialogVisible, setUploadDialogVisible] = useState<boolean>(false);
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(() => {
    const fetchLists = async () => {
      await getCategories(MediaTypes.Audiobook);
      await getTags(MediaTypes.Audiobook);
      await getTargetGroups();
    };

    fetchLists();
  }, [getCategories, getTags, getTargetGroups, categories, tags, targetGroups]);

  useEffect(() => {
    const fetchPage = async () => {
      if (id !== 'create') {
        await getSelectedAudiobook(id);
        if (selectedAudiobook?.image) {
          setImagePreviewUrl(selectedAudiobook?.image);
        }
        setAudiobook(selectedAudiobook);
        const chapters = selectedAudiobook?.chapters.map((c) => ({ ...c, state: '' })) ?? [];
        setChapters(chapters);
      }
    };

    fetchPage();
  }, [getSelectedAudiobook, selectedAudiobook, id]);

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target?.files) {
      const image = URL.createObjectURL(target.files[0]);
      setSelectedImage(target.files[0]);
      setImagePreviewUrl(image);
    }
  };

  const handleChapterNameChange = (key: string, value: string) => {
    setChapterName(value);
  };

  const handleFilePick = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files);
      setCurrentFiles(fileArray);

      if (e.target.files.length == 1) {
        if (chapterName == '') {
          const filenameWithoutExt = e.target.files[0].name.split('.').slice(0, -1).join('.');
          setChapterName(filenameWithoutExt);
        }
      } else {
        for (let index = 0; index < e.target.files.length; index++) {
          const file = e.target.files[index];
          const filenameWithoutExt = file.name.split('.').slice(0, -1).join('.');
          chapters.push({
            name: filenameWithoutExt,
            file: file,
            chapterNumber: chapters.length == 0 ? 0 : chapters[chapters.length - 1].chapterNumber + 1,
            src: file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            state: 'new',
          });
          files.push(file);
        }

        setCurrentFiles([]);
        setChapterName('');
        setCurrentChapterId(0);
        setUploadDialogVisible(false);
      }
    }
  };

  const handleChapterSave = () => {
    if (currentChapterId == 0) return;

    if (currentFiles.length == 1) {
      // new chapter
      chapters.push({
        name: chapterName,
        file: currentFiles[0],
        chapterNumber: chapters.length == 0 ? 0 : chapters[chapters.length - 1].chapterNumber + 1,
        src: currentFiles[0].name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        state: 'new',
      });
      setFiles([...files, currentFiles[0]]);
    } else {
      const index = chapters.findIndex((chapter) => chapter.id === currentChapterId);
      if (index > -1) {
        chapters[index].name = chapterName;
        chapters[index].state = 'edited';
      }
    }
    setCurrentFiles([]);
    setChapterName('');
    setCurrentChapterId(0);
    setUploadDialogVisible(false);
  };

  const handleChapterEdit = (chapter: Chapter) => {
    setUploadDialogVisible(true);
    setCurrentChapterId(chapter.id ?? 0);

    const index = getChapterIndex(chapter);
    setChapterName(chapters[index].name);
  };

  const handleDeleteFile = (chapter: Chapter) => {
    const index = getChapterIndex(chapter);

    if (index > -1) {
      if ((chapters[index].id ?? 0) > 0) {
        // already saved to db, flag for delete
        chapters[index].state = 'deleted';
      } else {
        // just remove item from array, not stored to db
        chapters.splice(index, 1);
      }

      setRefreshPage(!refreshPage);
    }
  };

  const getChapterIndex = (chapter: Chapter): number => {
    const index = chapters.findIndex(
      (c) => c.id == chapter.id && c.name == chapter.name && c.chapterNumber == chapter.chapterNumber
    );
    return index;
  };

  const changeFileOrder = (chapter: Chapter, direction: string) => {
    // comparison made with all values, in order find existing and new chapters
    // TODO deleted ones are not correctly handled
    const index = getChapterIndex(chapter);

    if (index > -1) {
      if (direction === 'up') {
        if (chapters[index].chapterNumber < 1) return;

        chapters[index].chapterNumber = chapters[index].chapterNumber - 1;
        chapters[index - 1].chapterNumber = chapters[index - 1].chapterNumber + 1;
      }

      if (direction === 'down') {
        if (chapters[index].chapterNumber > chapters.length) return;

        chapters[index].chapterNumber = chapters[index].chapterNumber + 1;
        chapters[index + 1].chapterNumber = chapters[index + 1].chapterNumber - 1;
      }
    }
    // Sort chapters
    chapters.sort((a, b) => a.chapterNumber - b.chapterNumber);

    setRefreshPage(!refreshPage);
  };

  const saveAudiobook = async (book: Audiobook) => {
    if (id !== 'create') {
      await updateAudiobook(book, chapters, files, selectedImage, selectedEbookFile, selectedPdfBookFile);
    } else {
      await createAudiobook(book, chapters, files, selectedImage, selectedEbookFile, selectedPdfBookFile);
    }
    navigate('/audiobooks');
  };

  const renderChapterUpload = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {t('chapters')}
          </Typography>
        </Grid>
        {chapters.length == 0 && (
          <Grid item xs={9}>
            <Typography variant="body2" color="GrayText">
              {t('no_chapters')}
            </Typography>
          </Grid>
        )}

        {chapters
          .filter((c) => c.state != 'deleted')
          .map((chapter: Chapter, index: number) => {
            return (
              <Grid
                container
                key={`${chapter.id}_${index}`}
                style={{ borderBottom: '1px solid lightGray', marginLeft: 24 }}
              >
                <Grid item xs={9} alignSelf={'center'}>
                  <Typography>{chapter.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleChapterEdit(chapter)}>
                    <img src={EditIcon} alt="Edit" />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteFile(chapter)}>
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Grid item xs={12}>
                    <span className="parent-span-up">
                      <IconButton
                        disabled={index === 0}
                        hidden={index === 0}
                        onClick={() => changeFileOrder(chapter, 'up')}
                        style={{
                          borderRadius: 0,
                          margin: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <img src={UpIcon} alt="Up" />
                      </IconButton>
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <span className="parent-span-down">
                      <IconButton
                        disabled={index === chapters.length - 1}
                        hidden={index === chapters.length - 1}
                        onClick={() => changeFileOrder(chapter, 'down')}
                        style={{
                          borderRadius: 0,
                          margin: 0,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        <img src={DownIcon} alt="Down" />
                      </IconButton>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <AddNewButton
            text={t('add_chapter')}
            onClick={() => {
              setCurrentChapterId(-1);
              setChapterName('');
              setUploadDialogVisible(!uploadDialogVisible);
            }}
          />
        </Grid>
        {uploadDialogVisible && (
          <Grid
            container
            item
            xs={10}
            spacing={1}
            style={{ border: 'solid 1px grey', borderRadius: 3, padding: 10, margin: 10 }}
          >
            {currentFiles.length <= 1 && (
              <Grid item xs={12}>
                <TextInput
                  label={'chapter_name'}
                  value={chapterName}
                  type={'name'}
                  placeholder={'chapter_name'}
                  onChange={handleChapterNameChange}
                  style={{ width: '80%' }}
                  multiline={false}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {currentChapterId == -1 ? (
                <UploadButton
                  buttonText="upload_chapter"
                  onChange={handleFilePick}
                  fileName={currentFiles?.length === 1 ? currentFiles[0].name : currentFiles.length > 0 ? 'Useita' : ''}
                  fileType=".mp3"
                />
              ) : (
                <Typography variant="caption" color="GrayText">
                  {t('audiobook_chapter_edit_limited')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <IconButton onClick={handleChapterSave}>
                <img src={SaveIcon} alt="Save" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderHeader = () => {
    const modeText = id !== 'create' ? t('audiobook_edit') : t('audiobook_add');
    return loading ? t('audiobook_loading') : modeText;
  };

  if (loading) {
    return (
      <Layout title={renderHeader()}>
        <CircularProgress size={40} style={{ alignSelf: 'center', marginTop: 30, color: color.primaryButton }} />
        <Typography variant="h5">{t('audiobook_loading_text')}</Typography>
      </Layout>
    );
  }

  return (
    <Formik
      initialValues={audiobook}
      enableReinitialize
      onSubmit={(values: Audiobook) => {
        saveAudiobook(values);
      }}
      validationSchema={Yup.lazy((values: Audiobook) => getValidationSchema(t, values))}
    >
      {({ values, isValid, submitForm, setFieldValue }: FormikProps<Audiobook>) => {
        return (
          <Layout title={renderHeader()}>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <Typography
                  variant="h4"
                  color={audiobook?.published ? 'primary' : 'secondary'}
                  borderColor={audiobook?.published ? 'primary' : 'secondary'}
                  border={1}
                  borderRadius={2}
                  sx={{ padding: 1, fontSize: 18, width: 200, marginBottom: 2 }}
                >
                  {audiobook?.published ? t('published') : t('draft')}
                </Typography>
              </Grid>

              {/* Add/modify name, description, number and release date */}
              <Grid item xs={10}>
                <FormFieldText label={t('audiobook_name')} name="name" />
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={4}>
                  <FormFieldText label={t('audiobook_author')} name="author" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldText label={t('audiobook_publisher')} name="publisher" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldDate label={t('release_date')} name="releaseDate" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={4}>
                  <FormFieldText label={t('audiobook_reader')} name="reader" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldText label={t('audiobook_language')} name="language" />
                </Grid>
                <Grid item xs={4}>
                  <FormFieldNumber label={t('audiobook_releaseYear')} name="releaseYear" />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={12}>
                  <FormFieldTextArea label={t('audiobook_description')} name="description" rows={7} />
                </Grid>
                <Grid item xs={12}>
                  <FormFieldText label={t('audiobook_shop_url')} name="shopUrl" />
                </Grid>
              </Grid>

              {/* Upload cover image */}
              <Grid item xs={10}>
                <ImageUploadButton
                  text="page_image"
                  imagePreviewUrl={imagePreviewUrl}
                  handleImageSelection={handleImageSelection}
                />
              </Grid>

              {/* Upload audiobook chapters (MP3) */}
              <Grid container item xs={10} spacing={3} ml={3}>
                {renderChapterUpload()}
              </Grid>

              {/* Upload ebook file */}
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t('book_ebook')}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <FileUploadButton
                    text="book_ebook_file"
                    idPrefix="abook"
                    src={values?.ebook}
                    mimeType="application/epub+zip"
                    handleSelection={(event) => {
                      if (event.target.files) {
                        setFieldValue('ebook', event.target.files[0].name);
                        setSelectedEbookFile(event.target.files[0]);
                      }
                    }}
                    handleRemove={() => {
                      setFieldValue('ebook', null);
                      setSelectedEbookFile(undefined);
                    }}
                    emptyText="ebook_not_set"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormFieldNumber label={t('audiobook.ebookStartSkip')} name="ebookStartSkip" />
                </Grid>
                <Grid item xs={3}>
                  <FormFieldNumber label={t('audiobook.ebookEndSkip')} name="ebookEndSkip" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="GrayText">
                    {t('audiobook.ebookStartSkipHelpText')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="GrayText">
                    {t('audiobook.ebookEndSkipHelpText')}
                  </Typography>
                </Grid>
              </Grid>

              {/* Upload pdf book file */}
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">{t('book_pdfBook')}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <FileUploadButton
                    text="book_pdfBook_file"
                    idPrefix="pdfbook"
                    src={values?.pdfBook}
                    mimeType="application/pdf"
                    handleSelection={(event) => {
                      if (event.target.files) {
                        setFieldValue('pdfBook', event.target.files[0].name);
                        setSelectedPdfBookFile(event.target.files[0]);
                      }
                    }}
                    handleRemove={() => {
                      setFieldValue('pdfBook', null);
                      setSelectedPdfBookFile(undefined);
                    }}
                    emptyText="pdfBook_not_set"
                  />
                </Grid>
              </Grid>

              {/* Select categories, tags, user groups */}
              <Grid container item xs={12} spacing={3}>
                <Grid container item xs={6} spacing={3} alignContent={'flex-start'}>
                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('categories')} name="categoryIds" listItems={categories} />
                  </Grid>

                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('target_groups')} name="targetGroupIds" listItems={targetGroups} />
                  </Grid>

                  {/* publish or save as draft */}
                  <Grid container item xs={10} spacing={3}>
                    <Button
                      text="save_draft"
                      onClick={() => {
                        setFieldValue('published', false);
                        submitForm();
                      }}
                      backgroundColor={color.secondaryButton}
                      width={220}
                      marginBottom={30}
                    />
                    <Button
                      text="publish"
                      onClick={() => {
                        setFieldValue('published', true);
                        submitForm();
                      }}
                      marginBottom={30}
                      disabled={!isValid}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={6} spacing={3}>
                  <Grid item xs={12}>
                    <FormFieldCheckboxList label={t('tags')} nameField="tag" name="tagIds" listItems={tags} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Layout>
        );
      }}
    </Formik>
  );
});

export default AudiobookEditScreen;
