import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { OnlineMagazine } from '../../types';
import { color } from '../../theme';
import Button from '../../components/Button/Button';
import { OnlineMagazineDefaults } from '../../utils/defaults';
import { Grid, Typography } from '@mui/material';
import ImageUploadButton from '../../components/Button/ImageUploadButton';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import FormFieldText from 'components/FormComponents/FormFieldText';
import FormFieldTextArea from 'components/FormComponents/FormFieldTextArea';
import BundledEditor from 'components/common/BundledEditor';
import { Editor as TinyMCEEditor } from 'tinymce';
import FormFieldDropDown from 'components/FormComponents/FormFieldDropDown';

const publishedSchema = (requiredText: string) => {
  return Yup.object().shape({
    name: Yup.string().required(requiredText),
    targetGroupIds: Yup.array().min(1, requiredText),
  });
};

const draftSchema = (requiredText: string) =>
  Yup.object().shape({
    name: Yup.string().required(requiredText),
  });

const getValidationSchema = (t: (key: string) => string, published: boolean) => {
  if (!published) {
    return draftSchema(t('common_required'));
  }

  return publishedSchema(t('common_required'));
};

const OnlineMagazineEditScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    onlineMagazineStore: {
      selectedOnlineMagazine,
      getSelectedOnlineMagazine,
      updateOnlineMagazine,
      createOnlineMagazine,
      loading,
    },
    userStore: { communities, getCommunities },
  } = useMst();
  const { id } = useParams<{ id: string }>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [onlineMagazine, setOnlineMagazine] = useState<OnlineMagazine>(OnlineMagazineDefaults);
  const editorEditoralStaffRef = useRef<TinyMCEEditor | null>(null);
  const editorCustomerServiceRef = useRef<TinyMCEEditor | null>(null);
  const editorSendOpinionRef = useRef<TinyMCEEditor | null>(null);

  useEffect(() => {
    const fetchLists = async () => {
      await getCommunities();
    };

    fetchLists();
  }, [getCommunities]);

  useEffect(() => {
    const fetchPage = async () => {
      if (id !== 'create') {
        await getSelectedOnlineMagazine(id);
        if (selectedOnlineMagazine?.image) {
          setImagePreviewUrl(selectedOnlineMagazine?.image);
        }
        setOnlineMagazine(selectedOnlineMagazine);
      }
    };

    fetchPage();
  }, [getSelectedOnlineMagazine, selectedOnlineMagazine, id]);

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target?.files) {
      const image = URL.createObjectURL(target.files[0]);
      setSelectedImage(target.files[0]);
      setImagePreviewUrl(image);
    }
  };

  const saveOnlineMagazine = async (onlineMagazine: OnlineMagazine) => {
    if (id !== 'create') {
      await updateOnlineMagazine(onlineMagazine, selectedImage);
    } else {
      await createOnlineMagazine(onlineMagazine, selectedImage);
    }
    navigate('/onlineMagazines');
  };

  const renderHeader = () => {
    const modeText = id !== 'create' ? t('onlineMagazine.edit') : t('onlineMagazine.add');
    return loading ? t('onlineMagazine.loading') : modeText;
  };

  if (loading) {
    return (
      <Layout title={renderHeader()}>
        <CircularProgress size={40} style={{ alignSelf: 'center', marginTop: 30, color: color.primaryButton }} />
        <Typography variant="h5">{t('onlineMagazine.loading_text')}</Typography>
      </Layout>
    );
  }

  return (
    <Formik
      initialValues={onlineMagazine}
      enableReinitialize
      onSubmit={(values: OnlineMagazine, helpers: FormikHelpers<OnlineMagazine>) => {
        const editorialStaffText = editorEditoralStaffRef.current?.getContent();
        helpers.setFieldValue('editorialStaff', editorialStaffText, false);

        const customerServiceText = editorCustomerServiceRef.current?.getContent();
        helpers.setFieldValue('customerService', customerServiceText, false);

        const sendOpinionText = editorSendOpinionRef.current?.getContent();
        helpers.setFieldValue('sendOpinion', sendOpinionText, false);

        const newValues: OnlineMagazine = {
          ...values,
          editorialStaff: editorialStaffText ?? '',
          customerService: customerServiceText ?? '',
          sendOpinion: sendOpinionText ?? '',
        };
        helpers.setValues(newValues); // update formik state

        saveOnlineMagazine(newValues);
      }}
      validationSchema={Yup.lazy((values: OnlineMagazine) => getValidationSchema(t, values.published))}
    >
      {({ isValid, submitForm, setFieldValue, values }: FormikProps<OnlineMagazine>) => {
        return (
          <Layout title={renderHeader()}>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <Typography
                  variant="h4"
                  color={onlineMagazine?.published ? 'primary' : 'secondary'}
                  borderColor={onlineMagazine?.published ? 'primary' : 'secondary'}
                  border={1}
                  borderRadius={2}
                  sx={{ padding: 1, fontSize: 18, width: 200, marginBottom: 2 }}
                >
                  {onlineMagazine?.published ? t('published') : t('draft')}
                </Typography>
              </Grid>
              {/* Add/modify name, description, number and release date */}
              <Grid item xs={10}>
                <FormFieldText label={t('onlineMagazine.name')} name="name" />
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={6}>
                  <FormFieldDropDown
                    placeholder={t('onlineMagazine.select_community') ?? ''}
                    label={t('onlineMagazine.community') ?? ''}
                    name="communityId"
                    values={communities.map((community) => ({ key: community.id, text: community.name }))}
                  />
                </Grid>{' '}
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={12}>
                  <FormFieldTextArea label={t('onlineMagazine.description')} name="description" rows={7} />
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={12}>
                  <FormFieldText label={t('onlineMagazine.tipEmail')} name="tipEmail" />
                </Grid>
              </Grid>

              {/* Upload cover image */}
              <Grid item xs={10}>
                <ImageUploadButton
                  text="onlineMagazine.image"
                  imagePreviewUrl={imagePreviewUrl}
                  handleImageSelection={handleImageSelection}
                />
              </Grid>

              {/* Contact information */}
              <Grid container item xs={10} spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {t('onlineMagazine.contactInformation')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="GrayText" fontSize={13} ml={1}>
                    {t('onlineMagazine.editorialStaff')}
                  </Typography>

                  <BundledEditor
                    onInit={(evt, editor) => (editorEditoralStaffRef.current = editor)}
                    initialValue={values?.editorialStaff}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: ['lists'],
                      toolbar:
                        'undo redo | blocks | bold italic | bullist numlist | addCitation | addImage | addSideStory | fixBoldClass',
                      inline: false,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="GrayText" fontSize={13} ml={1}>
                    {t('onlineMagazine.customerService')}
                  </Typography>

                  <BundledEditor
                    onInit={(evt, editor) => (editorCustomerServiceRef.current = editor)}
                    initialValue={values?.customerService}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: ['lists'],
                      toolbar:
                        'undo redo | blocks | bold italic | bullist numlist | addCitation | addImage | addSideStory | fixBoldClass',
                      inline: false,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="GrayText" fontSize={13} ml={1}>
                    {t('onlineMagazine.sendOpinion')}
                  </Typography>

                  <BundledEditor
                    onInit={(evt, editor) => (editorSendOpinionRef.current = editor)}
                    initialValue={values?.sendOpinion}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: ['lists'],
                      toolbar:
                        'undo redo | blocks | bold italic | bullist numlist | addCitation | addImage | addSideStory | fixBoldClass',
                      inline: false,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Select categories, tags, user groups */}
            <Grid container item xs={12} spacing={3} mt={1} alignContent={'flex-start'}>
              {/* <Grid item xs={12}>
                  <FormFieldCheckboxList label={t('target_groups')} name="targetGroupIds" listItems={targetGroups} />
                </Grid> */}

              {/* publish or save as draft */}
              <Grid container item xs={10} spacing={3} ml={1}>
                <Button
                  text="save_draft"
                  onClick={() => {
                    setFieldValue('published', false);
                    submitForm();
                  }}
                  backgroundColor={color.secondaryButton}
                  width={220}
                  marginBottom={30}
                />
                <Button
                  text="publish"
                  onClick={() => {
                    setFieldValue('published', true);
                    submitForm();
                  }}
                  marginBottom={30}
                  disabled={!isValid}
                />
              </Grid>
            </Grid>
          </Layout>
        );
      }}
    </Formik>
  );
});

export default OnlineMagazineEditScreen;
