import React, { ChangeEventHandler } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '../../static/add_icon.svg';
import DeleteIcon from '../../static/delete_icon.svg';

interface Props {
  text: string;
  handleSelection: ChangeEventHandler<HTMLInputElement>;
  handleRemove: () => void;
  src?: string | null;
  mimeType?: string;
  emptyText?: string;
  idPrefix: string;
}

export default function FileUploadButton({
  text,
  src,
  handleSelection,
  handleRemove,
  mimeType,
  emptyText,
  idPrefix,
}: Props) {
  const { t } = useTranslation();

  return (
    <Grid container mt={2}>
      <Grid item xs={10} mb={2}>
        {!!src && <Typography variant="body1">{src.split('/').slice(-1)[0]}</Typography>}
        {!src && (
          <Typography variant="caption" color="GrayText">
            {t(emptyText ?? '')}
          </Typography>
        )}
      </Grid>
      {!src && (
        <Grid item xs={10} mb={2}>
          <input
            accept={mimeType}
            style={{ display: 'none' }}
            id={`file-upload-button_${idPrefix}`}
            type="file"
            onChange={handleSelection}
          />
          <label htmlFor={`file-upload-button_${idPrefix}`}>
            <Button component="span" style={{ textTransform: 'none' }}>
              <Typography fontSize={18}>{t(text)}</Typography>
              <img src={AddIcon} style={{ marginLeft: 5, width: 15, height: 15 }} alt={'add'} />
            </Button>
          </label>
        </Grid>
      )}
      {src && (
        <Grid item xs={2}>
          <Button onClick={handleRemove}>
            <img src={DeleteIcon} style={{ width: 15, height: 15 }} alt={'remove'} />
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
