// TODO fix these typings
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import apiErrorHandler from './apiErrorHandler';
import { readStorage } from '../utils/storage';
import {
  AppItem,
  CollectionPageRequest,
  Link,
  LoginResponse,
  LinkResponse,
  Newspaper,
  ArticlePage,
  TargetGroup,
  MediaTypes,
  Community,
} from '../types';
import config from 'config/config';

export const API_PATHS = Object.freeze({
  login: '/auth/login',
  category: '/category',
  tag: '/tag',
  community: {
    create: '/community',
    id: '/community/{id}',
  },
  appItem: '/app-item',
  media: '/media',
  link: {
    byId: '/link/{id}',
    link: '/link',
    updateOrder: '/link/order/{id}',
    id: '/link/update/{id}',
    multiple: '/link/multiple/add',
  },
  user: {
    create: '/user',
    user: '/user/role',
    me: '/user/me',
    single: '/user/single',
    upload: '/user/upload',
    logout: '/user/logout',
    password: '/user/password',
    resetPassword: '/user/reset-password',
    forgotPassword: '/user/forgot-password',
  },
  targetGroup: {
    targetGroup: '/targetgroup',
    id: '/targetgroup/{id}',
  },
  collection: {
    collection: '/collection',
    id: '/collection/{id}',
  },
  promotion: {
    promotion: '/promotion',
    id: '/promotion/{id}',
  },
  content: {
    create: '/content',
    id: '/content/{id}',
  },
  articles: {
    get: '/articles/all',
    create: '/articles',
    id: '/articles/{id}',
  },
  newspaper: {
    newspaper: '/newspaper',
    id: '/newspaper/{id}',
  },
});

export const api = axios.create({
  baseURL: config.API_URL,
  timeout: 20000,
});

// Errors
api.interceptors.response.use((response) => response, apiErrorHandler);

api.interceptors.request.use((config) => {
  const accessToken = readStorage('AUTH_TOKEN');
  if (accessToken !== null && config.headers) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

export const login = async (email: string, password: string): Promise<LoginResponse | undefined> => {
  const res = await api.post<LoginResponse>(API_PATHS.login, { email, password }, {});
  return res.data;
};

// Content endpoints
// Categories
export const fetchCategories = (mediatype: MediaTypes) => api.get(`${API_PATHS.category}/${mediatype}`, {});
export const createNewCategory = (fields: any) => api.post(API_PATHS.category, fields);
export const categoryUpdate = (id: number, fields: any) => api.put(`${API_PATHS.category}/${id}`, fields);
export const categoryDelete = (categoryId: number) => api.delete(`${API_PATHS.category}/${categoryId}`, {});

//Tags
export const fetchTags = (mediatype: MediaTypes) => api.get(`${API_PATHS.tag}/${mediatype}`, {});
export const createNewTag = (fields: any) => api.post(API_PATHS.tag, fields);
export const tagUpdate = (id: number, fields: any) => api.put(`${API_PATHS.tag}/${id}`, fields);
export const tagDelete = (tagId: number) => api.delete(`${API_PATHS.tag}/${tagId}`, {});

//User
export const fetchMe = () => {
  return api.get(API_PATHS.user.me, {});
};

export const logout = () => api.get(API_PATHS.user.logout, {});

export const fetchUsers = (userRole: string) => api.get(`${API_PATHS.user.user}/${userRole}`, {});
export const getSingleUser = (id: string) => api.get(`${API_PATHS.user.single}/${id}`, {});
export const createSingleUser = (fields: any) => api.post(API_PATHS.user.create, fields);
export const updateSingleUser = (id: number, fields: any) => api.put(`${API_PATHS.user.single}/${id}`, fields);
export const deleteSingleUser = (id: number) => api.delete(`${API_PATHS.user.single}/${id}`);
export const changeUserPassword = (password: string) => api.post(API_PATHS.user.password, { password });
export const resetUserPassword = (password: string, token: string) =>
  api.post(API_PATHS.user.resetPassword, { password, token });
export const forgotUserPassword = (email: string) => api.post(API_PATHS.user.forgotPassword, { email });

export const uploadUsersFromFile = (file: any) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post(API_PATHS.user.upload, formData);
};

// Target groups
export const getGroups = () => api.get(API_PATHS.targetGroup.targetGroup, {});
export const getGroupById = (id: string) => api.get(API_PATHS.targetGroup.id.replace('{id}', id), {});
export const createGroup = (fields: TargetGroup) => api.post(API_PATHS.targetGroup.targetGroup, fields);
export const updateSingleGroup = (fields: TargetGroup) => api.put(API_PATHS.targetGroup.targetGroup, fields);
export const deleteSingleGroup = (id: string) => api.delete(API_PATHS.targetGroup.id.replace('{id}', id), {});

//AppItem
export const fetchAppItems = () => api.get(API_PATHS.appItem, {});
export const updateAppItem = (appItem: AppItem, logo: File) => {
  const formData = new FormData();
  formData.append('logo', logo ?? appItem.logo);
  formData.append('appName', appItem.appName);
  formData.append('address', appItem.address);
  formData.append('postalcode', appItem.postalcode);
  formData.append('city', appItem.city);
  formData.append('phone', appItem.phone);
  formData.append('businessId', appItem.businessId);

  return api.post(API_PATHS.appItem, formData);
};

// Community
export const fetchCommunities = () => api.get(API_PATHS.community.create, {});
export const getSingleCommunity = (id: string) => api.get(API_PATHS.community.id.replace('{id}', id));
export const createSingleCommunity = (fields: Community) => api.post(API_PATHS.community.create, fields);
export const updateSingleCommunity = (id: string, fields: Community) =>
  api.put(API_PATHS.community.id.replace('{id}', id), fields);
export const deleteSingleCommunity = (id: string) => api.delete(API_PATHS.community.id.replace('{id}', id));

// Links
export const getAllLinks = () => api.get<Link[]>(API_PATHS.link.link, {});
export const updateLinkOrder = (linkId: string, direction: string) =>
  api.put(API_PATHS.link.updateOrder.replace('{id}', linkId), { direction });
export const updateLink = (linkId: string, link: Link) => api.put(API_PATHS.link.byId.replace('{id}', linkId), link);
export const addNewLink = (newLink: LinkResponse) => api.post(API_PATHS.link.link, newLink);
export const deleteLink = (linkId: string) => api.delete(API_PATHS.link.byId.replace('{id}', linkId), {});
export const addMultipleLinks = (links: Link[]) => api.post(API_PATHS.link.multiple, links);
export const updateMultipleLinks = (links: Link[]) => api.put(API_PATHS.link.multiple, links);

// Collection pages
export const getAllCollectionPages = () => api.get(API_PATHS.collection.collection, {});
export const getSinglePage = (id: string) => api.get(API_PATHS.collection.id.replace('{id}', id), {});
export const updateCollectionPages = (id: string, page: CollectionPageRequest) =>
  api.put(API_PATHS.collection.id.replace('{id}', id), page);
export const createNewCollectionPage = (page: CollectionPageRequest) => api.post(API_PATHS.collection.collection, page);
export const deleteCollectionPage = (id: string) => api.delete(API_PATHS.collection.id.replace('{id}', id));

// Content pages
export const getAllContentPages = () => api.get(API_PATHS.content.create, {});
export const getSingleContentPage = (id: string) => api.get(API_PATHS.content.id.replace('{id}', id), {});
export const createSingleContentPage = (page: any, image: any) => {
  const formData = new FormData();
  formData.append('image', image ?? page.image);
  formData.append('title', page.title);
  formData.append('text', page.text ?? '');
  formData.append('tagIds', page.tagIds);
  formData.append('categoriesIds', page.categoriesIds);
  formData.append('targetGroupIds', page.targetGroupIds);
  formData.append('url', page?.url ?? '');
  formData.append('published', page.published);
  formData.append('priorityIndex', page.priorityIndex);

  return api.post(API_PATHS.content.create, formData);
};

export const updateSingleContentPage = (id: string, page: any, image: any) => {
  const formData = new FormData();
  formData.append('image', image ?? page.image);
  formData.append('title', page.title);
  formData.append('text', page.text ?? '');
  formData.append('tagIds', page.tagIds);
  formData.append('categoriesIds', page.categoriesIds);
  formData.append('targetGroupIds', page.targetGroupIds);
  formData.append('url', page?.url ?? '');
  formData.append('published', page.published);
  formData.append('priorityIndex', page.priorityIndex);

  return api.put(API_PATHS.content.id.replace('{id}', id), formData);
};

export const deleteSingleContentPage = (id: string) => {
  return api.delete(API_PATHS.content.id.replace('{id}', id), {});
};

// Article pages
export const getAllArticlePages = () => api.get(API_PATHS.articles.get, {});
export const getSingleArticlePage = (id: string) => api.get(API_PATHS.articles.id.replace('{id}', id), {});

export const createSingleArticlePage = (page: ArticlePage, image: any) => {
  const formData = new FormData();

  Object.keys(page).forEach((key) => {
    if (key !== 'id' && key !== 'image') {
      formData.append(key, page[key as keyof ArticlePage]?.toString() ?? '');
    }
  });
  formData.append('image', image ?? page.image);

  return api.post(API_PATHS.articles.create, formData);
};

export const updateSingleArticlePage = (id: string, page: ArticlePage, image: any) => {
  const formData = new FormData();

  Object.keys(page).forEach((key) => {
    if (key !== 'image') {
      formData.append(key, page[key as keyof ArticlePage]?.toString() ?? '');
    }
  });
  formData.append('image', image ?? page.image);
  return api.put(API_PATHS.articles.id.replace('{id}', id), formData);
};

export const deleteSingleArticlePage = (id: string) => {
  return api.delete(API_PATHS.articles.id.replace('{id}', id), {});
};

//Media
export const getMediaTypes = () => api.get(API_PATHS.media, {});

//Promotions
export const fetchPromotions = () => api.get(API_PATHS.promotion.promotion, {});
export const getSinglePromotion = (id: string) => api.get(API_PATHS.promotion.id.replace('{id}', id), {});

export const deleteSinglePromotion = (id: string) => api.delete(API_PATHS.promotion.id.replace('{id}', id), {});
export const createPromotion = (page: any, image: any) => {
  const formData = new FormData();

  formData.append('name', page.name);
  formData.append('placement', page.placement);
  formData.append('url', page.url);
  formData.append('image', image ?? page.image);
  if (page.promotionText) {
    formData.append('promotionText', page.promotionText);
  }

  return api.post(API_PATHS.promotion.promotion, formData);
};

export const updateSinglePromotion = (page: any, image: any) => {
  const formData = new FormData();

  formData.append('id', page.id);
  formData.append('name', page.name);
  formData.append('placement', page.placement);
  formData.append('url', page.url);
  formData.append('image', image ?? page.image);
  if (page.promotionText) {
    formData.append('promotionText', page.promotionText);
  }

  return api.put(API_PATHS.promotion.promotion, formData);
};

//Newspaper
export const getNewspapers = () => api.get(API_PATHS.newspaper.newspaper, {});
export const getSingleNewspaper = (id: string) => api.get(API_PATHS.newspaper.id.replace('{id}', id), {});
export const addSingleNewspaper = (newspaper: Newspaper, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  Object.keys(newspaper).forEach((key) => {
    formData.append(key, newspaper[key as keyof Newspaper]?.toString() ?? '');
  });

  return api.post(API_PATHS.newspaper.newspaper, formData, { timeout: 300000 });
};
export const updateSingleNewspaper = (newspaper: Newspaper, file: File | null) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(newspaper).forEach((key) => {
    formData.append(key, newspaper[key as keyof Newspaper]?.toString() ?? '');
  });

  return api.put(API_PATHS.newspaper.newspaper, formData, { timeout: 300000 });
};

export const deleteSingleNewspaper = (id: string) => {
  return api.delete(API_PATHS.newspaper.id.replace('{id}', id), {});
};
