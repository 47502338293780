import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Article } from '../../types';
import { Grid, Typography, IconButton } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import FormFieldText from 'components/FormComponents/FormFieldText';
import ClearIcon from '@mui/icons-material/Clear';
import { renderSubTitle } from './ArticleEditScreen';

const ArticleCitationsEdit: FC = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<Article>();

  return (
    <FieldArray name="citations">
      {({ remove, push }) => (
        <>
          {renderSubTitle(t('article.citations'), t('article.addCitation'), () => push(''))}

          <Grid item xs={12}>
            {(values?.citations?.length ?? 0) == 0 && (
              <Typography variant="caption" color="GrayText">
                {t('article.noCitations')}
              </Typography>
            )}

            <Grid container>
              {(values?.citations?.length ?? 0) > 0 &&
                values.citations?.map((_, index) => (
                  <Grid container key={index} mt={2}>
                    <Grid item mt={1} mr={1} xs={1}>
                      <Typography>{`nosto_${index + 1}`}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <FormFieldText label={t('article.citation')} name={`citations.${index}`} />
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => remove(index)}>
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </>
      )}
    </FieldArray>
  );
};

export default ArticleCitationsEdit;
