import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import Table from '../../components/Table/Table';
import AddButton from '../../components/Button/AddButton';
import AlertModal from '../../components/AlertModal';
import { useTranslation } from 'react-i18next';
import { CONTENT_PAGE_TABLE } from '../../utils/constants';
import { LinkRow, Row } from '../../types';

const ContentPageScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    contentPageStore: { pages, getPages, deleteContentPage },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow>();
  const [result, setResult] = useState<Row[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchPages = async () => {
      await getPages();
      const tempResult: Row[] =
        pages.length > 0
          ? pages.map((page) => ({
              id: page.id,
              name: page.title,
              value: page.title,
              secondValue: page.published ? t('published') : t('draft'),
            }))
          : [];

      setResult(tempResult);
    };

    fetchPages();
  }, [getPages, pages, t]);

  useEffect(() => {
    if (!selectedRow?.id || modalOpen) return;
    navigate(`/content/${selectedRow.id}`);
  }, [selectedRow?.id, navigate, modalOpen]);

  const navigateTo = () => {
    navigate('/content/create');
  };

  const cancel = () => {
    setSelectedRow(undefined);
    setModalOpen(false);
  };

  const deletePage = async () => {
    if (selectedRow?.id) {
      await deleteContentPage(selectedRow?.id.toString());
      await getPages();
      const tempResult: Row[] =
        pages.length > 0
          ? pages.map((page) => ({
              id: page.id,
              name: page.title,
              value: page.title,
              secondValue: page.published ? t('published') : t('draft'),
            }))
          : [];

      setResult(tempResult);
    }
    setModalOpen(false);
  };

  const toggleDelete = (id: number) => {
    setModalOpen(true);
    setSelectedRow({ id: id });
  };

  return (
    <Layout title={t('pages')}>
      <AddButton onClick={navigateTo} text={t('add_page')} />
      <Table
        tableType={CONTENT_PAGE_TABLE}
        data={result}
        setSelectedRow={(value) => setSelectedRow({ id: value?.id, value: value?.value })}
        selectedRow={selectedRow}
        onDelete={(id) => toggleDelete(id)}
      />
      <AlertModal
        open={modalOpen}
        title="delete_content"
        onClose={() => setModalOpen(!modalOpen)}
        leftButtonPress={cancel}
        rightButtonPress={deletePage}
      />
    </Layout>
  );
});

export default ContentPageScreen;
