import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { User } from '../types';
import { Button, Grid, Typography } from '@mui/material';

interface HeaderProps {
  user: User;
  logoutHandler: () => void;
}

export default function Header(props: HeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, logoutHandler } = props;

  return (
    <Grid container style={{ height: '30px' }}>
      <Grid item>
        {user?.id && (
          <Button variant="text" onClick={() => navigate('/change-password')}>
            <Typography variant="caption">{`${user.firstname} ${user.lastname}`}</Typography>
          </Button>
        )}
      </Grid>
      <Grid item>
        <Button variant="text" onClick={logoutHandler}>
          <Typography variant="caption">{t('logout')}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
