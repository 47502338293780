import { flow, types } from 'mobx-state-tree';
import { getMediaTypes } from '../services/api';
import { composeError } from '../utils/transforms';
import { withRootStore } from './withRootStore';
import { MediaType } from '../types';

export const MediaModel = types.model('MediaModel', {
  id: types.optional(types.number, -1),
  media: types.optional(types.string, ''),
});

export const MediaStore = types
  .model({
    medias: types.optional(types.array(MediaModel), []),
    error: types.maybeNull(types.frozen()),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getMedias = flow(function* () {
      try {
        const response = yield getMediaTypes();
        self.medias = response.data.map((r: MediaType) => ({ id: r.id, media: r.media }));
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getMedias,
    };
  });
