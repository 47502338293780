import React, { Dispatch, SetStateAction } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { makeStyles } from 'tss-react/mui';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editor.css';

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    justifyItems: 'flex-start',
    marginTop: 40,
    marginBottom: 40,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '80%',
    minWidth: 500,
    minHeight: 300,
    maxHeight: 500,
  },
});

interface Props {
  editorState: EditorState;
  onChange: Dispatch<SetStateAction<EditorState>>;
}

function TextEditor(props: Props) {
  const { classes } = useStyles();

  const { editorState, onChange } = props;

  const toolbarOption = {
    options: [
      'inline',
      'fontSize',
      'fontFamily',
      'blockType',
      'list',
      'textAlign',
      'link',
      'embedded',
      'image',
      'remove',
      'history',
    ],
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
    },
    fontSize: {
      options: [18],
    },
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    fontFamily: {
      options: ['Arial'],
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Editor
          editorState={editorState}
          onEditorStateChange={onChange}
          toolbarClassName="toolbar-class"
          editorClassName="editor-class"
          toolbar={toolbarOption}
        />
      </div>
    </div>
  );
}

export default TextEditor;
