import { FC, useState } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import AlertModal from 'components/AlertModal';
import { useTranslation } from 'react-i18next';
import { color } from 'theme';
import { makeStyles } from 'tss-react/mui';
import { Row } from 'types';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { getColorByType } from 'containers/MagazineSegment/SegmentCard';
import { getColorByState } from 'containers/Article/ArticleEditScreen';
import { format } from 'date-fns';

const useStyles = makeStyles()({
  cell_draft: {
    fontSize: '18px',
    color: color.black,
    backgroundColor: color.warning,
    borderRadius: 3,
  },
  cell_published: {
    fontSize: '18px',
    color: color.black,
    backgroundColor: color.success,
    borderRadius: 3,
  },
  cell_future_publish: {
    fontSize: '14px',
    color: color.black,
    fontWeight: 500,
    backgroundColor: '#43a04777',
    borderRadius: 3,
  },
  cell: {
    fontSize: '18px',
    lineHeight: 1.2,
  },
});

interface MediaListProps {
  rows: Row[];
  headers: string[];
  onEdit?: (id: number) => void;
  onDelete: (id: number) => Promise<void>;
  onCancel?: () => void;
  onExtraAction?: (id: number) => void;
  extraActionTitle?: string;
}

const MediaList: FC<MediaListProps> = ({
  headers,
  rows,
  onDelete,
  onEdit,
  onCancel,
  onExtraAction,
  extraActionTitle,
}: MediaListProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<number>();

  const toggleDelete = (id: number) => {
    setDeleteDialogOpen(true);
    setSelectedRowId(id);
  };

  const cancel = () => {
    setDeleteDialogOpen(false);
    onCancel?.();
  };

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>
                <Typography variant="h6">{t(header)}</Typography>
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            let rowValue = row.value;

            let cellClass = classes.cell;

            if (row.value == 'Luonnos') {
              cellClass = classes.cell_draft;
            }
            if (row.value == 'Julkaistu') {
              cellClass = classes.cell_published;

              if (row.releaseDate && new Date(row.releaseDate ?? '').getTime() > Date.now()) {
                cellClass = classes.cell_future_publish;
                rowValue = `Ajastettu julkaisu\n${format(new Date(row.releaseDate), 'dd.MM.yyyy')}`;
              }
            }

            return (
              <TableRow key={row.id}>
                <TableCell width={100} className={cellClass}>
                  {rowValue}
                </TableCell>
                <TableCell className={classes.cell}>
                  <Grid>{row.secondValue}</Grid>
                  <Grid container>
                    {row.secondHelp && (
                      <Grid
                        item
                        xs={3}
                        sx={{ backgroundColor: getColorByType(row.secondHelp), padding: '0px 5px', borderRadius: 1 }}
                        mt={0.5}
                      >
                        <Typography variant="caption" fontWeight={500}>
                          {t(`articleTypes.${row.secondHelp}`, t(`articleTypes_ev.${row.secondHelp}`))}
                        </Typography>
                      </Grid>
                    )}
                    {row.imageState && (
                      <Grid
                        item
                        xs={2}
                        sx={{ backgroundColor: getColorByState(row.imageState), padding: '0px 5px', borderRadius: 1 }}
                        mt={0.5}
                        ml={1}
                        mr={1}
                      >
                        <Typography variant="caption" fontWeight={500}>
                          {t(`articleImageStates.${row.imageState}`)}
                        </Typography>
                      </Grid>
                    )}
                    {row.editorState && (
                      <Grid
                        item
                        xs={2}
                        sx={{ backgroundColor: getColorByState(row.editorState), padding: '0px 5px', borderRadius: 1 }}
                        mt={0.5}
                        ml={1}
                        mr={1}
                      >
                        <Typography variant="caption" fontWeight={500}>
                          {t(`articleEditorStates.${row.editorState}`)}
                        </Typography>
                      </Grid>
                    )}
                    {row.secondExtra && (
                      <Grid item ml={1} alignSelf={'center'}>
                        <Typography variant="caption" color={'#ee3300'} fontWeight={500}>
                          {row.secondExtra}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
                <TableCell width={onExtraAction ? 120 : 100}>
                  {onExtraAction && (
                    <IconButton onClick={() => onExtraAction?.(row.id ?? 0)}>
                      <EditNoteIcon titleAccess={t(extraActionTitle ?? '') ?? ''} />
                    </IconButton>
                  )}
                  <IconButton onClick={() => onEdit?.(row.id ?? 0)}>
                    <EditIcon titleAccess={t('edit') ?? ''} />
                  </IconButton>
                  <IconButton onClick={() => toggleDelete(row.id ?? 0)}>
                    <ClearIcon titleAccess={t('delete') ?? ''} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {rows.length == 0 && (
        <Grid item mt={2}>
          <Typography variant="caption" fontSize={14} color="GrayText">
            {t('no_rows')}
          </Typography>
        </Grid>
      )}
      <AlertModal
        open={deleteDialogOpen}
        title="onlineMagazine.delete"
        onClose={() => setDeleteDialogOpen(!deleteDialogOpen)}
        leftButtonPress={cancel}
        rightButtonPress={async () => {
          setDeleteDialogOpen(false);
          await onDelete(selectedRowId ?? 0);
        }}
      />
    </>
  );
};

export default MediaList;
