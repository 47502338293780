import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import AddButton from '../../components/Button/AddButton';
import { useTranslation } from 'react-i18next';
import { Community, Row } from '../../types';
import { CircularProgress, Grid, IconButton, TextField } from '@mui/material';
import MediaList from '../../components/common/MediaList';
import ClearIcon from '@mui/icons-material/Clear';
import { format, parseISO } from 'date-fns';
import { getArticlesByMagazineId } from 'services/articleApi';

const ArticleScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    articleStore: { deleteArticle },
    userStore: { communities },
    onlineMagazineStore: { selectedOnlineMagazine, getSelectedOnlineMagazine },
  } = useMst();
  const [result, setResult] = useState<Row[]>();
  const [filter, setFilter] = useState('');
  const { magazineId } = useParams<{ magazineId: string }>();

  const getPublisher = useCallback(
    (publisher: number) => {
      const community = communities.find((comm: Community) => comm.id == publisher);

      return community?.community ?? '';
    },
    [communities]
  );

  const articleMapper = useCallback(
    (article) => ({
      id: article.id,
      name: article.title,
      value: article.published ? t('published') : t('draft'),
      secondValue: `${article.title}`,
      secondHelp: `${article.type}`,
      secondExtra: `${article.author} ${format(parseISO(article.visibleDate), 'dd.MM.yyyy')} ${getPublisher(
        article.publisher
      )} ${article.editorNotes ?? ''} `,
      editorState: article.editorState,
      imageState: article.imageState,
      releaseDate: article.releaseDate,
    }),
    [t, getPublisher]
  );

  const getArticles = useCallback(
    async (id?: string) => {
      if (id) {
        await getSelectedOnlineMagazine(id);

        if (selectedOnlineMagazine) {
          const allArticles = await getArticlesByMagazineId(selectedOnlineMagazine.id?.toString() ?? '');
          const tempResult: Row[] = (allArticles?.data?.length ?? 0) > 0 ? allArticles?.data?.map(articleMapper) : [];
          setResult(tempResult);
        }
      }
    },
    [articleMapper, getSelectedOnlineMagazine, selectedOnlineMagazine]
  );

  useEffect(() => {
    getArticles(magazineId);
  }, [magazineId, getArticles]);

  const addArticle = () => {
    navigate(`/onlinemagazine/${magazineId}/articles/create`);
  };

  const onEditArticle = async (id: number) => {
    navigate(`/onlinemagazine/${magazineId}/articles/${id}`);
  };

  const onDeleteArticle = async (id: number) => {
    await deleteArticle(id.toString());
    await getArticles(magazineId);
  };

  return (
    <Layout title={t('article.header')} fullWidth>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <AddButton onClick={addArticle} text={t('article.add_btn')} />
        </Grid>
        <Grid item xs={7}>
          <TextField
            placeholder={'Haku: otsikko, toimittaja tai lehden nimi'}
            value={filter}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFilter(event.target.value);
            }}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item>
          {filter && (
            <IconButton onClick={() => setFilter('')} title="Poista rajaus">
              <ClearIcon />
            </IconButton>
          )}
        </Grid>
        {!result && (
          <Grid container item xs={12} m={2}>
            <CircularProgress />
          </Grid>
        )}
        {result && (
          <Grid container item xs={12} mt={2}>
            <MediaList
              rows={result.filter(
                (row) =>
                  row.name?.toLowerCase().includes(filter.toLowerCase()) ||
                  row.secondValue?.toLowerCase().includes(filter.toLowerCase()) ||
                  row.secondExtra?.toLowerCase().includes(filter.toLowerCase())
              )}
              headers={['visibility', 'article.title']}
              onDelete={onDeleteArticle}
              onEdit={onEditArticle}
            />
          </Grid>
        )}
      </Grid>
    </Layout>
  );
});

export default ArticleScreen;
