import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';
import { useMst } from '../../model/Root';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import { ContentPage, SelectedCategory } from '../../types';
import { ContentPageDefaults } from '../../utils/defaults';
import { color } from '../../theme';
import TextEditor from '../../components/TextEditor/TextEditor';
import CheckboxGroup from '../../components/FormComponents/CheckboxGroup';
import TextInput from '../../components/FormComponents/TextInput';
import Button from '../../components/Button/Button';
import ImageUploadButton from '../../components/Button/ImageUploadButton';
import ErrorText from '../../components/ErrorText';
import { validatePage } from '../../utils/validation';
import { TextField } from '@mui/material';
import config from 'config/config';

const useStyles = makeStyles()({
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 30,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 30,
  },
  url: {
    border: 'none',
    alignSelf: 'flex-start',
    margin: 0,
    width: '60%',
    height: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
  },
  urlText: {
    color: color.black,
    textDecoration: 'none',
  },
});

const ContentPageEditScreen = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    contentPageStore: { selectedPage, getSinglePage, createContentPage, updateContentPage },
    categoryStore: { categories, getCategories, createCategory },
    tagStore: { tags, getTags, createTag },
    targetGroupStore: { targetGroups, getTargetGroups },
    userStore: { user },
  } = useMst();
  const { id } = useParams();
  const [result, setResult] = useState<ContentPage | null>();
  const [selectedCategories, setSelectedCategories] = useState<SelectedCategory[]>([]);
  const [selectedTags, setSelectedTags] = useState<SelectedCategory[]>([]);
  const [selectedTargetGroups, setSelectedTargetGroups] = useState<SelectedCategory[]>([]);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [hasErrors, setHasErrors] = useState<boolean>();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [newValue, setNewValue] = useState<any | null>(null);

  const getSelectedCategories = useCallback(() => {
    categories.forEach((category) => {
      if (!selectedPage?.categories?.length) return;
      const isFound = selectedPage?.categories?.find((el) => el === category.id);
      setSelectedCategories((prev) => ({ ...prev, [category.name]: isFound }) as SelectedCategory[]);
    });

    tags.forEach((tag) => {
      if (!selectedPage?.tags?.length) return;
      const isFound = selectedPage?.tags?.find((el) => el === tag.id);
      setSelectedTags((prev) => ({ ...prev, [tag.tag]: isFound }) as SelectedCategory[]);
    });

    targetGroups.forEach((group) => {
      if (!selectedPage?.targetGroups?.length) return;
      const isFound = selectedPage?.targetGroups?.find((el) => el === group.id);
      setSelectedTargetGroups((prev) => ({ ...prev, [group.name]: isFound }) as SelectedCategory[]);
    });
  }, [categories, targetGroups, selectedPage, tags]);

  useEffect(() => {
    const fetchPage = async () => {
      await getCategories();
      await getTags();
      await getTargetGroups();
      if (id !== 'create') {
        await getSinglePage(id);
        setResult(selectedPage);
        convertFromHTML(selectedPage.text);
        if (selectedPage.image) {
          setImagePreviewUrl(selectedPage.image);
        }

        getSelectedCategories();
      } else {
        setSelectedCategories(categories.map((category: any) => ({ [category.name]: false })));

        setSelectedTags(tags.map((tag: any) => ({ [tag.tag]: false })));
        setSelectedTargetGroups(targetGroups.map((group: any) => ({ [group.name]: false })));
      }
    };

    fetchPage();
  }, [
    getSinglePage,
    getCategories,
    getTags,
    getTargetGroups,
    selectedPage,
    categories,
    getSelectedCategories,
    id,
    targetGroups,
    tags,
  ]);

  useEffect(() => {
    const draft = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onChange('text', draft);
  }, [editorState]);

  const onChange = (key: string, value: any) => {
    setResult((result: any | ContentPage) => {
      const tempResult = result ? result : ContentPageDefaults;
      return { ...tempResult, [key]: value };
    });
  };

  const saveTagOrCategory = async (key: string) => {
    if (!newValue || newValue[key].length <= 0) return null;
    if (key === 'tag') {
      await createTag({ tag: newValue[key] });
      await getTags();
    } else if (key === 'category') {
      await createCategory({ name: newValue[key] });
      await getCategories();
    }
    setNewValue(null);
  };

  const handleImageSelection = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target?.files) {
      const image = URL.createObjectURL(target.files[0]);
      setSelectedImage(target.files[0]);
      setImagePreviewUrl(image);
    }
  };

  const renderTextField = (
    label: string,
    value: string,
    type: string,
    placeholder: string,
    width?: number,
    inputType?: string
  ) => {
    return (
      <TextInput
        label={label}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={(key, value) => onChange(key, value)}
        style={{ width: width ?? '81%' }}
        inputType={inputType}
      />
    );
  };

  const renderHeader = () => {
    return id !== 'create' ? t('pages_edit') : t('pages_add');
  };

  const convertFromHTML = (value = '') => {
    if (!value) return;
    const draftDescription = htmlToDraft(value);
    const { contentBlocks, entityMap } = draftDescription;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const newDescription = EditorState.createWithContent(contentState);
    setEditorState(newDescription);
  };

  const onCheckBoxGroupChange = (type: string, name: string | null, checked: boolean | null) => {
    if (!name) return;
    switch (type) {
      case 'category':
        setSelectedCategories({ ...selectedCategories, [name]: checked });
        break;
      case 'tag':
        setSelectedTags({ ...selectedTags, [name]: checked });
        break;
      case 'targetGroup':
        setSelectedTargetGroups({ ...selectedTargetGroups, [name]: checked });
        break;
    }
  };

  const getSelectedCheckboxes = (checkBoxData: any[], selectedData: any[], key: string): number[] => {
    return checkBoxData
      .filter((data: any) => {
        if (!data[key]) return null;
        if (selectedData[data[key]]) {
          return data.id;
        }
      })
      .map((selected) => selected.id);
  };

  const save = async (publish: boolean) => {
    if (!result) return;
    const isValid = validatePage(result, selectedImage);
    if (isValid) {
      const tempCategories = getSelectedCheckboxes(categories, selectedCategories, 'name');
      const tempTags = getSelectedCheckboxes(tags, selectedTags, 'tag');
      const tempTargetGroups = getSelectedCheckboxes(targetGroups, selectedTargetGroups, 'targetGroup');
      const pageData = {
        ...result,
        published: publish,
        targetGroupIds: tempTargetGroups,
        tagIds: tempTags,
        categoriesIds: tempCategories,
      };
      if (id !== 'create') {
        await updateContentPage(id, pageData, selectedImage);
      } else {
        await createContentPage(pageData, selectedImage);
      }
      navigate('/content');
    }
    setHasErrors(!isValid);
  };

  const onNewChange = (key: string, value: string) => {
    setNewValue((result: any) => {
      return { ...result, [key]: value };
    });
  };

  const renderCheckBoxGroup = (
    type: string,
    data: any[],
    selectedData: any[],
    addNew: boolean,
    label?: string,
    newValue?: string,
    addButtonText?: string
  ) => (
    <CheckboxGroup
      label={label}
      type={type}
      data={data}
      selectedData={selectedData}
      onChange={onCheckBoxGroupChange}
      newValue={newValue}
      onNewChange={addNew ? onNewChange : undefined}
      onSaveNew={addNew ? saveTagOrCategory : undefined}
      addButtonText={addButtonText}
    />
  );

  const getUrl = (urlTitle?: string) => {
    const baseUrl = `${config.APP_URL}/sisaltosivu/`;
    const pageName = urlTitle ? urlTitle.replace(/\s/g, '-') : '';
    return baseUrl.concat(pageName).toLowerCase();
  };

  return (
    <Layout title={renderHeader()}>
      {renderTextField('page_title', result?.title ?? '', 'title', 'page_title')}
      <ImageUploadButton
        text="page_image"
        imagePreviewUrl={imagePreviewUrl}
        handleImageSelection={handleImageSelection}
      />
      <div className={classes.editorContainer}>
        <TextEditor editorState={editorState} onChange={(state: any) => setEditorState(state)} />
      </div>
      <div className={classes.container}>
        {renderCheckBoxGroup(
          'category',
          categories,
          selectedCategories,
          true,
          'categories',
          newValue?.category,
          'add_category'
        )}
        {renderCheckBoxGroup('tag', tags, selectedTags, true, 'tags', newValue?.tag, 'add_tag')}
      </div>
      <div className={classes.container}>
        {renderCheckBoxGroup('targetGroup', targetGroups, selectedTargetGroups, false, 'target_groups')}
        {renderTextField(
          'priority',
          result?.priorityIndex.toString() ?? '',
          'priorityIndex',
          'priority',
          460,
          'number'
        )}
      </div>
      <div>
        <Button
          text="save_draft"
          onClick={() => save(false)}
          backgroundColor={color.secondaryButton}
          width={220}
          marginBottom={30}
        />
        <Button text="publish" onClick={() => save(true)} marginBottom={30} disabled={!user?.admin} />
      </div>
      <div className={classes.container} style={{ alignItems: 'center', margin: 0, width: '55%', minWidth: 600 }}>
        <TextField
          value={getUrl(result?.title)}
          type={'url'}
          placeholder={t('url') ?? ''}
          className={classes.url}
          InputProps={{
            classes: {
              input: classes.urlText,
            },
          }}
          disabled={true}
          multiline
        />
        <Button
          onClick={() => {
            navigator.clipboard.writeText(getUrl(result?.title));
          }}
          text="copy_url"
          width={200}
          marginTop={0}
        />
      </div>
      {hasErrors && <ErrorText text="content_page_save_error" />}
    </Layout>
  );
});

export default ContentPageEditScreen;
