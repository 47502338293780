import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { color } from '../../theme';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  inputTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'flex-start',
    textTransform: 'uppercase',
  },
  input: {
    border: `1px solid ${color.greyBorderLight}`,
    borderRadius: 4,
    alignSelf: 'flex-start',
    margin: 0,
  },
  inputText: {
    color: color.black,
    textDecoration: 'none',
  },
});

interface TextInputProps {
  type: string;
  value?: string | null;
  placeholder: string;
  onChange: (key: string, value: string) => void;
  label?: string;
  style?: React.CSSProperties;
  inputType?: string;
  multiline?: boolean;
}

export default function TextInput(props: TextInputProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { type, value, placeholder, onChange, label, style, inputType, multiline } = props;

  return (
    <div className={classes.container}>
      {label && <h3 className={classes.inputTitle}>{t(label)}</h3>}
      <TextField
        placeholder={t(placeholder) ?? ''}
        value={value ?? ''}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) => onChange(type, target.value)}
        className={classes.input}
        InputProps={{
          classes: {
            input: classes.inputText,
          },
        }}
        style={style}
        type={inputType}
        multiline={multiline}
        minRows={3}
      />
    </div>
  );
}
