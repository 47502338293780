import { Program, Video } from '../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  programs: {
    programs: '/programs',
    id: '/programs/{id}',
  },
});

// Program
export const getPrograms = (): Promise<Program[]> => api.get(API_PATHS.programs.programs, {});

export const getSingleProgram = (id: string): Promise<Program> =>
  api.get(API_PATHS.programs.id.replace('{id}', id), {});

export const addSingleProgram = (
  program: Program,
  videos: Video[],
  files: File[],
  imageFile?: File
): Promise<Program> => {
  const formData = new FormData();

  videos?.forEach((video) => formData.append('videos', JSON.stringify(video)));
  files?.forEach((file) => {
    formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  });

  Object.keys(program)
    .filter((k) => k !== 'videos')
    .forEach((key) => {
      formData.append(key, program[key as keyof Program]?.toString() ?? '');
    });

  if (imageFile) formData.append('image', imageFile ?? program.image);

  return api.post(API_PATHS.programs.programs, formData, { timeout: 360000 });
};

export const updateSingleProgram = (
  program: Program,
  videos: Video[],
  files: File[],
  imageFile?: File
): Promise<Program> => {
  const formData = new FormData();

  videos?.forEach((video) => formData.append('videos', JSON.stringify(video)));
  files?.forEach((file) => {
    formData.append('files', file, file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  });

  Object.keys(program)
    .filter((k) => k !== 'videos')
    .forEach((key) => {
      const value = program[key as keyof Program];
      value && formData.append(key, program[key as keyof Program]?.toString() ?? '');
    });

  const imageUpdated = formData.get('image') && !imageFile;
  if (!imageUpdated && imageFile) formData.append('image', imageFile);

  return api.put(API_PATHS.programs.programs, formData, { timeout: 360000 });
};

export const deleteSingleProgram = (id: string): Promise<void> => {
  return api.delete(API_PATHS.programs.id.replace('{id}', id), {});
};
