import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../model/Root';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import AddButton from '../../components/Button/AddButton';
import Table from '../../components/Table/Table';
import { Row } from '../../types';
import { PROMOTIONS_TABLE } from '../../utils/constants';

const PromotionPagesScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    promotionStore: { promotions, getPromotions },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | null>(null);
  const [result, setResult] = useState<Row[]>();

  useEffect(() => {
    const fetchPromotions = async () => {
      await getPromotions();
      const tempResult: Row[] =
        promotions.length > 0
          ? promotions.map((promotion) => ({
              id: promotion.id,
              name: promotion.name,
              value: promotion.name,
              secondValue: promotion.placement !== 0 ? t('published') : undefined,
            }))
          : [];

      setResult(tempResult);
    };
    fetchPromotions();
  }, [getPromotions, promotions, t]);

  useEffect(() => {
    if (!selectedRow?.id) return;
    navigate(`/promotion/${selectedRow.id}`);
  }, [selectedRow?.id, navigate]);

  const addButtonHandler = () => {
    navigate('/promotion/create');
  };

  return (
    <Layout title={t('main_page_promotions')}>
      <AddButton onClick={addButtonHandler} text={t('add_new')} />
      <Table
        data={result ?? []}
        tableType={PROMOTIONS_TABLE}
        selectedRow={selectedRow}
        setSelectedRow={(value) => setSelectedRow({ id: Number(value?.id), value: value?.value })}
      />
    </Layout>
  );
});

export default PromotionPagesScreen;
