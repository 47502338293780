import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../model/Root';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import AddButton from '../components/Button/AddButton';
import Table from '../components/Table/Table';
import { TAG_TABLE } from '../utils/constants';
import { Row, LinkRow, MediaTypes } from '../types';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

const TagsScreen = observer(() => {
  const { t } = useTranslation();
  const {
    tagStore: { tags, getTags, createTag, updateTag, deleteTag },
    userStore: { user },
  } = useMst();
  const [selectedRow, setSelectedRow] = useState<Row | LinkRow | null>(null);
  const [addTags, setAddTags] = useState(false);
  const [mediaType, setMediaType] = useState<MediaTypes>();

  const handleChange = (event: SelectChangeEvent) => {
    setMediaType(parseInt(event.target.value) as MediaTypes);
  };

  useEffect(() => {
    if (mediaType) {
      getTags(mediaType);
    }
  }, [getTags, tags, mediaType]);

  const save = async (isNew: boolean) => {
    if (!selectedRow) return;

    const newTag = {
      id: selectedRow.id,
      tag: selectedRow.value,
      mediaTypeId: mediaType,
      communityId: user.admin ? undefined : user.communities?.[0],
    };

    if (isNew) {
      await createTag(newTag);
    } else {
      await updateTag(selectedRow.id, newTag);
    }

    setAddTags(false);
    setSelectedRow(null);
    getTags(mediaType);
  };

  const deleteHandler = async (id: number) => {
    await deleteTag(id);
    setSelectedRow(null);
    getTags(mediaType);
  };

  const getData = () => {
    if (!user) {
      return [];
    }

    if (user?.admin) {
      return tags.length > 0 ? tags : [];
    }

    return tags.filter((tag) => user.communities.some((c) => tag.communityId == c.id));
  };

  return (
    <Layout title={t('tags')}>
      <Grid container>
        <Grid item xs={12} mb={3}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Mediatyyppi</InputLabel>
            <Select
              labelId="select-label"
              id="select"
              value={mediaType?.toString() ?? ''}
              label="Mediatyyppi"
              onChange={handleChange}
            >
              {Object.values(MediaTypes)
                .filter((item) => Number(item) >= 0)
                .map((key) => (
                  <MenuItem key={key} value={key}>
                    {t(`mediatype_${key}`)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        {mediaType && (
          <Grid item xs={12} mb={1}>
            <AddButton onClick={() => setAddTags(!addTags)} text={t('add_tag')} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Table
            tableType={TAG_TABLE}
            data={getData()}
            selectedRow={selectedRow ?? null}
            setSelectedRow={(value: Row | LinkRow | null) => setSelectedRow(value)}
            addNew={addTags}
            onSave={() => save(addTags)}
            onAddNew={() => setAddTags(!addTags)}
            onDelete={(id) => deleteHandler(id)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
});

export default TagsScreen;
